import Nouislider from 'nouislider-react'
import { useState } from 'react'
import { kFormatter, kUnformatterNumber } from '../../../../../../../utils/CurrencyFormatter'

function Investment() {
  const [moneyInWords, setMoneyInWords] = useState<any>()

  const tooltipFns = {
    to(value: number) {
      return kFormatter(value)
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }
  const securityTypes = [
    'Equity',
    'CCPS',
    'CCD',
    'OCD',
    'Note',
    'Warrant',
    'RPS',
    'NCD',
    'OCPS',
    'OCRPS',
    'Options',
  ]
  return (
    <div className='card shadow-sm mt-4 p-8'>
      <div className='row align-items-center'>
        <h3 className='col fw-bold pb-4'>Investment Details</h3>
      </div>
      <div className='row align-items-baseline'>
        <div className='col-6 col-md-4'>
          <h4>Money to be invested</h4>
        </div>
        <div className='col-6 col-md-4'>
          <Nouislider
            tooltips={[tooltipFns]}
            start={250000000}
            range={{ min: 0, max: 1000000000 }}
            connect
            onChange={(e) => setMoneyInWords(e)}
            className=''
            disabled
          />
        </div>
        <div className='col-6 col-md-4'>
          <input
            type='text'
            value={'250000000'}
            className='form-control form-control-md form-control-solid'
            disabled
          />
          <div className='form-text'>Rupees Twenty Five Crores</div>
        </div>
      </div>

      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Add new Investors</h4>
        </div>
        <div className='col-6 col-md-8'>
          <div className='form-check form-switch form-check-custom form-check-solid me-10'>
            <input
              className='form-check-input h-20px w-40px'
              type='checkbox'
              value=''
              readOnly
              checked
            />
            <label className='form-check-label'>Yes</label>
          </div>
        </div>
      </div>

      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Investor Name</h4>
        </div>
        <div className='col-6 col-md-8'>
          <input
            type='text'
            className='form-control form-control-md form-control-solid w-md-50'
            disabled
          />
        </div>
      </div>

      <div className='row align-items-baseline'>
        <div className='col-6 col-md-4'>
          <h4>Investment Amount</h4>
        </div>
        <div className='col-6 col-md-4'>
          <Nouislider
            tooltips={[tooltipFns]}
            start={250000000}
            range={{ min: 0, max: 1000000000 }}
            connect
            onChange={(e) => setMoneyInWords(e)}
            className=''
            disabled
          />
        </div>
        <div className='col-6 col-md-4'>
          <input
            type='text'
            value={'250000000'}
            className='form-control form-control-md form-control-solid'
            disabled
          />
          <div className='form-text'>Rupees Twenty Five Crores</div>
        </div>
      </div>

      <div className='row align-items-center pb-4 pt-6'>
        <div className='col-6 col-md-4'>
          <h4>Select Security</h4>
        </div>

        <div className='row align-items-baseline pt-6'>
          {securityTypes.map((item: string) => (
            <div className='col-6 col-md-4'>
              <label className='d-flex flex-left mb-2 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    name='security'
                    value={item}
                  />
                </span>
                <span className='d-flex align-items-center me-2'>
                  <span className='d-flex flex-column'>
                    <h5 className='ms-4 text-hover-primary'>{item}</h5>
                  </span>
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Investment
