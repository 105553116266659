function ActionMenu(props: any) {
  const options: any[] = []
  if (props.item.size) {
    options.push('Show File')
    if (props.item.source != 'company') options.push('Delete')
  } else if (props.item.count == 0) {
    if (props.item.source != 'company') options.push('Delete Folder')
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
      data-kt-menu='true'
    >
      {options.map((option) => {
        return (
          <label
            key={option}
            className='form-check-custom py-3 bg-hover-primary text-hover-white'
            onClick={() => {
              if (option === 'Delete' || option === 'Delete Folder') {
                props.deleteItem(props.item)
              } else if (option === 'Show File') {
                props.handleClick(props.item)
              }
            }}
          >
            <span className='form-check-label fs-base fw-normal ms-9 cursor-pointer '>
              {option}
            </span>
          </label>
        )
      })}
    </div>
  )
}

export default ActionMenu
