import React from 'react'

function TableHeaderDropdown(props: any) {
  const options = [
    'Investor Name',
    // 'Net profit/loss',
    // 'Headcount',
    // 'Net sales (Forecast)',
    // 'Runway',
    // 'Company report',
  ]
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px py-3'
      data-kt-menu='true'
    >
      {options.map((option) => {
        return (
          <label
            key={option}
            className='ms-3 form-check form-check-sm form-check-custom form-check-solid me-5 px-3 py-3'
          >
            <input
              className='form-check-input'
              type='checkbox'
              value={option}
              onChange={(e) => {
                if (e.currentTarget.value === 'Investor Name') {
                  props.setVisible()
                }
              }}
            />
            <span className='form-check-label fw-bold fs-5 ms-3'>{option}</span>
          </label>
        )
      })}
    </div>
  )
}

export default TableHeaderDropdown
