import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import {
  AddClassificationMutation,
  AddCompaniesToClassificationMutation,
  Investor,
  useAddClassificationMutation,
  useAddCompaniesToClassificationMutation,
  useGetAllClassificationsQuery,
  useGetClassificationQuery,
  useGetTasksOverviewQuery,
  useGetTermsheetsQuery,
  useGetWatchlistHighlightsQuery,
} from '../../../../generated/graphql'
import { globalFilter } from '../../../../utils/array'
import { kFormatter, kUnformatterNumber } from '../../../../utils/CurrencyFormatter'
import { setupInitials } from '../../../../utils/Utils'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../_metronic/helpers'
import { classificationInits, classificationSchema } from './ClassificationGroupHelper'
import Nouislider from 'nouislider-react'
import PortfolioSearchItem from './PortfolioSearchItem'
import { useClassificationFilterStore } from '../../store/ClassificationFilters'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { ClassificationContext } from '../../store/context/classificationContextProvider'
import { ClassificationType } from '../../../../types/Classification'
import { GlobalContext } from '../../store/context/globalContext'
import { Actions, FilterType } from '../../store/context/reducer'
import Toast, { ToastType } from '../../../../utils/toast'

type Props = {
  showModal: boolean
  handleClose: () => void
  isAddCompany?: boolean
  groupName?: string
  groupId?: number
}

function AddClassificationModal({
  groupId,
  groupName,
  isAddCompany,
  handleClose,
  showModal,
}: Props) {
  const defaultGroups = ['Group 1', 'Group 2', 'Group 3', 'Group 4']
  const { allInvestments, investors, groups } = useContext(ClassificationContext)
  const { state, dispatch } = useContext(GlobalContext)
  const { ctxGroupIds, ctxFilterType } = state
  const [maxValRange, setMaxValRange] = useState(1000000000)
  const [maxRaiseRange, setMaxRaiseRange] = useState(1000000000)
  const [valuationSliderRef, setValuationSliderRef] = useState<any>(null)
  const [moneyRaisedSliderRef, setMoneyRaisedSliderRef] = useState<any>(null)
  const industryRef = useRef<any>()
  const roundsRef = useRef<any>()
  const [initVal, setInitVal] = useState(800000000)
  const [companies, setCompanies] = useState<any>([])
  const [fetchedData, setFetchedData] = useState([])
  const [initRaise, setInitRaise] = useState(800000000)
  const [investorList, setInvestorList] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [distinct, setDistinct] = useState<any>([])
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([])
  const [classificationName, setClassificationName] = useState('')
  const [error, setError] = useState('')
  const filter = useClassificationFilterStore()

  const addClassificationMutation = useAddClassificationMutation<AddClassificationMutation, Error>(
    graphqlRequestClient,
    {
      onSuccess: (data) => {
        setSelectedCompanies([])
        Toast('Classification Created Successfully!', ToastType.success)
        handleClose()
        queryClient.invalidateQueries(useGetAllClassificationsQuery.getKey())
        queryClient.invalidateQueries(useGetTasksOverviewQuery.getKey())
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        queryClient.invalidateQueries(useGetWatchlistHighlightsQuery.getKey())
        setClassificationName('')
        const newGroupId = Number(data.addClassification.newList.id)
        let ids = [...ctxGroupIds, newGroupId]
        if (ctxFilterType === FilterType.group) {
          dispatch({ type: Actions.setGroupIds, payload: ids })
        }
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const addCompaniesMutation = useAddCompaniesToClassificationMutation<
    AddCompaniesToClassificationMutation,
    Error
  >(
    graphqlRequestClient,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(useGetAllClassificationsQuery.getKey())
        queryClient.invalidateQueries(useGetClassificationQuery.getKey({ input: { id: groupId } }))
        setSelectedCompanies([])
        Toast('Classification Created Successfully!', ToastType.success)

        handleClose()
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  function handleSubmit(e: any) {
    e.preventDefault()
    e.stopPropagation()
    const mutationData = selectedCompanies.map((company) => {
      return {
        companyName: company,
      }
    })
    if (!isAddCompany) {
      const data = {
        name: classificationName,
        companies: mutationData,
      }

      if (classificationName.length == 0) {
        setError('Please select a classification name')
      } else if (selectedCompanies.length == 0) {
        setError('Please add at least one company to the group')
      } else {
        addClassificationMutation.mutate({ input: data })
      }
    } else {
      const data = {
        id: groupId,
        companies: mutationData,
      }
      addCompaniesMutation.mutate({ input: data })
    }
  }

  const tooltipFns = {
    to(value: number) {
      return kFormatter(value)
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }

  let originalData: any = []

  useEffect(() => {
    originalData = []
    let investorList: any = []
    let maxValuation = 0
    let maxLatestRange = 0
    let initValuation = 0
    let initLatestRaise = 0
    let groupCompanies: string[] = []
    let group = groups?.classifications.find((group: ClassificationType) => group.id === groupId)
    group?.companies.forEach((company: any) => groupCompanies.push(company.companyName))

    investors?.investors.forEach((investor: Investor) => {
      investorList.push({ investorId: investor.id, investorName: investor.name })

      investor.holdings?.forEach((holding: any) => {
        let isChecked = false
        if (isAddCompany) {
          if (groupCompanies.includes(holding?.name)) {
            isChecked = true
          }
        }
        originalData.push({
          investorId: investor.id,
          investorName: investor.name,
          name: holding?.name,
          companyId: holding?.cin,
          investedAmount: holding?.investedAmount,
          holding: holding?.percentage,
          moic: holding?.moic,
          irr: holding?.irr,
          isVerified: holding?.hissaVerified,
          industry: holding?.industry,
          noOfRounds: null,
          latestRaise: null,
          totalShares: null,
          totalFunding: null,
          valuation: null,
          logo: null,
          business: null,
          isExited: holding?.isExited,
          checked: isChecked,
        })
      })
    })

    originalData.sort((a: any, b: any) => a.name.localeCompare(b.name))
    const initialsMap = setupInitials(originalData)
    originalData.forEach((holding: any) => {
      let index: any = -1
      index = allInvestments?.allInvestments?.findIndex((company: any) => {
        return (
          holding.name === company.companyData.name && holding.companyId === company.companyData.cin
        )
      })
      if (index > -1) {
        const invRecords = allInvestments?.allInvestments![index]
        holding.investments = invRecords.investmentData.filter(
          (i: any) => i.name.toLowerCase() === holding.investorName.toLowerCase()
        )
        const companyData = allInvestments?.allInvestments![index].companyData
        const initColours = initialsMap.get(holding.name)
        holding.initials = initColours.initials
        holding.initIndex = initColours.count
        holding.noOfRounds = companyData.noOfRounds || holding.investments.length
        holding.latestRound = companyData.latestRound || ''
        holding.founderHolding = companyData.founderHolding || 0
        holding.coInvestors = companyData.coInvestors || ''
        holding.esopHolding = companyData.esopHolding || 0
        holding.founded = companyData.founded || ''
        holding.latestRaise = companyData.latestRaise || 0
        holding.latestRaiseDate = companyData.latestRaiseDate || ''
        holding.totalShares = companyData.totalShares || 0
        holding.totalFunding = companyData.totalFunding || 0
        holding.valuation = companyData.valuation || 0
        holding.logo = companyData.logo
        holding.incorporationDate = companyData.incorporationDate || ''
        holding.business =
          companyData.business === undefined || companyData.business === 'null'
            ? 'Others'
            : companyData.business
        if (holding.valuation > maxValuation) {
          maxValuation = holding.valuation
        } else {
          maxValuation = 1000000000
        }
        if (holding.latestRaise > maxLatestRange) {
          maxLatestRange = holding.latestRaise
        } else {
          maxLatestRange = 1000000000
        }
      }
      if (holding.isExited) {
        const initColours = initialsMap.get(holding.name)

        holding.initials = initColours.initials
        holding.initIndex = initColours.count
      }
    })
    initValuation = maxValuation - (maxValuation / 100) * 20
    initLatestRaise = maxLatestRange - (maxLatestRange / 100) * 20
    setCompanies(originalData)
    setFetchedData(originalData)
    setInvestorList(investorList)
    setMaxValRange(maxValuation)
    setMaxRaiseRange(maxLatestRange)
    setInitVal(initValuation)
    setInitRaise(initLatestRaise)
    return () => {
      companies.forEach((company: any) => (company.checked = false))
      setCompanies(companies)
      setSelectedCompanies([])
      setError('')
    }
  }, [investors, allInvestments, groups])

  useEffect(() => {
    if (!companies) setDistinct([])
    const result = globalFilter(companies, filter.searchText, ['name'])
      .filter(
        (company: any) =>
          !filter.industry ||
          company.industry === filter.industry ||
          (company.industry === null && filter.industry === 'Others')
      )
      .filter(
        (company: any) => !filter.isVerified || company.isVerified.toString() === filter.isVerified
      )
      .filter((company: any) => !filter.investorId || company.investorId === filter.investorId)
      .filter(
        (company: any) =>
          !filter.roundName ||
          company.investments?.some((investment: any) => {
            if (investment.roundName === filter.roundName) {
              return company
            }
          })
      )
      .filter((company: any) => {
        return filter.minValuation === 0 || company.valuation >= filter.minValuation!
      })
      .filter((company: any) => {
        return filter.maxValuation === 0 || company.valuation <= filter.maxValuation!
      })
      .filter((company: any) => {
        return filter.minLatestRaise === 0 || company.latestRaise >= filter.minLatestRaise!
      })
      .filter((company: any) => {
        return filter.maxLatestRaise === 0 || company.latestRaise <= filter.maxLatestRaise!
      })

    // for removing duplicate items from result
    const result1 = result.filter((company, index) => {
      let i = result.findIndex((item) => item.name == company.name)
      return i == index
    })
    setDistinct(result1)
    // return result
  }, [companies, filter])

  const searchResults = distinct?.map((company: any, index: number) => (
    <span className='d-flex' key={`${index}-${company.name}`}>
      {/* <div className='mx-4 form-check form-check-custom form-check-solid me-5'>
        <input
          className='form-check-input'
          type='checkbox'
          key={Math.random()}
          // checked={company.checked}
          defaultChecked={company.checked}
          value={company.checked}
          onChange={(e) => {
            handleChecked(company)
          }}
        />
      </div> */}
      <PortfolioSearchItem
        key={index}
        companyName={company.name}
        companyId={company.companyId}
        companyLogo={company.logo}
        pps={company.totalShares}
        valuation={company.valuation}
        latestRaise={company.latestRaise}
        noOfRounds={company.noOfRounds}
        totalFunding={company.totalFunding}
        isOddRow={index % 2 == 0 ? false : true}
        business={company.business}
        industry={company.industry}
        isHissaVerified={company.isVerified}
        isChecked={company.checked}
        handleChecked={() => handleChecked(company)}
        isAddButton={true}
      />
    </span>
  ))

  function buildFundDropdown() {
    let dropdown: any = []
    dropdown.push({ name: 'All Funds', id: '0' })
    investors?.investors.forEach((investor: Investor) => {
      dropdown.push({ name: investor.name, id: investor.id })
    })
    return dropdown
  }

  function buildRoundDropdown() {
    let dropdown: any = []
    dropdown.push('Select an option')
    companies.forEach((company: any) => {
      company.investments?.forEach((investment: any) => {
        if (!dropdown.includes(investment.roundName)) {
          dropdown.push(investment.roundName)
        }
      })
    })
    return dropdown
  }

  function buildIndustryDropdown() {
    let dropdown: any = []
    dropdown.push('Select an option')
    fetchedData.forEach((company: any) => {
      if (company.industry === null) {
        if (!dropdown.includes('Others')) {
          dropdown.push('Others')
        }
      } else if (!dropdown.includes(company.industry)) {
        dropdown.push(company.industry)
      }
    })
    return dropdown
  }

  function handleChecked(company: any) {
    let index = companies.findIndex((item: any) => company.name == item.name)
    if (index > -1) {
      let list = companies
      if (company.checked) {
        let listIndex = selectedCompanies.findIndex((item) => company.name === item)
        let list = selectedCompanies.splice(listIndex, 1)
        setSelectedCompanies(list)
      } else if (!company.checked && !selectedCompanies.includes(company.name)) {
        let list = selectedCompanies
        list.push(company.name)
        setSelectedCompanies(list)
      }
      list[index].checked = !list[index].checked
      setCompanies(list)
    }
  }

  function clearFilters() {
    filter.reset()
    valuationSliderRef.noUiSlider.set([0, initVal])
    moneyRaisedSliderRef.noUiSlider.set([0, initRaise])
    industryRef.current!.value = ''
    roundsRef.current!.value = ''
  }

  return (
    <span
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Modal
        id='kt_modal_add_inv'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
        show={showModal}
        onHide={() => {
          handleClose()
        }}
      >
        <div className='col d-flex justify-content-between align-items-center mt-8 mx-8'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            {!isAddCompany ? 'Add Classification' : `Add Companies to ${groupName}`}
          </h2>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleClose()
            }}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </div>
        <div className='separator'></div>
        <Modal.Title className=''>
          <div className='card p-10 mx-6'>
            <Formik
              validationSchema={classificationSchema}
              initialValues={classificationInits}
              onSubmit={handleSubmit}
              validateOnMount={true}
            >
              {({ values, errors, setFieldValue, handleChange }) => {
                return (
                  <>
                    <Form className='' id='add_classification_form'>
                      <div className='row mb-4'>
                        <div className='col mb-4'>
                          <h4>Name of Classification</h4>

                          {!isAddCompany ? (
                            <Field
                              type='text'
                              name='groupName'
                              value={classificationName}
                              onChange={(e: any) => {
                                handleChange(e)
                                setClassificationName(e.target.value)
                              }}
                              className='form-control form-control-lg form-control-solid'
                            />
                          ) : (
                            <Field
                              type='text'
                              name='groupName'
                              value={groupName}
                              disabled
                              className='form-control form-control-lg form-control-solid'
                            />
                          )}
                          <div className='text-danger fs-7 my-4'>
                            <ErrorMessage name='groupName' className=' fs-7 text-danger' />
                          </div>

                          <div>
                            {!isAddCompany &&
                              defaultGroups.map((group, index) => (
                                <span
                                  key={index}
                                  className={`cursor-pointer badge badge-primary fs-7 fw-semibold mb-1 me-2`}
                                  onClick={(e) => {
                                    setFieldValue('groupName', group)
                                    setClassificationName(group)
                                  }}
                                  style={{
                                    background: getCSSVariableValue('--kt-light'),
                                    color: getCSSVariableValue('--kt-primary'),
                                    border: `1px solid ${getCSSVariableValue('--kt-primary')}`,
                                  }}
                                >
                                  {group}
                                </span>
                              ))}
                          </div>
                        </div>
                        <div className='col mb-4'>
                          <h4>Select the Funds</h4>
                          <select
                            name='investorId'
                            className='form-select form-select-lg form-select-solid'
                            onChange={(e: any) => {
                              filter.setInvestorId(e.target.value)
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                            }}
                          >
                            {buildFundDropdown().map((dropdown: any, index: number) => {
                              if (dropdown.id === '0') {
                                return (
                                  <option
                                    value=''
                                    key={index}
                                    className='text-gray-heading fw-bold'
                                  >
                                    {dropdown.name}
                                  </option>
                                )
                              }
                              return (
                                <option
                                  value={dropdown.id}
                                  key={index}
                                  className='text-gray-heading fw-bold'
                                >
                                  {dropdown.name}
                                </option>
                              )
                            })}
                          </select>
                          <div className='text-danger fs-7 my-4'>
                            <ErrorMessage
                              name='investorId'
                              className='fs-7 text-primary bg-primary'
                            />
                          </div>
                        </div>
                        <div className='col'></div>
                      </div>
                    </Form>
                    <div className='row'>
                      <div className='col'>
                        <div className='col mb-4'>
                          <h4>Add Companies</h4>
                        </div>
                        <div className='d-flex col-8'>
                          <input
                            type='text'
                            className='form-control form-control-lg w-80 form-control-solid me-2 mb-4'
                            name='search'
                            placeholder='Search by Company Name'
                            onChange={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              filter.setSearchText(e.target.value)
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                            }}
                          />
                          <button
                            className='btn btn-lg btn-icon btn-secondary me-2 w-65px'
                            onClick={() => setShowFilter(!showFilter)}
                          >
                            <span className='svg-icon svg-icon-2 text-center'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen031.svg'
                                className='svg-icon-2'
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }}
            </Formik>
          </div>

          {showFilter && (
            <div className='card shadow-sm mx-6 p-10 mb-6'>
              <div className='col d-flex justify-content-between'>
                <div className='card-title'>Filter By</div>
                <button className='btn btn-primary fw-bold' onClick={clearFilters}>
                  Clear
                </button>
              </div>
              <div className=''>
                <div className='row'>
                  <div className='col'>
                    <span className='d-flex align-items-baseline'>
                      <p className='fs-base me-4'>Industry</p>
                      <select
                        ref={industryRef}
                        className='form-select form-select-solid w-75'
                        onChange={(e) => {
                          filter.setIndustry(e.target.value)
                        }}
                      >
                        {buildIndustryDropdown().map((dropdown: any, index: number) => {
                          if (dropdown === 'Select an option')
                            return (
                              <option key={index} value='' className='text-gray-heading fw-bold'>
                                {dropdown}
                              </option>
                            )
                          return (
                            <option
                              key={index}
                              value={dropdown}
                              className='text-gray-heading fw-bold'
                            >
                              {dropdown}
                            </option>
                          )
                        })}
                      </select>
                    </span>
                  </div>
                  <div className='col'>
                    <span className='d-flex align-items-baseline w-75'>
                      <p className='fs-base me-4'>Rounds</p>

                      <select
                        ref={roundsRef}
                        className='form-select form-select-solid'
                        aria-label='Select example'
                        onChange={(e) => {
                          filter.setRoundName(e.target.value)
                        }}
                      >
                        {buildRoundDropdown().map((dropdown: any, index: number) => {
                          if (dropdown === 'Select an option')
                            return (
                              <option value='' key={index} className='text-gray-heading fw-bold'>
                                {dropdown}
                              </option>
                            )
                          return (
                            <option
                              key={index}
                              value={dropdown}
                              className='text-gray-heading fw-bold'
                            >
                              {dropdown.charAt(0).toUpperCase() + dropdown.slice(1)}
                            </option>
                          )
                        })}
                      </select>
                    </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col align-content-center'>
                    <span className='d-flex align-items-baseline'>
                      <p className='fs-base me-4'>Valuation</p>

                      <Nouislider
                        key={'valuationSlider'}
                        instanceRef={(instance) => {
                          if (instance && !valuationSliderRef) {
                            setValuationSliderRef(instance)
                          }
                        }}
                        tooltips={[tooltipFns, tooltipFns]}
                        start={[0, 800000000]}
                        range={{ min: 0, max: maxValRange }}
                        connect
                        onChange={(value) => {
                          filter.setMinValuation(parseFloat(value[0]))
                          filter.setMaxValuation(parseFloat(value[1]))
                        }}
                        className='w-75'
                      />
                    </span>
                  </div>
                  <div className='col align-content-center'>
                    <span className='d-flex align-items-baseline'>
                      <p className='fs-base me-4'>Money Raised</p>

                      <Nouislider
                        key={'moneyRaisedSlider'}
                        instanceRef={(instance) => {
                          if (instance && !moneyRaisedSliderRef) {
                            setMoneyRaisedSliderRef(instance)
                          }
                        }}
                        tooltips={[tooltipFns, tooltipFns]}
                        start={[0, 800000000]}
                        range={{ min: 0, max: maxRaiseRange }}
                        connect
                        onChange={(value) => {
                          filter.setMinLatestRaise(parseFloat(value[0]))
                          filter.setMaxLatestRaise(parseFloat(value[1]))
                        }}
                        className='w-75'
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='card mx-6 min-h-500px mh-500px py-5 overflow-auto scrollable'>
            {searchResults}
          </div>
        </Modal.Title>
        <Modal.Footer>
          {!isAddCompany ? (
            <div className='col d-flex justify-content-end align-items-baseline'>
              {(addClassificationMutation.isError || error.length > 0) && (
                <span className='fs-7 text-danger me-10'>{error}</span>
              )}
              <button
                type='submit'
                onClick={handleSubmit}
                className='btn btn-lg btn-primary mt-3'
                disabled={addClassificationMutation.isLoading}
              >
                {addClassificationMutation.isLoading ? (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Adding...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  'Done'
                )}
              </button>
            </div>
          ) : (
            <div className='col d-flex justify-content-end align-items-baseline'>
              {(addCompaniesMutation.isError || error.length > 0) && (
                <span className='fs-7 text-danger me-10'>{error}</span>
              )}
              <button
                type='submit'
                onClick={handleSubmit}
                className='btn btn-lg btn-primary mt-3'
                disabled={addCompaniesMutation.isLoading}
              >
                {addCompaniesMutation.isLoading ? (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Adding...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  'Done'
                )}
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </span>
  )
}

export default AddClassificationModal
