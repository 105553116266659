import { create } from 'zustand'
export type TermsheetBuilderState = {
  sections: string[]
  setSections: (text: string[]) => void
  addSection: (text: string) => void
  removeSection: (text: string) => void
  containsSection: (text: string) => boolean
  reset: () => void
}

const initialState = {
  sections: [],
}

const useStore = create<TermsheetBuilderState>((set, get) => ({
  ...initialState,
  setSections: (text) => {
    set((state) => ({ ...state, sections: text }))
  },
  addSection: (text) => {
    set((state) => {
      if (!state.sections.includes(text)) {
        return { ...state, sections: [...state.sections, text] }
      }
      return { ...state }
    })
  },
  removeSection: (text) => {
    set((state) => ({
      ...state,
      sections: [...state.sections.filter((section) => section !== text)],
    }))
  },
  containsSection: (text) => {
    return get().sections.includes(text)
  },
  reset: () => {
    set(() => initialState)
  },
}))

export const useTermsheetBuilderStore = useStore
