import { useEffect, useRef, useState } from 'react'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { Formik, Form, FormikErrors, FormikHelpers } from 'formik'
import {
  ISendTermsheet,
  sendTermsheetSchemas,
  inits,
  additionalSectionSchemas,
} from './SendTermsheetHelper'
import {
  GetInvestorsQuery,
  Investor,
  SendNewTermsheetMutation,
  SendNewTermsheetMutationVariables,
  useGetInvestorsQuery,
  useGetTermsheetsQuery,
  useSendNewTermsheetMutation,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import jwtDecode from 'jwt-decode'
import { getAuth, JwtPayload } from '../../../../modules/auth'
import { formatDate } from '../../../../../utils/FormatDate'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import axios from 'axios'
import InvestorRights from './steps/additional/InvestorRights'
import FounderRestrictions from './steps/additional/FounderRestrictions'
import ExitRights from './steps/mandatory/ExitRights'
import Miscellaneous from './steps/additional/Miscellaneous'
import GovernanceRights from './steps/mandatory/GovernanceRights'
import InvestmentDetails from './steps/mandatory/InvestmentDetails'
import RoundDetails from './steps/mandatory/RoundDetails'
import TransferRestrictions from './steps/mandatory/TransferRestrictions'
import Validity from './steps/mandatory/Validity'
import ValuationDetails from './steps/mandatory/ValuationDetails'
import Preview from './steps/mandatory/Preview'
import LiquidationPreference from './steps/mandatory/LiquidationPreference'
import Toast, { ToastType } from '../../../../../utils/toast'

type CompanyDetail = {
  investorList: any[]
  name?: string
  companyId?: string
}

type Props = {
  allowedSections?: string[]
  wizardRef?: any
  templateId?: number
  initialValues?: any
  draftTermsheetId?: number
}

type StepperErrors = {
  roundDetails: boolean
  valuation: boolean
  investment: boolean
  boardMatters?: boolean
  liquidityPreference?: boolean
  transferRestrictions: boolean
  validity: boolean
  investorRights?: boolean
  founderRestrictions?: boolean
  exitRights?: boolean
  miscellaneous?: boolean
}

const SendTermsheetWizard = ({
  allowedSections,
  wizardRef,
  templateId,
  initialValues,
  draftTermsheetId,
}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const newSchema = [...sendTermsheetSchemas.slice(0, -1)]
  let additionalStepperContent: any[] = []
  let additionalStepperHeaders: any[] = []
  let initialStepperErrors: StepperErrors = {
    roundDetails: false,
    valuation: false,
    investment: false,
    liquidityPreference: false,
    transferRestrictions: false,
    validity: false,
  }
  const [stepperErrors, setStepperErrors] = useState<StepperErrors>(initialStepperErrors)
  const [termsheetId, setTermsheetId] = useState<any>()
  const navigate = useNavigate()
  useEffect(() => {
    const unloadCallback = (event: { preventDefault: () => void; returnValue: string }) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }
    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [])
  allowedSections?.forEach((section, index) => {
    let stepCount = index + 8
    newSchema.push(additionalSectionSchemas.get(section))
    if (section === 'Investor Rights') {
      additionalStepperContent.push(
        <div data-kt-stepper-element='content'>
          <InvestorRights />
        </div>
      )
      additionalStepperHeaders.push(
        <div className='stepper-item' data-kt-stepper-element='nav'>
          <div className='stepper-wrapper cursor-pointer'>
            <div className='stepper-icon w-40px h-40px'>
              {stepperErrors.investorRights ? (
                <i className='stepper-check fas fa-exclamation'></i>
              ) : (
                <i className='stepper-check fas fa-check'></i>
              )}{' '}
              <span className='stepper-number'>{stepCount}</span>
            </div>

            <div
              className='stepper-label'
              onClick={() => {
                goTo(stepCount)
              }}
            >
              <h3 className='stepper-title'>{section}</h3>
              <div className='stepper-desc fw-semibold'>Enter Your {section} Details</div>
            </div>
          </div>
          <div className='stepper-line h-40px'></div>
        </div>
      )
      initialStepperErrors.investorRights = false
    } else if (section === 'Founder Restrictions') {
      additionalStepperContent.push(
        <div data-kt-stepper-element='content'>
          <FounderRestrictions />
        </div>
      )
      additionalStepperHeaders.push(
        <div className='stepper-item' data-kt-stepper-element='nav'>
          <div className='stepper-wrapper cursor-pointer'>
            <div className='stepper-icon w-40px h-40px'>
              {stepperErrors.founderRestrictions ? (
                <i className='stepper-check fas fa-exclamation'></i>
              ) : (
                <i className='stepper-check fas fa-check'></i>
              )}{' '}
              <span className='stepper-number'>{stepCount}</span>
            </div>

            <div
              className='stepper-label'
              onClick={() => {
                goTo(stepCount)
              }}
            >
              <h3 className='stepper-title'>{section}</h3>
              <div className='stepper-desc fw-semibold'>Enter Your {section} Details</div>
            </div>
          </div>
          <div className='stepper-line h-40px'></div>
        </div>
      )
      initialStepperErrors.founderRestrictions = false
    } else if (section === 'Miscellaneous') {
      additionalStepperContent.push(
        <div data-kt-stepper-element='content'>
          <Miscellaneous />
        </div>
      )
      additionalStepperHeaders.push(
        <div className='stepper-item' data-kt-stepper-element='nav'>
          <div className='stepper-wrapper cursor-pointer'>
            <div className='stepper-icon w-40px h-40px'>
              {stepperErrors.miscellaneous ? (
                <i className='stepper-check fas fa-exclamation'></i>
              ) : (
                <i className='stepper-check fas fa-check'></i>
              )}
              <span className='stepper-number'>{stepCount}</span>
            </div>

            <div
              className='stepper-label'
              onClick={() => {
                goTo(stepCount)
              }}
            >
              <h3 className='stepper-title'>{section}</h3>
              <div className='stepper-desc fw-semibold'>Enter Your {section} Details</div>
            </div>
          </div>
          <div className='stepper-line h-40px'></div>
        </div>
      )
      initialStepperErrors.miscellaneous = false
    }
  })

  newSchema.push(sendTermsheetSchemas.slice(-1)[0])
  const location = useLocation()
  const name = (location.state as CompanyDetail)?.name
  const companyId = (location.state as CompanyDetail)?.companyId
  const [companyObj, setCompanyObj] = useState<any>()
  const submitBtnRef = useRef<HTMLButtonElement | null>(null)
  const [currentSchema, setCurrentSchema] = useState<any>(newSchema[0])
  const initValues = initialValues ? initialValues : inits
  const [loading, setLoading] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<any>()
  const auth = getAuth()!
  const jwt = jwtDecode<JwtPayload>(auth)
  let date = new Date()
  let minDate = date.toISOString().split('T')[0]

  useEffect(() => {
    if (draftTermsheetId) setTermsheetId(draftTermsheetId)
  }, [draftTermsheetId])

  const { data } = useGetInvestorsQuery<GetInvestorsQuery, Error>(graphqlRequestClient, {}, {})

  const investorList = data?.investors.map((investor: Investor) => {
    return {
      investorId: investor.id,
      investorName: investor.name,
    }
  })

  const usePreviewTermsheetMutation = useMutation(
    ['previewTermsheet'],
    (object: any) => axios.post(`${process.env.REACT_APP_API_URL}/termsheetPreview`, object),
    {
      onSuccess: (data) => {
        setPreviewUrl(data.data.resultCode)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    }
  )

  const mutateSendTermsheet = useSendNewTermsheetMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SendNewTermsheetMutation,
        _variables: SendNewTermsheetMutationVariables,
        _context: unknown
      ) => {
        setLoading(false)
        submitBtnRef.current?.removeAttribute('data-kt-indicator')
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        if (!termsheetId) setTermsheetId(data.addTermsheet.id!)
        Toast('Termsheet Sent Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const mutateSendDraftTermsheet = useSendNewTermsheetMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SendNewTermsheetMutation,
        _variables: SendNewTermsheetMutationVariables,
        _context: unknown
      ) => {
        setLoading(false)
        // submitBtnRef.current?.removeAttribute('data-kt-indicator')
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        if (!termsheetId) setTermsheetId(data.addTermsheet.termsheetId!)
        // return console.log('termsheet sent successfully', data)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const handleSendTermsheet = (termsheet: any) => {
    setLoading(true)
    submitBtnRef.current?.setAttribute('data-kt-indicator', 'on')

    mutateSendTermsheet.mutate({ input: termsheet, sendImmediately: true })
  }
  const handleSendDraftTermsheet = (termsheet: any) => {
    mutateSendDraftTermsheet.mutate({ input: termsheet, sendImmediately: false })
  }

  const getTermsheetData = (values: ISendTermsheet) => {
    let d = new Date()
    let date = values.validity.split('-')
    d.setFullYear(parseInt(date[0]))
    d.setMonth(parseInt(date[1]) - 1)
    d.setDate(parseInt(date[2]))
    let diff: number = d.getTime() - new Date().getTime()
    diff = Math.ceil(Math.abs(diff) / (1000 * 60 * 60 * 24))

    const termsheetData: any = {
      investorId: parseInt(values.investorId.toString()),
      templateId: templateId && templateId != 10000000 ? parseInt(templateId.toString()) : null,
      termsheetName: values.termsheetName.toString(),
      // companyName: '63IDEAS INFOLABS PRIVATE LIMITED',
      // companyCin: 'U72200KA2015PTC081330',
      companyName: name ?? companyObj.companyName,
      companyCin: companyId ?? companyObj.companyId,
      roundSize: Math.round(Number(values.roundSize)).toString(),
      valuation:
        values.isPricedRound === 'false'
          ? Math.round(Number(values.floorValuation))!.toString()
          : Math.round(Number(values.valuation)).toString(),
      expiryDate: formatDate(values.validity, 'dd/MM/yyyy'),
      amountInvested: Math.round(values.investmentAmount),
      mandatorySections: {
        roundDetails: {
          roundName: values.roundName,
          roundSize: Math.round(Number(values.roundSize)).toString(),
          isPricedRound: values.isPricedRound === 'true' ? true : false,
        },
        valuation: {
          valuation:
            values.isPricedRound === 'false'
              ? Math.round(Number(values.floorValuation))!.toString()
              : Math.round(Number(values.valuation)).toString(),
          postRoundEsopPercentage:
            values.isPricedRound == 'true' && values.autoDilute === false
              ? values.postRoundEsopPercentage
              : '',
          discount: values.isPricedRound == 'false' ? values.discountConvertible : '',
          autoDilute: values.autoDilute,
          valuationCap:
            values.isPricedRound === 'false'
              ? Math.round(Number(values.valuationCap))!.toString()
              : '',
        },
        investment: {
          investedAmount: Math.round(values.investmentAmount),
          remainingAmount: values.remainingAmount,
          investorList: values.investorList,
          security: values.security,
        },
        liquidationPreference: {
          investmentRank: values.investmentRank,
          pariPassu: values.isPariPassu,
          investmentMultiple: values.investmentMultiple,
          participation: values.isParticipation,
          participationCap: values.isParticipation === true ? values.participationCap : '',
        },
        transferRestrictions: {
          dragAlong: values.dar,
          tagAlong: values.tar,
          rightOfFR: values.rofr,
          rightOfFO: values.rofo,
        },
        governanceRights: {
          directorBoardComposition: parseInt(values.directorBoardComposition),
          observerBoardComposition: parseInt(values.observerBoardComposition),
          quorumRights: values.quorumRights,
        },
        exitRights: {
          rights: values.exitRights,
          consequences: values.consequences,
        },
        validity: {
          expiryDate: formatDate(values.validity, 'dd/MM/yyyy'),
          expiryDays: diff,
        },
      },
      additionalSections: {},
    }

    allowedSections?.forEach((section) => {
      if (section === 'Investor Rights') {
        termsheetData.additionalSections = {
          ...termsheetData.additionalSections,
          investorRights: {
            rights: values.investorRights,
            antiDilution: values.antiDilution,
          },
        }
      } else if (section === 'Founder Restrictions') {
        termsheetData.additionalSections = {
          ...termsheetData.additionalSections,
          founderRestrictions: {
            founderVesting:
              values.founderVesting === 'Custom'
                ? values.customFounderVesting
                : values.founderVesting,
            founderLockin: values.founderLockin,
          },
        }
      } else if (section === 'Miscellaneous') {
        termsheetData.additionalSections = {
          ...termsheetData.additionalSections,
          miscellaneous: {
            dueDiligence: values.diligence === true ? true : false,
            exclusivity: values.exclusivity,
            transactionCost: values.transactionCost,
            country: values.country,
          },
        }
      }
    })

    return termsheetData
  }

  const getTermsheetDraftData = (values: ISendTermsheet) => {
    const termsheetData: any = {
      investorId: parseInt(values.investorId.toString()),
      templateId: templateId && templateId != 10000000 ? parseInt(templateId.toString()) : null,
      termsheetName: values.termsheetName,
      // companyName: '63IDEAS INFOLABS PRIVATE LIMITED',
      // companyCin: 'U72200KA2015PTC081330',
      companyName: name ?? companyObj.companyName,
      companyCin: companyId ?? companyObj.companyId,
      roundSize: Math.round(Number(values.roundSize)).toString(),
      valuation:
        values.isPricedRound === 'false'
          ? Math.round(Number(values.floorValuation))!.toString()
          : Math.round(Number(values.valuation)).toString(),
      expiryDate:
        values.validity && values.validity !== '' ? formatDate(values.validity, 'dd/MM/yyyy') : '',
      amountInvested: Math.round(values.investmentAmount),
      mandatorySections: {
        roundDetails: {
          roundName: values.roundName,
          roundSize: Math.round(Number(values.roundSize)).toString(),
          isPricedRound: values.isPricedRound === 'true' ? true : false,
        },
        valuation: {
          valuation:
            values.isPricedRound === 'false'
              ? Math.round(Number(values.floorValuation))!.toString()
              : Math.round(Number(values.valuation)).toString(),
          postRoundEsopPercentage:
            values.isPricedRound == 'true' && values.autoDilute === false
              ? values.postRoundEsopPercentage
              : '',
          discount: values.isPricedRound == 'false' ? values.discountConvertible : '',
          autoDilute: values.autoDilute,
          valuationCap:
            values.isPricedRound === 'false'
              ? Math.round(Number(values.valuationCap))!.toString()
              : '',
        },
        investment: {
          investedAmount: Math.round(values.investmentAmount),
          remainingAmount: values.remainingAmount,
          investorList: values.investorList,
          security: values.security,
        },
        liquidationPreference: {
          investmentRank: values.investmentRank,
          pariPassu: values.isPariPassu,
          investmentMultiple: values.investmentMultiple,
          participation: values.isParticipation,
          participationCap: values.isParticipation === true ? values.participationCap : 0,
        },
        transferRestrictions: {
          dragAlong: values.dar,
          tagAlong: values.tar,
          rightOfFR: values.rofr,
          rightOfFO: values.rofo,
        },
        governanceRights: {
          directorBoardComposition: parseInt(values.directorBoardComposition),
          observerBoardComposition: parseInt(values.observerBoardComposition),
          quorumRights: values.quorumRights,
        },
        exitRights: {
          rights: values.exitRights,
          consequences: values.consequences,
        },
        validity: {
          expiryDate:
            values.validity && values.validity !== ''
              ? formatDate(values.validity, 'dd/MM/yyyy')
              : '',
        },
      },
      additionalSections: {},
    }

    allowedSections?.forEach((section) => {
      if (section === 'Investor Rights') {
        termsheetData.additionalSections = {
          ...termsheetData.additionalSections,
          investorRights: {
            rights: values.investorRights,
            antiDilution: values.antiDilution,
          },
        }
      } else if (section === 'Founder Restrictions') {
        termsheetData.additionalSections = {
          ...termsheetData.additionalSections,
          founderRestrictions: {
            founderVesting:
              values.founderVesting === 'Custom'
                ? values.customFounderVesting
                : values.founderVesting,
            founderLockin: values.founderLockin,
          },
        }
      } else if (section === 'Miscellaneous') {
        termsheetData.additionalSections = {
          ...termsheetData.additionalSections,
          miscellaneous: {
            dueDiligence: values.diligence === true ? true : false,
            exclusivity: values.exclusivity,
            transactionCost: values.transactionCost,
            country: values.country,
          },
        }
      }
    })

    return termsheetData
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const goTo = (step: number) => {
    if (!stepper.current) {
      return
    }

    stepper.current.goto(step)
    setCurrentSchema(newSchema[stepper.current.currentStepIndex - 1])
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(newSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ISendTermsheet, formikHelpers: FormikHelpers<ISendTermsheet>) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(newSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 1) {
        const termsheet = getTermsheetData(values)
        setPreviewUrl('')

        const variablesList = [
          {
            variableName: 'roundSize',
            variableValue: Math.round(termsheet.roundSize).toString(),
          },
          {
            variableName: 'isPricedRound',
            variableValue: values.isPricedRound == 'true' ? 'Yes' : 'No',
          },
          {
            variableName: values.isPricedRound == 'true' ? 'postRoundEsopPercentage' : 'discount',
            variableValue:
              values.isPricedRound == 'true'
                ? values.postRoundEsopPercentage.toString()
                : values.discountConvertible.toString(),
          },
          {
            variableName: 'autoDilute',
            variableValue: values.autoDilute.toString(),
          },
          {
            variableName: 'valuation',
            variableValue: termsheet.valuation.toString(),
          },
          {
            variableName: 'expiryDate',
            variableValue: termsheet.expiryDate,
          },
          {
            variableName: 'expiryDays',
            variableValue: termsheet.mandatorySections.validity.expiryDays.toString(),
          },
          {
            variableName: 'investorId',
            variableValue: termsheet.investorId.toString(),
          },
          {
            variableName: 'companyName',
            variableValue: termsheet.companyName,
          },
          {
            variableName: 'companyCin',
            variableValue: termsheet.companyCin,
          },
          {
            variableName: 'investmentAmount',
            variableValue: termsheet.amountInvested.toString(),
          },
          {
            variableName: 'moneyRemaining',
            variableValue: termsheet.mandatorySections.investment.remainingAmount,
          },
          {
            variableName: 'newInvestorList',
            variableValue: termsheet.mandatorySections.investment.investorList.toString(),
          },
          {
            variableName: 'securities',
            variableValue: termsheet.mandatorySections.investment.security.toString(),
          },
          {
            variableName: 'investmentRank',
            variableValue: termsheet.mandatorySections.liquidationPreference.investmentRank,
          },
          {
            variableName: 'isPariPassu',
            variableValue:
              termsheet.mandatorySections.liquidationPreference.pariPassu == 'true' ? 'Yes' : 'No',
          },
          {
            variableName: 'investmentMultiple',
            variableValue: termsheet.mandatorySections.liquidationPreference.investmentMultiple,
          },
          {
            variableName: 'participationCap',
            variableValue: termsheet.mandatorySections.liquidationPreference.participationCap,
          },
          {
            variableName: 'dragAlong',
            variableValue: termsheet.mandatorySections.transferRestrictions.dragAlong,
          },
          {
            variableName: 'tagAlong',
            variableValue: termsheet.mandatorySections.transferRestrictions.tagAlong,
          },
          {
            variableName: 'rightOfFR',
            variableValue: termsheet.mandatorySections.transferRestrictions.rightOfFR,
          },
          {
            variableName: 'rightOfFO',
            variableValue: termsheet.mandatorySections.transferRestrictions.rightOfFO,
          },
          {
            variableName: 'amountCommited',
            variableValue: termsheet.amountInvested.toString(),
          },
          {
            variableName: 'emailId',
            variableValue: jwt.emailId,
          },
          {
            variableName: 'ValuationType',
            variableValue:
              termsheet.mandatorySections.roundDetails.isPricedRound == 'true'
                ? 'Pre-Money'
                : 'Floor',
          },
          {
            variableName: 'directorBoardComposition',
            variableValue:
              termsheet.mandatorySections.governanceRights?.directorBoardComposition.toString(),
          },
          {
            variableName: 'observerBoardComposition',
            variableValue:
              termsheet.mandatorySections.governanceRights?.observerBoardComposition.toString(),
          },
          {
            variableName: 'quorumRights',
            variableValue: termsheet.mandatorySections.governanceRights?.quorumRights.toString(),
          },
        ]

        usePreviewTermsheetMutation.mutate({
          companyName: termsheet.companyName,
          templateName: 'Termsheet',
          contextVariables: [],
          variablesList: variablesList,
          templateId: Number(templateId),
        })
        stepper.current.goNext()
      }
      const termsheet = getTermsheetDraftData(values)
      termsheet.status = 'Draft'
      if (termsheetId) {
        termsheet.termsheetId = termsheetId
      }
      handleSendDraftTermsheet(termsheet)
      stepper.current.goNext()
    } else {
      const termsheet = getTermsheetData(values)
      if (termsheetId) {
        termsheet.termsheetId = termsheetId
      }
      handleSendTermsheet(termsheet)
    }
  }

  const reloadStepper = () => {
    if (StepperComponent.hasInstace(stepperRef.current as HTMLDivElement))
      StepperComponent.destroy(stepperRef.current as HTMLDivElement)
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    reloadStepper()
  }, [allowedSections])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const handleStepperErrors = (errors: FormikErrors<ISendTermsheet>) => {
    if (errors.investorId || errors.roundSize || errors.isPricedRound) {
      let stepperError = stepperErrors
      stepperError.roundDetails = true
      setStepperErrors(stepperError)
    } else if (!errors.investorId && !errors.roundSize && !errors.isPricedRound) {
      let stepperError = stepperErrors
      stepperError.roundDetails = false
      setStepperErrors(stepperError)
    }

    if (errors.valuation || errors.postRoundEsopPercentage) {
      let stepperError = stepperErrors
      stepperError.valuation = true
      setStepperErrors(stepperError)
    } else if (!errors.valuation && !errors.postRoundEsopPercentage) {
      let stepperError = stepperErrors
      stepperError.valuation = false
      setStepperErrors(stepperError)
    }

    if (errors.investmentAmount || errors.liquidationPreference) {
      let stepperError = stepperErrors
      stepperError.investment = true
      setStepperErrors(stepperError)
    } else if (!errors.investmentAmount && !errors.liquidationPreference) {
      let stepperError = stepperErrors
      stepperError.investment = false
      setStepperErrors(stepperError)
    }

    if (errors.directorBoardComposition || errors.observerBoardComposition) {
      let stepperError = stepperErrors
      stepperError.boardMatters = true
      setStepperErrors(stepperError)
    } else if (!errors.directorBoardComposition && !errors.observerBoardComposition) {
      let stepperError = stepperErrors
      stepperError.boardMatters = false
      setStepperErrors(stepperError)
    }

    if (errors.dar || errors.tar || errors.rofr || errors.rofo) {
      let stepperError = stepperErrors
      stepperError.transferRestrictions = true
      setStepperErrors(stepperError)
    } else if (!errors.dar && !errors.tar && !errors.rofr && !errors.rofo) {
      let stepperError = stepperErrors
      stepperError.transferRestrictions = false
      setStepperErrors(stepperError)
    }

    if (errors.investorRights || errors.antiDilution) {
      let stepperError = stepperErrors
      stepperError.investorRights = true
      setStepperErrors(stepperError)
    } else if (!errors.investorRights && !errors.antiDilution) {
      let stepperError = stepperErrors
      stepperError.investorRights = false
      setStepperErrors(stepperError)
    }

    if (errors.founderVesting || errors.founderLockin) {
      let stepperError = stepperErrors
      stepperError.founderRestrictions = true
      setStepperErrors(stepperError)
    } else if (!errors.founderVesting && !errors.founderLockin) {
      let stepperError = stepperErrors
      stepperError.founderRestrictions = false
      setStepperErrors(stepperError)
    }

    if (errors.exitRights) {
      let stepperError = stepperErrors
      stepperError.exitRights = true
      setStepperErrors(stepperError)
    } else if (!errors.exitRights) {
      let stepperError = stepperErrors
      stepperError.exitRights = false
      setStepperErrors(stepperError)
    }

    if (
      errors.diligence ||
      errors.validityMisc ||
      errors.exclusivity ||
      errors.transactionCost ||
      errors.country
    ) {
      let stepperError = stepperErrors
      stepperError.miscellaneous = true
      setStepperErrors(stepperError)
    } else if (
      !errors.diligence &&
      !errors.validityMisc &&
      !errors.exclusivity &&
      !errors.transactionCost &&
      !errors.country
    ) {
      let stepperError = stepperErrors
      stepperError.miscellaneous = false
      setStepperErrors(stepperError)
    }
  }

  return mutateSendTermsheet.isSuccess ? (
    <Navigate to='/deals' />
  ) : (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex gx-8'
      id='kt_create_account_stepper'
    >
      <div
        ref={wizardRef}
        className='card d-flex justify-content-center justify-content-xl-start flex-row-auto col-12 col-md-4 mh-700px min-h-700px overflow-auto'
      >
        <div className='card-body p-5'>
          <div className='stepper-nav'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper cursor-pointer'>
                <div className='stepper-icon w-40px h-40px'>
                  {stepperErrors.roundDetails ? (
                    <i className='stepper-check fas fa-exclamation'></i>
                  ) : (
                    <i className='stepper-check fas fa-check'></i>
                  )}{' '}
                  <span className='stepper-number'>1</span>
                </div>

                <div
                  className='stepper-label'
                  onClick={() => {
                    goTo(1)
                  }}
                >
                  <h3 className='stepper-title'>Round Details</h3>

                  <div className='stepper-desc fw-semibold'>Enter Your Round Details</div>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper cursor-pointer'>
                <div className='stepper-icon w-40px h-40px'>
                  {stepperErrors.valuation ? (
                    <i className='stepper-check fas fa-exclamation'></i>
                  ) : (
                    <i className='stepper-check fas fa-check'></i>
                  )}
                  <span className='stepper-number'>2</span>
                </div>

                <div
                  className='stepper-label'
                  onClick={() => {
                    goTo(2)
                  }}
                >
                  <h3 className='stepper-title'>Valuation</h3>
                  <div className='stepper-desc fw-semibold'>Enter Your Valuation Details</div>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper cursor-pointer'>
                <div className='stepper-icon w-40px h-40px'>
                  {stepperErrors.investment ? (
                    <i className='stepper-check fas fa-exclamation'></i>
                  ) : (
                    <i className='stepper-check fas fa-check'></i>
                  )}{' '}
                  <span className='stepper-number'>3</span>
                </div>

                <div
                  className='stepper-label'
                  onClick={() => {
                    goTo(3)
                  }}
                >
                  <h3 className='stepper-title'>Investment Details</h3>
                  <div className='stepper-desc fw-semibold'>Investment Related Info</div>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper cursor-pointer'>
                <div className='stepper-icon w-40px h-40px'>
                  {stepperErrors.liquidityPreference ? (
                    <i className='stepper-check fas fa-exclamation'></i>
                  ) : (
                    <i className='stepper-check fas fa-check'></i>
                  )}{' '}
                  <span className='stepper-number'>4</span>
                </div>

                <div
                  className='stepper-label'
                  onClick={() => {
                    goTo(4)
                  }}
                >
                  <h3 className='stepper-title'>Liquidation Preference</h3>
                  <div className='stepper-desc fw-semibold'>Choose your Liquidation Preference</div>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper cursor-pointer'>
                <div className='stepper-icon w-40px h-40px'>
                  {stepperErrors.transferRestrictions ? (
                    <i className='stepper-check fas fa-exclamation'></i>
                  ) : (
                    <i className='stepper-check fas fa-check'></i>
                  )}{' '}
                  <span className='stepper-number'>5</span>
                </div>

                <div
                  className='stepper-label'
                  onClick={() => {
                    goTo(5)
                  }}
                >
                  <h3 className='stepper-title'>Transfer Restrictions</h3>
                  <div className='stepper-desc fw-semibold'>Choose your Transfer Restrictions</div>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper cursor-pointer'>
                <div className='stepper-icon w-40px h-40px'>
                  {stepperErrors.transferRestrictions ? (
                    <i className='stepper-check fas fa-exclamation'></i>
                  ) : (
                    <i className='stepper-check fas fa-check'></i>
                  )}{' '}
                  <span className='stepper-number'>6</span>
                </div>

                <div
                  className='stepper-label'
                  onClick={() => {
                    goTo(6)
                  }}
                >
                  <h3 className='stepper-title'>Governance Rights</h3>
                  <div className='stepper-desc fw-semibold'>
                    Enter your Governance Rights Details
                  </div>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper cursor-pointer'>
                <div className='stepper-icon w-40px h-40px'>
                  {stepperErrors.transferRestrictions ? (
                    <i className='stepper-check fas fa-exclamation'></i>
                  ) : (
                    <i className='stepper-check fas fa-check'></i>
                  )}{' '}
                  <span className='stepper-number'>7</span>
                </div>

                <div
                  className='stepper-label'
                  onClick={() => {
                    goTo(7)
                  }}
                >
                  <h3 className='stepper-title'>Exit Rights</h3>
                  <div className='stepper-desc fw-semibold'>Enter your Exit Rights Details</div>
                </div>
              </div>

              <div className='stepper-line h-40px'></div>
            </div>

            {additionalStepperHeaders}

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper cursor-pointer'>
                <div className='stepper-icon w-40px h-40px'>
                  {stepperErrors.validity ? (
                    <i className='stepper-check fas fa-exclamation'></i>
                  ) : (
                    <i className='stepper-check fas fa-check'></i>
                  )}{' '}
                  <span className='stepper-number'>{newSchema.length}</span>
                </div>

                <div
                  className='stepper-label'
                  onClick={() => {
                    goTo(newSchema.length)
                  }}
                >
                  <h3 className='stepper-title'>Validity</h3>
                  <div className='stepper-desc fw-semibold'>Termsheet Expiry</div>
                </div>
              </div>
              <div className='stepper-line h-40px'></div>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper cursor-pointer'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>{newSchema.length + 1}</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title'>Preview Termsheet</h3>
                  <div className='stepper-desc fw-semibold'>Preview details before submitting</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-md-8 '>
        <div className='card ms-5 px-5 mh-700px min-h-700px overflow-auto'>
          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {({ errors, values }) => {
              handleStepperErrors(errors)
              return (
                <Form noValidate id='kt_create_account_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <RoundDetails
                      investorList={investorList}
                      companyId={initialValues ? initialValues.cin : companyId}
                      name={initialValues ? initialValues.companyName : name}
                      setCompanyObj={setCompanyObj}
                      isDraft={initialValues ? true : false}
                      values={values}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <ValuationDetails values={values} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <InvestmentDetails values={values} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <LiquidationPreference values={values} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <TransferRestrictions />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <GovernanceRights />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <ExitRights />
                  </div>
                  {additionalStepperContent}
                  <div data-kt-stepper-element='content'>
                    <Validity minDate={minDate} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    {usePreviewTermsheetMutation.isSuccess && previewUrl ? (
                      <Preview url={usePreviewTermsheetMutation.data?.data.resultCode || ''} />
                    ) : usePreviewTermsheetMutation.isLoading ? (
                      <h6 className='text-center'>
                        Loading...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </h6>
                    ) : (
                      <span>Failed to load preview </span>
                    )}
                  </div>

                  <div
                    className={`d-flex ${
                      stepper.current?.currentStepIndex !== 1
                        ? 'justify-content-between'
                        : 'justify-content-end'
                    } pt-5`}
                  >
                    <div className='btn-toolbar'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-secondary m-1'
                        data-kt-stepper-action='previous'
                      >
                        Back
                      </button>
                    </div>
                    <div className='btn-toolbar'>
                      {stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && (
                        <button
                          onClick={() => {
                            navigate('/deals')
                            Toast('Draft Created Successfully!', ToastType.success)
                          }}
                          type='button'
                          className='btn btn-secondary m-1'
                        >
                          Save
                        </button>
                      )}
                      <button
                        ref={submitBtnRef}
                        type='submit'
                        className='btn btn-primary m-1'
                        disabled={mutateSendTermsheet.isSuccess || mutateSendTermsheet.isLoading}
                      >
                        <span className='indicator-label'>
                          {(!loading &&
                            stepper.current?.currentStepIndex ===
                              stepper.current?.totatStepsNumber! - 1 &&
                            'Preview') ||
                            (!loading &&
                              (stepper.current?.currentStepIndex !==
                                stepper.current?.totatStepsNumber! ||
                                stepper.current?.currentStepIndex === 0) &&
                              'Continue') ||
                            (!loading && mutateSendTermsheet.isSuccess && 'Sent')}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! && 'Issue to Company'}
                        </span>
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Sending...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export { SendTermsheetWizard }
