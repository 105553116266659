import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import { useChangePassword } from '../../../../api/authentication'
import { ChangePasswordRequest } from '../../../../types/Login'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import * as Yup from 'yup'
import { KTSVG } from '../../../../_metronic/helpers'
type Props = {
  show: boolean
  handleClose: () => void
}
const initialValues: ChangePasswordRequest = {
  password: '',
  newPassword: '',
  confirmPassword: '',
  jwt: '',
}
const changePasswordSchema = Yup.object().shape({
  password: Yup.string().required('Old Password is required'),
  newPassword: Yup.string()
    .min(6, 'Password length should be atleast 6')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character'
    )
    .max(50, 'Maximum 50 symbols')
    .required('New Password is required'),
  confirmPassword: Yup.string()
    .required('Confirmation Password is required')
    .when('newPassword', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        "Password and Confirm Password didn't match"
      ),
    }),
})
function ChangePassword(props: Props) {
  const token = useAuth()
  const jwt = token.auth
  const [oldPwd, showOldPwd] = useState(true)
  const [newPwd, showNewPwd] = useState(true)
  const [cnfPwd, showCnfPwd] = useState(true)
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [newPwd])
  const changePassword = useChangePassword()
  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values: ChangePasswordRequest) => {
      setLoading(true)
      values.jwt = jwt!
      setError(undefined)
      if (values.jwt !== undefined) {
        changePassword.mutate(
          {
            password: values.password,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
            jwt: values.jwt,
          },
          {
            onSuccess: () => {
              token.logout()
              setLoading(false)
            },
            onError: (err: any) => {
              setError(err.response.data?.errors[0])
              setLoading(false)
            },
          }
        )
      }
    },
  })

  return (
    <Modal show={props.show} dialogClassName='modal-dialog modal-dialog-centered'>
      <ModalHeader>
        <h1 className='fw-bolder'>Change Password</h1>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <div className='mb-5'>*Note: Once password is changed you will be logged out</div>
          <div className='position-relative pb-2'>
            <label className='form-label required'>Old Password</label>
            <input
              type={`${oldPwd ? 'password' : 'text'}`}
              placeholder='Old Password'
              className='form-control form-control-solid'
              {...formik.getFieldProps('password')}
            />
            <span
              className='btn btn-icon position-absolute translate-middle end-0 top-50 pt-4 ps-3'
              onClick={() => showOldPwd(!oldPwd)}
            >
              {oldPwd ? (
                <i className='bi bi-eye-slash fs-2'></i>
              ) : (
                <i className='bi bi-eye fs-2'></i>
              )}
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container mb-3'>
              <div className='fv-help-block'>
                <span role='alert'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen045.svg'
                    className='svg-icon-primary svg-icon-1 me-2'
                  />
                  {formik.errors.password}
                </span>
              </div>
            </div>
          )}
          <div className='position-relative pb-2' data-kt-password-meter='true'>
            <label className='form-label required'>New Password</label>
            <input
              type={`${newPwd ? 'password' : 'text'}`}
              className='form-control form-control-solid'
              placeholder='New Password'
              autoComplete='off'
              {...formik.getFieldProps('newPassword')}
            />
            <span
              data-kt-password-meter-control='visibility'
              className='btn btn-icon position-absolute translate-middle end-0 top-50 ps-3 pb-4'
              onClick={() => showNewPwd(!newPwd)}
            >
              {newPwd ? (
                <i className='bi bi-eye-slash fs-2'></i>
              ) : (
                <i className='bi bi-eye fs-2'></i>
              )}
            </span>
            <div
              className='d-flex align-items-center my-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
          </div>
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div className='fv-plugins-message-container mb-3'>
              <div className='fv-help-block'>
                <span role='alert'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen045.svg'
                    className='svg-icon-primary svg-icon-1 me-2'
                  />
                  {formik.errors.newPassword}
                </span>
              </div>
            </div>
          )}
          <div className='position-relative pb-2'>
            <label className='form-label required'>Confirm Password</label>
            <input
              type={`${cnfPwd ? 'password' : 'text'}`}
              className='form-control form-control-solid'
              placeholder='Confirm New Password'
              {...formik.getFieldProps('confirmPassword')}
            />
            <span
              className='btn btn-icon position-absolute translate-middle end-0 top-50 pt-4 ps-3'
              onClick={() => showCnfPwd(!cnfPwd)}
            >
              {cnfPwd ? (
                <i className='bi bi-eye-slash fs-2'></i>
              ) : (
                <i className='bi bi-eye fs-2'></i>
              )}
            </span>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container mb-3'>
              <div className='fv-help-block'>
                <span role='alert'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen045.svg'
                    className='svg-icon-primary svg-icon-1 me-2'
                  />
                  {formik.errors.confirmPassword}
                </span>
              </div>
            </div>
          )}
          {error && (
            <div className='fv-plugins-message-container mb-3'>
              <div className='fv-help-block'>
                <span role='alert'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen045.svg'
                    className='svg-icon-primary svg-icon-1 me-2'
                  />
                  {error}
                </span>
              </div>
            </div>
          )}
          <div className='d-flex justify-content-between'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => {
                props.handleClose()
                formik.resetForm()
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              type='submit'
              disabled={
                formik.values.password === '' &&
                formik.values.newPassword === '' &&
                formik.values.confirmPassword === ''
              }
            >
              {/* Change Password */}
              {!loading && <span className='indicator-label fw-bold'>Change Password</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ChangePassword
