import { FC } from 'react'
import { Field, ErrorMessage } from 'formik'

const GovernanceRights: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10'>
        <h2 className='fw-bolder text-dark'>Governance Rights</h2>
      </div>
      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Director Board Composition</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='directorBoardComposition'
                  value='0'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>None</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='directorBoardComposition'
                  value='1'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>1</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='directorBoardComposition'
                  value='2'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>2</h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='directorBoardComposition' />
          </div>
        </div>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Observer Board Composition</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='observerBoardComposition'
                  value='0'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>None</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='observerBoardComposition'
                  value='1'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>1</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='observerBoardComposition'
                  value='2'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>2</h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='observerBoardComposition' />
          </div>
        </div>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Quorum Rights</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field className='form-check-input' type='radio' name='quorumRights' value='0' />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>None</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='quorumRights'
                  value='Board Meeting'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Board Meeting</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='quorumRights'
                  value='Shareholdings Meeting'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Shareholdings Meeting</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field className='form-check-input' type='radio' name='quorumRights' value='Both' />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Both</h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='quorumRights' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GovernanceRights
