import { useEffect, useState } from 'react'
import { isPortfolioMetricEnabled } from '../../../../utils/LocalStorageUtils'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import KeyMetricsDropdown from './KeyMetricsDropdown'

type keyMetricsProps = {
  currentValue: string
  gain: string
  // irr:string
  netProfit: string
  industries: number
  moic: string
  totalShareValue: string
  openDeals: number
}

type MetricCard = {
  title: string
  display: boolean
  value: string
  textColor: string
}
function KeyMetrics(props: keyMetricsProps) {
  const [activeCards, setActiveCards] = useState<MetricCard[]>([])

  useEffect(() => {
    if (props.currentValue !== 'NaN') {
      const cards: MetricCard[] = []
      // let card: MetricCard = {
      //   title: 'Net Profit',
      //   display: isPortfolioMetricEnabled('Net Profit'),
      //   value: props.netProfit,
      //   textColor: 'text-gray-heading',
      // }
      // cards.push(card)

      let card = {
        title: 'Current Value',
        display: isPortfolioMetricEnabled('Current Value'),
        value: props.currentValue,
        textColor: 'text-success',
      }
      cards.push(card)
      card = {
        title: 'Gain',
        display: isPortfolioMetricEnabled('Gain'),
        value: props.gain,
        textColor: 'text-warning',
      }
      cards.push(card)
      card = {
        title: 'MOIC',
        display: isPortfolioMetricEnabled('MOIC'),
        value: props.moic,
        textColor: 'text-danger',
      }
      cards.push(card)
      card = {
        title: 'IRR',
        display: isPortfolioMetricEnabled('IRR'),
        value: 'NA',
        textColor: 'text-danger',
      }
      // cards.push(card)
      card = {
        title: 'Industries',
        display: isPortfolioMetricEnabled('Industries'),
        value: props.industries ? props.industries.toString() : 'NA',
        textColor: 'text-success',
      }
      cards.push(card)
      card = {
        title: 'Total Shares Value',
        display: isPortfolioMetricEnabled('Total Shares Value'),
        value: props.totalShareValue,
        textColor: 'text-gray-heading',
      }
      cards.push(card)
      card = {
        title: 'Open Deals',
        display: isPortfolioMetricEnabled('Open Deals'),
        value: props.openDeals ? props.openDeals.toString() : 'NA',
        textColor: 'text-warning',
      }
      cards.push(card)
      setActiveCards(cards)
    }
  }, [props])

  function handleAction(event: any, cardTitle: any) {
    const cardIndex = activeCards.findIndex((card: MetricCard) => {
      return card.title === cardTitle
    })

    const newActiveCards = activeCards.map((card: MetricCard, index) => {
      if (index === cardIndex) {
        const display = card.display
        return { ...card, display: !display }
      }
      return card
    })
    setActiveCards(newActiveCards)
  }

  const allCards = activeCards?.map((card: any) => {
    return (
      card.display && (
        <div
          key={card.title}
          className='w-auto w-lg-150px col-12 col-sm-5 col-md-3 col-lg-2 bg-card-gray p-5 rounded-3 m-2'
        >
          <div className='col'>
            <h2 className={'fs-1 ' + card.textColor}>{card.value}</h2>
            <p className='text-gray-600 fs-6 mb-0'>{card.title}</p>
          </div>
        </div>
      )
    )
  })

  return (
    <div className='card p-2 position-relative'>
      <div className='row me-2 mt-2 justify-content-end'>
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
        <KeyMetricsDropdown handleChange={handleAction} activeCards={activeCards} />
      </div>
      <div className='row d-flex g-0'>
        <div
          key='empty-card'
          className='w-auto w-lg-150px col-12 col-sm-5 col-md-3 col-lg-2 p-5 rounded-3 m-2'
        />
        {parseInt(props.currentValue) > 0 ? allCards : ''}
      </div>
    </div>
  )
}

export default KeyMetrics
