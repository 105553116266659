import React, { useEffect, useState } from 'react'
import { useGetUrlForS3ObjectQuery, GetUrlForS3ObjectQuery } from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'
import { formatDate } from '../../../../../utils/FormatDate'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { toAbsoluteUrl, KTSVG } from '../../../../../_metronic/helpers'
import ActionMenu from '../ActionMenu'
import { PDFModalViewer } from './PDFModalViewer'

type Props = {
  folderData: any
  handleDirectoryClick: any
  showAddFolder: boolean
  handleAddFolderClose: Function
  handleCreateFolder: Function
  handleDeleteItem: Function
  companyId?: string
  companyName: string
  investorId?: number
  id?: number
  vaultType?: string
}

const FilesTable: React.FC<Props> = (props: Props) => {
  const [state, setState] = useState(false)
  const [bucket, setBucket] = useState<any>()
  const [error, setError] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [key, setKey] = useState('')
  const [folderName, setFolderName] = useState('')
  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    { input: { key, bucket: bucket } },
    {
      enabled: state,
    }
  )
  if (query.isFetched && state == true) {
    if (key.toLowerCase().endsWith('pdf')) {
      setState(false)
      setShowModal(true)
    } else {
      window.open(query.data?.urlForS3Object || '')
      setState(false)
    }
  }
  let i = 0

  function handleClick(item: any) {
    if (item.size) {
      if (item.source == 'link') {
        if (!item.link.startsWith('http')) {
          item.link = 'https://' + item.link
        }
        window.open(item.link, '_blank', 'noopener,noreferrer')
      } else if (item.source != 'company') {
        if (props.id) {
          setBucket(process.env.REACT_APP_S3_INVESTOR_BUCKET || '')
          setKey(props.companyName + '/' + props.id + '/' + item.key)
        } else {
          setBucket(process.env.REACT_APP_S3_INVESTOR_BUCKET || '')
          setKey('investor/' + props.investorId + '/' + props.companyId + '/' + item.key)
        }
        setState(true)
      } else {
        setBucket(undefined)
        setKey(props.companyName + '/OriginalData/' + item.key)
        setState(true)
      }
    } else {
      props.handleDirectoryClick(item.name)
    }
  }
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [props.folderData])

  const tableData = props.folderData?.map((item: any, index: any) => {
    const fileName = item.name.split('/')
    let source = item.source
    if (item.source != 'company' && item.source != 'link') {
      if (props.id) {
        source = props.vaultType
      } else {
        source = 'Investor'
      }
    }
    index = item.source == 'company' ? 0 : 4
    return (
      <tr key={item.name + i++} className='bg-hover-primary-light'>
        <>
          <td onClick={() => handleClick(item)}>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-35px me-5 ps-2'>
                {item.size ? (
                  <img
                    src={
                      item.name.toLowerCase().endsWith('.pdf')
                        ? toAbsoluteUrl('/media/svg/files/pdf.svg')
                        : toAbsoluteUrl('/media/svg/files/doc.svg')
                    }
                    alt=''
                  />
                ) : (
                  <img src={toAbsoluteUrl('/media/svg/files/folder-document.svg')} alt='' />
                )}
              </div>
              <span className='text-wrap fs-base cursor-pointer'>
                {fileName[fileName.length - 1]}
              </span>
            </div>
          </td>
        </>
        <td>
          <span
            key={item.name}
            className={`badge fs-9 fw-semibold mb-1 me-2`}
            style={{
              background: 'white',
              color: 'black',
              border: '1px solid black',
            }}
          >
            {item.size ? source : ''}
          </span>
        </td>
        <td>
          <p className='d-block fs-base'>
            {item.size
              ? item?.size > 1000000
                ? (item?.size / 1048576).toFixed(2) + ' MB'
                : (item?.size / 1024).toFixed(2) + ' KB'
              : '-'}
          </p>
        </td>
        <td className=''>
          <p className='d-block fs-base'>
            {item.lastModified ? formatDate(item.lastModified, 'dd-MMM-yyyy').toString() : '-'}
          </p>
        </td>
        <td className=''>
          {item.count === 0 || item.size ? (
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen052.svg'
                className='svg-icon-muted svg-icon-2x'
              />
            </button>
          ) : (
            <></>
          )}
          <ActionMenu item={item} deleteItem={props.handleDeleteItem} handleClick={handleClick} />
        </td>
      </tr>
    )
  })

  const addNewFolder = () => {
    return (
      <tr key={'new-folder'}>
        <td>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-35px me-5 ps-2'>
              <img src={toAbsoluteUrl('/media/svg/files/folder-document.svg')} alt='' />
            </div>
            <input
              type='text'
              className='form-control form-control-lg w-50 form-control-solid'
              placeholder='Enter Folder Name'
              value={folderName}
              onChange={(e) => {
                setFolderName(e.target.value)
                e.target.value == ''
                  ? setError('Folder Name should not be empty')
                  : e.target.value.includes('/')
                  ? setError('Folder Name should not contain /')
                  : setError('')
              }}
            />
            <div className='ps-5'>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary'
                type='button'
                data-bs-toggle='tooltip'
                title='Add'
                onClick={() => {
                  props.handleCreateFolder(folderName)
                  setFolderName('')
                }}
              >
                <KTSVG
                  className='svg-icon-1 text-primary'
                  path='/media/icons/duotune/arrows/arr012.svg'
                />
              </button>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary'
                type='button'
                data-bs-toggle='tooltip'
                title='Close'
                onClick={() => {
                  setFolderName('')
                  props.handleAddFolderClose()
                }}
              >
                <KTSVG
                  className='svg-icon-1 text-primary'
                  path='/media/icons/duotune/arrows/arr061.svg'
                />
              </button>
            </div>
          </div>
          <span role='alert' className='fs-base text-danger ps-2'>
            {error}
          </span>
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    )
  }

  const noFilesRow = () => {
    return (
      <div className='overlay-layer bg-opacity-100'>
        <div className='text-center px-10'>
          <img
            src={toAbsoluteUrl('/media/icons/duotune/general/no-files.svg')}
            className='h-100px my-10'
            alt=''
          />
          <h2>No files present. Click on Upload Files to add files</h2>
        </div>
      </div>
    )
  }

  return (
    <div className={`card`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive h-350px overflow-scroll'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-gray-600'>
                <th className='min-w-100px text-wrap'>Name</th>
                <th className='min-w-100px text-wrap'>Source</th>
                <th className='min-w-140px'>Size</th>
                <th className='min-w-120px'>Last Modified</th>
                <th className='min-w-100px'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {props.showAddFolder && addNewFolder()}
              {tableData}
            </tbody>
            {/* end::Table body */}
          </table>
          {tableData.length === 0 && !props.showAddFolder && noFilesRow()}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      <PDFModalViewer
        show={showModal}
        handleClose={() => {
          setShowModal(false)
        }}
        url={query.data?.urlForS3Object || ''}
        documentName={key.split('/')[key.split('/').length - 1]}
      />
      {/* begin::Body */}
    </div>
  )
}

export { FilesTable }
