/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, ErrorMessage, useFormikContext } from 'formik'
import Nouislider from 'nouislider-react'
import { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import { useSearchQuery, SearchQuery } from '../../../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../../../queries/client'
import { SearchCompanyResultType } from '../../../../../../../types/SearchCompanyResultType'
import { kFormatter, kUnformatterNumber } from '../../../../../../../utils/CurrencyFormatter'
import numToWords from '../../../../../../../utils/NumToWords'
import { selectCustomStyles } from '../../../../../../../utils/Select'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { ISendTermsheet } from '../../SendTermsheetHelper'

const RoundDetails = (props: any) => {
  const formik = useFormikContext<ISendTermsheet>()
  const [moneyInWords, setMoneyInWords] = useState<String>(numToWords(formik.values.roundSize))

  const tooltipFns = {
    to(value: number) {
      return kFormatter(value)
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }
  const [roundSizeSliderRef, setRoundSizeSliderRef] = useState<any>(null)

  const [companyName, setCompanyName] = useState('')
  const [isQueryEnabled, setQueryEnabled] = useState(false)
  const [companyOptions, setCompanyOptions] = useState<any>([])
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [searchResults, setSearchResults] = useState<SearchCompanyResultType[]>([])
  const [showResults, setShowResults] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [nameOfInvestor, setNameOfInvestor] = useState<any>({
    value: props.values.investorName,
    label: props.values.investorName,
  })
  const [templateName, setTemplateName] = useState<string>()

  const searchCompany = useSearchQuery<SearchQuery, Error>(
    graphqlRequestClient,
    { input: { name: companyName } },
    {
      enabled: isQueryEnabled,
      onSuccess(data) {
        handleSearchResults(data.searchCompany)
        setQueryEnabled(false)
      },
    }
  )

  useEffect(() => {
    if (props.name) {
      formik.setFieldValue('companyName', props.name)
      setCompanyName(props.name)
      props.setCompanyObj({
        companyName: props.name,
        companyId: props.companyId,
      })
    }
  }, [])

  useEffect(() => {
    const getData = setTimeout(() => {
      if (isQueryEnabled) searchCompany.refetch()
    }, 2000)
    return () => clearTimeout(getData)
  }, [companyName])

  function handleSearchResults(data: SearchCompanyResultType[]) {
    setSearchResults(data)
    setShowResults(true)
    var options = data.map((company) => {
      return {
        key: company.name,
        value: company.name,
        label: company.name,
        companyName: company.name,
        companyCin: company.cin,
      }
    })
    setCompanyOptions(options)
  }
  const investorName: any[] = []
  props.investorList?.map((investor: any) =>
    !investorName.find((invObj: any) => invObj.value === investor.investorId)
      ? investorName.push({
          value: investor.investorId,
          label: investor.investorName,
        })
      : {}
  )

  return (
    <div className='w-100'>
      <div className='pb-10'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Round Details </h2>
      </div>
      <div className='d-flex align-items-center'>
        {isEdit ? (
          <div className='d-flex'>
            <div className='me-4'>
              <input
                className='form-control form-control-solid me-2'
                placeholder='Enter Term sheet Name'
                {...formik.getFieldProps('termsheetName')}
                defaultValue={formik.values.termsheetName}
                onFocus={(e) => setTemplateName(e.target.value)}
              />
              {formik.touched.termsheetName && formik.errors.termsheetName && (
                <div className='text-danger mt-2'>
                  <ErrorMessage name='termsheetName' />
                </div>
              )}
            </div>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary mb-9'
              type='submit'
              disabled={!!formik.errors.termsheetName}
              onClick={() => {
                setIsEdit(false)
              }}
            >
              <KTSVG
                className='svg-icon-1 text-primary'
                path='/media/icons/duotune/arrows/arr012.svg'
              />
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary mb-9'
              onClick={() => {
                formik.setFieldValue('termsheetName', templateName)
                setIsEdit(false)
              }}
            >
              <KTSVG
                className='svg-icon-1 text-primary'
                path='/media/icons/duotune/arrows/arr061.svg'
              />
            </button>
          </div>
        ) : (
          <>
            <h6>{formik.values.termsheetName}</h6>
            <button
              className='ms-2 btn btn-icon btn-sm'
              onClick={() => {
                setIsEdit(!isEdit)
              }}
            >
              <KTSVG
                path='/media/icons/duotune/art/art005.svg'
                className='svg-icon svg-icon-2 text-center'
              />
            </button>
          </>
        )}
      </div>
      <div className='fv-row mb-6 mt-4'>
        <label className='form-label required'>Select Investor/Fund</label>
        {props.name ? (
          <Select
            className=''
            options={investorName}
            name='investorId'
            onChange={(e: any) => (
              setNameOfInvestor(e), formik.setFieldValue('investorId', e.value)
            )}
            value={nameOfInvestor}
            styles={selectCustomStyles}
          />
        ) : (
          <>
            <Select
              className=''
              options={investorName}
              name='investorId'
              onChange={(e: any) => formik.setFieldValue('investorId', e.value)}
              styles={selectCustomStyles}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='investorId' />
            </div>
          </>
        )}
      </div>

      <div className='fv-row mb-6'>
        <label className='form-label required mb-3'>Company Name</label>

        {props.name ? (
          <Field
            className='form-control form-control-lg form-control-solid'
            name='companyName'
            disabled={true}
          />
        ) : (
          <Select
            styles={selectCustomStyles}
            inputId='aria-company-input'
            name='aria-company'
            options={companyOptions}
            isClearable={true}
            onInputChange={(e) => {
              if (e.length >= 3) {
                setCompanyName(e)
                setQueryEnabled(true)
              }
            }}
            onChange={(e: any) => {
              var index = companyOptions.findIndex(
                (company: any) => company.companyName == e.companyName
              )
              if (index > -1) {
                setSelectedItem(companyOptions[index])
                props.setCompanyObj({
                  companyName: companyOptions[index].companyName,
                  companyId: companyOptions[index].companyCin,
                })
                formik.setFieldValue('companyName', companyOptions[index].companyName)
              }
              setShowResults(true)
            }}
          />
        )}
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyName' />
        </div>
      </div>
      <div className='fv-row mb-6'>
        <label className='form-label required mb-3'>CIN</label>

        {props.companyId ? (
          <Field
            className='form-control form-control-lg form-control-solid'
            name='companyCin'
            value={props.companyId}
            disabled={true}
          />
        ) : (
          showResults &&
          selectedItem && (
            <Field
              className='form-control form-control-lg form-control-solid'
              name='companyCin'
              value={selectedItem.companyCin}
              disabled={true}
            />
          )
        )}
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyCin' />
        </div>
      </div>
      <div className='col pb-6 pt-4'>
        <label className='form-label required mb-3'>Round Name</label>
        <div className='col-12 col-md-5 pt-md-0'>
          <Field
            type='text'
            className='form-control form-control-solid'
            name='roundName'
            placeholder='Enter Round Name'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='roundName' />
          </div>
        </div>
      </div>
      <div className='col fv-md-row'>
        <label className='form-label required'>Round Size</label>

        <div className='col md-row d-md-flex align-items-baseline'>
          <div className='col-12 col-md-7'>
            <Nouislider
              instanceRef={(instance) => {
                if (instance && !roundSizeSliderRef) {
                  setRoundSizeSliderRef(instance)
                }
              }}
              tooltips={[tooltipFns]}
              start={formik.values.roundSize}
              range={{ min: 0, max: 1000000000 }}
              connect
              onChange={(e) => {
                formik.setFieldValue('roundSize', e.at(0))
                setMoneyInWords(numToWords(Math.round(e.at(0))))
              }}
              className=''
            />
          </div>
          <div className='col-12 col-md-5 pt-6 pt-md-0 ps-md-6'>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='roundSize'
              onChange={(e: any) => {
                formik.handleChange(e)
                const roundSize = parseInt(e.target.value?.replaceAll(',', '') || 0, 10)
                formik.setFieldValue('roundSize', Math.round(roundSize))
                roundSizeSliderRef?.noUiSlider.set(roundSize)
                setMoneyInWords(numToWords(Math.round(roundSize)))
              }}
              value={Intl.NumberFormat('en-IN').format(formik.values.roundSize)}
            />
            <div className='form-text'>{moneyInWords}</div>
          </div>
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='roundSize' />
        </div>
      </div>
      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Is the round priced?</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='isPricedRound'
                  value='true'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Yes</h5>
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='isPricedRound'
                  value='false'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>No</h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='isPricedRound' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoundDetails
