import { useEffect, useState } from 'react'
import Dropzone, {
  IFileWithMeta,
  IInputProps,
  ILayoutProps,
  IPreviewProps,
  IUploadParams,
  StatusValue,
} from 'react-dropzone-uploader'
import {
  useGetUrlForS3ObjectQuery,
  GetUrlForS3ObjectQuery,
  useGetAllInvestmentsQuery,
  useGetInvestorsQuery,
} from '../../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../../queries/client'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { getAuth } from '../../../../../modules/auth'

type RowErrorType = {
  rowNum: number
  errors: ColumnErrorsType[]
  errorCount: number
}
type ColumnErrorsType = {
  column: number
  errorMessage: string
}
export default function Step5() {
  const auth = getAuth()!
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState<any>()
  const [csvError, setCsvError] = useState<any>()
  const [errors, setErrors] = useState<any[]>()
  const [bucket, setBucket] = useState<any>()
  const [key, setKey] = useState('')
  const [state, setState] = useState(false)
  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, RowErrorType>(
    graphqlRequestClient,
    { input: { key, bucket: bucket } },
    {
      enabled: state,
    }
  )
  if (query.isFetched && state == true) {
    window.open(query.data?.urlForS3Object || '')
    setState(false)
  }
  function handleTemplateDownload() {
    setBucket(process.env.REACT_APP_S3_INVESTOR_BUCKET || '')
    setKey('investor/RuleZero_CapTable_Template.csv')
    setState(true)
  }
  useEffect(() => {
    if (data?.errors.length > 0) {
      let err: any = []
      data.errors.forEach((error: RowErrorType) => {
        err.push(error)
      })
      setErrors(err)
      setIsError(true)
    } else {
      setIsError(false)
    }
    if (data?.successRecords > -1) {
      setIsSuccess(true)
      if (data?.errors.length === 0) {
        setIsError(false)
      }
    }
  }, [data])
  const getUploadParams = async ({ meta, file }: IFileWithMeta) => {
    var formData = new FormData()
    formData.append(
      'operations',
      JSON.stringify({
        operationName: 'uploadCaptableInvestments',
        query:
          '\n    mutation uploadCaptableInvestments($up: Upload) {\n  addInvestmentImportCaptable(input: {filename: $up})\n}\n    ',
        variables: { up: null },
      })
    )
    formData.append('map', JSON.stringify({ '1': ['variables.up'] }))
    formData.append('1', file)

    const ret: IUploadParams = {
      url: process.env.REACT_APP_GQL_URL!,
      method: 'post',
      body: formData,
      headers: {
        authorization: auth,
      },
    }
    return ret
  }
  const handleChangeStatus = ({ meta, xhr }: IFileWithMeta, status: StatusValue) => {
    if (status === 'done') {
      let result = JSON.parse(xhr?.response)
      if (result.errors) {
        setCsvError('CSV Error: ' + result.errors[0].message)
      } else if (
        result.data.addInvestmentImportCaptable.errors?.length > 0 ||
        result.data.addInvestmentImportCaptable.createMany?.count > 0
      ) {
        setCsvError(null)
        setData({
          errors: result.data.addInvestmentImportCaptable.errors,
          successRecords: result.data.addInvestmentImportCaptable.createMany?.count,
        })
      }
      if (result.data.addInvestmentImportCaptable.createMany?.count > 0) {
        queryClient.invalidateQueries(useGetInvestorsQuery.getKey())
        queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())
      }
    }
  }
  const Input = ({ accept, onFiles, files, getFilesFromEvent, extra }: IInputProps) => {
    return (
      <div>
        <label className='btn btn-outline btn-outline-dashed btn-outline-default bg-primary bg-opacity-10 p-6 d-flex justify-content-center m-10'>
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            disabled={files.length >= extra.maxFiles}
            onChange={async (e) => {
              let file = await getFilesFromEvent(e)
              onFiles(file)
            }}
          />
          <KTSVG
            path='/media/icons/duotune/files/upload.svg'
            className={`svg-icon-3x me-5 text-primary`}
          />

          <span className='text-start'>
            <h3 className='fw-bold mb-2'>Captable Upload</h3>
            <h4 className='text-gray-400'>Upload all your investments</h4>
          </span>
        </label>
        <div className='text-center'>
          <h6 className='text-gray-400'>Click or Drag to upload files</h6>
        </div>
      </div>
    )
  }

  const Preview = ({ meta, fileWithMeta }: IPreviewProps) => {
    const { name, percent, status, size } = meta
    if (status !== 'done' && data?.errors.length === 0) {
      setIsError(true)
    }
    return (
      <div className='dropzone dropzone-queue mb-2'>
        <div className='dropzone-items'>
          <div className='dropzone-item p-5 dz-image-preview'>
            <div className='dropzone-file'>
              <div className='dropzone-filename' title={name}>
                <span className='fs-base fw-bold'>{name}</span>{' '}
                <strong>
                  (
                  <span>
                    {size > 1000000
                      ? (size / 1048576).toFixed(2) + ' MB'
                      : (size / 1024).toFixed(2) + ' KB'}
                  </span>
                  )
                </strong>
              </div>
              <h6 className={status === 'done' ? 'text-success mt-2' : 'text-primary mt-2'}>
                {status === 'done' ? 'Uploaded Successfully' : status}
              </h6>
            </div>
            <div className='dropzone-progress'>
              <div
                className={`progress + ${
                  percent === 100 && !isError ? 'bg-success' : 'bg-primary'
                }`}
              >
                <div
                  className='progress-bar bg-primary'
                  role='progressbar'
                  aria-valuemin={0}
                  aria-valuemax={100}
                  aria-valuenow={percent}
                ></div>
              </div>
            </div>
            <div className='dropzone-toolbar'>
              <span className='dropzone-delete' data-dz-remove>
                <span
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  onClick={() => {
                    fileWithMeta.remove()
                    setErrors([])
                    setIsError(false)
                    setIsSuccess(false)
                    setCsvError(null)
                  }}
                >
                  <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    return (
      <div className=''>
        <div {...dropzoneProps} className='border-dashed border-hover-primary'>
          <div className=''>{input}</div>
        </div>
        {previews}
      </div>
    )
  }
  const errorCards: any[] = errors
    ? errors.map((rowError: RowErrorType, index: number) => {
        return rowError.rowNum === 0 ? (
          <h5 className='text-danger'>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon-danger svg-icon-2hx me-2'
              />
            </span>
            {rowError.errors[0].errorMessage}
          </h5>
        ) : rowError.errorCount === 0 ? (
          rowError.errors.map((colError: ColumnErrorsType, index: number) => {
            return (
              <h6 className=''>
                <div className='d-flex'>
                  <div className='col-12'>
                    <span>
                      <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-warning svg-icon-2hx me-2'
                      />
                    </span>
                    {`${colError.errorMessage} [Row ${rowError.rowNum} ${
                      colError.errorMessage === 'Investment record with same details already exist'
                        ? `"Column "+${colError.column}`
                        : ''
                    }]`}
                  </div>
                </div>
                <div className='separator mt-2'></div>
              </h6>
            )
          })
        ) : (
          rowError.errors.map((colError: ColumnErrorsType, index: number) => {
            return (
              <h6 className=''>
                <div className='d-flex'>
                  <div className='col-12'>
                    <span>
                      <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-danger svg-icon-2hx me-2'
                      />
                    </span>
                    {`${colError.errorMessage} [Row ${rowError.rowNum} Column ${colError.column}]`}
                  </div>
                </div>
                <div className='separator mt-2'></div>
              </h6>
            )
          })
        )
      })
    : []

  return (
    <div className='w-100 px-10'>
      <div className='pb-10'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Upload Cap Table</h2>
      </div>
      <div className='fv-row'>
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          multiple={false}
          styles={{
            dropzone: { minHeight: 200 },
            dropzoneActive: { background: 'rgba(222, 220, 220, 0.5)' },
          }}
          accept={'.csv'}
          maxSizeBytes={100000000}
          InputComponent={Input}
          canCancel={true}
          canRemove={true}
          PreviewComponent={Preview}
          LayoutComponent={Layout}
          autoUpload={true}
          maxFiles={1}
        />
        <div className='separator mt-6 mb-3'></div>
        {csvError && <h5 className='text-danger'>{csvError}</h5>}
        {isSuccess && (
          <div>
            {data?.successRecords > 0 ? (
              <h5 className='text-success'>
                <span>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr016.svg'
                    className='svg-icon-success svg-icon-2hx me-2'
                  />
                </span>
                {data?.successRecords} Investment Records added successfully.
              </h5>
            ) : (
              <h5 className='text-danger'>
                <span>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className='svg-icon-danger svg-icon-2hx me-2'
                  />
                </span>
                {data?.successRecords} Investment Records added.
              </h5>
            )}
          </div>
        )}
        {isError ? (
          <div className='card shadow-sm h-200px overflow-scroll px-10 py-5'>
            <h3 className='fw-bold m-0'>Captable Sheet Errors</h3>
            <div className='separator my-3'></div>
            {errorCards}
          </div>
        ) : (
          <></>
        )}
        {isError || isSuccess || csvError ? (
          <></>
        ) : (
          <div>
            <h3 className='fw-bold pb-4'>RuleZero Cap Table template download</h3>
            <div className='d-inline-block'>
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default bg-primary bg-opacity-10 p-6 d-flex justify-content-center w-auto mb-10'
                htmlFor='kt_add_investment_type_single'
              >
                <KTSVG
                  path='/media/icons/duotune/files/fil021.svg'
                  className={`svg-icon-3x me-5 text-primary`}
                />

                <span className='text-start' onClick={handleTemplateDownload}>
                  <h4 className='fw-bold mb-2'>Download RuleZero Cap Table Template</h4>
                  <div className='text-gray-400 fs-base'>RuleZero_CapTable_Template.csv</div>
                </span>
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
