import { GraphQLClient } from 'graphql-request'
import { QueryCache, QueryClient } from 'react-query'
import { getAuth } from '../app/modules/auth'
import { middleware } from '../services/Middleware'

const jwt = getAuth()
const requestHeaders = {
  authorization: jwt!,
}

export const graphqlRequestClient = new GraphQLClient(process.env.REACT_APP_GQL_URL as string, {
  headers: requestHeaders,
})

export const queryCache = new QueryCache({
  onError: (error) => {
    // console.log(error)
  },
  onSuccess: (data) => {
    // console.log(data)
  },
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30, // data considered stale after 30 min
      cacheTime: 1000 * 60 * 10, // data will be in cache for 10 minutes
      // but usage of cache will depend on stale time
    },
  },
  queryCache: queryCache,

  // queryKeySerializer: (queryKey: QueryKey) => {
  //   if (typeof queryKey === 'object' && queryKey.query) {
  //     // If the query key is an object with a "query" property, use the query name
  //     const jwt = localStorage.getItem('jwt')
  //     return `${queryKey.query.loc?.source?.name}_${jwt}`
  //   }
  //   // Otherwise, use the query key as is
  //   return queryKey
  // },
})
