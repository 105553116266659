import { useContext } from 'react'
import {
  GetStarCompaniesQuery,
  GetWatchlistsQuery,
  useGetStarCompaniesQuery,
  useGetWatchlistsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { isoToDate } from '../../../../utils/FormatDate'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { GlobalContext } from '../../store/context/globalContext'
import CompanyCard from './CompanyCard'
type CompanyDetails = {
  name: string
  valuation: number
  sharePrice: number
  latestRaise: number
  latestRaiseDate: string
  noOfRounds: number
  logo: string
}
export type InvestorDetails = {
  id: any
  invInfo: any
}

type Props = {
  data: InvestorDetails[]
  companies?: string[]
}

function StarredCompanies(props: Props) {
  const { state } = useContext(GlobalContext)
  const { ctxInvestorIds } = state
  const starCompanies = useGetStarCompaniesQuery<GetStarCompaniesQuery, Error>(
    graphqlRequestClient,
    { input: { investorIds: props.data[0].id } },
    { enabled: props.data[0].id.length > 0 }
  )
  const { data } = useGetWatchlistsQuery<GetWatchlistsQuery, Error>(graphqlRequestClient, {}, {})

  let watchlistCompanies: any = []
  data?.watchlists?.forEach((watchlist: any) => {
    watchlist.companies.forEach((c: any) => {
      if (
        c.starred === true &&
        !watchlistCompanies.map((c: CompanyDetails) => c.name).includes(c.name)
      ) {
        if (props.companies && props.companies.length > 0) {
          if (props.companies.includes(c.name)) {
            watchlistCompanies.push(c)
          }
        } else {
          watchlistCompanies.push(c)
        }
      }
    })
  })

  let invCompanies: any = []
  starCompanies.data?.starCompanies?.forEach((starCompany: string) => {
    if (props.companies && props.companies.length > 0) {
      if (props.companies.includes(starCompany)) {
        const companyData = props.data[0].invInfo?.allInvestments?.find(
          (company: any) => starCompany === company.companyData.name
        )
        if (companyData != undefined) {
          invCompanies.push(companyData)
        }
      }
    } else {
      const companyData = props.data[0].invInfo?.allInvestments?.find(
        (company: any) => starCompany === company.companyData.name
      )
      if (companyData != undefined) {
        invCompanies.push(companyData)
      }
    }
  })

  let companies: any = []
  watchlistCompanies.forEach((item: any, index: number) => {
    companies.push(
      <div key={item.cin} className='mw-400px gx-5'>
        <CompanyCard
          name={item.name}
          companyId={item.cin}
          hissaVerified={true}
          valuation={item.valuation}
          sharePrice={item.sharePrice}
          latestRaise={item.latestRaise}
          noOfRounds={item.noOfRounds}
          logoPath={item.logo}
          lastInvestmentDate={
            item.latestRaiseDate === 'NA' ? 'NA' : isoToDate(item.latestRaiseDate.split('T')[0])
          }
          lastInvestment={item.latestRaise}
        />
      </div>
    )
  })

  invCompanies.forEach((item: any, index: number) => {
    let isPresent = item.investmentData.some((investment: any) =>
      ctxInvestorIds?.includes(Number(investment.investorId))
    )
    if (isPresent)
      if (!companies.map((c: any) => c.key).includes(item.companyData.cin))
        companies.push(
          <div key={index + item.companyData.cin} className='mw-400px gx-5'>
            <CompanyCard
              name={item.companyData.name}
              companyId={item.companyData.cin}
              hissaVerified={true}
              valuation={item.companyData.valuation}
              sharePrice={item.companyData.sharePrice}
              latestRaise={item.companyData.totalFunding}
              noOfRounds={item.companyData.noOfRounds}
              logoPath={item.companyData.logo}
              lastInvestmentDate={
                item.companyData.latestRaiseDate === 'NA'
                  ? 'NA'
                  : isoToDate(item.companyData.latestRaiseDate.split('T')[0])
              }
              lastInvestment={item.companyData.latestRaise}
            />
          </div>
        )
  })

  return (
    <div className='card h-100'>
      <div className='d-flex p-5 pb-0'>
        <h1>Starred Companies</h1>
        <select className='form-select w-25 mx-4' hidden>
          <option value='overall'>Overall</option>
          <option value='starred'>Starred</option>
        </select>
      </div>
      <div className='card-body overflow-auto'>
        <div className='row flex-nowrap'>
          {companies.length === 0 ? (
            <div className='text-center'>
              <img
                src={toAbsoluteUrl('/media/icons/duotune/general/no-starCompany.svg')}
                className='h-100px my-20'
                alt=''
              />
              <h1 className=''>You have no Starred Companies</h1>
            </div>
          ) : (
            <>{companies}</>
          )}
        </div>
      </div>
    </div>
  )
}

export default StarredCompanies
