import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import { SearchQuery, useSearchQuery } from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { SearchCompanyResultType } from '../../../../types/SearchCompanyResultType'
import { multiSelectCustomStyles } from '../../../../utils/MultiSelect'
import Toast, { ToastType } from '../../../../utils/toast'
import { KTSVG } from '../../../../_metronic/helpers'
import { WatchListItem } from './WatchListItem'

type addCompanyProps = {
  show: boolean
  handleClose: () => void
  watchlistName: string
  watchlistId: number
  companyList: string[]
}

function AddCompanyToWatchlist(props: addCompanyProps) {
  const [companyName, setCompanyName] = useState('')
  const [searchResults, setSearchResults] = useState<SearchCompanyResultType[]>([])
  const [showResults, setShowResults] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [companyOptions, setCompanyOptions] = useState<any>([])
  const [isQueryEnabled, setQueryEnabled] = useState(false)
  const searchCompany = useSearchQuery<SearchQuery, Error>(
    graphqlRequestClient,
    { input: { name: companyName } },
    {
      enabled: isQueryEnabled,
      onSuccess(data) {
        handleSearchResults(data.searchCompany)
        setQueryEnabled(false)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    }
  )

  useEffect(() => {
    const getData = setTimeout(() => {
      if (isQueryEnabled) searchCompany.refetch()
    }, 2000)
    return () => clearTimeout(getData)
  }, [companyName])

  function handleSearchResults(data: SearchCompanyResultType[]) {
    setSearchResults(data)
    setShowResults(true)
    var options = data.map((company) => {
      return {
        value: company.name,
        label:
          company.business !== 'NA' && company.business !== ''
            ? company.name + ' (' + company.business + ')'
            : company.name,
        companyLogo: company.logo,
        companyName: company.name,
        companyCin: company.cin,
        watchlistId: props.watchlistId,
        pps: company.sharePrice,
        valuation: company.valuation,
        latestRaise: company.latestRaise,
        noOfRounds: company.noOfRounds,
        totalFunding: company.totalFunding,
        isOddRow: true,
        ctaText: 'ADD +',
        isSearchResults: true,
        business: company.business != null ? company.business : '',
        industry: company.industry!,
      }
    })
    setCompanyOptions(options)
  }

  return (
    <Modal
      id='kt_header_search_modal'
      aria-hidden='true'
      tabIndex={-1}
      dialogClassName='modal-dialog modal-dialog-centered h-auto mw-1000px '
      show={props.show}
      centered
      animation={true}
      backdrop={true}
      onHide={() => {
        props.handleClose()
        setShowResults(false)
        setQueryEnabled(false)
        setSelectedItem(null)
      }}
    >
      <Modal.Header>
        <Modal.Title className='fs-1 text-gray-heading px-8'>
          Add Company to {props.watchlistName}
        </Modal.Title>
        <button
          className='btn btn-sm btn-icon'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={() => {
            props.handleClose()
            setShowResults(false)
            setQueryEnabled(false)
            setSelectedItem(null)
            setCompanyOptions([])
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </button>
      </Modal.Header>
      <Modal.Body className='px-15'>
        <div className='fv-row mb-4 justify'>
          <label className='d-flex align-items-center fs-5 fw-medium mb-4'>
            <span className='required'>Search for companies you would like to add </span>
          </label>
          <Select
            styles={multiSelectCustomStyles}
            inputId='aria-company-input'
            name='aria-company'
            options={companyOptions}
            isClearable={true}
            onInputChange={(e) => {
              if (e.length >= 3) {
                setCompanyName(e)
                setQueryEnabled(true)
                // handleCompanyChange(e)
              }
            }}
            onChange={(e: any) => {
              var index = companyOptions.findIndex(
                (company: any) => company.companyName == e.companyName
              )
              if (index > -1) setSelectedItem(companyOptions[index])
              else setShowResults(false)
            }}
          />

          <div className={`h-50 mt-10`}>
            {showResults && selectedItem && (
              <WatchListItem
                key={selectedItem.cin}
                companyLogo={selectedItem.companyLogo}
                companyName={selectedItem.companyName}
                companyId={selectedItem.companyCin}
                watchlistId={selectedItem.watchlistId}
                pps={selectedItem.pps}
                valuation={selectedItem.valuation}
                latestRaise={selectedItem.latestRaise}
                noOfRounds={selectedItem.noOfRounds}
                totalFunding={selectedItem.totalFunding}
                isOddRow={selectedItem.isOddRow}
                ctaText={selectedItem.ctaText}
                isSearchResults={selectedItem.isSearchResults}
                business={selectedItem.business}
                industry={selectedItem.industry}
                isAdded={props.companyList
                  .map((company) => company.toLowerCase())
                  .includes(selectedItem.companyName.toLowerCase())}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='row justify-content-end'>
          <button
            type='submit'
            onClick={() => {
              props.handleClose()
              setShowResults(false)
              setQueryEnabled(false)
              setSelectedItem(null)
              setCompanyOptions([])
              Toast('Company Added To Watchlist Successfully!', ToastType.success)
            }}
            className='btn btn-lg btn-primary w-100 mt-3'
          >
            Done
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AddCompanyToWatchlist
