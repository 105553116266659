import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Dropzone, {
  IFileWithMeta,
  IInputProps,
  ILayoutProps,
  IPreviewProps,
  ISubmitButtonProps,
  IUploadParams,
  StatusValue,
} from 'react-dropzone-uploader'
import { useNavigate } from 'react-router-dom'
import { useGetDealTemplatesQuery } from '../../../../../generated/graphql'
import { queryClient } from '../../../../../queries/client'
import Toast, { ToastType } from '../../../../../utils/toast'
import { KTSVG } from '../../../../../_metronic/helpers'
import { getAuth } from '../../../../modules/auth'

type Props = {
  showModal: boolean
  handleClose: () => void
  toggleBuilder: () => void
  inputData: any
}

function TemplateUpload({ showModal, handleClose, inputData, toggleBuilder }: Props) {
  const auth = getAuth()!
  const navigate = useNavigate()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errors, setErrors] = useState()

  const getUploadParams = async ({ meta, file }: IFileWithMeta) => {
    let formData = new FormData()
    formData.append(
      'operations',
      JSON.stringify({
        operationName: 'addDealTemplate',
        query: `\n    mutation addDealTemplate($up: Upload, $investorId: BigInt, $name: String, $sections: [String]) {\n  addDealTemplate(input: {investorId: $investorId, name: $name, sections: $sections, fileName: $up})\n}\n    `,
        variables: {
          up: null,
          investorId: inputData.investorId,
          name: inputData.name,
          sections: inputData.sections,
        },
      })
    )
    formData.append('map', JSON.stringify({ '1': ['variables.up'] }))
    formData.append('1', file)

    const ret: IUploadParams = {
      url: process.env.REACT_APP_GQL_URL!,
      method: 'post',
      body: formData,
      headers: {
        authorization: auth,
      },
    }
    return ret
  }

  const handleChangeStatus = ({ meta, xhr }: IFileWithMeta, status: StatusValue) => {
    if (status === 'done') {
      let result = JSON.parse(xhr?.response)
      if (result.errors) {
        setIsError(true)
        setErrors(result.errors[0].message)
        Toast(result.errors[0].message, ToastType.error)
      } else {
        setIsSuccess(true)
        queryClient.invalidateQueries(useGetDealTemplatesQuery.getKey())
        Toast('Template Uploaded Successfully!', ToastType.success)
      }
    }
  }

  const handleSubmit = async (files: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
    const acceptedFile = files[0]

    acceptedFile.restart()

    if (acceptedFile?.meta?.status === 'done') {
      handleClose()
      toggleBuilder()
    }
  }

  const Input = ({ accept, onFiles, files, getFilesFromEvent, extra }: IInputProps) => {
    return (
      <div className=''>
        <label className='btn btn-outline btn-outline-dashed btn-outline-default bg-primary bg-opacity-10 p-6 d-flex justify-content-center m-10'>
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            disabled={files.length >= extra.maxFiles}
            onChange={async (e) => {
              let file = await getFilesFromEvent(e)
              onFiles(file)
            }}
          />
          <KTSVG
            path='/media/icons/duotune/files/upload.svg'
            className={`svg-icon-3x me-5 text-primary`}
          />

          <span className='text-start'>
            <h3 className='fw-bold mb-2'>Upload Term Sheet Sample</h3>
            <h4 className='text-gray-400'>Upload single sample in docx format</h4>
          </span>
        </label>
        <div className='text-center'>
          <h6 className='text-gray-400'>Click or Drag to upload files</h6>
        </div>
      </div>
    )
  }

  const Preview = ({ meta, fileWithMeta }: IPreviewProps) => {
    const { name, percent, status, size } = meta
    if (status != 'done') {
      setIsError(true)
    }

    if (status == 'done') {
      handleClose()
      toggleBuilder()
    }

    return (
      <div className='dropzone dropzone-queue mb-2'>
        <div className='dropzone-items'>
          <div className='dropzone-item p-5 dz-image-preview'>
            <div className='dropzone-file'>
              <div className='dropzone-filename' title={name}>
                <span className='fs-base fw-bold'>{name}</span>{' '}
                <strong>
                  (
                  <span>
                    {size > 1000000
                      ? (size / 1048576).toFixed(2) + ' MB'
                      : (size / 1024).toFixed(2) + ' KB'}
                  </span>
                  )
                </strong>
              </div>
              <h6 className={status === 'done' ? 'text-success mt-2' : 'text-primary mt-2'}>
                {status === 'done' ? 'Uploaded Successfully' : status}
              </h6>
            </div>
            <div className='dropzone-progress'>
              <div
                className={`progress + ${
                  percent === 100 && !isError ? 'bg-success' : 'bg-primary'
                }`}
              >
                <div
                  className='progress-bar bg-primary'
                  role='progressbar'
                  aria-valuemin={0}
                  aria-valuemax={100}
                  aria-valuenow={percent}
                ></div>
              </div>
            </div>
            <div className='dropzone-toolbar'>
              {isError ? (
                <span className='dropzone-delete' data-dz-remove>
                  <span
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    onClick={() => {
                      fileWithMeta.remove()
                      setIsError(false)
                    }}
                  >
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                  </span>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    return (
      <div className=''>
        <div {...dropzoneProps} className='border-dashed border-hover-primary'>
          <div className=''>{input}</div>
        </div>
        {previews}
        <div>{submitButton}</div>
      </div>
    )
  }

  const submitButton = (props: ISubmitButtonProps) => {
    return (
      <>
        <div className='separator my-6'></div>
        <div className='text-end'>
          <h6 className='text-danger'>{errors}</h6>
        </div>
        <div className='d-flex flex-stack py-10 px-10'>
          <div className=''>
            <button onClick={handleClose} type='button' className='btn btn-lg btn-secondary me-3'>
              Back
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                props.onSubmit(props.files)
                navigate('/deals/issueTermsheet')
              }}
              className='btn btn-lg btn-primary'
              disabled={props.files[0]?.meta?.status !== 'ready'}
            >
              {props.files[0]?.meta?.status !== 'done' &&
                props.files[0]?.meta?.status !== 'uploading' && (
                  <span className='indicator-label fw-bold'>Upload</span>
                )}
              {props.files[0]?.meta?.status === 'uploading' && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Uploading...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              {props.files[0]?.meta?.status === 'done' && (
                <span className='indicator-label fw-bold'>Uploaded</span>
              )}
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px '
      show={showModal}
      onHide={() => {
        handleClose()
      }}
    >
      <Modal.Header>
        <div className='col d-flex justify-content-between mt-2'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            Upload Term Sheet Sample
          </h2>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary me-1'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => {
              handleClose()
            }}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </div>
      </Modal.Header>
      <Modal.Title className='px-10'>
        <div className='card card-p-0 p-0'>
          <div className='card-body'>
            <h4 className='d-flex align-items-center mt-4'>
              Uploading a copy of the current company term sheet will help us tailor make any future
              term sheets.{' '}
            </h4>
            <div className='fv-row mt-8'>
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                multiple={false}
                styles={{
                  dropzone: { minHeight: 200 },
                  dropzoneActive: { background: 'rgba(222, 220, 220, 0.5)' },
                }}
                accept={'.docx'}
                maxSizeBytes={100000000}
                InputComponent={Input}
                canCancel={true}
                canRemove={true}
                PreviewComponent={Preview}
                LayoutComponent={Layout}
                autoUpload={false}
                maxFiles={1}
                submitButtonContent={'Upload template'}
                onSubmit={handleSubmit}
                SubmitButtonComponent={submitButton}
              />
            </div>
          </div>
        </div>
      </Modal.Title>
    </Modal>
  )
}

export default TemplateUpload
