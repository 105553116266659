import _ from 'lodash'

/**
 *
 * @param arr
 * @param globalFilterText
 * @param fields defaults to all fields
 * @returns search for text in any of the given fields and return all elements matching the criteria
 */
export function globalFilter<T>(arr: T[], globalFilterText: string, fields?: Array<keyof T>): T[] {
  const data = arr.filter((i) => {
    const item = i as any
    fields = fields || (Object.keys(i) as Array<keyof T>)
    for (const field of fields) {
      if (item?.[field].toString().toLowerCase().includes(globalFilterText.toLowerCase().trim())) {
        return true
      }
    }
    return false
  })
  arr = data
  return data
}
