import { FC, useState, useEffect } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import { KTSVG } from '../../../../../../../_metronic/helpers/components/KTSVG'
import { ISendTermsheet } from '../../SendTermsheetHelper'

const FounderRestrictions: FC = () => {
  const formik = useFormikContext<ISendTermsheet>()
  const founderVestingList: string[] = [
    '4 year vesting with 1 year cliff, subject to continued employment',
    'None',
  ]
  const [showOtherField, setShowOtherField] = useState(
    formik.values.founderVesting &&
      founderVestingList.includes(formik.values.founderVesting) === true
      ? false
      : true
  )
  useEffect(() => {
    if (!showOtherField) {
      formik.setFieldValue('customFounderVesting', '')
    }
  }, [showOtherField])

  return (
    <div className='w-100'>
      <div className='pb-10'>
        <h2 className='fw-bolder text-dark'>Founder Restrictions</h2>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Founder Vesting</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='founderVesting'
                  value='4 year vesting with 1 year cliff, subject to continued employment'
                  onChange={(e: any) => {
                    formik.handleChange(e)
                    setShowOtherField(false)
                  }}
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>
                    4 year vesting with 1 year cliff, subject to continued employment
                  </h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='founderVesting'
                  value='None'
                  onChange={(e: any) => {
                    formik.handleChange(e)
                    setShowOtherField(false)
                  }}
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>None</h5>
                  <KTSVG path='V' className='svg-icon-5 svg-icon-gray-500 me-1' />
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='founderVesting'
                  value={
                    formik.values.founderVesting &&
                    !founderVestingList.includes(formik.values.founderVesting)
                      ? formik.values.founderVesting
                      : 'Custom'
                  }
                  defaultChecked={showOtherField}
                  onClick={(e: any) => {
                    formik.handleChange(e)
                    setShowOtherField(!showOtherField)
                  }}
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Custom</h5>
                </span>
              </span>
              {showOtherField && (
                <Field
                  type='text'
                  className='form-control form-control-lg form-control-solid w-50 ms-4'
                  name='founderVesting'
                  defaultValue={showOtherField && formik.values.founderVesting}
                  disabled={!showOtherField}
                />
              )}
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='founderVesting' />
          </div>
        </div>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Founder Lock-in</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='founderLockin'
                  value='3 year lock-in on transfer'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>3 year lock-in on transfer</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='founderLockin'
                  value='No transfer of founder shares without prior Investor consent'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>
                    No transfer of founder shares without prior Investor consent
                  </h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='founderLockin'
                  value='Lock-in until the exit of Investor'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Lock-in until the exit of Investor</h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='founderLockin' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FounderRestrictions
