import { useState } from 'react'
import {
  isPortfolioMetricEnabled,
  setPortfolioMetricEnabled,
} from '../../../../utils/LocalStorageUtils'

function KeyMetricsDropdown(props: any) {
  const metricsOptions = [
    // { title: 'Net Profit', display: isPortfolioMetricEnabled('Net Profit') },
    { title: 'Current Value', display: isPortfolioMetricEnabled('Current Value') },
    { title: 'Gain', display: isPortfolioMetricEnabled('Gain') },
    { title: 'MOIC', display: isPortfolioMetricEnabled('MOIC') },
    { title: 'Industries', display: isPortfolioMetricEnabled('Industries') },
    { title: 'Total Shares Value', display: isPortfolioMetricEnabled('Total Shares Value') },
    { title: 'Open Deals', display: isPortfolioMetricEnabled('Open Deals') },
  ]

  const [options, setOptions] = useState(metricsOptions)

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px py-3'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content text-muted pb-2 px-3 fs-5'>Summary</div>
      </div>

      {options.map((option) => {
        return (
          <label
            key={option.title}
            className='ms-3 form-check form-check-sm form-check-custom form-check-solid me-5 px-3 py-3'
          >
            <input
              className='form-check-input'
              type='checkbox'
              key={option.title}
              checked={isPortfolioMetricEnabled(option.title)}
              onChange={(e) => {
                const index = options.findIndex((item) => {
                  return item.title === option.title
                })
                let updatedOptions = options.map((item, itemIndex) => {
                  if (index === itemIndex) {
                    const checked = option.display
                    return { ...option, display: !checked }
                  }
                  return item
                })
                setOptions(updatedOptions)
                props.handleChange(e, option.title)
                setPortfolioMetricEnabled(option.title)
              }}
            />
            <span className='form-check-label fw-bold fs-5 ms-3'>{option.title}</span>
          </label>
        )
      })}
    </div>
  )
}

export default KeyMetricsDropdown
