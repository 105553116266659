/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import ChangePassword from '../../../../app/modules/auth/components/ChangePassword'
import { queryCache } from '../../../../queries/client'
import { toAbsoluteUrl } from '../../../helpers'

const HeaderUserMenu: FC<{ userName: string; userEmail: string }> = ({ userName, userEmail }) => {
  const { logout } = useAuth()
  const [showModal, setShowModal] = useState(false)
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {userName}
              {/* <span className='badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2'>Angel</span> */}
            </div>
            <p className='text-primary py-1 fs-base'>
              {userEmail.length < 22 ? userEmail : userEmail.substring(0, 22) + '...'}
            </p>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          Profile
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Settings
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/widgets/feeds' className='menu-link px-5'>
          Notifications
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <a onClick={() => setShowModal(true)} className='menu-link px-5'>
          Change Password
        </a>
      </div>
      <div className='menu-item px-5'>
        <a
          onClick={() => {
            logout()
            queryCache.clear()
            // queryClient.invalidateQueries()
            // console.log(queryCache.getAll())
          }}
          className='menu-link px-5'
        >
          Sign Out
        </a>
      </div>
      {showModal && (
        <ChangePassword
          show={showModal}
          handleClose={() => {
            setShowModal(false)
          }}
        />
      )}
    </div>
  )
}

export { HeaderUserMenu }
