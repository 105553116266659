import { FC } from 'react'
import { Field, ErrorMessage } from 'formik'

const TransferRestrictions: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10'>
        <h2 className='fw-bolder text-dark'>Transfer Restrictions</h2>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Drag Along Right</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='dar'
                  value='Investors can drag others'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Investors can drag others</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='dar'
                  value='Founders and other shareholders (except the Investor) can drag others'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>
                    Founders and other shareholders (except the Investor) can drag others
                  </h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='dar'
                  value='Terms to be negotiated in definitive agreements'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>
                    Terms to be negotiated in definitive agreements
                  </h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='dar' />
          </div>
        </div>
      </div>
      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Tag Along Right</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='tar'
                  value='Investor have a right to tag along'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Investor have a right to tag along</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='tar'
                  value='Founders have a right to tag along'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Founders have a right to tag along</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='tar'
                  value='Terms to be negotiated in definitive agreements'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>
                    Terms to be negotiated in definitive agreements
                  </h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='tar' />
          </div>
        </div>
      </div>
      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Right of First Refusal</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='rofr'
                  value='Investor has a ROFR'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Investor has a ROFR</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='rofr'
                  value='Promoters have a ROFR'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Promoters have a ROFR</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='rofr'
                  value='Terms to be negotiated in definitive agreements'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>
                    Terms to be negotiated in definitive agreements
                  </h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='rofr' />
          </div>
        </div>
      </div>
      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Right of First Offer</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='rofo'
                  value='Investor has a ROFO'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Investor has a ROFO</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='rofo'
                  value='Promoters have a ROFO'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Promoters have a ROFO</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='rofo'
                  value='Terms to be negotiated in definitive agreements'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>
                    Terms to be negotiated in definitive agreements
                  </h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='rofo' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransferRestrictions
