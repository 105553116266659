import { useNavigate } from 'react-router-dom'
import { kFormatter } from '../../../../utils/CurrencyFormatter'
import { getInitials } from '../../../../utils/Utils'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'

type CompanyCardProps = {
  logoPath: string
  name: any
  companyId: string
  hissaVerified: boolean
  valuation: number
  sharePrice: number
  latestRaise: number
  noOfRounds: number
  lastInvestmentDate?: string
  lastInvestment?: number
}

function CompanyCard(props: CompanyCardProps) {
  const navigate = useNavigate()
  return (
    <div className='mb-0'>
      <div
        className='d-flex justify-content-start align-items-start card shadow-sm p-5 h-100 card-hover'
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          navigate('/company/stakeholders', {
            state: {
              props: {
                name: props.name,
                companyId: props.companyId,
                hissaVerified: props.hissaVerified,
                initials: getInitials(props.name),
              },
            },
          })
        }}
      >
        <div className='row'>
          <div className='d-flex h-80px'>
            {props.logoPath && props.logoPath !== 'NA' && props.logoPath !== '' ? (
              <img src={props.logoPath} alt='' className='p-4 bg-secondary rounded' />
            ) : (
              <div className='bg-secondary rounded h-80px min-w-80px fw-bold d-flex justify-content-center align-items-center'>
                <p
                  className='mb-0 fs-3x'
                  style={{
                    color: getCSSVariableValue('--kt-donut-' + (9 % 10)),
                  }}
                >
                  {getInitials(props.name)}
                </p>
              </div>
            )}
            <h2 className='px-4 mt-2 cursor-pointer text-hover-primary'>{props.name}</h2>
          </div>
        </div>
        <div className='row d-flex'>
          <div className='col-6 p-0'>
            <div className='row m-2 d-flex justify-content-between align-items-center h-100'>
              <div className='col-6 p-0 fs-6 text-gray-600'>Valuation</div>
              <div
                className='col-6 p-0 fs-5 text-end'
                style={{ color: getCSSVariableValue('--kt-donut-' + 6) }}
              >
                {props.valuation === 0 ? (
                  'NA'
                ) : (
                  <>
                    <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                    <span>{kFormatter(props.valuation)}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='col-6 p-0'>
            <div className='row m-2 d-flex justify-content-between align-items-center h-100'>
              <div className='col-6 p-0 fs-6 text-gray-600'>PPS value</div>
              <div
                className='col-6 p-0 fs-5 text-end'
                style={{ color: getCSSVariableValue('--kt-donut-' + 9) }}
              >
                {props.sharePrice === 0 ? (
                  'NA'
                ) : (
                  <>
                    <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                    <span>{kFormatter(props.sharePrice)}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='col-6 p-0'>
            <div className='row m-2 d-flex justify-content-between align-items-center h-100'>
              <div className='col-6 p-0 fs-6 text-gray-600'>Latest raise</div>
              <div
                className='col-6 p-0 fs-5 text-end'
                style={{ color: getCSSVariableValue('--kt-donut-' + 0) }}
              >
                {props.latestRaise === 0 ? (
                  'NA'
                ) : (
                  <>
                    <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                    <span>{kFormatter(props.latestRaise)}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='col-6 p-0'>
            <div className='row m-2 d-flex justify-content-between align-items-center h-100'>
              <div className='col-8 p-0 fs-6 text-gray-600'>No. of rounds</div>
              <div
                className='col-4 p-0 fs-5 text-end'
                style={{ color: getCSSVariableValue('--kt-donut-' + 4) }}
              >
                {props.noOfRounds === 0 ? (
                  'NA'
                ) : (
                  <>
                    <span>{kFormatter(props.noOfRounds)}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          {props.lastInvestment !== null && props.lastInvestment! > 0 ? (
            <div className='col-12 p-0 mt-2 card-footer'>
              <div className='row m-2 d-flex justify-content-between align-items-center'>
                <div className='col-12 p-0 fs-6'>Last Investment</div>
                <div className='col-6 p-0 fs-6 text-gray-600'>{props.lastInvestmentDate!}</div>
                <div className='col-6 p-0 fs-6 text-gray-600 text-end'>
                  <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                  <span>{kFormatter(props.lastInvestment!)}</span>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {props.valuation > 81000000000 ? (
          <div className='card card-dashed bg-primary-light mx-0 mb-n5'>
            <p className='m-5'>
              Company has reached <span className='text-danger'>Unicorn Status</span>!
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default CompanyCard
