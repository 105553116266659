import { GraphQLClient } from 'graphql-request'
import { RequestInit } from 'graphql-request/dist/types.dom'
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }

function fetcher<TData, TVariables>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers']
) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables, headers)
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigInt: any
  Date: any
  DateTime: any
  JSON: any
  JSONObject: any
  Upload: any
}

export type AddBeneficiaryInput = {
  beneficiaries?: InputMaybe<Array<InputMaybe<Beneficiary>>>
  investmentId?: InputMaybe<Scalars['BigInt']>
  investorId?: InputMaybe<Scalars['BigInt']>
}

export type AddCompaniesToGroupInput = {
  companies?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
  id?: InputMaybe<Scalars['BigInt']>
}

export type AddCompanyToListInput = {
  cin?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['BigInt']>
  name?: InputMaybe<Scalars['String']>
}

export type AddDealTemplateInput = {
  fileName?: InputMaybe<Scalars['Upload']>
  investorId?: InputMaybe<Scalars['BigInt']>
  name?: InputMaybe<Scalars['String']>
  sections?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AddExitManualInvestment = {
  companyName?: InputMaybe<Scalars['String']>
  exchangeRate?: InputMaybe<Scalars['Float']>
  exitDate?: InputMaybe<Scalars['DateTime']>
  exitType?: InputMaybe<Scalars['String']>
  investmentId?: InputMaybe<Scalars['BigInt']>
  investorName?: InputMaybe<Scalars['String']>
  noOfShares?: InputMaybe<Scalars['BigInt']>
  roundName?: InputMaybe<Scalars['String']>
  sharePrice?: InputMaybe<Scalars['Float']>
}

export type AddGroupInput = {
  companies?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
  name?: InputMaybe<Scalars['String']>
}

export type AddInvestmentBulkUpload = {
  filename?: InputMaybe<Scalars['Upload']>
}

export type AddInvestmentImportCaptable = {
  filename?: InputMaybe<Scalars['Upload']>
}

export type AddInvestmentInput = {
  cin?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  currentExchangeRate?: InputMaybe<Scalars['Float']>
  currentSharePrice?: InputMaybe<Scalars['Float']>
  exchangeRate?: InputMaybe<Scalars['Float']>
  industry?: InputMaybe<Scalars['String']>
  investmentDate?: InputMaybe<Scalars['DateTime']>
  investorId?: InputMaybe<Scalars['BigInt']>
  jurisdiction?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  noOfShares?: InputMaybe<Scalars['BigInt']>
  round?: InputMaybe<Scalars['String']>
  sharePrice?: InputMaybe<Scalars['Float']>
  valuation?: InputMaybe<Scalars['Float']>
}

export type AddInvestorInput = {
  hissaId?: InputMaybe<Scalars['BigInt']>
  name?: InputMaybe<Scalars['String']>
  source: Source
  type?: InputMaybe<Scalars['String']>
}

export type AddLinkInput = {
  cin?: InputMaybe<Scalars['String']>
  folder?: InputMaybe<Scalars['String']>
  investorId?: InputMaybe<Scalars['BigInt']>
  link?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type AddStarCompanyInput = {
  companyName?: InputMaybe<Scalars['String']>
  investorIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>
  watchlistId?: InputMaybe<Scalars['BigInt']>
}

export type AddWatchlistInput = {
  name?: InputMaybe<Scalars['String']>
}

export type Beneficiary = {
  name?: InputMaybe<Scalars['String']>
  percentage?: InputMaybe<Scalars['Float']>
  range?: InputMaybe<Scalars['String']>
}

export type Company = {
  __typename?: 'Company'
  business?: Maybe<Scalars['String']>
  cin?: Maybe<Scalars['String']>
  exitData?: Maybe<Array<Maybe<Transaction>>>
  id?: Maybe<Scalars['BigInt']>
  industry?: Maybe<Scalars['String']>
  latestRaise?: Maybe<Scalars['Float']>
  latestRaiseDate?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  noOfRounds?: Maybe<Scalars['Int']>
  sharePrice?: Maybe<Scalars['Float']>
  shareholderSummary?: Maybe<ShareholderSummary>
  shareholders?: Maybe<Array<Maybe<Scalars['JSON']>>>
  shareholdingDetails?: Maybe<Array<Maybe<Scalars['JSON']>>>
  starred?: Maybe<Scalars['Boolean']>
  totalFunding?: Maybe<Scalars['Float']>
  totalShares?: Maybe<Scalars['BigInt']>
  valuation?: Maybe<Scalars['Float']>
}

export type CopyBeneficiaryInput = {
  fromId?: InputMaybe<Scalars['BigInt']>
  investorId?: InputMaybe<Scalars['BigInt']>
  toId?: InputMaybe<Scalars['BigInt']>
}

export type CurrencyExchangeInput = {
  currencyCode?: InputMaybe<Scalars['String']>
  currencyName?: InputMaybe<Scalars['String']>
}

export type CurrencyExchangeRate = {
  __typename?: 'CurrencyExchangeRate'
  currencyCode?: Maybe<Scalars['String']>
  currencyName?: Maybe<Scalars['String']>
  currencyRate?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['BigInt']>
  updatedAt?: Maybe<Scalars['String']>
}

export enum Document_Vault {
  Employee = 'EMPLOYEE',
  Investor = 'INVESTOR',
}

export type DealTemplate = {
  __typename?: 'DealTemplate'
  companiesIssuedTo?: Maybe<Array<Maybe<Scalars['String']>>>
  creationDate?: Maybe<Scalars['String']>
  fileURL?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['BigInt']>
  investorId?: Maybe<Scalars['BigInt']>
  lastIssuedDate?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sections?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type EditInvestmentInput = {
  cin?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  currentExchangeRate?: InputMaybe<Scalars['Float']>
  currentSharePrice?: InputMaybe<Scalars['Float']>
  exchangeRate?: InputMaybe<Scalars['Float']>
  id?: InputMaybe<Scalars['BigInt']>
  investmentDate?: InputMaybe<Scalars['DateTime']>
  investorId?: InputMaybe<Scalars['BigInt']>
  jurisdiction?: InputMaybe<Scalars['String']>
  noOfShares?: InputMaybe<Scalars['BigInt']>
  round?: InputMaybe<Scalars['String']>
  sharePrice?: InputMaybe<Scalars['Float']>
  valuation?: InputMaybe<Scalars['Float']>
}

export type EditWatchlistInput = {
  id?: InputMaybe<Scalars['BigInt']>
  name?: InputMaybe<Scalars['String']>
}

export type EmailTermsheetInput = {
  companyEmail?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  investorEmail?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  termsheetId?: InputMaybe<Scalars['BigInt']>
}

export type FetchCompanyInput = {
  cin?: InputMaybe<Scalars['String']>
  invId?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

export type FetchDealTemplatesInput = {
  investorId?: InputMaybe<Scalars['BigInt']>
}

export type FetchTermsheetInput = {
  termsheetId?: InputMaybe<Scalars['BigInt']>
}

export type FetchWatchlistInput = {
  id?: InputMaybe<Scalars['BigInt']>
}

export type File = {
  __typename?: 'File'
  encoding: Scalars['String']
  filename: Scalars['String']
  mimetype: Scalars['String']
}

export type FilesDataInput = {
  cin?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['BigInt']>
  vaultType?: InputMaybe<Document_Vault>
}

export type GetGroupInput = {
  id?: InputMaybe<Scalars['BigInt']>
}

export type Group = {
  __typename?: 'Group'
  companies?: Maybe<Array<Maybe<Scalars['String']>>>
  id?: Maybe<Scalars['BigInt']>
  name?: Maybe<Scalars['String']>
}

export type Holding = {
  __typename?: 'Holding'
  business?: Maybe<Scalars['String']>
  cin?: Maybe<Scalars['String']>
  currentSharePrice?: Maybe<Scalars['Float']>
  currentValue?: Maybe<Scalars['Float']>
  exitValue?: Maybe<Scalars['Float']>
  hissaVerified?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['BigInt']>
  industry?: Maybe<Scalars['String']>
  investedAmount?: Maybe<Scalars['Float']>
  investmentDate?: Maybe<Scalars['DateTime']>
  irr?: Maybe<Scalars['Float']>
  isExited?: Maybe<Scalars['Boolean']>
  leadInvestorName?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  moic?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  noOfShares?: Maybe<Scalars['Int']>
  perSharePrice?: Maybe<Scalars['Float']>
  percentage?: Maybe<Scalars['Float']>
  round?: Maybe<Scalars['String']>
}

export type InvestmentByStage = {
  __typename?: 'InvestmentByStage'
  count?: Maybe<Scalars['Int']>
  stage?: Maybe<Scalars['String']>
}

export type Investor = {
  __typename?: 'Investor'
  hissaId?: Maybe<Scalars['BigInt']>
  holdings?: Maybe<Array<Maybe<Holding>>>
  id?: Maybe<Scalars['BigInt']>
  name?: Maybe<Scalars['String']>
  portfolios?: Maybe<Array<Maybe<Portfolio>>>
  watchlists?: Maybe<Array<Maybe<Watchlist>>>
}

export type InvestorDataInput = {
  cin?: InputMaybe<Scalars['String']>
  investorId?: InputMaybe<Scalars['BigInt']>
}

export type InvestorDetail = {
  amountCommited?: InputMaybe<Scalars['BigInt']>
  emailId?: InputMaybe<Scalars['String']>
  investorId?: InputMaybe<Scalars['BigInt']>
  investorName?: InputMaybe<Scalars['String']>
}

export type InvestorFetchInput = {
  id?: InputMaybe<Scalars['BigInt']>
  name?: InputMaybe<Scalars['String']>
}

export type InvestorOrCompanyInput = {
  companies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  investorIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>
}

export type InvestorRemoveInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>
}

export type Mutation = {
  __typename?: 'Mutation'
  addBeneficiary?: Maybe<Scalars['JSONObject']>
  addClassification?: Maybe<Scalars['JSON']>
  addCompaniesToClassification?: Maybe<Scalars['JSON']>
  addCompanyToList?: Maybe<Scalars['JSONObject']>
  addDealTemplate?: Maybe<Scalars['JSONObject']>
  addDocumentLink?: Maybe<Scalars['JSON']>
  addExitManualInvestment?: Maybe<Scalars['JSONObject']>
  addInvestment?: Maybe<Scalars['JSONObject']>
  addInvestmentBulkUpload?: Maybe<Scalars['JSONObject']>
  addInvestmentImportCaptable?: Maybe<Scalars['JSONObject']>
  /** add an investor entity to current user  no user id is required as it is taken from JWT */
  addInvestor?: Maybe<Scalars['JSONObject']>
  addStarCompany?: Maybe<Scalars['JSONObject']>
  addTermsheet?: Maybe<Scalars['JSON']>
  addWatchlist?: Maybe<Scalars['JSONObject']>
  copyBeneficiary?: Maybe<Scalars['JSONObject']>
  editClassification?: Maybe<Scalars['JSON']>
  editInvestment?: Maybe<Scalars['JSONObject']>
  editWatchlist?: Maybe<Scalars['JSONObject']>
  emailTermsheet?: Maybe<Scalars['JSON']>
  fileUpload?: Maybe<Scalars['JSON']>
  getCurrencyExchangeRate?: Maybe<CurrencyExchangeRate>
  removeBeneficiary?: Maybe<Scalars['JSONObject']>
  removeClassification?: Maybe<Scalars['JSON']>
  removeCompaniesFromClassification?: Maybe<Scalars['JSON']>
  removeCompanyFromList?: Maybe<Scalars['JSONObject']>
  removeDealTemplate?: Maybe<Scalars['JSONObject']>
  removeDocumentLink?: Maybe<Scalars['JSON']>
  removeExitManualInvestment?: Maybe<Scalars['JSONObject']>
  removeInvestment?: Maybe<Scalars['JSONObject']>
  removeInvestors?: Maybe<Scalars['JSONObject']>
  removeObject?: Maybe<Scalars['JSON']>
  removeStarCompany?: Maybe<Scalars['JSONObject']>
  removeTermsheet?: Maybe<Scalars['JSONObject']>
  removeWatchlist?: Maybe<Scalars['JSONObject']>
  singleUpload: File
  updateDealTemplate?: Maybe<Scalars['JSONObject']>
  updateDefaultInvestor?: Maybe<Scalars['JSON']>
  updateExitManualInvestment?: Maybe<Scalars['JSONObject']>
  updateInvestments?: Maybe<Scalars['JSONObject']>
}

export type MutationAddBeneficiaryArgs = {
  input?: InputMaybe<AddBeneficiaryInput>
}

export type MutationAddClassificationArgs = {
  input?: InputMaybe<AddGroupInput>
}

export type MutationAddCompaniesToClassificationArgs = {
  input?: InputMaybe<AddCompaniesToGroupInput>
}

export type MutationAddCompanyToListArgs = {
  input: AddCompanyToListInput
}

export type MutationAddDealTemplateArgs = {
  input?: InputMaybe<AddDealTemplateInput>
}

export type MutationAddDocumentLinkArgs = {
  input?: InputMaybe<AddLinkInput>
}

export type MutationAddExitManualInvestmentArgs = {
  input?: InputMaybe<AddExitManualInvestment>
}

export type MutationAddInvestmentArgs = {
  input?: InputMaybe<AddInvestmentInput>
}

export type MutationAddInvestmentBulkUploadArgs = {
  input?: InputMaybe<AddInvestmentBulkUpload>
}

export type MutationAddInvestmentImportCaptableArgs = {
  input?: InputMaybe<AddInvestmentImportCaptable>
}

export type MutationAddInvestorArgs = {
  input: AddInvestorInput
}

export type MutationAddStarCompanyArgs = {
  input?: InputMaybe<AddStarCompanyInput>
}

export type MutationAddTermsheetArgs = {
  input?: InputMaybe<TermsheetInput>
  sendImmediately?: InputMaybe<Scalars['Boolean']>
}

export type MutationAddWatchlistArgs = {
  input: AddWatchlistInput
}

export type MutationCopyBeneficiaryArgs = {
  input?: InputMaybe<CopyBeneficiaryInput>
}

export type MutationEditClassificationArgs = {
  input?: InputMaybe<UpdateGroupInput>
}

export type MutationEditInvestmentArgs = {
  input?: InputMaybe<EditInvestmentInput>
}

export type MutationEditWatchlistArgs = {
  input: EditWatchlistInput
}

export type MutationEmailTermsheetArgs = {
  input?: InputMaybe<EmailTermsheetInput>
}

export type MutationFileUploadArgs = {
  input?: InputMaybe<UploadFile>
}

export type MutationGetCurrencyExchangeRateArgs = {
  input?: InputMaybe<CurrencyExchangeInput>
}

export type MutationRemoveBeneficiaryArgs = {
  input?: InputMaybe<RemoveInvestmentInput>
}

export type MutationRemoveClassificationArgs = {
  input?: InputMaybe<RemoveGroupInput>
}

export type MutationRemoveCompaniesFromClassificationArgs = {
  input?: InputMaybe<AddCompaniesToGroupInput>
}

export type MutationRemoveCompanyFromListArgs = {
  input?: InputMaybe<RemoveCompanyFromListInput>
}

export type MutationRemoveDealTemplateArgs = {
  input?: InputMaybe<RemoveDealTemplateInput>
}

export type MutationRemoveDocumentLinkArgs = {
  input?: InputMaybe<RemoveLinkInput>
}

export type MutationRemoveExitManualInvestmentArgs = {
  input?: InputMaybe<RemoveExitManualInvestment>
}

export type MutationRemoveInvestmentArgs = {
  input?: InputMaybe<RemoveInvestmentInput>
}

export type MutationRemoveInvestorsArgs = {
  input?: InputMaybe<InvestorRemoveInput>
}

export type MutationRemoveObjectArgs = {
  input?: InputMaybe<UploadFile>
}

export type MutationRemoveStarCompanyArgs = {
  input?: InputMaybe<RemoveStarCompanyInput>
}

export type MutationRemoveTermsheetArgs = {
  input?: InputMaybe<TermsheetRemoveInput>
}

export type MutationRemoveWatchlistArgs = {
  input?: InputMaybe<RemoveWatchlistInput>
}

export type MutationSingleUploadArgs = {
  file: Scalars['Upload']
}

export type MutationUpdateDealTemplateArgs = {
  input?: InputMaybe<UpdateDealTemplateInput>
}

export type MutationUpdateDefaultInvestorArgs = {
  defaultInvestorId?: InputMaybe<Scalars['BigInt']>
}

export type MutationUpdateExitManualInvestmentArgs = {
  input?: InputMaybe<UpdateExitManualInvestment>
}

export type MutationUpdateInvestmentsArgs = {
  input?: InputMaybe<UpdateInvestmentsInput>
}

export type Portfolio = {
  __typename?: 'Portfolio'
  holdings?: Maybe<Array<Maybe<Holding>>>
  id?: Maybe<Scalars['BigInt']>
  name?: Maybe<Scalars['String']>
}

export type PortfolioEvents = {
  __typename?: 'PortfolioEvents'
  companyName?: Maybe<Scalars['String']>
  eventDate?: Maybe<Scalars['Date']>
  eventType?: Maybe<Scalars['String']>
  investorId?: Maybe<Scalars['BigInt']>
}

export type Query = {
  __typename?: 'Query'
  BigInt?: Maybe<Scalars['BigInt']>
  Date?: Maybe<Scalars['Date']>
  JSON?: Maybe<Scalars['JSON']>
  JSONObject?: Maybe<Scalars['JSONObject']>
  allInvestments?: Maybe<Array<Maybe<Scalars['JSON']>>>
  beneficiaries?: Maybe<Array<Maybe<Scalars['String']>>>
  classification?: Maybe<Scalars['JSON']>
  classifications?: Maybe<Scalars['JSON']>
  companies: Array<Maybe<Company>>
  data?: Maybe<Scalars['JSON']>
  dealTemplates?: Maybe<Array<Maybe<DealTemplate>>>
  /** get default investor for user */
  defaultInvestor?: Maybe<Scalars['JSON']>
  exitDataInvestorWise?: Maybe<Array<Maybe<Transaction>>>
  investmentByStage?: Maybe<Array<Maybe<InvestmentByStage>>>
  investments?: Maybe<Scalars['JSON']>
  /** get all investors for user */
  investors?: Maybe<Scalars['JSON']>
  portfolioEvents?: Maybe<Array<Maybe<PortfolioEvents>>>
  portfolioMetrics?: Maybe<Array<Maybe<RoundsCount>>>
  /** search company for watchlist */
  searchCompany?: Maybe<Scalars['JSON']>
  starCompanies?: Maybe<Scalars['JSON']>
  tasksOverview?: Maybe<Array<Maybe<TaskOverviewEvent>>>
  termsheet?: Maybe<Scalars['JSON']>
  termsheets?: Maybe<Array<Maybe<Scalars['JSON']>>>
  urlForS3Object?: Maybe<Scalars['String']>
  watchlists?: Maybe<Array<Maybe<Watchlist>>>
}

export type QueryClassificationArgs = {
  input?: InputMaybe<GetGroupInput>
}

export type QueryCompaniesArgs = {
  input?: InputMaybe<FetchCompanyInput>
}

export type QueryDataArgs = {
  input?: InputMaybe<FilesDataInput>
}

export type QueryExitDataInvestorWiseArgs = {
  investorIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>
}

export type QueryInvestmentByStageArgs = {
  input?: InputMaybe<InvestorOrCompanyInput>
}

export type QueryInvestmentsArgs = {
  input?: InputMaybe<FetchInvestmentInput>
}

export type QueryInvestorsArgs = {
  input?: InputMaybe<InvestorFetchInput>
}

export type QueryPortfolioEventsArgs = {
  input?: InputMaybe<InvestorOrCompanyInput>
}

export type QueryPortfolioMetricsArgs = {
  input?: InputMaybe<InvestorOrCompanyInput>
}

export type QuerySearchCompanyArgs = {
  input?: InputMaybe<SearchCompanyInput>
}

export type QueryTermsheetArgs = {
  input?: InputMaybe<FetchTermsheetInput>
}

export type QueryUrlForS3ObjectArgs = {
  input?: InputMaybe<S3ObjectUrl>
}

export type QueryWatchlistsArgs = {
  input?: InputMaybe<FetchWatchlistInput>
}

export type QueryStarCompaniesArgs = {
  investorIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>
}

export type RemoveCompanyFromGroupInput = {
  id?: InputMaybe<Scalars['BigInt']>
  name?: InputMaybe<Scalars['String']>
}

export type RemoveCompanyFromListInput = {
  cin?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['BigInt']>
}

export type RemoveDealTemplateInput = {
  id?: InputMaybe<Scalars['BigInt']>
  investorId?: InputMaybe<Scalars['BigInt']>
}

export type RemoveExitManualInvestment = {
  id?: InputMaybe<Scalars['BigInt']>
}

export type RemoveGroupInput = {
  id?: InputMaybe<Scalars['BigInt']>
}

export type RemoveInvestmentInput = {
  id?: InputMaybe<Scalars['BigInt']>
  investorId?: InputMaybe<Scalars['BigInt']>
}

export type RemoveLinkInput = {
  id?: InputMaybe<Scalars['BigInt']>
  investorId?: InputMaybe<Scalars['BigInt']>
}

export type RemoveStarCompanyInput = {
  companyName?: InputMaybe<Scalars['String']>
  investorIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>
  watchlistId?: InputMaybe<Scalars['BigInt']>
}

export type RemoveWatchlistInput = {
  id?: InputMaybe<Scalars['BigInt']>
}

export type RoundsCount = {
  __typename?: 'RoundsCount'
  amount?: Maybe<Scalars['Float']>
  count?: Maybe<Scalars['BigInt']>
  investorId?: Maybe<Scalars['BigInt']>
  investorName?: Maybe<Scalars['String']>
}

export type S3ObjectUrl = {
  bucket?: InputMaybe<Scalars['String']>
  expiry?: InputMaybe<Scalars['BigInt']>
  isUpload?: InputMaybe<Scalars['Boolean']>
  key?: InputMaybe<Scalars['String']>
}

export enum Source {
  Existing = 'EXISTING',
  New = 'NEW',
}

export enum Star_Comp_Source {
  Portfolio = 'PORTFOLIO',
  Watchlist = 'WATCHLIST',
}

export type SearchCompanyInput = {
  name?: InputMaybe<Scalars['String']>
}

export type SendTermsheetInput = {
  companyCin: Scalars['String']
  companyName: Scalars['String']
  investorId: Scalars['BigInt']
}

export type ShareholderSummary = {
  __typename?: 'ShareholderSummary'
  esopHolding?: Maybe<Scalars['Float']>
  founderOwnerShip?: Maybe<Scalars['Float']>
  leadInvestor?: Maybe<Scalars['String']>
  noOfFounders?: Maybe<Scalars['Int']>
  noOfInvestors?: Maybe<Scalars['Int']>
}

export enum Task_Event {
  InvestmentMade = 'INVESTMENT_MADE',
  TermsheetIssued = 'TERMSHEET_ISSUED',
}

export enum Task_Event_Status {
  Completed = 'COMPLETED',
  Inprogress = 'INPROGRESS',
  Pending = 'PENDING',
}

export type TaskOverviewEvent = {
  __typename?: 'TaskOverviewEvent'
  companyName?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  eventStatus?: Maybe<Task_Event_Status>
  eventType?: Maybe<Task_Event>
}

export type Termsheet = {
  __typename?: 'Termsheet'
  amountInvested?: Maybe<Scalars['BigInt']>
  antiDilution?: Maybe<Scalars['String']>
  companyCin: Scalars['String']
  companyName: Scalars['String']
  country?: Maybe<Scalars['String']>
  diligence?: Maybe<Scalars['Boolean']>
  directorBoardComposition?: Maybe<Scalars['Int']>
  discount?: Maybe<Scalars['Float']>
  discountConvertible?: Maybe<Scalars['Float']>
  dragAlong?: Maybe<Scalars['String']>
  exclusivity?: Maybe<Scalars['Int']>
  exitRights?: Maybe<Scalars['String']>
  expiryDate?: Maybe<Scalars['String']>
  expiryDays?: Maybe<Scalars['Int']>
  fileURL?: Maybe<Scalars['String']>
  floorValuation?: Maybe<Scalars['BigInt']>
  founderLockin?: Maybe<Scalars['String']>
  founderVesting?: Maybe<Scalars['String']>
  investorId: Scalars['BigInt']
  investorRights?: Maybe<Array<Maybe<Scalars['String']>>>
  isPricedRound?: Maybe<Scalars['Boolean']>
  liquidationPreference?: Maybe<Scalars['String']>
  observerBoardComposition?: Maybe<Scalars['Int']>
  postRoundEsopPercentage?: Maybe<Scalars['Float']>
  pricedRoundString?: Maybe<Scalars['String']>
  proposedDate?: Maybe<Scalars['String']>
  rightOfFO?: Maybe<Scalars['String']>
  rightOfFR?: Maybe<Scalars['String']>
  roundSize?: Maybe<Scalars['BigInt']>
  tagAlong?: Maybe<Scalars['String']>
  templateId?: Maybe<Scalars['Int']>
  transactionCost?: Maybe<Scalars['String']>
  validityMisc?: Maybe<Scalars['String']>
  valuation?: Maybe<Scalars['BigInt']>
  valuationCap?: Maybe<Scalars['BigInt']>
}

export type TermsheetInput = {
  additionalSections?: InputMaybe<Scalars['JSON']>
  amountInvested: Scalars['BigInt']
  companyCin: Scalars['String']
  companyName: Scalars['String']
  expiryDate: Scalars['String']
  investorId: Scalars['BigInt']
  mandatorySections?: InputMaybe<Scalars['JSON']>
  roundSize: Scalars['BigInt']
  status?: InputMaybe<Scalars['String']>
  templateId?: InputMaybe<Scalars['Int']>
  termsheetId?: InputMaybe<Scalars['BigInt']>
  termsheetName: Scalars['String']
  valuation: Scalars['BigInt']
}

export type TermsheetRemoveInput = {
  termsheetId?: InputMaybe<Scalars['BigInt']>
}

export type Transaction = {
  __typename?: 'Transaction'
  amount?: Maybe<Scalars['Float']>
  cin?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  investorName?: Maybe<Scalars['String']>
  noOfShares?: Maybe<Scalars['BigInt']>
  pricePerShare?: Maybe<Scalars['Float']>
  roundIdentifier?: Maybe<Scalars['String']>
  roundName?: Maybe<Scalars['String']>
  transactionDate?: Maybe<Scalars['String']>
  transactionType?: Maybe<Scalars['String']>
}

export type UpdateDealTemplateInput = {
  id?: InputMaybe<Scalars['BigInt']>
  investorId?: InputMaybe<Scalars['BigInt']>
  name?: InputMaybe<Scalars['String']>
  sections?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UpdateExitManualInvestment = {
  exchangeRate?: InputMaybe<Scalars['Float']>
  exitDate?: InputMaybe<Scalars['DateTime']>
  exitType?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['BigInt']>
  investmentId?: InputMaybe<Scalars['BigInt']>
  noOfShares?: InputMaybe<Scalars['BigInt']>
  sharePrice?: InputMaybe<Scalars['Float']>
}

export type UpdateGroupInput = {
  id?: InputMaybe<Scalars['BigInt']>
  name?: InputMaybe<Scalars['String']>
}

export type UpdateInvestmentsInput = {
  cin?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>
  industry?: InputMaybe<Scalars['String']>
  investorIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>
}

export type UpdateTermsheetInput = {
  additionalSections?: InputMaybe<Scalars['JSON']>
  amountInvested: Scalars['BigInt']
  expiryDate: Scalars['String']
  investorId: Scalars['BigInt']
  mandatorySections?: InputMaybe<Scalars['JSON']>
  roundSize: Scalars['BigInt']
  templateId?: InputMaybe<Scalars['Int']>
  termsheetId: Scalars['BigInt']
  valuation: Scalars['BigInt']
}

export type UploadFile = {
  cin?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['Upload']>
  fileName?: InputMaybe<Scalars['String']>
  folder?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['BigInt']>
  investorId?: InputMaybe<Scalars['BigInt']>
  name?: InputMaybe<Scalars['String']>
}

export enum Watchlist_Event {
  LatestRaise = 'LATEST_RAISE',
  MultipleUsers = 'MULTIPLE_USERS',
  Termsheet = 'TERMSHEET',
  Unicorn = 'UNICORN',
}

export type Watchlist = {
  __typename?: 'Watchlist'
  companies?: Maybe<Array<Maybe<Company>>>
  highlights?: Maybe<Array<Maybe<WatchlistEvents>>>
  id?: Maybe<Scalars['BigInt']>
  name?: Maybe<Scalars['String']>
}

export type WatchlistCompaniesArgs = {
  input?: InputMaybe<FetchCompanyInput>
}

export type WatchlistHighlightsArgs = {
  input?: InputMaybe<Scalars['BigInt']>
}

export type WatchlistEvents = {
  __typename?: 'WatchlistEvents'
  companyName?: Maybe<Scalars['String']>
  eventType?: Maybe<Watchlist_Event>
}

export type FetchInvestmentInput = {
  cin?: InputMaybe<Scalars['String']>
  investmentId?: InputMaybe<Scalars['BigInt']>
  investorId?: InputMaybe<Scalars['BigInt']>
  name?: InputMaybe<Scalars['String']>
}

export type GetInvestmentQueryVariables = Exact<{ [key: string]: never }>

export type GetInvestmentQuery = { __typename?: 'Query'; investments?: any | null }

export type GetInvestorsQueryVariables = Exact<{
  input?: InputMaybe<InvestorFetchInput>
}>

export type GetInvestorsQuery = { __typename?: 'Query'; investors?: any | null }

export type GetWatchlistsQueryVariables = Exact<{ [key: string]: never }>

export type GetWatchlistsQuery = {
  __typename?: 'Query'
  watchlists?: Array<{
    __typename?: 'Watchlist'
    id?: any | null
    name?: string | null
    companies?: Array<{
      __typename?: 'Company'
      id?: any | null
      cin?: string | null
      name?: string | null
      sharePrice?: number | null
      latestRaise?: number | null
      latestRaiseDate?: string | null
      noOfRounds?: number | null
      valuation?: number | null
      totalFunding?: number | null
      logo?: string | null
      industry?: string | null
      starred?: boolean | null
      business?: string | null
    } | null> | null
    highlights?: Array<{
      __typename?: 'WatchlistEvents'
      eventType?: Watchlist_Event | null
      companyName?: string | null
    } | null> | null
  } | null> | null
}

export type AddWatchlistMutationVariables = Exact<{
  input: AddWatchlistInput
}>

export type AddWatchlistMutation = { __typename?: 'Mutation'; addWatchlist?: any | null }

export type RemoveWatchlistMutationVariables = Exact<{
  input?: InputMaybe<Scalars['BigInt']>
}>

export type RemoveWatchlistMutation = { __typename?: 'Mutation'; removeWatchlist?: any | null }

export type GetInvestmentByStageQueryVariables = Exact<{
  input?: InputMaybe<InvestorOrCompanyInput>
}>

export type GetInvestmentByStageQuery = {
  __typename?: 'Query'
  investmentByStage?: Array<{
    __typename?: 'InvestmentByStage'
    stage?: string | null
    count?: number | null
  } | null> | null
}

export type RemoveCompanyMutationVariables = Exact<{
  id: Scalars['BigInt']
  cin: Scalars['String']
}>

export type RemoveCompanyMutation = { __typename?: 'Mutation'; removeCompanyFromList?: any | null }

export type AddCompanyMutationVariables = Exact<{
  input: AddCompanyToListInput
}>

export type AddCompanyMutation = { __typename?: 'Mutation'; addCompanyToList?: any | null }

export type SearchQueryVariables = Exact<{
  input?: InputMaybe<SearchCompanyInput>
}>

export type SearchQuery = { __typename?: 'Query'; searchCompany?: any | null }

export type GetCompanyDetailsQueryVariables = Exact<{
  input: FetchCompanyInput
}>

export type GetCompanyDetailsQuery = {
  __typename?: 'Query'
  companies: Array<{
    __typename?: 'Company'
    id?: any | null
    name?: string | null
    cin?: string | null
    shareholders?: Array<any | null> | null
    totalFunding?: number | null
    totalShares?: any | null
    sharePrice?: number | null
    valuation?: number | null
    latestRaise?: number | null
    noOfRounds?: number | null
    industry?: string | null
    logo?: string | null
    business?: string | null
    starred?: boolean | null
  } | null>
}

export type GetTermsheetsQueryVariables = Exact<{ [key: string]: never }>

export type GetTermsheetsQuery = { __typename?: 'Query'; termsheets?: Array<any | null> | null }

export type GetShareholdingDetailsByCompanyQueryVariables = Exact<{
  input: FetchCompanyInput
}>

export type GetShareholdingDetailsByCompanyQuery = {
  __typename?: 'Query'
  companies: Array<{
    __typename?: 'Company'
    id?: any | null
    name?: string | null
    cin?: string | null
    shareholdingDetails?: Array<any | null> | null
    exitData?: Array<{
      __typename?: 'Transaction'
      investorName?: string | null
      companyName?: string | null
      transactionType?: string | null
      noOfShares?: any | null
      transactionDate?: string | null
      roundName?: string | null
      pricePerShare?: number | null
      roundIdentifier?: string | null
      cin?: string | null
      amount?: number | null
    } | null> | null
  } | null>
}

export type GetExitDataInvestorWiseQueryVariables = Exact<{
  input?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>> | InputMaybe<Scalars['BigInt']>>
}>

export type GetExitDataInvestorWiseQuery = {
  __typename?: 'Query'
  exitDataInvestorWise?: Array<{
    __typename?: 'Transaction'
    investorName?: string | null
    companyName?: string | null
    transactionType?: string | null
    noOfShares?: any | null
    transactionDate?: string | null
    roundName?: string | null
    pricePerShare?: number | null
    roundIdentifier?: string | null
    cin?: string | null
    amount?: number | null
  } | null> | null
}

export type AddInvestmentInputMutationVariables = Exact<{
  input?: InputMaybe<AddInvestmentInput>
}>

export type AddInvestmentInputMutation = { __typename?: 'Mutation'; addInvestment?: any | null }

export type GetAllInvestmentsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllInvestmentsQuery = {
  __typename?: 'Query'
  allInvestments?: Array<any | null> | null
}

export type RemoveInvestmentMutationVariables = Exact<{
  input?: InputMaybe<RemoveInvestmentInput>
}>

export type RemoveInvestmentMutation = { __typename?: 'Mutation'; removeInvestment?: any | null }

export type GetPortfolioMetricsQueryVariables = Exact<{
  input?: InputMaybe<InvestorOrCompanyInput>
}>

export type GetPortfolioMetricsQuery = {
  __typename?: 'Query'
  portfolioMetrics?: Array<{
    __typename?: 'RoundsCount'
    investorName?: string | null
    count?: any | null
    amount?: number | null
    investorId?: any | null
  } | null> | null
}

export type AddStarCompanyMutationVariables = Exact<{
  input?: InputMaybe<AddStarCompanyInput>
}>

export type AddStarCompanyMutation = { __typename?: 'Mutation'; addStarCompany?: any | null }

export type RemoveStarCompanyMutationVariables = Exact<{
  input?: InputMaybe<RemoveStarCompanyInput>
}>

export type RemoveStarCompanyMutation = { __typename?: 'Mutation'; removeStarCompany?: any | null }

export type GetStarCompaniesQueryVariables = Exact<{
  input?: InputMaybe<QueryStarCompaniesArgs>
}>

export type GetStarCompaniesQuery = { __typename?: 'Query'; starCompanies?: any | null }

export type GetUrlForS3ObjectQueryVariables = Exact<{
  input?: InputMaybe<S3ObjectUrl>
}>

export type GetUrlForS3ObjectQuery = { __typename?: 'Query'; urlForS3Object?: string | null }

export type GetManualInvestmentQueryVariables = Exact<{
  input: FetchInvestmentInput
}>

export type GetManualInvestmentQuery = { __typename?: 'Query'; investments?: any | null }

export type EditInvestmentInputMutationVariables = Exact<{
  input?: InputMaybe<EditInvestmentInput>
}>

export type EditInvestmentInputMutation = { __typename?: 'Mutation'; editInvestment?: any | null }

export type UpdateInvestmentsMutationVariables = Exact<{
  input?: InputMaybe<UpdateInvestmentsInput>
}>

export type UpdateInvestmentsMutation = { __typename?: 'Mutation'; updateInvestments?: any | null }

export type GetCompanyDataQueryVariables = Exact<{
  input?: InputMaybe<FilesDataInput>
}>

export type GetCompanyDataQuery = { __typename?: 'Query'; data?: any | null }

export type UploadFileToCompanyMutationVariables = Exact<{
  input?: InputMaybe<UploadFile>
}>

export type UploadFileToCompanyMutation = { __typename?: 'Mutation'; fileUpload?: any | null }

export type GetPortfolioEventsQueryVariables = Exact<{
  input?: InputMaybe<InvestorOrCompanyInput>
}>

export type GetPortfolioEventsQuery = {
  __typename?: 'Query'
  portfolioEvents?: Array<{
    __typename?: 'PortfolioEvents'
    investorId?: any | null
    companyName?: string | null
    eventType?: string | null
    eventDate?: any | null
  } | null> | null
}

export type EditWatchlistNameMutationVariables = Exact<{
  input: EditWatchlistInput
}>

export type EditWatchlistNameMutation = { __typename?: 'Mutation'; editWatchlist?: any | null }

export type UploadBulkInvestmentsMutationVariables = Exact<{
  up?: InputMaybe<Scalars['Upload']>
}>

export type UploadBulkInvestmentsMutation = {
  __typename?: 'Mutation'
  addInvestmentBulkUpload?: any | null
}

export type RemoveObjectMutationVariables = Exact<{
  input?: InputMaybe<UploadFile>
}>

export type RemoveObjectMutation = { __typename?: 'Mutation'; removeObject?: any | null }

export type GetWatchlistHighlightsQueryVariables = Exact<{ [key: string]: never }>

export type GetWatchlistHighlightsQuery = {
  __typename?: 'Query'
  watchlists?: Array<{
    __typename?: 'Watchlist'
    id?: any | null
    name?: string | null
    highlights?: Array<{
      __typename?: 'WatchlistEvents'
      eventType?: Watchlist_Event | null
      companyName?: string | null
    } | null> | null
  } | null> | null
}

export type GetDealTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type GetDealTemplatesQuery = {
  __typename?: 'Query'
  dealTemplates?: Array<{
    __typename?: 'DealTemplate'
    id?: any | null
    investorId?: any | null
    name?: string | null
    sections?: Array<string | null> | null
    fileURL?: string | null
    companiesIssuedTo?: Array<string | null> | null
    creationDate?: string | null
    lastIssuedDate?: string | null
  } | null> | null
}

export type AddDealTemplateMutationVariables = Exact<{
  input?: InputMaybe<AddDealTemplateInput>
}>

export type AddDealTemplateMutation = { __typename?: 'Mutation'; addDealTemplate?: any | null }

export type EditDealTemplateMutationVariables = Exact<{
  input?: InputMaybe<UpdateDealTemplateInput>
}>

export type EditDealTemplateMutation = { __typename?: 'Mutation'; updateDealTemplate?: any | null }

export type DeleteDealTemplateMutationVariables = Exact<{
  input?: InputMaybe<RemoveDealTemplateInput>
}>

export type DeleteDealTemplateMutation = {
  __typename?: 'Mutation'
  removeDealTemplate?: any | null
}

export type SendNewTermsheetMutationVariables = Exact<{
  input?: InputMaybe<TermsheetInput>
  sendImmediately?: InputMaybe<Scalars['Boolean']>
}>

export type SendNewTermsheetMutation = { __typename?: 'Mutation'; addTermsheet?: any | null }

export type GetTasksOverviewQueryVariables = Exact<{ [key: string]: never }>

export type GetTasksOverviewQuery = {
  __typename?: 'Query'
  tasksOverview?: Array<{
    __typename?: 'TaskOverviewEvent'
    date?: string | null
    eventType?: Task_Event | null
    eventStatus?: Task_Event_Status | null
    companyName?: string | null
  } | null> | null
}

export type GetCurrencyExchangeRateMutationVariables = Exact<{
  input?: InputMaybe<CurrencyExchangeInput>
}>

export type GetCurrencyExchangeRateMutation = {
  __typename?: 'Mutation'
  getCurrencyExchangeRate?: {
    __typename?: 'CurrencyExchangeRate'
    currencyName?: string | null
    currencyCode?: string | null
    currencyRate?: number | null
    updatedAt?: string | null
  } | null
}

export type UploadLinksMutationVariables = Exact<{
  input?: InputMaybe<AddLinkInput>
}>

export type UploadLinksMutation = { __typename?: 'Mutation'; addDocumentLink?: any | null }

export type DeleteLinksMutationVariables = Exact<{
  input?: InputMaybe<RemoveLinkInput>
}>

export type DeleteLinksMutation = { __typename?: 'Mutation'; removeDocumentLink?: any | null }

export type AddBeneficiaryMutationVariables = Exact<{
  input?: InputMaybe<AddBeneficiaryInput>
}>

export type AddBeneficiaryMutation = { __typename?: 'Mutation'; addBeneficiary?: any | null }

export type GetAllClassificationsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllClassificationsQuery = { __typename?: 'Query'; classifications?: any | null }

export type GetClassificationQueryVariables = Exact<{
  input?: InputMaybe<GetGroupInput>
}>

export type GetClassificationQuery = { __typename?: 'Query'; classification?: any | null }

export type AddClassificationMutationVariables = Exact<{
  input?: InputMaybe<AddGroupInput>
}>

export type AddClassificationMutation = { __typename?: 'Mutation'; addClassification?: any | null }

export type EditClassificationMutationVariables = Exact<{
  input?: InputMaybe<UpdateGroupInput>
}>

export type EditClassificationMutation = {
  __typename?: 'Mutation'
  editClassification?: any | null
}

export type RemoveClassificationMutationVariables = Exact<{
  input?: InputMaybe<RemoveGroupInput>
}>

export type RemoveClassificationMutation = {
  __typename?: 'Mutation'
  removeClassification?: any | null
}

export type AddCompaniesToClassificationMutationVariables = Exact<{
  input?: InputMaybe<AddCompaniesToGroupInput>
}>

export type AddCompaniesToClassificationMutation = {
  __typename?: 'Mutation'
  addCompaniesToClassification?: any | null
}

export type RemoveCompaniesFromClassificationMutationVariables = Exact<{
  input?: InputMaybe<AddCompaniesToGroupInput>
}>

export type RemoveCompaniesFromClassificationMutation = {
  __typename?: 'Mutation'
  removeCompaniesFromClassification?: any | null
}

export type ManualShareholdingDetailsQueryVariables = Exact<{
  input?: InputMaybe<FetchCompanyInput>
}>

export type ManualShareholdingDetailsQuery = {
  __typename?: 'Query'
  companies: Array<{
    __typename?: 'Company'
    shareholdingDetails?: Array<any | null> | null
  } | null>
}

export type BeneficiariesQueryVariables = Exact<{ [key: string]: never }>

export type BeneficiariesQuery = {
  __typename?: 'Query'
  beneficiaries?: Array<string | null> | null
}

export type AddExitManualInvestmentMutationVariables = Exact<{
  input?: InputMaybe<AddExitManualInvestment>
}>

export type AddExitManualInvestmentMutation = {
  __typename?: 'Mutation'
  addExitManualInvestment?: any | null
}

export type UpdateExitManualInvestmentMutationVariables = Exact<{
  input?: InputMaybe<UpdateExitManualInvestment>
}>

export type UpdateExitManualInvestmentMutation = {
  __typename?: 'Mutation'
  updateExitManualInvestment?: any | null
}

export type DeleteExitManualInvestmentMutationVariables = Exact<{
  input?: InputMaybe<RemoveExitManualInvestment>
}>

export type DeleteExitManualInvestmentMutation = {
  __typename?: 'Mutation'
  removeExitManualInvestment?: any | null
}

export type RemoveBeneficiaryMutationVariables = Exact<{
  input?: InputMaybe<RemoveInvestmentInput>
}>

export type RemoveBeneficiaryMutation = { __typename?: 'Mutation'; removeInvestment?: any | null }

export type EmailTermsheetMutationVariables = Exact<{
  input?: InputMaybe<EmailTermsheetInput>
}>

export type EmailTermsheetMutation = { __typename?: 'Mutation'; emailTermsheet?: any | null }

export const GetInvestmentDocument = `
    query getInvestment {
  investments(input: {investorId: 47})
}
    `
export const useGetInvestmentQuery = <TData = GetInvestmentQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetInvestmentQueryVariables,
  options?: UseQueryOptions<GetInvestmentQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetInvestmentQuery, TError, TData>(
    variables === undefined ? ['getInvestment'] : ['getInvestment', variables],
    fetcher<GetInvestmentQuery, GetInvestmentQueryVariables>(
      client,
      GetInvestmentDocument,
      variables,
      headers
    ),
    options
  )

useGetInvestmentQuery.getKey = (variables?: GetInvestmentQueryVariables) =>
  variables === undefined ? ['getInvestment'] : ['getInvestment', variables]
export const GetInvestorsDocument = `
    query getInvestors($input: InvestorFetchInput) {
  investors(input: $input)
}
    `
export const useGetInvestorsQuery = <TData = GetInvestorsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetInvestorsQueryVariables,
  options?: UseQueryOptions<GetInvestorsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetInvestorsQuery, TError, TData>(
    variables === undefined ? ['getInvestors'] : ['getInvestors', variables],
    fetcher<GetInvestorsQuery, GetInvestorsQueryVariables>(
      client,
      GetInvestorsDocument,
      variables,
      headers
    ),
    options
  )

useGetInvestorsQuery.getKey = (variables?: GetInvestorsQueryVariables) =>
  variables === undefined ? ['getInvestors'] : ['getInvestors', variables]
export const GetWatchlistsDocument = `
    query getWatchlists {
  watchlists {
    id
    name
    companies {
      id
      cin
      name
      sharePrice
      latestRaise
      latestRaiseDate
      noOfRounds
      valuation
      totalFunding
      logo
      industry
      starred
      business
    }
    highlights {
      eventType
      companyName
    }
  }
}
    `
export const useGetWatchlistsQuery = <TData = GetWatchlistsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetWatchlistsQueryVariables,
  options?: UseQueryOptions<GetWatchlistsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetWatchlistsQuery, TError, TData>(
    variables === undefined ? ['getWatchlists'] : ['getWatchlists', variables],
    fetcher<GetWatchlistsQuery, GetWatchlistsQueryVariables>(
      client,
      GetWatchlistsDocument,
      variables,
      headers
    ),
    options
  )

useGetWatchlistsQuery.getKey = (variables?: GetWatchlistsQueryVariables) =>
  variables === undefined ? ['getWatchlists'] : ['getWatchlists', variables]
export const AddWatchlistDocument = `
    mutation addWatchlist($input: AddWatchlistInput!) {
  addWatchlist(input: $input)
}
    `
export const useAddWatchlistMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AddWatchlistMutation,
    TError,
    AddWatchlistMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<AddWatchlistMutation, TError, AddWatchlistMutationVariables, TContext>(
    ['addWatchlist'],
    (variables?: AddWatchlistMutationVariables) =>
      fetcher<AddWatchlistMutation, AddWatchlistMutationVariables>(
        client,
        AddWatchlistDocument,
        variables,
        headers
      )(),
    options
  )
useAddWatchlistMutation.getKey = () => ['addWatchlist']

export const RemoveWatchlistDocument = `
    mutation removeWatchlist($input: BigInt) {
  removeWatchlist(input: {id: $input})
}
    `
export const useRemoveWatchlistMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveWatchlistMutation,
    TError,
    RemoveWatchlistMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<RemoveWatchlistMutation, TError, RemoveWatchlistMutationVariables, TContext>(
    ['removeWatchlist'],
    (variables?: RemoveWatchlistMutationVariables) =>
      fetcher<RemoveWatchlistMutation, RemoveWatchlistMutationVariables>(
        client,
        RemoveWatchlistDocument,
        variables,
        headers
      )(),
    options
  )
useRemoveWatchlistMutation.getKey = () => ['removeWatchlist']

export const GetInvestmentByStageDocument = `
    query getInvestmentByStage($input: InvestorOrCompanyInput) {
  investmentByStage(input: $input) {
    stage
    count
  }
}
    `
export const useGetInvestmentByStageQuery = <TData = GetInvestmentByStageQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetInvestmentByStageQueryVariables,
  options?: UseQueryOptions<GetInvestmentByStageQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetInvestmentByStageQuery, TError, TData>(
    variables === undefined ? ['getInvestmentByStage'] : ['getInvestmentByStage', variables],
    fetcher<GetInvestmentByStageQuery, GetInvestmentByStageQueryVariables>(
      client,
      GetInvestmentByStageDocument,
      variables,
      headers
    ),
    options
  )

useGetInvestmentByStageQuery.getKey = (variables?: GetInvestmentByStageQueryVariables) =>
  variables === undefined ? ['getInvestmentByStage'] : ['getInvestmentByStage', variables]
export const RemoveCompanyDocument = `
    mutation removeCompany($id: BigInt!, $cin: String!) {
  removeCompanyFromList(input: {id: $id, cin: $cin})
}
    `
export const useRemoveCompanyMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveCompanyMutation,
    TError,
    RemoveCompanyMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<RemoveCompanyMutation, TError, RemoveCompanyMutationVariables, TContext>(
    ['removeCompany'],
    (variables?: RemoveCompanyMutationVariables) =>
      fetcher<RemoveCompanyMutation, RemoveCompanyMutationVariables>(
        client,
        RemoveCompanyDocument,
        variables,
        headers
      )(),
    options
  )
useRemoveCompanyMutation.getKey = () => ['removeCompany']

export const AddCompanyDocument = `
    mutation addCompany($input: AddCompanyToListInput!) {
  addCompanyToList(input: $input)
}
    `
export const useAddCompanyMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<AddCompanyMutation, TError, AddCompanyMutationVariables, TContext>,
  headers?: RequestInit['headers']
) =>
  useMutation<AddCompanyMutation, TError, AddCompanyMutationVariables, TContext>(
    ['addCompany'],
    (variables?: AddCompanyMutationVariables) =>
      fetcher<AddCompanyMutation, AddCompanyMutationVariables>(
        client,
        AddCompanyDocument,
        variables,
        headers
      )(),
    options
  )
useAddCompanyMutation.getKey = () => ['addCompany']

export const SearchDocument = `
    query search($input: SearchCompanyInput) {
  searchCompany(input: $input)
}
    `
export const useSearchQuery = <TData = SearchQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: SearchQueryVariables,
  options?: UseQueryOptions<SearchQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<SearchQuery, TError, TData>(
    variables === undefined ? ['search'] : ['search', variables],
    fetcher<SearchQuery, SearchQueryVariables>(client, SearchDocument, variables, headers),
    options
  )

useSearchQuery.getKey = (variables?: SearchQueryVariables) =>
  variables === undefined ? ['search'] : ['search', variables]
export const GetCompanyDetailsDocument = `
    query getCompanyDetails($input: FetchCompanyInput!) {
  companies(input: $input) {
    id
    name
    cin
    shareholders
    totalFunding
    totalShares
    sharePrice
    valuation
    latestRaise
    noOfRounds
    industry
    logo
    business
    starred
  }
}
    `
export const useGetCompanyDetailsQuery = <TData = GetCompanyDetailsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetCompanyDetailsQueryVariables,
  options?: UseQueryOptions<GetCompanyDetailsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetCompanyDetailsQuery, TError, TData>(
    ['getCompanyDetails', variables],
    fetcher<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>(
      client,
      GetCompanyDetailsDocument,
      variables,
      headers
    ),
    options
  )

useGetCompanyDetailsQuery.getKey = (variables: GetCompanyDetailsQueryVariables) => [
  'getCompanyDetails',
  variables,
]
export const GetTermsheetsDocument = `
    query getTermsheets {
  termsheets
}
    `
export const useGetTermsheetsQuery = <TData = GetTermsheetsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetTermsheetsQueryVariables,
  options?: UseQueryOptions<GetTermsheetsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetTermsheetsQuery, TError, TData>(
    variables === undefined ? ['getTermsheets'] : ['getTermsheets', variables],
    fetcher<GetTermsheetsQuery, GetTermsheetsQueryVariables>(
      client,
      GetTermsheetsDocument,
      variables,
      headers
    ),
    options
  )

useGetTermsheetsQuery.getKey = (variables?: GetTermsheetsQueryVariables) =>
  variables === undefined ? ['getTermsheets'] : ['getTermsheets', variables]
export const GetShareholdingDetailsByCompanyDocument = `
    query getShareholdingDetailsByCompany($input: FetchCompanyInput!) {
  companies(input: $input) {
    id
    name
    cin
    shareholdingDetails
    exitData {
      investorName
      companyName
      transactionType
      noOfShares
      transactionDate
      roundName
      pricePerShare
      roundIdentifier
      cin
      amount
    }
  }
}
    `
export const useGetShareholdingDetailsByCompanyQuery = <
  TData = GetShareholdingDetailsByCompanyQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: GetShareholdingDetailsByCompanyQueryVariables,
  options?: UseQueryOptions<GetShareholdingDetailsByCompanyQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetShareholdingDetailsByCompanyQuery, TError, TData>(
    ['getShareholdingDetailsByCompany', variables],
    fetcher<GetShareholdingDetailsByCompanyQuery, GetShareholdingDetailsByCompanyQueryVariables>(
      client,
      GetShareholdingDetailsByCompanyDocument,
      variables,
      headers
    ),
    options
  )

useGetShareholdingDetailsByCompanyQuery.getKey = (
  variables: GetShareholdingDetailsByCompanyQueryVariables
) => ['getShareholdingDetailsByCompany', variables]
export const GetExitDataInvestorWiseDocument = `
    query getExitDataInvestorWise($input: [BigInt]) {
  exitDataInvestorWise(investorIds: $input) {
    investorName
    companyName
    transactionType
    noOfShares
    transactionDate
    roundName
    pricePerShare
    roundIdentifier
    cin
    amount
  }
}
    `
export const useGetExitDataInvestorWiseQuery = <
  TData = GetExitDataInvestorWiseQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: GetExitDataInvestorWiseQueryVariables,
  options?: UseQueryOptions<GetExitDataInvestorWiseQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetExitDataInvestorWiseQuery, TError, TData>(
    variables === undefined ? ['getExitDataInvestorWise'] : ['getExitDataInvestorWise', variables],
    fetcher<GetExitDataInvestorWiseQuery, GetExitDataInvestorWiseQueryVariables>(
      client,
      GetExitDataInvestorWiseDocument,
      variables,
      headers
    ),
    options
  )

useGetExitDataInvestorWiseQuery.getKey = (variables?: GetExitDataInvestorWiseQueryVariables) =>
  variables === undefined ? ['getExitDataInvestorWise'] : ['getExitDataInvestorWise', variables]
export const AddInvestmentInputDocument = `
    mutation addInvestmentInput($input: AddInvestmentInput) {
  addInvestment(input: $input)
}
    `
export const useAddInvestmentInputMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AddInvestmentInputMutation,
    TError,
    AddInvestmentInputMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<AddInvestmentInputMutation, TError, AddInvestmentInputMutationVariables, TContext>(
    ['addInvestmentInput'],
    (variables?: AddInvestmentInputMutationVariables) =>
      fetcher<AddInvestmentInputMutation, AddInvestmentInputMutationVariables>(
        client,
        AddInvestmentInputDocument,
        variables,
        headers
      )(),
    options
  )
useAddInvestmentInputMutation.getKey = () => ['addInvestmentInput']

export const GetAllInvestmentsDocument = `
    query getAllInvestments {
  allInvestments
}
    `
export const useGetAllInvestmentsQuery = <TData = GetAllInvestmentsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetAllInvestmentsQueryVariables,
  options?: UseQueryOptions<GetAllInvestmentsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetAllInvestmentsQuery, TError, TData>(
    variables === undefined ? ['getAllInvestments'] : ['getAllInvestments', variables],
    fetcher<GetAllInvestmentsQuery, GetAllInvestmentsQueryVariables>(
      client,
      GetAllInvestmentsDocument,
      variables,
      headers
    ),
    options
  )

useGetAllInvestmentsQuery.getKey = (variables?: GetAllInvestmentsQueryVariables) =>
  variables === undefined ? ['getAllInvestments'] : ['getAllInvestments', variables]
export const RemoveInvestmentDocument = `
    mutation removeInvestment($input: RemoveInvestmentInput) {
  removeInvestment(input: $input)
}
    `
export const useRemoveInvestmentMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveInvestmentMutation,
    TError,
    RemoveInvestmentMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<RemoveInvestmentMutation, TError, RemoveInvestmentMutationVariables, TContext>(
    ['removeInvestment'],
    (variables?: RemoveInvestmentMutationVariables) =>
      fetcher<RemoveInvestmentMutation, RemoveInvestmentMutationVariables>(
        client,
        RemoveInvestmentDocument,
        variables,
        headers
      )(),
    options
  )
useRemoveInvestmentMutation.getKey = () => ['removeInvestment']

export const GetPortfolioMetricsDocument = `
    query getPortfolioMetrics($input: InvestorOrCompanyInput) {
  portfolioMetrics(input: $input) {
    investorName
    count
    amount
    investorId
  }
}
    `
export const useGetPortfolioMetricsQuery = <TData = GetPortfolioMetricsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetPortfolioMetricsQueryVariables,
  options?: UseQueryOptions<GetPortfolioMetricsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetPortfolioMetricsQuery, TError, TData>(
    variables === undefined ? ['getPortfolioMetrics'] : ['getPortfolioMetrics', variables],
    fetcher<GetPortfolioMetricsQuery, GetPortfolioMetricsQueryVariables>(
      client,
      GetPortfolioMetricsDocument,
      variables,
      headers
    ),
    options
  )

useGetPortfolioMetricsQuery.getKey = (variables?: GetPortfolioMetricsQueryVariables) =>
  variables === undefined ? ['getPortfolioMetrics'] : ['getPortfolioMetrics', variables]
export const AddStarCompanyDocument = `
    mutation addStarCompany($input: AddStarCompanyInput) {
  addStarCompany(input: $input)
}
    `
export const useAddStarCompanyMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AddStarCompanyMutation,
    TError,
    AddStarCompanyMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<AddStarCompanyMutation, TError, AddStarCompanyMutationVariables, TContext>(
    ['addStarCompany'],
    (variables?: AddStarCompanyMutationVariables) =>
      fetcher<AddStarCompanyMutation, AddStarCompanyMutationVariables>(
        client,
        AddStarCompanyDocument,
        variables,
        headers
      )(),
    options
  )
useAddStarCompanyMutation.getKey = () => ['addStarCompany']

export const RemoveStarCompanyDocument = `
    mutation removeStarCompany($input: RemoveStarCompanyInput) {
  removeStarCompany(input: $input)
}
    `
export const useRemoveStarCompanyMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveStarCompanyMutation,
    TError,
    RemoveStarCompanyMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<RemoveStarCompanyMutation, TError, RemoveStarCompanyMutationVariables, TContext>(
    ['removeStarCompany'],
    (variables?: RemoveStarCompanyMutationVariables) =>
      fetcher<RemoveStarCompanyMutation, RemoveStarCompanyMutationVariables>(
        client,
        RemoveStarCompanyDocument,
        variables,
        headers
      )(),
    options
  )
useRemoveStarCompanyMutation.getKey = () => ['removeStarCompany']

export const GetStarCompaniesDocument = `
    query getStarCompanies($input: QueryStarCompaniesArgs) {
  starCompanies(input: $input)
}
    `
export const useGetStarCompaniesQuery = <TData = GetStarCompaniesQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetStarCompaniesQueryVariables,
  options?: UseQueryOptions<GetStarCompaniesQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetStarCompaniesQuery, TError, TData>(
    variables === undefined ? ['getStarCompanies'] : ['getStarCompanies', variables],
    fetcher<GetStarCompaniesQuery, GetStarCompaniesQueryVariables>(
      client,
      GetStarCompaniesDocument,
      variables,
      headers
    ),
    options
  )

useGetStarCompaniesQuery.getKey = (variables?: GetStarCompaniesQueryVariables) =>
  variables === undefined ? ['getStarCompanies'] : ['getStarCompanies', variables]
export const GetUrlForS3ObjectDocument = `
    query getUrlForS3Object($input: S3ObjectUrl) {
  urlForS3Object(input: $input)
}
    `
export const useGetUrlForS3ObjectQuery = <TData = GetUrlForS3ObjectQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetUrlForS3ObjectQueryVariables,
  options?: UseQueryOptions<GetUrlForS3ObjectQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetUrlForS3ObjectQuery, TError, TData>(
    variables === undefined ? ['getUrlForS3Object'] : ['getUrlForS3Object', variables],
    fetcher<GetUrlForS3ObjectQuery, GetUrlForS3ObjectQueryVariables>(
      client,
      GetUrlForS3ObjectDocument,
      variables,
      headers
    ),
    options
  )

useGetUrlForS3ObjectQuery.getKey = (variables?: GetUrlForS3ObjectQueryVariables) =>
  variables === undefined ? ['getUrlForS3Object'] : ['getUrlForS3Object', variables]
export const GetManualInvestmentDocument = `
    query getManualInvestment($input: fetchInvestmentInput!) {
  investments(input: $input)
}
    `
export const useGetManualInvestmentQuery = <TData = GetManualInvestmentQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetManualInvestmentQueryVariables,
  options?: UseQueryOptions<GetManualInvestmentQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetManualInvestmentQuery, TError, TData>(
    ['getManualInvestment', variables],
    fetcher<GetManualInvestmentQuery, GetManualInvestmentQueryVariables>(
      client,
      GetManualInvestmentDocument,
      variables,
      headers
    ),
    options
  )

useGetManualInvestmentQuery.getKey = (variables: GetManualInvestmentQueryVariables) => [
  'getManualInvestment',
  variables,
]
export const EditInvestmentInputDocument = `
    mutation editInvestmentInput($input: EditInvestmentInput) {
  editInvestment(input: $input)
}
    `
export const useEditInvestmentInputMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    EditInvestmentInputMutation,
    TError,
    EditInvestmentInputMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<EditInvestmentInputMutation, TError, EditInvestmentInputMutationVariables, TContext>(
    ['editInvestmentInput'],
    (variables?: EditInvestmentInputMutationVariables) =>
      fetcher<EditInvestmentInputMutation, EditInvestmentInputMutationVariables>(
        client,
        EditInvestmentInputDocument,
        variables,
        headers
      )(),
    options
  )
useEditInvestmentInputMutation.getKey = () => ['editInvestmentInput']

export const UpdateInvestmentsDocument = `
    mutation updateInvestments($input: UpdateInvestmentsInput) {
  updateInvestments(input: $input)
}
    `
export const useUpdateInvestmentsMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateInvestmentsMutation,
    TError,
    UpdateInvestmentsMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<UpdateInvestmentsMutation, TError, UpdateInvestmentsMutationVariables, TContext>(
    ['updateInvestments'],
    (variables?: UpdateInvestmentsMutationVariables) =>
      fetcher<UpdateInvestmentsMutation, UpdateInvestmentsMutationVariables>(
        client,
        UpdateInvestmentsDocument,
        variables,
        headers
      )(),
    options
  )
useUpdateInvestmentsMutation.getKey = () => ['updateInvestments']

export const GetCompanyDataDocument = `
    query getCompanyData($input: FilesDataInput) {
  data(input: $input)
}
    `
export const useGetCompanyDataQuery = <TData = GetCompanyDataQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetCompanyDataQueryVariables,
  options?: UseQueryOptions<GetCompanyDataQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetCompanyDataQuery, TError, TData>(
    variables === undefined ? ['getCompanyData'] : ['getCompanyData', variables],
    fetcher<GetCompanyDataQuery, GetCompanyDataQueryVariables>(
      client,
      GetCompanyDataDocument,
      variables,
      headers
    ),
    options
  )

useGetCompanyDataQuery.getKey = (variables?: GetCompanyDataQueryVariables) =>
  variables === undefined ? ['getCompanyData'] : ['getCompanyData', variables]
export const UploadFileToCompanyDocument = `
    mutation uploadFileToCompany($input: UploadFile) {
  fileUpload(input: $input)
}
    `
export const useUploadFileToCompanyMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UploadFileToCompanyMutation,
    TError,
    UploadFileToCompanyMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<UploadFileToCompanyMutation, TError, UploadFileToCompanyMutationVariables, TContext>(
    ['uploadFileToCompany'],
    (variables?: UploadFileToCompanyMutationVariables) =>
      fetcher<UploadFileToCompanyMutation, UploadFileToCompanyMutationVariables>(
        client,
        UploadFileToCompanyDocument,
        variables,
        headers
      )(),
    options
  )
useUploadFileToCompanyMutation.getKey = () => ['uploadFileToCompany']

export const GetPortfolioEventsDocument = `
    query getPortfolioEvents($input: InvestorOrCompanyInput) {
  portfolioEvents(input: $input) {
    investorId
    companyName
    eventType
    eventDate
  }
}
    `
export const useGetPortfolioEventsQuery = <TData = GetPortfolioEventsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetPortfolioEventsQueryVariables,
  options?: UseQueryOptions<GetPortfolioEventsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetPortfolioEventsQuery, TError, TData>(
    variables === undefined ? ['getPortfolioEvents'] : ['getPortfolioEvents', variables],
    fetcher<GetPortfolioEventsQuery, GetPortfolioEventsQueryVariables>(
      client,
      GetPortfolioEventsDocument,
      variables,
      headers
    ),
    options
  )

useGetPortfolioEventsQuery.getKey = (variables?: GetPortfolioEventsQueryVariables) =>
  variables === undefined ? ['getPortfolioEvents'] : ['getPortfolioEvents', variables]
export const EditWatchlistNameDocument = `
    mutation editWatchlistName($input: EditWatchlistInput!) {
  editWatchlist(input: $input)
}
    `
export const useEditWatchlistNameMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    EditWatchlistNameMutation,
    TError,
    EditWatchlistNameMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<EditWatchlistNameMutation, TError, EditWatchlistNameMutationVariables, TContext>(
    ['editWatchlistName'],
    (variables?: EditWatchlistNameMutationVariables) =>
      fetcher<EditWatchlistNameMutation, EditWatchlistNameMutationVariables>(
        client,
        EditWatchlistNameDocument,
        variables,
        headers
      )(),
    options
  )
useEditWatchlistNameMutation.getKey = () => ['editWatchlistName']

export const UploadBulkInvestmentsDocument = `
    mutation uploadBulkInvestments($up: Upload) {
  addInvestmentBulkUpload(input: {filename: $up})
}
    `
export const useUploadBulkInvestmentsMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UploadBulkInvestmentsMutation,
    TError,
    UploadBulkInvestmentsMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    UploadBulkInvestmentsMutation,
    TError,
    UploadBulkInvestmentsMutationVariables,
    TContext
  >(
    ['uploadBulkInvestments'],
    (variables?: UploadBulkInvestmentsMutationVariables) =>
      fetcher<UploadBulkInvestmentsMutation, UploadBulkInvestmentsMutationVariables>(
        client,
        UploadBulkInvestmentsDocument,
        variables,
        headers
      )(),
    options
  )
useUploadBulkInvestmentsMutation.getKey = () => ['uploadBulkInvestments']

export const RemoveObjectDocument = `
    mutation removeObject($input: UploadFile) {
  removeObject(input: $input)
}
    `
export const useRemoveObjectMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveObjectMutation,
    TError,
    RemoveObjectMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<RemoveObjectMutation, TError, RemoveObjectMutationVariables, TContext>(
    ['removeObject'],
    (variables?: RemoveObjectMutationVariables) =>
      fetcher<RemoveObjectMutation, RemoveObjectMutationVariables>(
        client,
        RemoveObjectDocument,
        variables,
        headers
      )(),
    options
  )
useRemoveObjectMutation.getKey = () => ['removeObject']

export const GetWatchlistHighlightsDocument = `
    query getWatchlistHighlights {
  watchlists {
    id
    name
    highlights {
      eventType
      companyName
    }
  }
}
    `
export const useGetWatchlistHighlightsQuery = <
  TData = GetWatchlistHighlightsQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: GetWatchlistHighlightsQueryVariables,
  options?: UseQueryOptions<GetWatchlistHighlightsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetWatchlistHighlightsQuery, TError, TData>(
    variables === undefined ? ['getWatchlistHighlights'] : ['getWatchlistHighlights', variables],
    fetcher<GetWatchlistHighlightsQuery, GetWatchlistHighlightsQueryVariables>(
      client,
      GetWatchlistHighlightsDocument,
      variables,
      headers
    ),
    options
  )

useGetWatchlistHighlightsQuery.getKey = (variables?: GetWatchlistHighlightsQueryVariables) =>
  variables === undefined ? ['getWatchlistHighlights'] : ['getWatchlistHighlights', variables]
export const GetDealTemplatesDocument = `
    query getDealTemplates {
  dealTemplates {
    id
    investorId
    name
    sections
    fileURL
    companiesIssuedTo
    creationDate
    lastIssuedDate
  }
}
    `
export const useGetDealTemplatesQuery = <TData = GetDealTemplatesQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetDealTemplatesQueryVariables,
  options?: UseQueryOptions<GetDealTemplatesQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetDealTemplatesQuery, TError, TData>(
    variables === undefined ? ['getDealTemplates'] : ['getDealTemplates', variables],
    fetcher<GetDealTemplatesQuery, GetDealTemplatesQueryVariables>(
      client,
      GetDealTemplatesDocument,
      variables,
      headers
    ),
    options
  )

useGetDealTemplatesQuery.getKey = (variables?: GetDealTemplatesQueryVariables) =>
  variables === undefined ? ['getDealTemplates'] : ['getDealTemplates', variables]
export const AddDealTemplateDocument = `
    mutation addDealTemplate($input: AddDealTemplateInput) {
  addDealTemplate(input: $input)
}
    `
export const useAddDealTemplateMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AddDealTemplateMutation,
    TError,
    AddDealTemplateMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<AddDealTemplateMutation, TError, AddDealTemplateMutationVariables, TContext>(
    ['addDealTemplate'],
    (variables?: AddDealTemplateMutationVariables) =>
      fetcher<AddDealTemplateMutation, AddDealTemplateMutationVariables>(
        client,
        AddDealTemplateDocument,
        variables,
        headers
      )(),
    options
  )
useAddDealTemplateMutation.getKey = () => ['addDealTemplate']

export const EditDealTemplateDocument = `
    mutation editDealTemplate($input: UpdateDealTemplateInput) {
  updateDealTemplate(input: $input)
}
    `
export const useEditDealTemplateMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    EditDealTemplateMutation,
    TError,
    EditDealTemplateMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<EditDealTemplateMutation, TError, EditDealTemplateMutationVariables, TContext>(
    ['editDealTemplate'],
    (variables?: EditDealTemplateMutationVariables) =>
      fetcher<EditDealTemplateMutation, EditDealTemplateMutationVariables>(
        client,
        EditDealTemplateDocument,
        variables,
        headers
      )(),
    options
  )
useEditDealTemplateMutation.getKey = () => ['editDealTemplate']

export const DeleteDealTemplateDocument = `
    mutation deleteDealTemplate($input: RemoveDealTemplateInput) {
  removeDealTemplate(input: $input)
}
    `
export const useDeleteDealTemplateMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteDealTemplateMutation,
    TError,
    DeleteDealTemplateMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<DeleteDealTemplateMutation, TError, DeleteDealTemplateMutationVariables, TContext>(
    ['deleteDealTemplate'],
    (variables?: DeleteDealTemplateMutationVariables) =>
      fetcher<DeleteDealTemplateMutation, DeleteDealTemplateMutationVariables>(
        client,
        DeleteDealTemplateDocument,
        variables,
        headers
      )(),
    options
  )
useDeleteDealTemplateMutation.getKey = () => ['deleteDealTemplate']

export const SendNewTermsheetDocument = `
    mutation sendNewTermsheet($input: TermsheetInput, $sendImmediately: Boolean) {
  addTermsheet(input: $input, sendImmediately: $sendImmediately)
}
    `
export const useSendNewTermsheetMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    SendNewTermsheetMutation,
    TError,
    SendNewTermsheetMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<SendNewTermsheetMutation, TError, SendNewTermsheetMutationVariables, TContext>(
    ['sendNewTermsheet'],
    (variables?: SendNewTermsheetMutationVariables) =>
      fetcher<SendNewTermsheetMutation, SendNewTermsheetMutationVariables>(
        client,
        SendNewTermsheetDocument,
        variables,
        headers
      )(),
    options
  )
useSendNewTermsheetMutation.getKey = () => ['sendNewTermsheet']

export const GetTasksOverviewDocument = `
    query getTasksOverview {
  tasksOverview {
    date
    eventType
    eventStatus
    companyName
  }
}
    `
export const useGetTasksOverviewQuery = <TData = GetTasksOverviewQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetTasksOverviewQueryVariables,
  options?: UseQueryOptions<GetTasksOverviewQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetTasksOverviewQuery, TError, TData>(
    variables === undefined ? ['getTasksOverview'] : ['getTasksOverview', variables],
    fetcher<GetTasksOverviewQuery, GetTasksOverviewQueryVariables>(
      client,
      GetTasksOverviewDocument,
      variables,
      headers
    ),
    options
  )

useGetTasksOverviewQuery.getKey = (variables?: GetTasksOverviewQueryVariables) =>
  variables === undefined ? ['getTasksOverview'] : ['getTasksOverview', variables]
export const GetCurrencyExchangeRateDocument = `
    mutation getCurrencyExchangeRate($input: CurrencyExchangeInput) {
  getCurrencyExchangeRate(input: $input) {
    currencyName
    currencyCode
    currencyRate
    updatedAt
  }
}
    `
export const useGetCurrencyExchangeRateMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    GetCurrencyExchangeRateMutation,
    TError,
    GetCurrencyExchangeRateMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    GetCurrencyExchangeRateMutation,
    TError,
    GetCurrencyExchangeRateMutationVariables,
    TContext
  >(
    ['getCurrencyExchangeRate'],
    (variables?: GetCurrencyExchangeRateMutationVariables) =>
      fetcher<GetCurrencyExchangeRateMutation, GetCurrencyExchangeRateMutationVariables>(
        client,
        GetCurrencyExchangeRateDocument,
        variables,
        headers
      )(),
    options
  )
useGetCurrencyExchangeRateMutation.getKey = () => ['getCurrencyExchangeRate']

export const UploadLinksDocument = `
    mutation uploadLinks($input: AddLinkInput) {
  addDocumentLink(input: $input)
}
    `
export const useUploadLinksMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UploadLinksMutation, TError, UploadLinksMutationVariables, TContext>,
  headers?: RequestInit['headers']
) =>
  useMutation<UploadLinksMutation, TError, UploadLinksMutationVariables, TContext>(
    ['uploadLinks'],
    (variables?: UploadLinksMutationVariables) =>
      fetcher<UploadLinksMutation, UploadLinksMutationVariables>(
        client,
        UploadLinksDocument,
        variables,
        headers
      )(),
    options
  )
useUploadLinksMutation.getKey = () => ['uploadLinks']

export const DeleteLinksDocument = `
    mutation deleteLinks($input: RemoveLinkInput) {
  removeDocumentLink(input: $input)
}
    `
export const useDeleteLinksMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<DeleteLinksMutation, TError, DeleteLinksMutationVariables, TContext>,
  headers?: RequestInit['headers']
) =>
  useMutation<DeleteLinksMutation, TError, DeleteLinksMutationVariables, TContext>(
    ['deleteLinks'],
    (variables?: DeleteLinksMutationVariables) =>
      fetcher<DeleteLinksMutation, DeleteLinksMutationVariables>(
        client,
        DeleteLinksDocument,
        variables,
        headers
      )(),
    options
  )
useDeleteLinksMutation.getKey = () => ['deleteLinks']

export const AddBeneficiaryDocument = `
    mutation addBeneficiary($input: AddBeneficiaryInput) {
  addBeneficiary(input: $input)
}
    `
export const useAddBeneficiaryMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AddBeneficiaryMutation,
    TError,
    AddBeneficiaryMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<AddBeneficiaryMutation, TError, AddBeneficiaryMutationVariables, TContext>(
    ['addBeneficiary'],
    (variables?: AddBeneficiaryMutationVariables) =>
      fetcher<AddBeneficiaryMutation, AddBeneficiaryMutationVariables>(
        client,
        AddBeneficiaryDocument,
        variables,
        headers
      )(),
    options
  )
useAddBeneficiaryMutation.getKey = () => ['addBeneficiary']

export const GetAllClassificationsDocument = `
    query getAllClassifications {
  classifications
}
    `
export const useGetAllClassificationsQuery = <TData = GetAllClassificationsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetAllClassificationsQueryVariables,
  options?: UseQueryOptions<GetAllClassificationsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetAllClassificationsQuery, TError, TData>(
    variables === undefined ? ['getAllClassifications'] : ['getAllClassifications', variables],
    fetcher<GetAllClassificationsQuery, GetAllClassificationsQueryVariables>(
      client,
      GetAllClassificationsDocument,
      variables,
      headers
    ),
    options
  )

useGetAllClassificationsQuery.getKey = (variables?: GetAllClassificationsQueryVariables) =>
  variables === undefined ? ['getAllClassifications'] : ['getAllClassifications', variables]
export const GetClassificationDocument = `
    query getClassification($input: GetGroupInput) {
  classification(input: $input)
}
    `
export const useGetClassificationQuery = <TData = GetClassificationQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetClassificationQueryVariables,
  options?: UseQueryOptions<GetClassificationQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetClassificationQuery, TError, TData>(
    variables === undefined ? ['getClassification'] : ['getClassification', variables],
    fetcher<GetClassificationQuery, GetClassificationQueryVariables>(
      client,
      GetClassificationDocument,
      variables,
      headers
    ),
    options
  )

useGetClassificationQuery.getKey = (variables?: GetClassificationQueryVariables) =>
  variables === undefined ? ['getClassification'] : ['getClassification', variables]
export const AddClassificationDocument = `
    mutation addClassification($input: AddGroupInput) {
  addClassification(input: $input)
}
    `
export const useAddClassificationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AddClassificationMutation,
    TError,
    AddClassificationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<AddClassificationMutation, TError, AddClassificationMutationVariables, TContext>(
    ['addClassification'],
    (variables?: AddClassificationMutationVariables) =>
      fetcher<AddClassificationMutation, AddClassificationMutationVariables>(
        client,
        AddClassificationDocument,
        variables,
        headers
      )(),
    options
  )
useAddClassificationMutation.getKey = () => ['addClassification']

export const EditClassificationDocument = `
    mutation editClassification($input: UpdateGroupInput) {
  editClassification(input: $input)
}
    `
export const useEditClassificationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    EditClassificationMutation,
    TError,
    EditClassificationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<EditClassificationMutation, TError, EditClassificationMutationVariables, TContext>(
    ['editClassification'],
    (variables?: EditClassificationMutationVariables) =>
      fetcher<EditClassificationMutation, EditClassificationMutationVariables>(
        client,
        EditClassificationDocument,
        variables,
        headers
      )(),
    options
  )
useEditClassificationMutation.getKey = () => ['editClassification']

export const RemoveClassificationDocument = `
    mutation removeClassification($input: RemoveGroupInput) {
  removeClassification(input: $input)
}
    `
export const useRemoveClassificationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveClassificationMutation,
    TError,
    RemoveClassificationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    RemoveClassificationMutation,
    TError,
    RemoveClassificationMutationVariables,
    TContext
  >(
    ['removeClassification'],
    (variables?: RemoveClassificationMutationVariables) =>
      fetcher<RemoveClassificationMutation, RemoveClassificationMutationVariables>(
        client,
        RemoveClassificationDocument,
        variables,
        headers
      )(),
    options
  )
useRemoveClassificationMutation.getKey = () => ['removeClassification']

export const AddCompaniesToClassificationDocument = `
    mutation addCompaniesToClassification($input: AddCompaniesToGroupInput) {
  addCompaniesToClassification(input: $input)
}
    `
export const useAddCompaniesToClassificationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AddCompaniesToClassificationMutation,
    TError,
    AddCompaniesToClassificationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    AddCompaniesToClassificationMutation,
    TError,
    AddCompaniesToClassificationMutationVariables,
    TContext
  >(
    ['addCompaniesToClassification'],
    (variables?: AddCompaniesToClassificationMutationVariables) =>
      fetcher<AddCompaniesToClassificationMutation, AddCompaniesToClassificationMutationVariables>(
        client,
        AddCompaniesToClassificationDocument,
        variables,
        headers
      )(),
    options
  )
useAddCompaniesToClassificationMutation.getKey = () => ['addCompaniesToClassification']

export const RemoveCompaniesFromClassificationDocument = `
    mutation removeCompaniesFromClassification($input: AddCompaniesToGroupInput) {
  removeCompaniesFromClassification(input: $input)
}
    `
export const useRemoveCompaniesFromClassificationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveCompaniesFromClassificationMutation,
    TError,
    RemoveCompaniesFromClassificationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    RemoveCompaniesFromClassificationMutation,
    TError,
    RemoveCompaniesFromClassificationMutationVariables,
    TContext
  >(
    ['removeCompaniesFromClassification'],
    (variables?: RemoveCompaniesFromClassificationMutationVariables) =>
      fetcher<
        RemoveCompaniesFromClassificationMutation,
        RemoveCompaniesFromClassificationMutationVariables
      >(client, RemoveCompaniesFromClassificationDocument, variables, headers)(),
    options
  )
useRemoveCompaniesFromClassificationMutation.getKey = () => ['removeCompaniesFromClassification']

export const ManualShareholdingDetailsDocument = `
    query manualShareholdingDetails($input: FetchCompanyInput) {
  companies(input: $input) {
    shareholdingDetails
  }
}
    `
export const useManualShareholdingDetailsQuery = <
  TData = ManualShareholdingDetailsQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: ManualShareholdingDetailsQueryVariables,
  options?: UseQueryOptions<ManualShareholdingDetailsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<ManualShareholdingDetailsQuery, TError, TData>(
    variables === undefined
      ? ['manualShareholdingDetails']
      : ['manualShareholdingDetails', variables],
    fetcher<ManualShareholdingDetailsQuery, ManualShareholdingDetailsQueryVariables>(
      client,
      ManualShareholdingDetailsDocument,
      variables,
      headers
    ),
    options
  )

useManualShareholdingDetailsQuery.getKey = (variables?: ManualShareholdingDetailsQueryVariables) =>
  variables === undefined ? ['manualShareholdingDetails'] : ['manualShareholdingDetails', variables]
export const BeneficiariesDocument = `
    query beneficiaries {
  beneficiaries
}
    `
export const useBeneficiariesQuery = <TData = BeneficiariesQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: BeneficiariesQueryVariables,
  options?: UseQueryOptions<BeneficiariesQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<BeneficiariesQuery, TError, TData>(
    variables === undefined ? ['beneficiaries'] : ['beneficiaries', variables],
    fetcher<BeneficiariesQuery, BeneficiariesQueryVariables>(
      client,
      BeneficiariesDocument,
      variables,
      headers
    ),
    options
  )

useBeneficiariesQuery.getKey = (variables?: BeneficiariesQueryVariables) =>
  variables === undefined ? ['beneficiaries'] : ['beneficiaries', variables]
export const AddExitManualInvestmentDocument = `
    mutation addExitManualInvestment($input: AddExitManualInvestment) {
  addExitManualInvestment(input: $input)
}
    `
export const useAddExitManualInvestmentMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AddExitManualInvestmentMutation,
    TError,
    AddExitManualInvestmentMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    AddExitManualInvestmentMutation,
    TError,
    AddExitManualInvestmentMutationVariables,
    TContext
  >(
    ['addExitManualInvestment'],
    (variables?: AddExitManualInvestmentMutationVariables) =>
      fetcher<AddExitManualInvestmentMutation, AddExitManualInvestmentMutationVariables>(
        client,
        AddExitManualInvestmentDocument,
        variables,
        headers
      )(),
    options
  )
useAddExitManualInvestmentMutation.getKey = () => ['addExitManualInvestment']

export const UpdateExitManualInvestmentDocument = `
    mutation updateExitManualInvestment($input: UpdateExitManualInvestment) {
  updateExitManualInvestment(input: $input)
}
    `
export const useUpdateExitManualInvestmentMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateExitManualInvestmentMutation,
    TError,
    UpdateExitManualInvestmentMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    UpdateExitManualInvestmentMutation,
    TError,
    UpdateExitManualInvestmentMutationVariables,
    TContext
  >(
    ['updateExitManualInvestment'],
    (variables?: UpdateExitManualInvestmentMutationVariables) =>
      fetcher<UpdateExitManualInvestmentMutation, UpdateExitManualInvestmentMutationVariables>(
        client,
        UpdateExitManualInvestmentDocument,
        variables,
        headers
      )(),
    options
  )
useUpdateExitManualInvestmentMutation.getKey = () => ['updateExitManualInvestment']

export const DeleteExitManualInvestmentDocument = `
    mutation deleteExitManualInvestment($input: RemoveExitManualInvestment) {
  removeExitManualInvestment(input: $input)
}
    `
export const useDeleteExitManualInvestmentMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteExitManualInvestmentMutation,
    TError,
    DeleteExitManualInvestmentMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    DeleteExitManualInvestmentMutation,
    TError,
    DeleteExitManualInvestmentMutationVariables,
    TContext
  >(
    ['deleteExitManualInvestment'],
    (variables?: DeleteExitManualInvestmentMutationVariables) =>
      fetcher<DeleteExitManualInvestmentMutation, DeleteExitManualInvestmentMutationVariables>(
        client,
        DeleteExitManualInvestmentDocument,
        variables,
        headers
      )(),
    options
  )
useDeleteExitManualInvestmentMutation.getKey = () => ['deleteExitManualInvestment']

export const RemoveBeneficiaryDocument = `
    mutation removeBeneficiary($input: RemoveInvestmentInput) {
  removeInvestment(input: $input)
}
    `
export const useRemoveBeneficiaryMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveBeneficiaryMutation,
    TError,
    RemoveBeneficiaryMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<RemoveBeneficiaryMutation, TError, RemoveBeneficiaryMutationVariables, TContext>(
    ['removeBeneficiary'],
    (variables?: RemoveBeneficiaryMutationVariables) =>
      fetcher<RemoveBeneficiaryMutation, RemoveBeneficiaryMutationVariables>(
        client,
        RemoveBeneficiaryDocument,
        variables,
        headers
      )(),
    options
  )
useRemoveBeneficiaryMutation.getKey = () => ['removeBeneficiary']

export const EmailTermsheetDocument = `
    mutation emailTermsheet($input: EmailTermsheetInput) {
  emailTermsheet(input: $input)
}
    `
export const useEmailTermsheetMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    EmailTermsheetMutation,
    TError,
    EmailTermsheetMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<EmailTermsheetMutation, TError, EmailTermsheetMutationVariables, TContext>(
    ['emailTermsheet'],
    (variables?: EmailTermsheetMutationVariables) =>
      fetcher<EmailTermsheetMutation, EmailTermsheetMutationVariables>(
        client,
        EmailTermsheetDocument,
        variables,
        headers
      )(),
    options
  )
useEmailTermsheetMutation.getKey = () => ['emailTermsheet']
