import { Chart as ChartJS, PointElement, Tooltip, TimeScale } from 'chart.js'
import format from 'date-fns/format'
import { Line } from 'react-chartjs-2'
import { LineChartData } from '../../../../types/chartData'
import { isoToDate } from '../../../../utils/FormatDate'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

ChartJS.register(PointElement, Tooltip, TimeScale)
const uniImg = new Image(32, 32)
uniImg.src = toAbsoluteUrl('/media/icons/unicornStar.svg')

type Props = {
  firstInvData: LineChartData[]
  firstInvMap: any[]
  centMilData: LineChartData[]
  centMilMap: any[]
  unicornData: LineChartData[]
  unicornMap: any[]
}

function ScatterChart(props: Props) {
  const years = props.firstInvData.map((d) => new Date(d.x).getFullYear())
  const options = {
    backgroundColor: 'transparent',
    layout: {
      autoPadding: true,
    },
    showLine: false,
    scales: {
      y: {
        display: false,
        beginAtZero: true,
        max: 3.2,
      },
      x: {
        type: 'time' as const,
        time: { unit: 'year' as const },
        min:
          years.length > 0
            ? format(new Date(Math.min(...years) - 1, 0, 1), 'yyyy-MM-dd HH:mm:ss')
            : '2020-01-01 00:00:00',
        max:
          years.length > 0
            ? format(new Date(Math.max(...years), 11, 31), 'yyyy-MM-dd HH:mm:ss')
            : '2020-11-31 00:00:00',
        distribution: 'linear',
        title: { display: false },
        grid: { display: false },
        tooltip: { display: false },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        backgroundColor: getCSSVariableValue('--kt-primary'),
        bodyColor: 'white',
        bodyFont: { weight: 'bold' },
        borderColor: getCSSVariableValue('--kt-primary'),
        borderWidth: 1,
        caretPadding: 12,
        caretSize: 6,
        callbacks: {
          label: function (context: any) {
            let thisMap: any[] = []
            let label: string = context.dataset.label || ''
            if (label) {
              if (label === 'First Investment') thisMap = thisMap.concat(props.firstInvMap)
              if (label === '100 Million Round') thisMap = thisMap.concat(props.centMilMap)
              if (label === 'Unicorn Status') thisMap = thisMap.concat(props.unicornMap)
              label += ' - '
            }
            if (context.parsed.y !== null) {
              let comp: any[]
              comp = thisMap.filter((c: any) => c.compKey === context.parsed.y)
              if (comp.length > 0) label += comp[0].comp
              else label += 'Unknown'
            }
            return label
          },
          title: function (context: any) {
            return ''
          },
        },
      },
      // tooltip: {
      //   // Disable the on-canvas tooltip
      //   enabled: false,
      //   events: ['mousemove', 'mouseout', 'click'],
      //   external: function (context: any) {
      //     function gotoCompanyPage(compName: string) {
      //       console.log('~   gotoCompanyPage  compName', compName)
      //       return (ev: React.MouseEvent) => {}
      //     }
      //     const clickHandler = () => {
      //       return (event: React.MouseEvent) => {
      //         console.log('~   clickHandler  clickHandler')
      //         event.stopPropagation()
      //         console.log('~   clickHandler  clickHandler')
      //       }
      //     }
      //     // Tooltip Element
      //     let tooltipEl = document.getElementById('chartjs-tooltip')

      //     // Create element on first render
      //     if (!tooltipEl) {
      //       tooltipEl = document.createElement('div')
      //       tooltipEl.id = 'chartjs-tooltip'
      //       tooltipEl.innerHTML = '<div id="scattertip" class="bg-card-gray p-2 rounded-3"></div>'
      //       tooltipEl.onclick = clickHandler
      //       // tooltipEl.onclick = gotoCompanyPage('asdASDASD')
      //       document.body.appendChild(tooltipEl)
      //     }
      //     let tableRoot = tooltipEl.querySelector('[id="scattertip"]')

      //     // Hide if no tooltip
      //     const tooltipModel = context.tooltip
      //     if (tooltipModel.opacity === 0) {
      //       tooltipEl.style.opacity = '0'
      //       tooltipEl.style.transition = 'opacity 1s ease-out'
      //       return
      //     }

      //     // Set caret Position
      //     tooltipEl.classList.remove('above', 'below', 'no-transform')
      //     if (tooltipModel.yAlign) {
      //       tooltipEl.classList.add(tooltipModel.yAlign)
      //     } else {
      //       tooltipEl.classList.add('no-transform')
      //     }

      //     // Set Text
      //     if (tooltipModel.dataPoints) {
      //       const val = tooltipModel.dataPoints[0].raw.y
      //       let thisMap: any[] = []
      //       let label: string = tooltipModel.dataPoints[0].dataset.label
      //       if (label) {
      //         if (label === 'First Investment') thisMap = thisMap.concat(props.firstInvMap)
      //         if (label === '100 Million Round') thisMap = thisMap.concat(props.centMilMap)
      //         if (label === 'Unicorn Status') thisMap = thisMap.concat(props.unicornMap)
      //       }
      //       let compName: string = 'Unknown'
      //       if (val !== null) {
      //         let comp: any[]
      //         comp = thisMap.filter((c: any) => c.compKey === val)
      //         if (comp.length > 0) compName = comp[0].comp
      //       }
      //       // <h2 class="fs-1 text-gray-heading">267.74%</h2><p class="text-gray-600 fs-6 mb-0">Net Profit</p>
      //       let innerHtml = '<div>'
      //       innerHtml += '<h3 class="text-primary mb-0">' + compName + '</h3>'
      //       innerHtml += '<p class="text-gray-600 text-end fs-6 mb-0">' + label + ' on '
      //       innerHtml += isoToDate(tooltipModel.dataPoints[0].raw.x) + '</p>'
      //       innerHtml += '</div>'
      //       tableRoot!.innerHTML = innerHtml
      //     }

      //     const position = context.chart.canvas.getBoundingClientRect()

      //     // Display, position, and set styles for font
      //     tooltipEl.style.opacity = '1'
      //     tooltipEl.style.position = 'absolute'
      //     tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
      //     tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
      //     tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px'
      //     // tooltipEl.style.pointerEvents = 'none'
      //   },
      // },
    },
  }
  const data = {
    datasets: [
      {
        pointRadius: 5,
        showLine: false,
        pointStyle: 'circle',
        pointBackgroundColor: 'transparent',
        pointBorderWidth: 2,
        pointHoverBorderWidth: 4,
        pointHoverBorderColor: getCSSVariableValue('--kt-primary'),
        pointBorderColor: getCSSVariableValue('--kt-primary'),
        label: 'First Investment',
        data: props.firstInvData,
        fill: 'start',
        pointHoverRadius: 6,
      },
      {
        pointRadius: 5,
        showLine: false,
        pointStyle: 'circle',
        pointBackgroundColor: getCSSVariableValue('--kt-primary'),
        pointBorderWidth: 3,
        pointHoverBorderColor: getCSSVariableValue('--kt-primary'),
        pointBorderColor: getCSSVariableValue('--kt-primary'),
        label: '100 Million Round',
        data: props.centMilData,
        fill: 'start',
        pointHoverRadius: 7,
      },
      {
        pointRadius: 5,
        showLine: false,
        pointStyle: uniImg,
        label: 'Unicorn Status',
        data: props.unicornData,
        fill: 'start',
      },
    ],
  }
  return (
    <div>
      <Line options={options} data={data} />
    </div>
  )
}

export default ScatterChart
