/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { ResetPassword } from './components/ResetPassword'
import LoginPageContent from './components/LoginPageContent'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])
  return (
    <div className='h-100'>
      <div className='d-flex g-0 h-100 row'>
        <div className='col-lg-5 order-2 order-lg-1 order-md-2 order-sm-2 '>
          <div className='d-flex p-10 h-100 row '>
            <div className='d-flex justify-content-center'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/default.svg')}
                className='img-fluid h-lg-60px h-md-60px h-sm-60px h-60px'
              />
            </div>
            <div className='d-flex justify-content-center'>
              <Outlet />
            </div>
          </div>
        </div>
        <div className={'col-lg-7 order-1 order-lg-2 order-md-1 order-sm-1 gradientHissa'}>
          <LoginPageContent />
        </div>
      </div>
    </div>
  )
}
const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)
export { AuthPage }
