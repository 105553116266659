import { useContext, useEffect, useRef, useState } from 'react'
import Select, { MultiValue } from 'react-select'
import {
  GetAllClassificationsQuery,
  GetAllInvestmentsQuery,
  GetInvestorsQuery,
  GetTermsheetsQuery,
  Investor,
  useGetAllClassificationsQuery,
  useGetAllInvestmentsQuery,
  useGetInvestorsQuery,
  useGetTermsheetsQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'
import { ClassificationType } from '../../../types/Classification'
import { UserPreferenceType } from '../../../types/UserPreferences'
import { SelectOptionType, multiSelectCustomStyles } from '../../../utils/MultiSelect'
import { getUserId, getUserPreferences } from '../../../utils/LocalStorageUtils'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import DonutByStage from '../dashboard/components/DonutByStage'
import DonutWithCloseButton from '../dashboard/components/DonutWithCloseButton'
import { GlobalContext } from '../store/context/globalContext'
import { Actions, FilterType } from '../store/context/reducer'
import makeAnimated from 'react-select/animated'
import ChartComponent from './components/ChartComponent'
import { CompanyDetailsTable } from './components/CompanyDetailsTable'
import OpenDealsCard from './components/OpenDealsCard'
import StarredCompanies, { InvestorDetails } from './components/StarredCompanies'
import _ from 'lodash'

const animatedComponents = makeAnimated()

type Portfolio = {
  investorId: number
  investorName: string
  totalInvested: number
  gain: number
  moic: string
  currentValue: number
  irr: number
  avgRoundSize: number
  upRounds: number
  exits: number
  rounds: number
  leadInvestments: number
  totalInvestments: number
  portfolioCompanies: number
  investments: any[]
}
type Industry = {
  industryName: string
  count: number
}

type Investment = {
  range: string
  count: number
}

function PortfolioWrapper() {
  const [investorId, setInvestorId] = useState<number>()
  const [investor, setInvestor] = useState<Portfolio>()
  const [portfolios, setPortfolios] = useState<Portfolio[]>([])
  const [isActive, setIsActive] = useState(true)
  const [investorIds, setInvestorIds] = useState<number[]>([])
  const { dispatch, state } = useContext(GlobalContext)
  const { ctxFilterType, ctxGroupIds, ctxInvestorIds } = state

  const multiSelectRef = useRef<any>(null)

  const { isLoading, data: getInvestors } = useGetInvestorsQuery<GetInvestorsQuery, Error>(
    graphqlRequestClient,
    {},
    {
      onSuccess(data) {
        setInvestorIds(data?.investors.map((investor: Investor) => Number(investor.id)))
      },
    }
  )
  const getAllInvestments = useGetAllInvestmentsQuery<GetAllInvestmentsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )
  const termsheetsData = useGetTermsheetsQuery<GetTermsheetsQuery, Error>(graphqlRequestClient, {})
  const { data: classifications } = useGetAllClassificationsQuery<
    GetAllClassificationsQuery,
    Error
  >(graphqlRequestClient, {}, {})

  const [groupCompanies, setGroupCompanies] = useState<string[]>([])
  const [industriesChartData, setIndustriesChartData] = useState<Industry[]>([])
  const [investmentsChartData, setInvestmentsChartData] = useState<Investment[]>([])
  const [showIndustryDonut, setShowIndustryDonut] = useState(true)
  const [showInvestmentDonut, setShowInvestmentDonut] = useState(true)
  const [fundOptions, setFundOptions] = useState<SelectOptionType[]>([])
  const [groupOptions, setGroupOptions] = useState<SelectOptionType[]>([])

  useEffect(() => {
    if (ctxInvestorIds!.length === 0) {
      let userId = getUserId()
      let userPreferencesObj = userId ? getUserPreferences(userId) : undefined

      if (userPreferencesObj !== undefined) {
        let userPref = userPreferencesObj as UserPreferenceType
        // if localStorage is empty then
        if (userPref.filterType === FilterType.fund && userPref.selectedInvestors.length === 0) {
          dispatch({ type: Actions.setFilterType, payload: FilterType.fund })
          dispatch({ type: Actions.setGroupIds, payload: [] })
          dispatch({
            type: Actions.setInvestorIds,
            payload: investorIds,
          })
        } else if (userPref.filterType === FilterType.fund) {
          dispatch({ type: Actions.setFilterType, payload: FilterType.fund })
          dispatch({ type: Actions.setInvestorIds, payload: userPref.selectedInvestors })
          dispatch({ type: Actions.setGroupIds, payload: [] })
        } else if (userPref.filterType === FilterType.group) {
          dispatch({ type: Actions.setFilterType, payload: FilterType.group })
          dispatch({ type: Actions.setInvestorIds, payload: userPref.selectedInvestors })
          dispatch({ type: Actions.setGroupIds, payload: userPref.selectedGroups })
        } else if (userPref.filterType === FilterType.all) {
          dispatch({ type: Actions.setFilterType, payload: FilterType.all })
          dispatch({ type: Actions.setInvestorIds, payload: [] })
          dispatch({ type: Actions.setGroupIds, payload: [] })
        }
      }
    }
  }, [])

  useEffect(() => {
    let ports: any = []
    getInvestors?.investors?.forEach((investor: Investor, index: number) => {
      let totalInvested = 0
      let currentValue = 0
      let totalIRR = 0
      let count = 0

      investor.holdings?.forEach((holding) => {
        totalInvested += holding?.investedAmount!
        currentValue += holding?.currentValue!
        totalIRR += holding?.irr!
        count++
      })

      ports.push({
        investorId: investor.id,
        investorName: investor.name!,
        totalInvested: totalInvested,
        gain: currentValue - totalInvested,
        moic: currentValue === 0 ? '0' : (currentValue / totalInvested).toFixed(2),
        irr: currentValue === 0 ? 0 : totalIRR / count,
        currentValue: currentValue,
        avgRoundSize: (totalInvested / count).toFixed(2),
        totalInvestments: count,
        portfolioCompanies: investor.holdings ? investor.holdings.length : 0,
        upRounds: 0,
        exits: 0,
        rounds: 0,
        leadInvestments: 0,
        investments: [],
      })
    })

    if (ports.length > 0) {
      setPortfolios(ports)
      setInvestorId(ports[0].investorId)
      setInvestor(ports[0])
    }
  }, [getInvestors])

  useEffect(() => {
    const fundOptions: SelectOptionType[] = getInvestors?.investors.map((p: Investor) => {
      return { value: p.id, label: p.name, type: FilterType.fund }
    })

    const groupOptions: SelectOptionType[] = classifications?.classifications.map(
      (group: ClassificationType) => {
        return { value: group.id, label: group.name, type: FilterType.group }
      }
    )
    setFundOptions(fundOptions)
    setGroupOptions(groupOptions)
  }, [classifications, getInvestors?.investors, portfolios])

  useEffect(() => {
    let selectedGroupCompanies: string[] = []
    classifications?.classifications
      .filter((group: ClassificationType) => ctxGroupIds?.includes(Number(group.id)))
      .forEach((group: ClassificationType) =>
        group.companies.forEach((comp) => selectedGroupCompanies.push(comp.companyName))
      )
    setGroupCompanies(selectedGroupCompanies)
  }, [classifications?.classifications, ctxGroupIds])

  useEffect(() => {
    let industryData: Industry[] = []
    let investmentData: Investment[] = [
      { range: '< 1mn', count: 0 },
      { range: '1-3mn', count: 0 },
      { range: '3-10mn', count: 0 },
      { range: '10-25mn', count: 0 },
      { range: '> 25mn', count: 0 },
    ]
    const compDone: string[] = []
    portfolios.forEach((portfolio, index) => {
      const investorData = getInvestors?.investors?.filter(
        (e: any) => e.id == portfolio.investorId && ctxInvestorIds?.includes(Number(e.id))
      )

      // setting industry data for donut
      if (investorData && investorData.length > 0) {
        const holdings = investorData[0].holdings
        holdings.forEach((c: any) => {
          if (!c.isExited && !compDone.includes(c.name)) {
            if (ctxFilterType === FilterType.group && groupCompanies.includes(c.name)) {
              if (c.industry === null || c.industry === '') {
                let othersObj = industryData.find((industry) => industry.industryName === 'Others')
                if (othersObj) {
                  othersObj.count += 1
                } else {
                  industryData.push({ industryName: 'Others', count: 1 })
                }
              } else {
                let industryObj = industryData.find(
                  (industry) => industry.industryName === c.industry
                )
                if (industryObj) {
                  industryObj.count += 1
                } else {
                  industryData.push({ industryName: c.industry, count: 1 })
                }
              }
            } else if (ctxFilterType === FilterType.fund) {
              if (c.industry === null || c.industry === '') {
                let othersObj = industryData.find((industry) => industry.industryName === 'Others')
                if (othersObj) {
                  othersObj.count += 1
                } else {
                  industryData.push({ industryName: 'Others', count: 1 })
                }
              } else {
                let industryObj = industryData.find(
                  (industry) => industry.industryName === c.industry
                )
                if (industryObj) {
                  industryObj.count += 1
                } else {
                  industryData.push({ industryName: c.industry, count: 1 })
                }
              }
            }
            compDone.push(c.name)
          }
        })
      }

      industryData.sort((a, b) => b.count - a.count)
      if (industryData.length > 5) {
        const others: Industry[] = industryData.filter((e) =>
          e.industryName.toLowerCase().includes('other')
        )
        if (others.length > 0) {
          const nonOtherIndustries = industryData.filter(
            (e) => !e.industryName.toLowerCase().includes('other')
          )
          nonOtherIndustries.sort((a, b) => b.count - a.count)
          const sum = nonOtherIndustries.slice(4).reduce((acc, e) => acc + e.count, 0)
          const othersSum = others.reduce((acc, e) => acc + e.count, 0)
          const data: Industry = { industryName: 'Others', count: sum + othersSum }
          industryData = [...nonOtherIndustries.slice(0, 4), data]
        } else {
          const data: Industry = {
            industryName: 'Others',
            count: industryData.slice(4).reduce((acc, e) => acc + e.count, 0),
          }
          industryData = [...industryData.slice(0, 4), data]
        }
      }

      const investments: any[] = []
      const invName = portfolio.investorName
      if (ctxFilterType === FilterType.group && groupCompanies && groupCompanies.length > 0) {
        getAllInvestments.data?.allInvestments?.forEach((company) => {
          if (groupCompanies.includes(company.companyData.name))
            company.investmentData.forEach((investment: any) => {
              if (investment.name.toLowerCase() === invName.toLowerCase()) {
                investment.investorId = portfolio.investorId
                investment.companyName = company.companyData.name
                investment.cin = company.companyData.cin
                investment.industry = company.companyData.industry
                investments.push(investment)
              }
            })
        })
      } else {
        getAllInvestments.data?.allInvestments?.forEach((company) => {
          company.investmentData.forEach((investment: any) => {
            if (investment.name.toLowerCase() === invName.toLowerCase()) {
              investment.companyName = company.companyData.name
              investment.cin = company.companyData.cin
              investment.industry = company.companyData.industry
              investment.investorId = portfolio.investorId
              investments.push(investment)
            }
          })
        })
      }

      investments.forEach((investment: any) => {
        // setting investment data for donut
        if (ctxInvestorIds?.includes(Number(investment.investorId))) {
          let investmentObj
          if (parseFloat(investment.investedAmount) < 1000000) {
            investmentObj = investmentData.find((investment) => investment.range === '< 1mn')
            if (investmentObj) investmentObj!.count += 1
          } else if (
            parseFloat(investment.investedAmount) >= 1000000 &&
            parseFloat(investment.investedAmount) < 3000000
          ) {
            investmentObj = investmentData.find((investment) => investment.range === '1-3mn')
            if (investmentObj) investmentObj!.count += 1
          } else if (
            parseFloat(investment.investedAmount) >= 3000000 &&
            parseFloat(investment.investedAmount) < 10000000
          ) {
            investmentObj = investmentData.find((investment) => investment.range === '3-10mn')
            if (investmentObj) investmentObj!.count += 1
          } else if (
            parseFloat(investment.investedAmount) >= 10000000 &&
            parseFloat(investment.investedAmount) < 25000000
          ) {
            investmentObj = investmentData.find((investment) => investment.range === '10-25mn')
            investmentObj!.count += 1
          } else if (parseFloat(investment.investedAmount) >= 25000000) {
            investmentObj = investmentData.find((investment) => investment.range === '> 25mn')
            if (investmentObj) investmentObj!.count += 1
          }
        }
      })
    })
    setIndustriesChartData(industryData)
    setInvestmentsChartData(investmentData)
  }, [
    investor,
    investorId,
    getAllInvestments.data,
    portfolios,
    getInvestors?.investors,
    groupCompanies,
    ctxFilterType,
    ctxInvestorIds,
  ])

  useEffect(() => {
    setInvestor(
      portfolios.filter((p: Portfolio) => {
        return '' + p.investorId === '' + investorId
      })[0]
    )
  }, [investorId, portfolios])

  const bgColors: string[] = []
  for (let i = 0; i < 10; i++) {
    const color = getCSSVariableValue('--kt-donut-' + i)
    bgColors.push(color)
  }
  const investorsList: any[] = []
  const investorwiseGrouped = _.groupBy(getInvestors?.investors, (data) => [data.id])

  const keys = Object.keys(investorwiseGrouped)

  portfolios
    .map((port) => Number(port.investorId))
    .forEach((id) => {
      keys.forEach((key) => {
        if (String(id) === key) {
          investorsList.push(...investorwiseGrouped[id])
        }
      })
    })

  const industryChartData = {
    labels: industriesChartData.map((industry) => industry.industryName),
    datasets: [
      {
        label: '# of Industries',
        data: industriesChartData.map((industry) => industry.count),
        backgroundColor: bgColors,
        borderColor: bgColors,
        count: industriesChartData.reduce((a, b) => a + b.count, 0),
        investors_selected_count: investorsList.length,
        investors: investorsList,
      },
    ],
  }

  const investmentChartData = {
    labels: investmentsChartData.map((investment) => investment.range),
    datasets: [
      {
        label: '# of Investments',
        data: investmentsChartData.map((investment) => investment.count),
        backgroundColor: bgColors,
        borderColor: bgColors,
        count: investmentsChartData.reduce((a, b) => a + b.count, 0),
        investors_selected_count: investorsList.length,
        investors: investorsList,
      },
    ],
  }

  let invDetails: InvestorDetails[] = []

  invDetails.push({ id: ctxInvestorIds, invInfo: getAllInvestments.data })

  function handleFundSelect(e: MultiValue<SelectOptionType>) {
    if (e.length === 0) {
      dispatch({ type: Actions.setFilterType, payload: FilterType.all })
      dispatch({ type: Actions.setInvestorIds, payload: [] })
      dispatch({ type: Actions.setGroupIds, payload: [] })

      setGroupCompanies([])
      setIsActive(false)
    } else if (e.some((item) => item.type === 'Group')) {
      let groups: any[] = []
      e.forEach((item) => {
        groups.push(item)
      })

      handleGroupSelect(groups)
    } else {
      let selected = e.map((item) => Number(item.value))

      dispatch({ type: Actions.setFilterType, payload: FilterType.fund })
      dispatch({ type: Actions.setInvestorIds, payload: selected })
      dispatch({ type: Actions.setGroupIds, payload: [] })
      setIsActive(true)
    }
  }

  function handleGroupSelect(e: MultiValue<SelectOptionType>) {
    let selected = portfolios.map((item) => Number(item.investorId))
    dispatch({ type: Actions.setInvestorIds, payload: selected })

    let arr = e.map((e) => Number(e.value))
    dispatch({ type: Actions.setGroupIds, payload: arr })

    dispatch({ type: Actions.setFilterType, payload: FilterType.group })

    setIsActive(true)
  }

  useEffect(() => {
    if (ctxFilterType === FilterType.all) {
      setIsActive(false)
    } else {
      setIsActive(true)
    }
  }, [ctxFilterType])

  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  return (
    <>
      <div className='row gy-4'>
        <div className='col-sm-12'>
          <h1>Please choose the classification you would like to view</h1>
        </div>
        {
          <div className='col-sm-12 col-xl-8 mt-0'>
            <div className='pb-5'>
              {fundOptions?.length > 0 && (
                <Select
                  ref={multiSelectRef}
                  className='w-100 text-primary'
                  options={[
                    { label: 'Investors/ Funds', options: fundOptions },
                    { label: 'Classification Groups', options: groupOptions },
                  ]}
                  backspaceRemovesValue
                  value={
                    ctxFilterType === FilterType.fund
                      ? ctxInvestorIds?.length > 0
                        ? fundOptions?.filter((option) =>
                            ctxInvestorIds?.includes(Number(option.value))
                          )
                        : [...fundOptions]
                      : ctxGroupIds?.length > 0
                      ? groupOptions?.filter((option) =>
                          ctxGroupIds?.includes(Number(option.value))
                        )
                      : []
                  }
                  isMulti
                  closeMenuOnSelect={false}
                  styles={multiSelectCustomStyles}
                  components={animatedComponents}
                  onChange={(e) => {
                    handleFundSelect(e)
                  }}
                  isOptionDisabled={(option) =>
                    ctxFilterType !== FilterType.all && option.type !== ctxFilterType
                  }
                />
              )}
            </div>
          </div>
        }
      </div>
      {isActive && (
        <div className='row mb-5'>
          <div className='col-sm-12 col-lg-8'>
            <div className='card'>
              <ChartComponent
                getInvestors={getInvestors}
                getAllInvestments={getAllInvestments}
                chartColor='primary'
                chartHeight='200px'
                strokeColor='#FFFFFF'
                companies={groupCompanies}
                termsheetData={termsheetsData}
              />
            </div>
          </div>
          <div className='col-sm-12 col-lg-4'>
            {termsheetsData && <OpenDealsCard data={termsheetsData} companies={groupCompanies} />}
          </div>
        </div>
      )}
      {isActive && (
        <div className='row card-group'>
          <div className=' mb-4 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
            {investor && (
              <DonutByStage
                title='Portfolio by Stage'
                investorId={ctxInvestorIds!}
                investors={investorsList}
                handleIndustryShow={() => setShowIndustryDonut(!showIndustryDonut)}
                handleInvestmentShow={() => setShowInvestmentDonut(!showInvestmentDonut)}
                showIndustryDonut={showIndustryDonut}
                showInvestmentDonut={showInvestmentDonut}
                companies={groupCompanies}
              />
            )}
          </div>
          <div className=' mb-4 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
            {showIndustryDonut && investor && (
              <DonutWithCloseButton
                header='by Industry'
                data={industryChartData}
                handleClose={() => setShowIndustryDonut(false)}
              />
            )}
          </div>
          <div className=' mb-4 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
            {
              <>
                {showInvestmentDonut && investorId && (
                  <DonutWithCloseButton
                    header='by Investments'
                    data={investmentChartData}
                    handleClose={() => setShowInvestmentDonut(false)}
                  />
                )}
              </>
            }
          </div>
        </div>
      )}
      {isActive && (
        <div className='row mb-5'>
          <div className='col-sm-12'>
            <StarredCompanies data={invDetails} companies={groupCompanies} />
          </div>
        </div>
      )}
      {isActive && (
        <div className='row mb-5'>
          <div className='col-sm-12'>
            <div>
              <CompanyDetailsTable className='' companies={groupCompanies} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PortfolioWrapper
