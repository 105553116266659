import { init, setUserId, track } from '@amplitude/analytics-browser'
import jwtDecode from 'jwt-decode'
import { getAuth, JwtPayload } from '../app/modules/auth'

export const amplitudeInit = () => {
  if (process.env.REACT_APP_AMPLITUDE?.length! > 0) {
    init(process.env.REACT_APP_AMPLITUDE!, undefined, {})
  }
}

export const trackEvent = (eventName: string, properties?: Map<string, any>) => {
  track(eventName, properties)
}

export function handleClickForTracking(event: any) {
  const auth = getAuth()!
  const jwt = jwtDecode<JwtPayload>(auth)
  if (
    (event.target as HTMLButtonElement).tagName === 'BUTTON' ||
    (event.target as HTMLElement).tagName === 'A'
  ) {
    const actionName =
      (event.target as HTMLButtonElement).getAttribute('data-event-name') || event.target.innerText
    const screen = window.location.pathname
    const eventName = `${screen} ${actionName}`
    setUserId(jwt.emailId ?? 'Unknown')
    track(eventName, undefined, {})
  }
}

export function decodeAuthToken(accesstoken: string) {
  const decodedToken = jwtDecode(accesstoken) as {
    id: string
    emailId: string
  }
  return decodedToken
}
