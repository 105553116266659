import Nouislider from 'nouislider-react'
import { useState } from 'react'
import { kFormatter, kUnformatterNumber } from '../../../../../../../utils/CurrencyFormatter'

function LiquidationPreference() {
  const [moneyInWords, setMoneyInWords] = useState<any>()

  const tooltipFns = {
    to(value: number) {
      return kFormatter(value)
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }

  return (
    <div className='card shadow-sm mt-4 p-8'>
      <div className='row align-items-center'>
        <h3 className='col fw-bold pb-4'>Liquidation Preference</h3>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Select Rank of Investment</h4>
        </div>
        <div className='col-6 col-md-4'>
          <select className='form-control form-control-md form-control-solid ' disabled />
        </div>
        <div className='col-6 col-md-4'>
          <div className='form-check form-switch form-check-custom form-check-solid align-items-center'>
            <h4>Pari Passu</h4>
            <input
              className='form-check-input h-20px w-40px ms-4  '
              type='checkbox'
              value=''
              readOnly
              checked
            />
            <label className='form-check-label'>Yes</label>
          </div>
        </div>
      </div>

      <div className='row align-items-baseline'>
        <div className='col-6 col-md-4'>
          <h4>Investment Multiple</h4>
        </div>
        <div className='col-6 col-md-4'>
          <Nouislider
            tooltips={[tooltipFns]}
            start={250000000}
            range={{ min: 0, max: 1000000000 }}
            connect
            onChange={(e) => setMoneyInWords(e)}
            className=''
            disabled
          />
        </div>
        <div className='col-6 col-md-4'>
          <input
            type='text'
            value={'250000000'}
            className='form-control form-control-md form-control-solid'
            disabled
          />
          <div className='form-text'>Rupees Twenty Five Crores</div>
        </div>
      </div>

      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Enable Participation</h4>
        </div>
        <div className='col-6 col-md-8'>
          <div className='form-check form-switch form-check-custom form-check-solid me-10'>
            <input
              className='form-check-input h-20px w-40px'
              type='checkbox'
              value=''
              readOnly
              checked
            />
            <label className='form-check-label'>Yes</label>
          </div>
        </div>
      </div>

      <div className='row align-items-baseline'>
        <div className='col-6 col-md-4'>
          <h4>Participation Cap</h4>
        </div>
        <div className='col-6 col-md-4'>
          <Nouislider
            tooltips={[tooltipFns]}
            start={250000000}
            range={{ min: 0, max: 1000000000 }}
            connect
            onChange={(e) => setMoneyInWords(e)}
            className=''
            disabled
          />
        </div>
        <div className='col-6 col-md-4'>
          <input
            type='text'
            value={'250000000'}
            className='form-control form-control-md form-control-solid'
            disabled
          />
          <div className='form-text'>Rupees Twenty Five Crores</div>
        </div>
      </div>
    </div>
  )
}

export default LiquidationPreference
