import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import {
  RemoveClassificationMutation,
  useGetAllClassificationsQuery,
  useGetClassificationQuery,
  useRemoveCompaniesFromClassificationMutation,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { kFormatter } from '../../../../utils/CurrencyFormatter'
import { getInitials } from '../../../../utils/Utils'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { toAbsoluteUrl, KTSVG } from '../../../../_metronic/helpers'

type PortfolioSearchItemProps = {
  groupId?: number
  companyName: string
  companyId: string
  companyLogo: string
  pps: number
  valuation: number
  latestRaise: number
  noOfRounds: number
  totalFunding: number
  isOddRow: boolean
  business: string
  industry: string
  isHissaVerified: boolean
  isChecked: boolean
  isAddButton: boolean
  handleChecked: () => void
}

function PortfolioSearchItem(props: PortfolioSearchItemProps) {
  const [isClicked, setIsClicked] = useState(props.isChecked)

  const removeCompanyMutation = useRemoveCompaniesFromClassificationMutation<
    Error,
    RemoveClassificationMutation
  >(
    graphqlRequestClient,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(useGetAllClassificationsQuery.getKey())
        queryClient.invalidateQueries(
          useGetClassificationQuery.getKey({ input: { id: props.groupId } })
        )
      },
    },
    {}
  )

  function handleRemoveCompany(company: string) {
    removeCompanyMutation.mutate({
      input: {
        id: props.groupId,
        companies: [{ companyName: company }],
      },
    })
  }

  return (
    <Card
      className={
        props.isOddRow
          ? 'wl-odd-row shadow-sm mb-4 z-index-0'
          : 'wl-even-row shadow-sm mb-4 z-index-0'
      }
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <div className='row px-6 pt-6 pb-2 justify-content-center align-items-start justify-content-lg-start'>
        <div className='col-xs-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 h-auto text-center'>
          <Card className='shadow-sm min-h-125px min-w-125px mh-125px mw-125px m-auto'>
            <Card.Body className={'d-flex justify-content-center align-items-center p-0'}>
              {props.companyLogo !== null &&
              props.companyLogo !== undefined &&
              props.companyLogo !== 'NA' &&
              props.companyLogo !== '' ? (
                <img
                  alt={props.companyName}
                  className='h-75px logo text-center'
                  src={toAbsoluteUrl(props.companyLogo)}
                />
              ) : (
                <div>
                  <p
                    className='mb-0 fs-5x'
                    style={{
                      color: getCSSVariableValue('--kt-donut-9'),
                    }}
                  >
                    {getInitials(props.companyName)}
                  </p>
                </div>
              )}
            </Card.Body>
          </Card>

          <div className='col d-flex justify-content-center mt-1 text-primary'>
            <KTSVG
              path='/media/icons/duotune/general/industry.svg'
              className='svg-icon-2 text-center px-2'
            />
            <p>{props.industry || 'Others'}</p>
          </div>
        </div>

        <div className='col'>
          <div className='row d-flex justify-content-between'>
            <div className='col-md-8 col-sm-12'>
              {props.companyName ? (
                <>
                  <h2>
                    {props.companyName}{' '}
                    {props.isHissaVerified && (
                      <KTSVG
                        path='/media/icons/duotune/general/gen043.svg'
                        className='text-primary'
                      />
                    )}
                  </h2>
                </>
              ) : (
                <></>
              )}

              {props.business && (
                <span className='text-muted fw-bold d-block fs-5'>{props.business}</span>
              )}
            </div>
            <div className='col-md-4 col-sm-12 justify-content-end d-flex'>
              <div>
                {props.isAddButton ? (
                  <button
                    className='btn btn-primary'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      props.handleChecked()
                      setIsClicked(!isClicked)
                    }}
                    disabled={isClicked}
                  >
                    {!isClicked ? 'ADD +' : 'ADDED'}
                  </button>
                ) : (
                  <button
                    className='btn btn-primary'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleRemoveCompany(props.companyName)
                    }}
                    disabled={removeCompanyMutation.isSuccess}
                  >
                    {removeCompanyMutation.isLoading ? (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Removing...
                        <span className='spinner-border spinner-border-sm align-middle mx-2'></span>
                      </span>
                    ) : removeCompanyMutation.isSuccess ? (
                      'Removed'
                    ) : (
                      'Remove'
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='d-flex mt-10'>
            <Card className={'shadow-sm h-100px w-auto me-3'}>
              <div className='row m-2 mt-4 d-flex justify-content-between align-items-center h-100'>
                <h1
                  className=' lh-1 mt-2'
                  style={{ color: getCSSVariableValue('--kt-donut-' + 9) }}
                >
                  {props.pps === 0 ? (
                    'NA'
                  ) : (
                    <>
                      <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                      <span>{kFormatter(props.pps)}</span>
                    </>
                  )}
                </h1>
                <p className='text-gray-600'>PPS value</p>
              </div>
            </Card>
            <Card className={' shadow-sm h-100px w-auto me-3'}>
              <div className='row m-2 mt-4 d-flex justify-content-between align-items-center h-100'>
                <h1 className='lh-1 mt-2' style={{ color: getCSSVariableValue('--kt-donut-' + 6) }}>
                  {props.valuation === 0 ? (
                    'NA'
                  ) : (
                    <>
                      <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                      <span>{kFormatter(props.valuation)}</span>
                    </>
                  )}
                </h1>
                <p className=' text-gray-600'>Valuation</p>
              </div>
            </Card>
            <Card className={'shadow-sm h-100px w-auto me-3'}>
              <div className='row m-2 mt-4 d-flex justify-content-between align-items-center h-100'>
                <h1 className='lh-1 mt-2' style={{ color: getCSSVariableValue('--kt-donut-' + 0) }}>
                  {props.latestRaise === 0 ? (
                    'NA'
                  ) : (
                    <>
                      <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                      <span>{kFormatter(props.latestRaise)}</span>
                    </>
                  )}
                </h1>
                <p className=' text-gray-600'>Latest raise</p>
              </div>
            </Card>
            <Card className={'shadow-sm h-100px w-auto me-3'}>
              <div className='row m-2 mt-4 d-flex justify-content-between align-items-center h-100'>
                <h1 className='lh-1 mt-2' style={{ color: getCSSVariableValue('--kt-donut-' + 4) }}>
                  {props.noOfRounds === 0 ? 'NA' : props.noOfRounds}
                </h1>
                <p className='text-gray-600'>No. of rounds</p>
              </div>
            </Card>
            <Card className={'shadow-sm h-100px w-auto '}>
              <div className='row m-2 mt-4 d-flex justify-content-between align-items-center h-100'>
                <h1 className='lh-1 mt-2' style={{ color: getCSSVariableValue('--kt-donut-' + 3) }}>
                  {props.totalFunding === 0 ? (
                    'NA'
                  ) : (
                    <>
                      <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                      <span>{kFormatter(props.totalFunding)}</span>
                    </>
                  )}
                </h1>
                <p className=' text-gray-600'>Total funding</p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default PortfolioSearchItem
