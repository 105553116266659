import jwtDecode, { JwtPayload } from 'jwt-decode'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Toast, { ToastType } from '../../../../utils/toast'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { getAuth } from './AuthHelpers'

const AuthVerify = (props: any) => {
  let location = useLocation()
  useEffect(() => {
    MenuComponent.reinitialization()
    const auth = getAuth()!
    const jwt = jwtDecode<JwtPayload>(auth)
    if (jwt === undefined || jwt.exp! * 1000 < Date.now()) {
      props.logout()
      Toast('Session Expired', ToastType.error)
    }
  }, [location])
  return <></>
}

export default AuthVerify
