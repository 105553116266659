import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  GetAllClassificationsQuery,
  GetAllInvestmentsQuery,
  GetInvestorsQuery,
  GetPortfolioMetricsQuery,
  Investor,
  useGetAllClassificationsQuery,
  useGetAllInvestmentsQuery,
  useGetInvestorsQuery,
  useGetPortfolioMetricsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { globalFilter } from '../../../../utils/array'
import { kFormatter, kUnformatterNumber } from '../../../../utils/CurrencyFormatter'
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent'
import { KTSVG } from '../../../../_metronic/helpers'
import { usePortfolioCompaniesTableStore } from '../../store/PortfolioCompaniesTableFilters'
import CustomRowDropDown from './CustomRowDropdown'
import TableHeaderDropdown from './TableHeaderDropdown'
import { AddInvestmentModal } from '../../manual-investments/components/AddInvestment/AddInvestmentModal'
import Nouislider from 'nouislider-react'
import { useExportToExcel } from '../../../../api/hissa'
import {
  convertBase64ToBlob,
  downloadBlobObject,
  portCompHeadersList,
  portCompHeadersObj,
  portCompTopHeadersList,
  InvDetails,
  CompWiseDetails,
  compWiseHeadersObj,
  compWiseHeaders,
  overviewKey,
  overviewLabel,
} from '../../../../utils/ExportExcel'
import { toast } from 'react-toastify'
import { getAuth, JwtPayload } from '../../../modules/auth'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { setupInitials } from '../../../../utils/Utils'
import { GlobalContext } from '../../store/context/globalContext'
import { ClassificationType } from '../../../../types/Classification'
import { isoToDate } from '../../../../utils/FormatDate'
import { FilterType } from '../../store/context/reducer'
import Toast, { ToastType } from '../../../../utils/toast'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../utils/Select'

type Props = {
  className: string
  companies?: string[]
}

type Group = {
  groupId: number
  groupName: string
  companies: string[]
}

const CompanyDetailsTable = (props: Props) => {
  const [showFilters, setShowFilter] = useState(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [investorList, setInvestorList] = useState([])
  const [industry, setIndustry] = useState<any>()
  const [verified, setVerified] = useState<any>()
  const [fund, setFund] = useState<any>()
  const [round, setRound] = useState<any>()
  const [maxValRange, setMaxValRange] = useState(1000000000)
  const [maxRaiseRange, setMaxRaiseRange] = useState(1000000000)
  const [valuationSliderRef, setValuationSliderRef] = useState<any>(null)
  const [moneyRaisedSliderRef, setMoneyRaisedSliderRef] = useState<any>(null)
  const [isInvestorNameVisible, setIsInvestorNameVisible] = useState(false)
  const minValRef = useRef<HTMLInputElement>(null)
  const maxValRef = useRef<HTMLInputElement>(null)
  const minRaiseRef = useRef<HTMLInputElement>(null)
  const maxRaiseRef = useRef<HTMLInputElement>(null)
  const [initVal, setInitVal] = useState(800000000)
  const [initRaise, setInitRaise] = useState(800000000)
  const initialValuationRange = [0, initVal]
  const initialMoneyRaisedRange = [0, initRaise]
  const auth = getAuth()!
  const jwt = jwtDecode<JwtPayload>(auth)
  const [portfolioMetrics, setPortfolioMetrics] = useState<GetPortfolioMetricsQuery>({})
  const { state } = useContext(GlobalContext)
  const { ctxFilterType, ctxGroupIds, ctxInvestorIds } = state
  const [groups, setGroups] = useState<Group[]>([])

  const getAllClassifications = useGetAllClassificationsQuery<GetAllClassificationsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const tooltipFns = {
    to(value: number) {
      return kFormatter(value)
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }

  const [rangeToggle, setRangeToggle] = useState(9999)
  function rangeSelection(days: number) {
    setRangeToggle(days)
    filter.setDateRange(days)
  }

  function handleShowFilter() {
    setShowFilter(!showFilters)
  }
  const { mutate: exportExcel } = useExportToExcel()

  const makeKeyValues = (keys: string[], obj: any) =>
    keys.map((ele: string) => ({ key: ele, value: obj[ele] }))

  const makeLabelValues = (keys: string[], obj: any, label: any, types: any) =>
    keys.map((ele: string, i) => ({ key: label[ele], value: obj[ele], type: types[i] }))

  const makeCoInvCounts = (data: CompWiseDetails[]) => {
    let retStr = ''
    let invMap = new Map<string, number>()

    data.forEach((row) => {
      const coIn = row.coInvestors ? row.coInvestors.split(',').map((s) => s.trim()) : []
      coIn.forEach((inv) => {
        if (inv.length > 0)
          if (invMap.has(inv)) {
            let c: number = invMap.get(inv)!
            c++
            invMap.delete(inv)
            invMap.set(inv, c)
          } else {
            invMap.set(inv, 1)
          }
      })
    })
    const sMap = new Map([...Array.from(invMap.entries())].sort((a, b) => b[1] - a[1]))
    for (let [key, value] of Array.from(sMap.entries()))
      if (value > 1) retStr += key + ' (' + value + '), '
    if (retStr.length > 2) retStr = retStr.substring(0, retStr.length - 2)

    return retStr
  }

  function top3Distinct(invList: string, me: string): string {
    const top3D: string[] = []
    const iList = invList.split('|')

    iList.map((i) => {
      if (i !== me && !top3D.includes(i) && top3D.length < 3)
        top3D.push(i.replaceAll(',', ' ').replaceAll('  ', ' '))
    })
    return top3D.join(', ')
  }

  function handleExportData() {
    const now = new Date().toLocaleDateString()
    const totalsLine: string[] = ['TOTAL', '', 'Amount', '', '', '', '', '', '', '', '', '', '']
    const compList: string[] = Array.from(
      new Set(
        memoizedResult
          .filter((c: any) => c.isExited === undefined || !c.isExited)
          .map((c: any) => c.name)
      )
    )
    const compArr: any[] = []
    const compWiseData: CompWiseDetails[] = []
    let gTotAmt: number = 0
    let noInvestments: number = 0
    let firstChecks: number = 0

    compList.forEach((comp) => {
      const cwD: CompWiseDetails = {
        companyName: comp,
        sector: '',
        valuation: 0,
        valuationDate: '',
        totalFunding: 0,
        lastRoundName: '',
        lastRaise: 0,
        lastRaiseDate: '',
        yourHolding: '',
        investedAmount: '',
        founderHolding: '',
        esopHolding: '',
        coInvestors: '',
        foundedYear: 0,
        incorporationDate: '',
      }
      const invDetArr: InvDetails[] = []
      let totShares: number = 0,
        totAmount: number = 0,
        compShares: number = 0
      let allRounds: string[] = []
      const subHeaders = makeKeyValues(portCompHeadersList, portCompHeadersObj)
      subHeaders[0].value = comp
      memoizedResult.map((item: any) => {
        if (item.name === comp) {
          cwD.sector = item.industry !== null ? item.industry : ''
          cwD.valuation = item.valuation ? item.valuation!.toFixed(2) : 'NA'
          cwD.totalFunding = item.totalFunding ? item.totalFunding!.toFixed(2) : 'NA'
          cwD.investedAmount = item.totalFunding ? item.totalFunding!.toFixed(2) : 'NA'
          cwD.lastRaise = item.latestRaise ? item.latestRaise!.toFixed(2) : 'NA'
          cwD.lastRaiseDate = item.latestRaiseDate ? isoToDate(item.latestRaiseDate) : ''
          cwD.valuationDate = item.latestRaiseDate ? isoToDate(item.latestRaiseDate) : ''
          cwD.investedAmount = item.totalFunding ? item.totalFunding!.toFixed(2) : 'NA'
          cwD.lastRaise = item.latestRaise ? item.latestRaise!.toFixed(2) : 'NA'
          cwD.lastRaiseDate = item.latestRaiseDate ? isoToDate(item.latestRaiseDate) : ''
          cwD.valuationDate = item.latestRaiseDate ? isoToDate(item.latestRaiseDate) : ''
          cwD.lastRoundName = item.latestRound
          cwD.founderHolding =
            item.founderHolding !== null ? (item.founderHolding * 100).toFixed(2) + ' %' : ''
          cwD.esopHolding =
            item.esopHolding !== null ? (item.esopHolding * 100).toFixed(2) + ' %' : ''
          cwD.coInvestors = ''
          cwD.foundedYear = item.founded ? item.founded.substring(0, 4) : ''
          cwD.incorporationDate = item.incorporationDate ? item.incorporationDate : ''

          const gID: InvDetails = {
            companyName: '',
            investmentVehicle: item.investorName,
            moic: item.moic ? item.moic.toFixed(2) + ' X' : 'NA',
            irr: item.irr ? item.irr.toFixed(2) : 'NA',
            holding: '',
            rounds: '',
            amount: 0,
            shares: 0,
            date: '',
            roundValn: 0,
            roundSize: 0,
            roundPreValn: 0,
            sharePrice: 0,
            coInvestors: '',
            dilution: 0,
          }
          if (item.investments) {
            item.investments.forEach((inv: any) => {
              let id = _.clone(gID)
              id.shares = item.isVerified
                ? parseInt(inv.dilutedShares, 10)
                : parseInt(inv.noOfShares, 10)
              id.amount = inv.investedAmount.toFixed(2)
              id.date = isoToDate(inv.dateInvested)
              id.date = isoToDate(inv.dateInvested)
              id.roundValn = inv.roundValn ? inv.roundValn.toFixed(2) : 0
              id.roundSize = inv.roundSize ? inv.roundSize.toFixed(2) : 0
              id.roundPreValn = inv.roundValn - inv.roundSize
              id.sharePrice = item.isVerified
                ? inv.pps && inv.pps.toFixed(2)
                : inv.currentSharePrice && parseInt(inv.currentSharePrice, 10).toFixed(2)
              id.coInvestors = inv.coInvestors
                ? top3Distinct(inv.coInvestors, item.investorName)
                : ''
              cwD.coInvestors += id.coInvestors.length > 0 ? id.coInvestors + ', ' : ''
              id.dilution = inv.dilution ? inv.dilution.toFixed(2) : 0
              id.rounds = inv.roundName
              id.holding = item.totalShares > 0 ? (id.shares / item.totalShares).toString() : 'NA'
              totShares += id.shares
              totAmount += inv.investedAmount
              noInvestments++
              allRounds.push(inv.roundName)
              invDetArr.push(id)
            })
            firstChecks +=
              item.investments && item.investments.length > 0
                ? item.investments[0].investedAmount
                : 0
          }
          compShares = item.totalShares
          cwD.coInvestors = Array.from(
            new Set(
              cwD.coInvestors
                .substring(0, cwD.coInvestors.length - 2)
                .split(',')
                .map((s) => s.trim())
            )
          ).join(', ')
          cwD.yourHolding =
            item.totalShares > 0 ? ((totShares * 100) / item.totalShares).toFixed(2) + ' %' : 'NA'
          cwD.investedAmount = totAmount.toFixed(2)
          return gID
        } else return null
      })
      subHeaders[2].value = totAmount.toFixed(2)
      subHeaders[3].value = totShares
      subHeaders[13].value = compShares > 0 ? (totShares / compShares).toString() : 'NA'
      subHeaders[14].value = allRounds
        .filter((value, index, self) => {
          return self.indexOf(value) === index
        })
        .join(',')
      gTotAmt += totAmount
      compArr.push({ [comp]: { subHeaders, investmentDetails: invDetArr } })
      compWiseData.push(cwD)
    })

    totalsLine[2] = gTotAmt.toFixed(2)

    const overview = {
      noPortComp: compList.length,
      noInvestments: noInvestments,
      totalInvested: gTotAmt.toFixed(2),
      totalFirstCheques: firstChecks.toFixed(2),
      avgInvestment: (gTotAmt / noInvestments).toFixed(2),
      noFollowRounds: portfolioMetrics?.portfolioMetrics?.reduce(
        (prev: any, current: any) => prev + +current.count,
        0
      ),
      noFollowCapital: portfolioMetrics?.portfolioMetrics
        ?.reduce((prev: any, current: any) => prev + +current.amount, 0)
        .toFixed(2),
      coInvestors: makeCoInvCounts(compWiseData),
    }

    const reqObj = {
      investorDetails:
        'Investor Name: ' +
        jwt.fullName +
        '\nData as on: ' +
        now +
        '\nReport Generation as on: ' +
        now,
      heading: 'Investor portfolio details',
      headers: portCompTopHeadersList,
      companyNames: compList,
      data: compArr,
      Total: totalsLine,
      overview: makeLabelValues(overviewKey, overview, overviewLabel, [1, 1, 4, 4, 4, 1, 4, 0]),
      compWiseHeaders: makeKeyValues(compWiseHeaders, compWiseHeadersObj),
      // 0=general 1=num 0, 2=num 0.00, 3=num',' 4= num','.00 9=pc 0% 10=pc 0.00%
      ipSheetDataType: [0, 0, 4, 3, 0, 4, 4, 4, 4, 0, 2, 0, 2, 10, 0],
      // 0=general 1=num 0, 2=num 0.00, 3=num',' 4= num','.00 9=pc 0% 10=pc 0.00%
      cwSheetDataType: [0, 0, 4, 0, 4, 4, 0, 4, 0, 10, 4, 10, 10, 0, 1, 0],
      compWiseData: compWiseData,
    }

    exportExcel(reqObj, {
      onSuccess: async (data) => {
        const blob = await convertBase64ToBlob(data.base64File, data.fileType)
        downloadBlobObject(blob, 'PortfolioCompanies.xlsx')
        toast('Data exported to Excel, check your downloads folder.')
        Toast('Data exported to Excel, check your downloads folder!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    })
  }

  const { isLoading, data, isPreviousData } = useGetInvestorsQuery<GetInvestorsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const getAllInvestments = useGetAllInvestmentsQuery<GetAllInvestmentsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )
  const input =
    props.companies !== undefined
      ? { investorIds: investorList.map((i: any) => i.investorId), companies: props.companies }
      : { investorIds: investorList.map((i: any) => i.investorId) }

  const { data: portMet } = useGetPortfolioMetricsQuery<GetPortfolioMetricsQuery, Error>(
    graphqlRequestClient,
    { input: input },

    { enabled: investorList.length > 0 }
  )

  useEffect(() => {
    if (portMet) setPortfolioMetrics(portMet)
  }, [portMet])

  const queryKey = useGetInvestorsQuery.getKey()
  const [companies, setCompanies] = useState([])
  const [fetchedData, setFetchedData] = useState([])
  const filter = usePortfolioCompaniesTableStore()
  let originalData: any = []

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [])

  useEffect(() => {
    let groups: Group[] = []
    getAllClassifications.data?.classifications
      .filter((group: ClassificationType) => ctxGroupIds?.includes(Number(group.id)))
      .forEach((group: ClassificationType) => {
        let companies: string[] = []
        group.companies.forEach((company) => companies.push(company.companyName))
        groups.push({ groupId: group.id, groupName: group.name, companies: companies })
      })
    setGroups(groups)
  }, [ctxGroupIds, getAllClassifications.data?.classifications])

  useEffect(() => {
    if (isPreviousData) return
    originalData = []
    let investorList: any = []
    let maxValuation = 1000000000
    let maxLatestRange = 1000000000
    let initValuation = 0
    let initLatestRaise = 0

    data?.investors.forEach((investor: Investor) => {
      if (ctxInvestorIds?.includes(Number(investor.id))) {
        investorList.push({ investorId: investor.id, investorName: investor.name })
        investor.holdings?.forEach((holding: any) => {
          if (props.companies && props.companies.length > 0) {
            if (props.companies.includes(holding.name)) {
              originalData.push({
                investorId: investor.id,
                investorName: investor.name,
                name: holding?.name,
                companyId: holding?.cin,
                investedAmount: holding?.investedAmount,
                holding: holding?.percentage,
                moic: holding?.moic,
                irr: holding?.irr,
                isVerified: holding?.hissaVerified,
                industry: holding?.industry,
                noOfRounds: null,
                latestRaise: null,
                totalShares: null,
                totalFunding: null,
                valuation: null,
                logo: holding?.logo,
                business: null,
                groupIds: [],
                isExited: holding?.isExited,
              })
            }
          } else {
            originalData.push({
              investorId: investor.id,
              investorName: investor.name,
              name: holding?.name,
              companyId: holding?.cin,
              investedAmount: holding?.investedAmount,
              holding: holding?.percentage,
              moic: holding?.moic,
              irr: holding?.irr,
              isVerified: holding?.hissaVerified,
              industry: holding?.industry,
              noOfRounds: null,
              latestRaise: null,
              totalShares: null,
              totalFunding: null,
              valuation: null,
              logo: holding?.logo,
              business: null,
              groupIds: [],
              isExited: holding?.isExited,
            })
          }
        })
      }
    })

    originalData.sort((a: any, b: any) => a.name.localeCompare(b.name))
    const initialsMap = setupInitials(originalData)
    originalData.forEach((holding: any) => {
      let index: any = -1
      if (props.companies && props.companies.length > 0) {
        if (props.companies.includes(holding.name))
          index = getAllInvestments.data?.allInvestments?.findIndex((company: any) => {
            return (
              holding.name === company.companyData.name &&
              holding.companyId === company.companyData.cin
            )
          })
      } else {
        index = getAllInvestments.data?.allInvestments?.findIndex((company: any) => {
          return (
            holding.name === company.companyData.name &&
            holding.companyId === company.companyData.cin
          )
        })
      }
      if (index > -1) {
        const invRecords = getAllInvestments.data?.allInvestments![index]
        holding.investments = invRecords.investmentData.filter(
          (i: any) => i.name.toLowerCase() === holding.investorName.toLowerCase()
        )
        const companyData = getAllInvestments.data?.allInvestments![index].companyData
        const initColours = initialsMap.get(holding.name)
        holding.initials = initColours.initials
        holding.initIndex = initColours.count
        holding.noOfRounds = companyData.noOfRounds || holding.investments.length
        holding.latestRound = companyData.latestRound || ''
        holding.founderHolding = companyData.founderHolding || 0
        holding.coInvestors = companyData.coInvestors || ''
        holding.esopHolding = companyData.esopHolding || 0
        holding.founded = companyData.founded || ''
        holding.latestRaise = companyData.latestRaise || 0
        holding.latestRaiseDate = companyData.latestRaiseDate || ''
        holding.totalShares = companyData.totalShares || 0
        holding.totalFunding = companyData.totalFunding || 0
        holding.valuation = companyData.valuation || 0
        holding.logo = companyData.logo
        holding.incorporationDate = companyData.incorporationDate || ''
        holding.business =
          companyData.business === undefined || companyData.business === 'null'
            ? 'Others'
            : companyData.business
        if (holding.valuation >= maxValuation) {
          maxValuation = holding.valuation
        }
        if (holding.latestRaise >= maxLatestRange) {
          maxLatestRange = holding.latestRaise
        }
      }
      if (holding.isExited) {
        const initColours = initialsMap.get(holding.name)
        holding.initials = initColours.initials
        holding.initIndex = initColours.count
      }
    })

    if (ctxFilterType === FilterType.group) {
      groups?.forEach((group) => {
        let companies = originalData.filter((comp: any) => group.companies.includes(comp.name))
        companies.forEach((comp: any) => comp.groupIds.push(Number(group.groupId)))
      })
    }
    initValuation = maxValuation
    initLatestRaise = maxLatestRange
    setCompanies(originalData)
    setFetchedData(originalData)
    setInvestorList(investorList)
    setMaxValRange(maxValuation)
    setMaxRaiseRange(maxLatestRange)
    setInitVal(initValuation)
    setInitRaise(initLatestRaise)
    filter.setMaxValuation(initValuation)
    filter.setMaxLatestRaise(initLatestRaise)
  }, [data, getAllInvestments.data, ctxInvestorIds, props.companies])

  let memoizedResult: any = []
  memoizedResult = useMemo(() => {
    if (!companies) return []
    const result = globalFilter(companies, filter.searchText, ['name'])
      .filter(
        (company: any) =>
          !filter.industry ||
          company.industry === filter.industry ||
          (company.industry === null && filter.industry === 'Others')
      )
      .filter(
        (company: any) => !filter.isVerified || company.isVerified.toString() === filter.isVerified
      )
      .filter((company: any) => !filter.investorId || company.investorId === filter.investorId)
      .filter(
        (company: any) =>
          !filter.dateOfInvestment ||
          company.investments?.some((investment: any) => {
            if (new Date(investment.dateInvested) <= filter.dateOfInvestment!) {
              return investment
            }
          })
      )
      .filter(
        (company: any) =>
          !filter.dateRange ||
          company.investments?.some((investment: any) => {
            if (filter.dateRange === 0) {
              return investment
            }
            const date = new Date(investment.dateInvested)
            const now = new Date()
            const diff = filter.dateOfInvestment
              ? filter.dateOfInvestment.getTime() - date.getTime()
              : now.getTime() - date.getTime()
            const result = Math.ceil(Math.abs(diff) / (1000 * 60 * 60 * 24))
            if (result < filter.dateRange) {
              return investment
            }
          })
      )
      .filter(
        (company: any) =>
          !filter.roundName ||
          company.investments?.some((investment: any) => {
            if (investment.roundName === filter.roundName) {
              return company
            }
          })
      )
      .filter((company: any) => {
        return filter.minValuation === 0 || company.valuation >= filter.minValuation!
      })
      .filter((company: any) => {
        return filter.maxValuation === 0 || company.valuation <= filter.maxValuation!
      })
      .filter((company: any) => {
        return filter.minLatestRaise === 0 || company.latestRaise >= filter.minLatestRaise!
      })
      .filter((company: any) => {
        return filter.maxLatestRaise === 0 || company.latestRaise <= filter.maxLatestRaise!
      })
      .filter((company: any) => {
        return !filter.groupId || company.groupIds.includes(filter.groupId)
      })

    return result
  }, [companies, filter])

  // clear all filters
  function clearFilters() {
    filter.reset()
    setFund({ value: 0, label: 'All Funds' })
    setVerified({ value: '', label: 'Select an option' })
    setIndustry({ value: '', label: 'Select an option' })
    setRound({ value: '', label: 'Select an option' })
    setRangeToggle(9999)
    valuationSliderRef.noUiSlider.set([0, initVal])
    moneyRaisedSliderRef.noUiSlider.set([0, initRaise])
    minValRef.current!.value = ''
    maxValRef.current!.value = ''
    minRaiseRef.current!.value = ''
    maxRaiseRef.current!.value = ''
    filter.setMaxValuation(initVal)
    filter.setMaxLatestRaise(initRaise)
  }

  // generate table rows
  const tableData = memoizedResult.map((company: any, index: number) => (
    <tr key={index}>
      <td>
        <div className='d-flex align-items-center'>
          {company.logo && company.logo !== '' && company.logo !== 'NA' ? (
            <div className='symbol symbol-50px me-5'>
              <img src={company.logo} alt='' />
            </div>
          ) : (
            <div className='me-5 bg-secondary rounded h-50px w-50px fw-bold d-flex justify-content-center align-items-center'>
              <p
                className='mb-0'
                style={{
                  color: getCSSVariableValue('--kt-donut-' + ((company.initIndex + 9) % 10)),
                }}
              >
                {company.initials}
              </p>
            </div>
          )}
          <div className='d-flex justify-content-start flex-column'>
            {
              <Link
                to='/company/stakeholders'
                state={{ props: company }}
                className='nav-link fw-bold fs-base'
              >
                {company.name}{' '}
                {company.isVerified && (
                  <KTSVG path='/media/icons/duotune/general/gen043.svg' className=' text-primary' />
                )}
                {company.isExited && (
                  <KTSVG path='/media/icons/duotune/general/exits.svg' className=' text-black' />
                )}
              </Link>
            }
            {company.business !== 'Others' && (
              <span className='text-muted fs-8'>{company.business}</span>
            )}
          </div>
        </div>
      </td>
      <td>
        <p className='fs-base text-center'>{kFormatter(company.investedAmount)}</p>
      </td>
      <td hidden={!isInvestorNameVisible}>
        <p className='fs-base text-center'>{company.investorName}</p>
      </td>
      <td>
        <p className='fs-base text-center'>
          {company.isExited === true
            ? 'NA'
            : company.moic == undefined
            ? 'NA'
            : company.moic < 1
            ? 'NA'
            : company.moic.toFixed(2)}
        </p>
      </td>
      <td>
        <p className='fs-base text-center'>
          {company.isExited === true
            ? 'NA'
            : company.irr == undefined || company.irr < 0
            ? 'NA'
            : `${company.irr?.toFixed(2)}%`}
        </p>
      </td>
      <td>
        <p className='fs-base text-center'>
          {company.isExited === true ? 'NA' : company.noOfRounds}
        </p>
      </td>
      <td className='text-center'>
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
        <CustomRowDropDown state={company} />
      </td>
    </tr>
  ))

  function buildIndustryDropdown() {
    let dropdown: any = []
    dropdown.push({ value: '', label: 'Select an option' })
    fetchedData.forEach((company: any) => {
      if (company.industry === null) {
        if (!dropdown.find((industry: any) => industry.value === 'Others')) {
          dropdown.push({ value: 'Others', label: 'Others' })
        }
      } else if (!dropdown.find((industry: any) => industry.value === company.industry)) {
        dropdown.push({ value: company.industry, label: company.industry })
      }
    })
    return dropdown
  }

  function buildRoundDropdown() {
    let dropdown: any = []
    dropdown.push({ value: '', label: 'Select an option' })
    companies.forEach((company: any) => {
      company.investments?.forEach((investment: any) => {
        if (!dropdown.find((rounds: any) => rounds.value === investment.roundName)) {
          dropdown.push({ value: investment.roundName, label: investment.roundName })
        }
      })
    })
    return dropdown
  }

  function buildFundDropdown() {
    let dropdown: any = []
    if (ctxFilterType === FilterType.fund) {
      dropdown.push({ value: 0, label: 'All Funds' })
      data?.investors.forEach((investor: Investor) => {
        if (ctxInvestorIds?.includes(Number(investor.id)))
          dropdown.push({
            value: investor.id,
            label: investor.name,
          })
      })
      return dropdown
    }
    if (ctxFilterType === FilterType.group) {
      dropdown.push({ value: '0', label: 'All Groups' })
      getAllClassifications.data?.classifications.forEach((group: ClassificationType) => {
        if (ctxGroupIds?.includes(Number(group.id)))
          dropdown.push({ value: group.id, label: group.name })
      })

      return dropdown
    }
  }

  const allFunds: any[] = []
  const industryType: any[] = []
  const roundType: any[] = []
  const verifiedOptions: any[] = [
    { value: '', label: 'Select an option' },
    { value: 'true', label: 'Verified' },
    { value: 'false', label: 'Not verified' },
  ]

  buildFundDropdown().map((dropdown: any) => {
    allFunds.push(dropdown)
  })

  buildIndustryDropdown().map((dropdown: any) => {
    industryType.push(dropdown)
  })

  buildRoundDropdown().map((dropdown: any) => {
    roundType.push(dropdown)
  })

  const handleChange = (e: any) => {
    if (ctxFilterType === FilterType.fund) {
      filter.setInvestorId(e.value)
      setFund(e)
    }
    if (ctxFilterType === FilterType.group) {
      filter.setGroupId(Number(e.value))
      setFund(e)
    }
  }

  const handleVerifiedChange = (e: any) => {
    if (e.value === 'true') {
      filter.setIsVerified('true')
      setVerified(e)
    } else if (e.value === 'false') {
      filter.setIsVerified('false')
      setVerified(e)
    } else {
      filter.setIsVerified('')
      setVerified(e)
    }
  }

  const handleIndustryChange = (e: any) => {
    filter.setIndustry(e.value)
    setIndustry(e)
  }

  const handleRoundChange = (e: any) => {
    filter.setRoundName(e.value)
    setRound(e)
  }

  if (isLoading) return <span className='spinner-border'></span>
  return (
    <div className='row m-0'>
      {showFilters ? (
        <div className='card col-3 p-0 me-4'>
          <div className='card-header border-0 pt-5'>
            <div className='card-title align-items-start justify-content-start flex-column m-0'>
              <h1>Filter Menu</h1>
            </div>
            <div className='flex-column m-0'>
              <button className='btn btn-sm btn-primary fw-bold' onClick={clearFilters}>
                Clear
              </button>
            </div>
          </div>
          <div className='card-body'>
            <Select
              options={allFunds}
              placeholder='All Funds'
              onChange={(e) => {
                handleChange(e)
              }}
              styles={selectCustomStyles}
              value={fund}
            />
            <div className='separator my-8'></div>
            <div className=''>
              <h3 className='pb-2'>Options:</h3>
              <p className='fs-base mt-6 mb-2'>Verified</p>
              <Select
                options={verifiedOptions}
                placeholder='Select an option'
                onChange={(e) => {
                  handleVerifiedChange(e)
                }}
                styles={selectCustomStyles}
                value={verified}
              />
              <p className='fs-base mt-6 mb-2'>Industry Type</p>

              <Select
                options={industryType}
                placeholder='Select an option'
                onChange={(e) => {
                  handleIndustryChange(e)
                }}
                styles={selectCustomStyles}
                value={industry}
              />
            </div>
            <div className='separator my-8'></div>
            <p className='fs-base mb-2'>Timeline</p>
            <div className='btn-group mb-6'>
              <p
                className={`btn btn-sm fs-base text-headings-color m-0 ${
                  rangeToggle === 7
                    ? 'btn-primary btn-active-primary text-white'
                    : 'btn-gray-800 btn-bg-light text-headings-color'
                }`}
                onClick={() => rangeSelection(7)}
              >
                1 Week
              </p>
              <p
                className={`btn btn-sm fs-base text-headings-color m-0 ${
                  rangeToggle === 30
                    ? 'btn-primary btn-active-primary text-white'
                    : 'btn-gray-800 btn-bg-light text-headings-color'
                }`}
                onClick={() => rangeSelection(30)}
              >
                1 Month
              </p>
              <p
                className={`btn btn-sm fs-base text-headings-color m-0 ${
                  rangeToggle === 365
                    ? 'btn-primary btn-active-primary text-white'
                    : 'btn-gray-800 btn-bg-light text-headings-color'
                }`}
                onClick={() => rangeSelection(365)}
              >
                1 Year
              </p>
            </div>
            <div className='fs-base mb-2 text-headings-color'>Date of Investing</div>
            <input
              type='date'
              className='form-control'
              value={filter.dateOfInvestment?.toISOString().substring(0, 10)}
              onChange={(e) => {
                const date = new Date(e.target.value)
                filter.setDateOfInvestment(date)
              }}
            />
            <div className='separator my-8'></div>
            <div className='fs-base text-headings-color'>Investment Round</div>
            <div className='pt-6'>
              <Select
                options={roundType}
                placeholder='Select an option'
                onChange={(e) => {
                  handleRoundChange(e)
                }}
                menuPlacement='auto'
                menuPortalTarget={document.body}
                styles={selectCustomStyles}
                value={round}
              />
            </div>
            <div className='separator my-8'></div>
            <div className='fs-base'>
              Valuation <span className='fs-8 fw-light'>(Select a range)</span>
              <Nouislider
                instanceRef={(instance) => {
                  if (instance && !valuationSliderRef) {
                    setValuationSliderRef(instance)
                  }
                }}
                tooltips={[tooltipFns, tooltipFns]}
                start={initialValuationRange}
                range={{ min: 0, max: maxValRange }}
                connect
                onChange={(e) => {
                  filter.setMinValuation(parseFloat(e[0]))
                  filter.setMaxValuation(parseFloat(e[1]))
                }}
                className=''
              />
              <div className='row my-4 mx-0 justify-content-between'>
                <input
                  ref={minValRef}
                  title={kFormatter(filter.minValuation)}
                  onChange={(e) => {
                    const minVal = parseFloat(e.target.value?.replaceAll(',', '')) || 0
                    filter.setMinValuation(minVal)
                    valuationSliderRef.noUiSlider.set([
                      minVal.toString(),
                      valuationSliderRef.noUiSlider.get()[1],
                    ])
                  }}
                  value={Intl.NumberFormat('en-IN').format(filter.minValuation)}
                  className='form-control form-control-sm w-90px'
                  placeholder='Min value'
                  type='text'
                />
                <input
                  ref={maxValRef}
                  title={kFormatter(filter.maxValuation)}
                  onChange={(e) => {
                    const maxVal = parseFloat(e.target.value?.replaceAll(',', '')) || 0
                    filter.setMaxValuation(maxVal)
                    valuationSliderRef.noUiSlider.set([
                      valuationSliderRef.noUiSlider.get()[0],
                      maxVal,
                    ])
                  }}
                  value={Intl.NumberFormat('en-IN').format(filter.maxValuation)}
                  className='form-control form-control-sm w-90px'
                  placeholder='Max value'
                  type='text'
                  min='0'
                />
              </div>
            </div>
            <div className='separator my-8'></div>
            <div className='fs-base'>
              Money Raised <span className='fs-8 fw-light'>(Select a range)</span>
              <Nouislider
                instanceRef={(instance) => {
                  if (instance && !moneyRaisedSliderRef) {
                    setMoneyRaisedSliderRef(instance)
                  }
                }}
                tooltips={[tooltipFns, tooltipFns]}
                start={initialMoneyRaisedRange}
                range={{ min: 0, max: maxRaiseRange }}
                connect
                onChange={(e) => {
                  filter.setMinLatestRaise(parseFloat(e[0]))
                  filter.setMaxLatestRaise(parseFloat(e[1]))
                }}
                className=''
              />
              <div className='row my-4 mx-0 justify-content-between'>
                <input
                  ref={minRaiseRef}
                  title={kFormatter(filter.minLatestRaise)}
                  onChange={(e) => {
                    const minRaise = parseFloat(e.target.value.replaceAll(',', '')) || 0
                    filter.setMinLatestRaise(minRaise)
                    moneyRaisedSliderRef.noUiSlider.set([
                      minRaise,
                      moneyRaisedSliderRef.noUiSlider.get()[1],
                    ])
                  }}
                  value={Intl.NumberFormat('en-IN').format(filter.minLatestRaise)}
                  className='form-control form-control-sm w-90px'
                  type='text'
                  placeholder='Min value'
                />
                <input
                  ref={maxRaiseRef}
                  title={kFormatter(filter.maxLatestRaise)}
                  onChange={(e) => {
                    const maxRaise = parseFloat(e.target.value.replaceAll(',', '')) || 0
                    filter.setMaxLatestRaise(maxRaise)
                    moneyRaisedSliderRef.noUiSlider.set([
                      moneyRaisedSliderRef.noUiSlider.get()[0],
                      maxRaise,
                    ])
                  }}
                  value={Intl.NumberFormat('en-IN').format(filter.maxLatestRaise)}
                  className='form-control form-control-sm w-90px'
                  placeholder='Max value'
                  type='text'
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className='card col p-0'>
        <div className={`${props.className}`}>
          <div className='card-header border-0 pt-5'>
            <div className='card-title align-items-start flex-column m-0'>
              <h1>Portfolio Companies</h1>
              <span className='text-muted fw-bold mt-2 fs-base'>
                {tableData.length} Results{' '}
                {filter.industry
                  ? 'filtered by "Industry”'
                  : filter.isVerified
                  ? 'filtered by "Status"'
                  : filter.searchText
                  ? 'filtered by "Company name"'
                  : filter.investorId
                  ? 'filtered by "Investment Fund"'
                  : ''}
              </span>
            </div>
            <div className=''>
              <div className='d-flex col'>
                <button
                  className='btn btn-sm btn-icon btn-secondary me-2 w-65px'
                  onClick={handleShowFilter}
                >
                  <span className='svg-icon svg-icon-2 text-center'>
                    <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                  </span>
                </button>
                <button
                  className='btn btn-sm btn-icon btn-secondary me-2 w-65px'
                  onClick={handleExportData}
                >
                  <span className='svg-icon svg-icon-2 text-center'>
                    <KTSVG path='/media/icons/duotune/files/fil002.svg' className='svg-icon-2' />
                  </span>
                </button>
                <input
                  type='text'
                  className='form-control form-control-sm w-80 form-control-solid me-2 fs-base'
                  name='search'
                  value={filter.searchText}
                  placeholder='Search by Company Name'
                  onChange={(e) => {
                    filter.setSearchText(e.target.value)
                  }}
                />
                <button
                  className='btn btn-sm btn-primary w-125px fs-base fw-bold'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                  onClick={() => {
                    setShowModal(!showModal)
                  }}
                >
                  ADD +
                </button>
                <AddInvestmentModal
                  showModal={showModal}
                  handleClose={() => {
                    queryClient.invalidateQueries(queryKey).then(() => console.log('invalidated'))
                    setShowModal(false)
                  }}
                  investorList={investorList}
                />
              </div>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-gray-600'>
                    <th className='ps-4 fs-base min-w-225px rounded-start'>Name</th>
                    <th className='fs-base min-w-125px text-center'>
                      Investment <span style={{ fontFamily: 'arial' }}>(₹)</span>
                    </th>
                    <th className='fs-base text-center min-w-100px' hidden={!isInvestorNameVisible}>
                      Investor/Fund Name
                    </th>
                    <th className='fs-base text-center min-w-100px'>MOIC</th>
                    <th className='fs-base text-center min-w-100px'>%IRR</th>
                    <th className='fs-base text-center min-w-50px text-end'>Rounds</th>
                    <th className='fs-base text-center min-w-50px text-end'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary mt-8'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen053.svg'
                          className='svg-icon-muted svg-icon-2x'
                        />
                      </button>
                      <TableHeaderDropdown
                        setVisible={() => setIsInvestorNameVisible(!isInvestorNameVisible)}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>{tableData}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CompanyDetailsTable }
