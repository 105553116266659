import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  GetTasksOverviewQuery,
  useGetTasksOverviewQuery,
  GetTermsheetsQuery,
  useGetTermsheetsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { isoToDate } from '../../../../utils/FormatDate'
import { hexToRGB } from '../../../../utils/HexToRgba'
import { getInitials } from '../../../../utils/Utils'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { GlobalContext } from '../../store/context/globalContext'

type Props = {
  exitData: any
  companies?: string[]
}

function TaskOverview(props: Props) {
  const navigate = useNavigate()
  const { state } = useContext(GlobalContext)
  const { ctxCompanies: companies } = state

  const { data } = useGetTasksOverviewQuery<GetTasksOverviewQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const { data: dealsData } = useGetTermsheetsQuery<GetTermsheetsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const [termsheetOverviewData, setTermsheetOverviewData] = useState<any[]>([])
  const [taskOverviewData, setTaskOverviewData] = useState<any[]>([])
  const [exitOverviewData, setExitOverviewData] = useState<any[]>([])

  useEffect(() => {
    if (dealsData && dealsData.termsheets) {
      const tsData = dealsData.termsheets.map((d, i) => {
        const ccComp = companies?.filter((c) => c.name === d.companyName)
        d.logo = ccComp![0] && ccComp![0].logo!
        if (d.status !== 'Draft') {
          if (props.companies !== undefined && props.companies.length > 0) {
            if (props.companies?.includes(d.companyName))
              return <DealsOverviewRow deals={d} key={i} />
          } else {
            return <DealsOverviewRow deals={d} key={i} />
          }
        }
      })
      setTermsheetOverviewData(tsData)
    }
  }, [dealsData, props.companies])

  useEffect(() => {
    if (data && data.tasksOverview) {
      const taskData = data.tasksOverview.map((t, i) => {
        const ccComp = companies?.filter((c) => c.name === t?.companyName)
        const taskOV: any = { ...t, logo: ccComp![0] && ccComp![0].logo! }
        if (props.companies !== undefined && props.companies.length > 0) {
          if (props.companies!.includes(t?.companyName!))
            return <TaskOverviewRow key={i} taskOverview={taskOV} />
        } else {
          return <TaskOverviewRow key={i} taskOverview={taskOV} />
        }
      })
      setTaskOverviewData(taskData)
    }
  }, [data, props.companies])

  useEffect(() => {
    const exitDataInvestorList: any[] = []
    const exitData = props.exitData?.filter((item: any) => item.transactionType === 'SELL')
    const grouped = _.groupBy(exitData, (data) => [data.investorName])
    const keys = Object.keys(grouped)

    keys.forEach((key) => {
      let d: _.Dictionary<any[]>

      if (props.companies !== undefined && props.companies.length > 0) {
        d = _.groupBy(
          grouped[key],
          (data) =>
            data.companyName &&
            props.companies !== undefined &&
            props.companies.includes(data.companyName)
        )
      } else {
        d = _.groupBy(grouped[key], (data) => data.companyName)
      }

      const keys = Object.keys(d)
      keys.forEach((key) => {
        d[key].sort((date1, date2) => date1.transactionDate - date2.transactionDate)
        d[key].reverse()
        exitDataInvestorList.push(d[key][0])
      })
    })

    const eoData = exitDataInvestorList.map((e, index) => {
      const ccComp = companies?.filter((c) => c.name === e.companyName)
      e.logo = ccComp![0] && ccComp![0].logo!
      if (props.companies !== undefined && props.companies.length > 0) {
        if (props.companies!.includes(e.companyName))
          return <ExitOverviewRow exitData={e} key={index} />
      } else {
        return <ExitOverviewRow exitData={e} key={index} />
      }
    })
    setExitOverviewData(eoData)
  }, [props.exitData, props.companies])

  return (
    <div className={'card mh-350px'}>
      <div className='card-header border-0'>
        <h1 className='mt-5'>Tasks Overview</h1>
      </div>
      <div className='card-body card-scroll p-3 mb-5'>
        {taskOverviewData.length === 0 &&
        termsheetOverviewData.length === 0 &&
        exitOverviewData.length === 0 ? (
          <div className='text-center'>
            <img
              src={toAbsoluteUrl('/media/icons/duotune/general/no-starCompany.svg')}
              className='h-100px my-20'
              alt=''
            />
            <p className='mb-0'>There are no tasks</p>
          </div>
        ) : (
          <>
            {taskOverviewData}
            {termsheetOverviewData}
            {exitOverviewData}
          </>
        )}
      </div>
    </div>
  )

  function getStateColor(state: string) {
    if (state === 'COMPLETED')
      return {
        backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.1),
        color: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.8),
      }

    if (state === 'INPROGRESS')
      return {
        backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 0.1),
        color: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 0.8),
      }

    if (state === 'PENDING')
      return {
        backgroundColor: hexToRGB(getCSSVariableValue('--kt-gray-600'), 0.1),
        color: hexToRGB(getCSSVariableValue('--kt-gray-600'), 0.8),
      }
    if (state === 'EXITED')
      return {
        backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.1),
        color: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.8),
      }
  }

  function getTermsheetEventStatus(date: string) {
    let todays_date = new Date()
    todays_date.setHours(0, 0, 0, 0)
    let exp_date = new Date(date)

    if (todays_date <= exp_date) {
      return 'ACTIVE'
    } else {
      return 'LAPSED'
    }
  }

  function getTermsheetStateColor(date: string) {
    let todays_date = new Date()
    todays_date.setHours(0, 0, 0, 0)
    let exp_date = new Date(date)

    if (todays_date <= exp_date) {
      return {
        backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.1),
        color: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.8),
      }
    } else {
      return {
        backgroundColor: hexToRGB(getCSSVariableValue('--kt-gray-600'), 0.1),
        color: hexToRGB(getCSSVariableValue('--kt-gray-600'), 0.8),
      }
    }
  }

  function getEventText(
    eventType: string,
    date: string,
    proposedDate: string,
    investorName: string
  ) {
    switch (eventType) {
      case 'INVESTMENT_MADE':
        return `Investment made on ${isoToDate(date)}`

      case 'DEALS':
        let todays_date = new Date()
        todays_date.setHours(0, 0, 0, 0)
        let exp_date = new Date(date)
        let proposed_date = new Date(proposedDate)
        if (todays_date <= exp_date) {
          return `Termsheet issued on ${isoToDate(proposed_date.toISOString() || '')}`
        } else {
          return `Termsheet lapsed on ${isoToDate(exp_date.toISOString() || '')}`
        }
      case 'EXITS':
        return `${investorName} exited this company on ${isoToDate(date)}`
    }
  }

  function TaskOverviewRow({ taskOverview }: { taskOverview: any }) {
    return (
      <div
        className='d-flex align-items-center mb-3 row-hover p-2'
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          navigate('/company/stakeholders', {
            state: {
              props: {
                name: taskOverview?.companyName,
                companyId: '',
                hissaVerified: true,
                initials: getInitials(taskOverview?.companyName!),
              },
            },
          })
        }}
      >
        {taskOverview?.logo && taskOverview.logo !== '' && taskOverview.logo !== 'NA' ? (
          <div className='symbol symbol-50px'>
            <img src={taskOverview?.logo} alt='' className='symbol-label' />
          </div>
        ) : (
          <div className='bg-secondary rounded min-h-50px min-w-50px fw-bold d-flex justify-content-center align-items-center'>
            <p
              className='mb-0 fs-2'
              style={{
                color: getCSSVariableValue('--kt-donut-' + (9 % 10)),
              }}
            >
              {getInitials(taskOverview?.companyName!)}
            </p>
          </div>
        )}

        <div className='flex-grow-1 ms-4'>
          <h3 className='fw-bold fs-7 cursor-pointer text-hover-primary'>
            {taskOverview?.companyName}
          </h3>
          <span className='text-muted d-block fw-light fs-7'>
            {getEventText(
              taskOverview?.eventType || '',
              taskOverview?.date?.toString() || '',
              '',
              ''
            )}
          </span>
        </div>
        <div>
          <span className='fs-9 badge' style={getStateColor(taskOverview?.eventStatus || '')}>
            {taskOverview?.eventStatus?.toString() || ''}
          </span>
        </div>
      </div>
    )
  }

  function DealsOverviewRow({ deals }: { deals: any }) {
    const dealStatus = getTermsheetEventStatus(deals?.expiryDate?.toString() || '')
    return (
      <div
        className='d-flex align-items-center mb-3 row-hover p-2'
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          navigate('/deals', { state: { props: { tab: dealStatus } } })
        }}
      >
        {deals?.logo && deals?.logo !== '' && deals?.logo !== 'NA' ? (
          <div className='symbol symbol-50px'>
            <img src={deals?.logo} alt='' className='symbol-label' />
          </div>
        ) : (
          <div className='bg-secondary rounded min-h-50px min-w-50px fw-bold d-flex justify-content-center align-items-center'>
            <p
              className='mb-0 fs-2'
              style={{
                color: getCSSVariableValue('--kt-donut-' + (9 % 10)),
              }}
            >
              {getInitials(deals?.companyName!)}
            </p>
          </div>
        )}

        <div className='flex-grow-1 ms-4'>
          <h3 className='fw-bold fs-7 cursor-pointer text-hover-primary'>{deals?.companyName}</h3>
          <span className='text-muted d-block fw-light fs-7'>
            {getEventText(
              'DEALS' || '',
              deals?.expiryDate?.toString() || '',
              deals?.proposedDate?.toString() || '',
              ''
            )}
          </span>
        </div>
        <div>
          <span
            className='fs-9 badge'
            style={getTermsheetStateColor(deals?.expiryDate?.toString() || '')}
          >
            {dealStatus}
          </span>
        </div>
      </div>
    )
  }

  function ExitOverviewRow({ exitData }: { exitData: any }) {
    return (
      <div
        className='d-flex align-items-center mb-3 row-hover p-2'
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          navigate('/company/stakeholders', {
            state: {
              props: {
                name: exitData?.companyName,
                companyId: '',
                hissaVerified: true,
                initials: getInitials(exitData?.companyName!),
              },
            },
          })
        }}
      >
        {exitData?.logo && exitData.logo !== '' && exitData.logo !== 'NA' ? (
          <div className='symbol symbol-50px'>
            <img src={exitData?.logo!} alt='' className='symbol-label' />
          </div>
        ) : (
          <div className='bg-secondary rounded min-h-50px min-w-50px fw-bold d-flex justify-content-center align-items-center'>
            <p
              className='mb-0 fs-2'
              style={{
                color: getCSSVariableValue('--kt-donut-' + (9 % 10)),
              }}
            >
              {getInitials(exitData?.companyName!)}
            </p>
          </div>
        )}

        <div className='flex-grow-1 ms-4'>
          <h3 className='fw-bold fs-7 cursor-pointer text-hover-primary'>
            {exitData?.companyName}
          </h3>
          <span className='text-muted d-block fw-light fs-7'>
            {getEventText(
              'EXITS' || '',
              exitData?.transactionDate?.toString() || '',
              '',
              exitData?.investorName?.toString() || ''
            )}
          </span>
        </div>
        <div>
          <span className='fs-9 badge' style={getStateColor('EXITED')}>
            {'EXITED'}
          </span>
        </div>
      </div>
    )
  }
}
export default TaskOverview
