import { StylesConfig } from 'react-select'
import { FilterType } from '../app/pages/store/context/reducer'
import { getCSSVariableValue } from '../_metronic/assets/ts/_utils'
import { hexToRGB } from './HexToRgba'

export type SelectOptionType = { label: string; value: number; type?: FilterType }

export const multiSelectCustomStyles: StylesConfig<SelectOptionType, true> = {
  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: getCSSVariableValue('--kt-table-bg'),
      borderColor: getCSSVariableValue('--kt-primary'),
      border: state.isFocused
        ? '1px solid ' + getCSSVariableValue('--kt-primary')
        : '1px solid #cccccc',
      boxShadow: state.isFocused ? '0px 0px 1px ' + getCSSVariableValue('--kt-primary') : 'none',
      '&:hover': {
        color: getCSSVariableValue('--kt-primary'),
        border: '1px solid ' + getCSSVariableValue('--kt-primary'),
        boxShadow: '0px 0px 1px ' + getCSSVariableValue('--kt-primary'),
      },
    }
  },
  menu: (provided, state) => {
    return {
      ...provided,
      backgroundColor: getCSSVariableValue('--kt-table-active-bg'),
    }
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? getCSSVariableValue('--kt-primary')
        : isFocused
        ? getCSSVariableValue('--kt-primary')
        : undefined,
      color: isDisabled ? '#ccc' : isFocused ? 'white' : getCSSVariableValue('--kt-gray-heading'),

      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? getCSSVariableValue('--kt-primary')
            : getCSSVariableValue('--kt-primary-light')
          : undefined,
      },
    }
  },
  multiValue: (styles, { data }) => {
    const color = getCSSVariableValue('--kt-primary')
    return {
      ...styles,
      backgroundColor: hexToRGB(color, 0.1),
    }
  },
  input: (styles) => {
    return {
      ...styles,
      color: getCSSVariableValue('--kt-body-color'),
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: getCSSVariableValue('--kt-primary'),
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: getCSSVariableValue('--kt-primary'),
    ':hover': {
      backgroundColor: getCSSVariableValue('--kt-primary'),
      color: 'white',
    },
  }),
}
