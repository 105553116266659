import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type props = {
  investorNames: string[]
  allInvestments: any[]
  companies?: string[]
}

type InvestmentRecord = {
  name: string
  roundName: string
  noOfShares: number
  investedAmount: number
  dateInvested: string
  round: string
}

type CompanyWiseData = {
  name: string
  investmentData: [InvestmentRecord]
}

type ChartData = {
  label: string
  data: any[]
  backgroundColor: string
}

function StackedBarChart(props: props) {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Total Invested over companies',
      },
    },
    barThickness: 15,
    borderRadius: 8,
    responsive: true,
    borderSkipped: 'bottom',
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
      },
    },
  }

  const optionsForManyCompanies = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Total Invested over companies',
      },
    },
    barThickness: 15,
    borderRadius: 8,
    responsive: false,
    borderSkipped: 'bottom',
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
      },
    },
  }

  const companyWiseRecords: CompanyWiseData[] = []
  props.allInvestments.forEach((companyData) => {
    const comp: CompanyWiseData = {
      name: companyData['companyData']['name'],
      investmentData: companyData['investmentData'],
    }
    if (props.companies !== undefined && props.companies.length > 0) {
      if (props.companies.includes(companyData['companyData']['name']))
        companyWiseRecords.push(comp)
    } else {
      companyWiseRecords.push(comp)
    }
  })

  companyWiseRecords.forEach((e) => {
    e.investmentData.sort((a, b) => a.dateInvested.localeCompare(b.dateInvested))
  })

  const bgColors: string[] = []
  for (let i = 0; i < 40; i++) {
    const color = getCSSVariableValue('--kt-donut-' + (i % 10))
    bgColors.push(color)
  }

  const chartData: ChartData[] = []
  let distinctRounds: string[] = []
  const companies: string[] = []
  const invs = companyWiseRecords.map((companyData) => companyData.investmentData)

  companyWiseRecords.forEach((e) => {
    const recs = e.investmentData.filter((o) => props.investorNames.includes(o.name))
    if (recs.length > 0) {
      companies.push(e.name.toUpperCase())
    }
  })

  invs.forEach((investments) => {
    investments.forEach((investment) => {
      if (props.investorNames.includes(investment.name)) {
        distinctRounds.push(investment.round)
      }
    })
  })
  companyWiseRecords.sort((a, b) => a.name.localeCompare(b.name))
  distinctRounds = Array.from(new Set(distinctRounds))
  for (let i = 0; i < distinctRounds.length; i++) {
    let data: any = []
    companyWiseRecords.forEach((e) => {
      if (companies.includes(e.name.toUpperCase())) {
        const recs = e.investmentData.filter((o) => o.round == distinctRounds[i])
        const sum = recs.reduce((acc, rec) => acc + rec.investedAmount, 0)
        if (sum > 0) {
          data.push(sum)
        } else {
          data.push(undefined)
        }
      }
    })
    const chartDataRecord: ChartData = {
      label: distinctRounds[i],
      data: data,
      backgroundColor: i < 40 ? bgColors[i] : bgColors[Number(Math.floor(Math.random() * 40))],
    }
    chartData.push(chartDataRecord)
  }

  const dataForChart = {
    labels: companies.map((company) => company.split(' ')[0]).sort(),
    datasets: chartData,
  }

  return (
    <div>
      {companies.length > 40 ? (
        <Bar
          options={optionsForManyCompanies}
          data={dataForChart}
          height={400}
          width={companies.length > 40 ? 1200 + (companies.length - 40) * 30 : 800}
        />
      ) : (
        <Bar options={options} data={dataForChart} />
      )}
    </div>
  )
}

export default StackedBarChart
