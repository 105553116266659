import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'

export default function LoginPageContent() {
  const headerObj = [
    {
      header: 'Organize your Investments',
      content:
        'Curate all your investments on a single platform. Gain insights on how your portfolio is performing and streamline all your investment data.',
    },
    {
      header: 'Monitor key performance indicators',
      content:
        'Monitor the key performance parameters of your portfolio companies and optimise your investment strategy with ease.',
    },
    {
      header: 'Make new investments',
      content:
        'Get rid of FOMO on investment opportunities. Add interested companies in your watchlist and get a jumpstart to investing by being the first to send term sheets.',
    },
    {
      header: 'Directly engage with founders',
      content:
        'Get connected to founders directly on the platform to analyse the investment opportunity on hand.',
    },
  ]
  const listObj = [
    {
      image: '/media/svg/login-page/Component 1.svg',
      title: 'Organize your Investments',
    },
    {
      image: '/media/svg/login-page/Component 2.svg',
      title: 'Monitor key performance indicators',
    },
    {
      image: '/media/svg/login-page/Component 3.svg',
      title: 'Make new investments',
    },
    {
      image: '/media/svg/login-page/Component 4.svg',
      title: 'Directly engage with founders',
    },
  ]
  const [headerContent, setHeaderContent] = useState(headerObj[0].header)
  const [content, setContent] = useState(headerObj[0].content)
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => {
      const random = Math.floor(Math.random() * headerObj.length)
      setActiveIndex(random)
      const selectedHeader = headerObj[random]
      setHeaderContent(selectedHeader.header)
      setContent(selectedHeader.content)
    }, 10000)
    return () => clearInterval(intervalId)
  }, [])
  return (
    <div className='d-flex p-5 h-100'>
      <div className='d-flex h-100 justify-content-around row '>
        <div className=''>
          <h1 className='text-center fw-bold display-7 mt-20 mb-10'>{headerContent}</h1>
          <h3 className='text-center fw-bold mb-10 mx-15 '>{content}</h3>
        </div>
        <div className='d-flex fw-bold'>
          <div className='col-xl-3 col-lg-2 col-md-3 '></div>
          <div className='col '>
            {listObj.map((obj, index) => {
              if (index !== activeIndex) {
                return (
                  <div key={index} className='d-flex align-items-center mb-10'>
                    <div className='col-2 '>
                      <img alt='logo' src={toAbsoluteUrl(obj.image)} className='me-10 w-80px' />
                    </div>
                    <h3 className=' col-10 ps-14'>{obj.title}</h3>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
