import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import WatchlistWrapper from '../pages/watchlist/WatchlistWrapper'
import PortfolioWrapper from '../pages/portfolio/PortfolioWrapper'
import AuthVerify from '../modules/auth/core/AuthVerify'
import { useAuth } from '../modules/auth'
import { OpenDealsWrapper } from '../pages/open-deals/OpenDealsWrapper'
import { SendTermsheetWizard } from '../pages/open-deals/components/termsheet-issuer/SendTermsheetWizard'
import TermsheetView from '../pages/open-deals/components/TermsheetView'
import TermsheetWrapper from '../pages/open-deals/components/TermsheetWrapper'
import DraftTermsheetView from '../pages/open-deals/components/termsheet-issuer/DraftTermsheetView'
import IssueTermSheetWrapper from '../pages/open-deals/components/termsheet-issuer/IssueTermsheetWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CompanyPage = lazy(() => import('../pages/company/CompanyPage'))
  const { logout } = useAuth()

  return (
    <div className='h-100'>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='portfolio' element={<PortfolioWrapper />} />
          <Route path='watchlist' element={<WatchlistWrapper />} />
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='deals' element={<OpenDealsWrapper />} />
          <Route path='deals/sendtermsheet' element={<SendTermsheetWizard />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          <Route path='deals/termsheetview' element={<TermsheetView />} />
          <Route path='deals/draftTermsheet' element={<DraftTermsheetView />} />
          <Route path='deals/buildTermsheet' element={<TermsheetWrapper />} />
          <Route path='deals/issueTermsheet' element={<IssueTermSheetWrapper />} />
          {/* Lazy Modules */}
          <Route
            path='company/*'
            element={
              <SuspensedView>
                <CompanyPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
      <AuthVerify logout={logout} />
    </div>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
