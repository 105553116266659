import { useContext, useState } from 'react'
import { Investor } from '../../../../generated/graphql'
import CustomCard from './CustomCard'
import { convertRate, RateInterval, xirr } from 'node-irr'
import AddClassificationModal from '../portfolio grouping/AddClassificationModal'
import { ClassificationType } from '../../../../types/Classification'
import { ClassificationContext } from '../../store/context/classificationContextProvider'
import { GlobalContext } from '../../store/context/globalContext'
import { FilterType } from '../../store/context/reducer'
import { calculateXirr } from '../../../../utils/Utils'

type Props = {
  investorIds: number[]
  type: FilterType
  allInvestments: any
  exitData: any
}

type Portfolio = {
  investorId: number
  id: number
  investorName: string
  totalInvested: number
  gain: number
  moic: number
  currentValue: number
  irr: number | null
  isGroup: boolean
}

const PortfolioCardsOverview = (props: Props) => {
  const [showModal, setShowModal] = useState(false)
  const { allInvestments, investors, groups } = useContext(ClassificationContext)
  const { state } = useContext(GlobalContext)
  const { ctxGroupIds } = state
  let portfolios: any = []

  if (props.type === FilterType.fund) {
    investors?.investors.forEach((investor: Investor, index: number) => {
      if (
        props.investorIds.includes(Number(investor.id)) ||
        props.investorIds.includes(investor.id)
      ) {
        let totalInvested = 0
        let currentValue = 0
        let irrData: any = []
        let irr: number | null = 0
        investor.holdings?.forEach((holding) => {
          if (!holding?.isExited) {
            totalInvested += holding?.investedAmount!
            currentValue += holding?.currentValue!
          }
        })
        allInvestments?.allInvestments?.forEach((investments: any) => {
          investments?.investmentData.forEach((inv: any) => {
            if (investor.name === inv.name) {
              if (inv.dateInvested && inv.dateInvested !== '')
                irrData.push({ date: new Date(inv.dateInvested), amount: inv.investedAmount * -1 })
            }
          })
        })
        props.exitData?.forEach((t: any) => {
          if (investor.name === t.investorName) {
            let amount = Number(t.pricePerShare) * Number(t.noOfShares)
            if (t.transactionType === 'SELL') {
              amount = -1 * amount
            }
            irrData.push({ amount: amount * -1, date: new Date(t.transactionDate!) })
          }
        })
        irrData.push({ date: new Date(), amount: currentValue })
        if (irrData.length > 1) {
          if (isNaN(convertRate(xirr(irrData).rate, RateInterval.Year))) {
            const values = irrData.map((value: any) => value.amount)
            const dates = irrData.map((date: any) => new Date(date.date))
            const irrValue = calculateXirr(values, dates)
            irr = irrValue !== 'NA' ? irrValue * 100 : null
          } else {
            irr = convertRate(xirr(irrData).rate, RateInterval.Year) * 100
          }
        }
        portfolios.push({
          investorId: investor.id,
          investorName: investor.name,
          totalInvested: totalInvested,
          gain: currentValue - totalInvested,
          moic: currentValue === 0 ? 0 : (currentValue / totalInvested).toFixed(2),
          currentValue: currentValue,
          irr: irr!,
          isGroup: false,
          id: investor.id,
        })
      }
    })
  } else {
    groups?.classifications.forEach((group: ClassificationType) => {
      if (ctxGroupIds?.includes(Number(group.id))) {
        if (group.companies.length === 0) {
          portfolios.push({
            investorName: group.name,
            totalInvested: 0,
            gain: 0,
            moic: 0,
            currentValue: 0,
            irr: 0,
            isGroup: true,
            id: group.id,
          })
        } else {
          let totalInvested = 0
          let currentValue = 0
          let irrData: any = []
          let irr: number | null = 0

          allInvestments?.allInvestments?.forEach((investment: any) => {
            let index = group.companies?.findIndex(
              (company) => company.companyName === investment.companyData.name
            )
            if (index > -1) {
              let totalShares = 0
              investment.investmentData.forEach((holding: any) => {
                if (holding.isMine === true) {
                  irrData.push({
                    date: new Date(holding.dateInvested),
                    amount: holding.investedAmount * -1,
                  })

                  totalInvested += Number(holding.investedAmount)
                  totalShares += Number(holding.noOfShares)
                }
              })
              currentValue +=
                investment.companyData.sharePrice !== undefined
                  ? Number(totalShares || 0) * Number(investment.companyData.sharePrice || 0)
                  : investment.investmentData[investment.investmentData.length - 1]
                      .currentSharePrice * totalShares
            }
          })
          props.exitData?.forEach((t: any) => {
            if (true) {
              let amount = Number(t.pricePerShare) * Number(t.noOfShares)
              if (t.transactionType === 'SELL') {
                amount = -1 * amount
              }
              irrData.push({ amount: amount * -1, date: new Date(t.transactionDate!) })
            }
          })
          irrData.push({ date: new Date(), amount: currentValue })
          if (irrData.length > 1) {
            if (isNaN(convertRate(xirr(irrData).rate, RateInterval.Year))) {
              const values = irrData.map((value: any) => value.amount)
              const dates = irrData.map((date: any) => new Date(date.date))
              const irrValue = calculateXirr(values, dates)
              irr = irrValue !== 'NA' ? irrValue * 100 : null
            } else {
              irr = convertRate(xirr(irrData).rate, RateInterval.Year) * 100
            }
          }
          portfolios.push({
            investorName: group.name,
            totalInvested: totalInvested,
            gain: currentValue - totalInvested,
            moic: currentValue === 0 ? 0 : (currentValue / totalInvested).toFixed(2),
            currentValue: currentValue,
            irr: irr!,
            isGroup: true,
            id: group.id,
          })
        }
      }
    })
  }

  return (
    <div>
      {portfolios.map((portfolio: Portfolio) => {
        if (portfolio.isGroup) {
          return (
            <CustomCard
              key={portfolio.investorName}
              cardTitle={portfolio.investorName}
              currentValue={portfolio.currentValue}
              gain={portfolio.gain}
              totalInvested={portfolio.totalInvested}
              cardValueText='Current value'
              irr={portfolio.irr}
              moic={portfolio.moic}
              isGroup={portfolio.isGroup}
              id={portfolio.id}
              investorIds={investors?.investors.map((investor: Investor) => Number(investor.id))}
            />
          )
        } else {
          return (
            <CustomCard
              key={portfolio.investorName}
              cardTitle={portfolio.investorName}
              currentValue={portfolio.currentValue}
              gain={portfolio.gain}
              totalInvested={portfolio.totalInvested}
              cardValueText='Current value'
              irr={portfolio.irr}
              moic={portfolio.moic}
              isGroup={portfolio.isGroup}
              id={portfolio.id}
              investorIds={investors?.investors.map((investor: Investor) => Number(investor.id))}
            />
          )
        }
      })}

      <div
        className='card btn btn-active-color-primary btn-outline btn-outline-dashed btn-outline-primary min-h-100px'
        onClick={() => setShowModal(true)}
      >
        <div className='col d-flex justify-content-center align-items-center'>
          <h1 className='mb-0'>+ Add Classification</h1>
        </div>
      </div>

      <AddClassificationModal handleClose={() => setShowModal(false)} showModal={showModal} />
    </div>
  )
}

export default PortfolioCardsOverview
