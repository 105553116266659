import { ErrorMessage, Field, useFormikContext } from 'formik'
import Nouislider from 'nouislider-react'
import React, { useState } from 'react'
import { kFormatter, kUnformatterNumber } from '../../../../../../../utils/CurrencyFormatter'
import numToWords from '../../../../../../../utils/NumToWords'
import { ISendTermsheet } from '../../SendTermsheetHelper'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../../../utils/Select'

function LiquidationPreference(props: any) {
  const formik = useFormikContext<ISendTermsheet>()
  const [value, setValue] = useState(formik.values.investmentRank)
  const [investmentSliderRef, setInvestmentSliderRef] = useState<any>(null)
  const [participationSliderRef, setParticipationSliderRef] = useState<any>(null)
  const [capInWords, setCapInWords] = useState<String>()
  const [investmentRank, setInvestmentRank] = useState<any>({
    value: props.values.investmentRank,
    label: props.values.investmentRank,
  })

  const tooltipFns = {
    to(value: number) {
      return kFormatter(value)
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }

  const rank = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const ranks: any[] = []
  rank?.map((number: number) =>
    !ranks.find((num: number) => num === number)
      ? ranks.push({
          value: number,
          label: number,
        })
      : {}
  )

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Liquidation Preference</h2>

        <h4 className='fw-bolder text-dark pt-12'>Investment Rank</h4>
      </div>

      <div className='col fv-md-row d-md-flex align-items-baseline'>
        <div className='col-6 col-md-4'>
          <label className='form-label required '>Select Rank of Investment</label>
        </div>
        <div className='col-6 col-md-4'>
          {props.values.investmentRank ? (
            <Select
              className='form-select-solid'
              options={ranks}
              name='investmentRank'
              onChange={(e: any) => (
                setInvestmentRank(e), formik.setFieldValue('investmentRank', Number(e.value))
              )}
              styles={selectCustomStyles}
              value={investmentRank}
              menuPortalTarget={document.body}
            />
          ) : (
            <>
              <Select
                className='form-select-solid'
                options={ranks}
                name='investmentRank'
                onChange={(e: any) => formik.setFieldValue('investmentRank', Number(e.value))}
                styles={selectCustomStyles}
                menuPortalTarget={document.body}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='investmentRank' />
              </div>
            </>
          )}
        </div>

        <div className='col-6 col-md-4 ps-md-10'>
          <span className='d-flex'>
            <label className='form-label required '>Pari Passu</label>
            <div className='form-check form-switch form-check-custom form-check-solid ps-2'>
              <Field
                className='form-check-input h-20px w-40px'
                type='checkbox'
                name='isPariPassu'
                defaultValue={formik.values.isPariPassu}
              />
            </div>
          </span>
        </div>
      </div>

      <div className='col fv-md-row'>
        <h4 className='fw-bolder text-dark pt-8'>Investment Multiple</h4>
        <div className='col md-row d-md-flex align-items-baseline'>
          <div className='col-12 col-md-4'>
            <label className='form-label required'>Select Investment Multiple</label>
          </div>
          <div className='col-12 col-md-4'>
            <Nouislider
              instanceRef={(instance) => {
                if (instance && !investmentSliderRef) {
                  setInvestmentSliderRef(instance)
                }
              }}
              tooltips={[tooltipFns]}
              start={formik.values.investmentMultiple}
              range={{
                min: 0,
                max: 3,
              }}
              connect
              onChange={(e: any) => {
                formik.setFieldValue('investmentMultiple', e.at(0))
              }}
              className=''
            />
          </div>
          <div className='col-12 col-md-4 pt-6 pt-md-0 ps-md-6'>
            <Field
              type='number'
              step='any'
              className='form-control form-control-lg form-control-solid'
              name='investmentMultiple'
              onChange={(e: any) => {
                formik.handleChange(e)
                investmentSliderRef?.noUiSlider.set(e.target.value)
              }}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='investmentMultiple' />
            </div>
          </div>
        </div>
      </div>

      <div className='col align-md-items-baseline'>
        <h4 className='fw-bolder text-dark pt-8'>Participation</h4>
        <div className='row align-md-items-baseline pb-4 pt-6'>
          <div className='col-6 col-md-4'>
            <label className='form-label required '>Enable Participation</label>
          </div>
          <div className='col-6 col-md-5 '>
            <div className='form-check form-switch form-check-custom form-check-solid me-10'>
              <Field
                className='form-check-input h-20px w-40px'
                type='checkbox'
                name='isParticipation'
              />
            </div>
          </div>
        </div>
      </div>

      {formik.values.isParticipation === true && (
        <div className='col fv-md-row'>
          <div className='col md-row d-md-flex align-items-baseline'>
            <div className='col-12 col-md-4'>
              <label className='form-label required'>Participation Cap</label>
            </div>
            <div className='col-12 col-md-4'>
              <Nouislider
                instanceRef={(instance) => {
                  if (instance && !participationSliderRef) {
                    setParticipationSliderRef(instance)
                  }
                }}
                tooltips={[tooltipFns]}
                start={formik.values.participationCap}
                range={{
                  min: 0,
                  max: 250000000,
                }}
                connect
                onChange={(e) => {
                  formik.setFieldValue('participationCap', e.at(0))
                  setCapInWords(numToWords(Math.round(e.at(0))))
                }}
                className=''
              />
            </div>
            <div className='col-12 col-md-4 pt-6 pt-md-0 ps-md-6'>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='participationCap'
                onChange={(e: any) => {
                  formik.handleChange(e)
                  const participationCap = parseInt(e.target.value?.replaceAll(',', '') || 0, 10)
                  formik.setFieldValue('participationCap', participationCap)
                  participationSliderRef?.noUiSlider.set(participationCap)
                  setCapInWords(numToWords(Math.round(participationCap)))
                }}
                value={Intl.NumberFormat('en-IN').format(formik.values.participationCap)}
              />
              <div className='form-text'>{capInWords}</div>
              <div className='text-danger mt-2'>
                <ErrorMessage name='participationCap' />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LiquidationPreference
