import { useEffect, useState } from 'react'
import Select from 'react-select'
import {
  Document_Vault,
  GetCompanyDataQuery,
  RemoveObjectMutation,
  RemoveObjectMutationVariables,
  UploadFileToCompanyMutation,
  UploadFileToCompanyMutationVariables,
  useGetCompanyDataQuery,
  useRemoveObjectMutation,
  useUploadFileToCompanyMutation,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import FileUpload from './FileUpload'
import { FilesTable } from './TabViews/FilesTable'
import { useParams } from 'react-router-dom'

function DocumentViewerWrapper() {
  const { id, vaultType, companyName } = useParams()
  const { data, isFetched } = useGetCompanyDataQuery<GetCompanyDataQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        id: id,
        companyName: companyName,
        vaultType: vaultType as Document_Vault,
      },
    }
  )
  const getcompanyDataQuery = useGetCompanyDataQuery.getKey()
  const mutateUploadFile = useUploadFileToCompanyMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: UploadFileToCompanyMutation,
        _variables: UploadFileToCompanyMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(getcompanyDataQuery)
        handleBreadcrumb('Home', 0)
      },
    },
    {}
  )
  const deleteObject = useRemoveObjectMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: RemoveObjectMutation,
        _variables: RemoveObjectMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(getcompanyDataQuery)
        handleBreadcrumb('Home', folder[folder.length - 1])
      },
    },
    {}
  )
  const [folder, setFolder] = useState<any[]>(['Home'])
  let directoryData: any = []
  if (data?.data.length > 0) {
    directoryData.push(...data?.data)
  }
  const [dirData, setDirData] = useState<any>()
  const [showAddFolder, setShowAddFolder] = useState<boolean>(false)

  const [showModal, setShowModal] = useState(false)
  const [key, setkey] = useState<String>('')
  useEffect(() => {
    setDirData(directoryData)
  }, [data])

  if (!isFetched) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  function handleDirectoryClick(key: string) {
    const data = dirData.filter((item: { name: string }) => item.name === key)[0].items
    setDirData(data)
    setFolder([...folder, key])
  }

  function handleAddFolderClose() {
    setShowAddFolder(false)
  }

  function handleCreateFolder(name: string) {
    setShowAddFolder(false)
    let key: string = ''
    folder.forEach((e) => {
      if (e != 'Home') {
        key += e + '/'
      }
    })
    key += name
    mutateUploadFile.mutate({
      input: {
        name: '',
        companyName: companyName,
        file: null,
        folder: key,
        id: id,
      },
    })
  }

  function handleBreadcrumb(item: string, endIndex: number) {
    if (item === 'Home') {
      setDirData(directoryData)
      setFolder(['Home'])
    } else {
      let data = directoryData
      for (let i = 1; i < endIndex + 1; i++) {
        const index = data.findIndex((item: any) => {
          return item.key === folder[i]
        })
        if (index !== -1) {
          data = data[index].items
        }
      }
      setDirData(data)
      setFolder(folder.slice(0, endIndex + 1))
    }
  }

  function handleFileUpload() {
    let name: string = ''
    folder.forEach((e) => {
      if (e != 'Home') {
        name += e + '/'
      }
    })
    setShowModal(true)
    setkey(name)
  }

  function handleFileUploadClose() {
    setShowModal(false)
    queryClient.invalidateQueries(getcompanyDataQuery)
    handleBreadcrumb('Home', 0)
  }

  function handleDeleteItem(item: any) {
    let directory: string = ''
    folder.forEach((e) => {
      if (e != 'Home') {
        directory += e + '/'
      }
    })
    directory = directory.substring(0, directory.length - 1)
    const itemName = item.name.split('/')
    if (item.size) {
      deleteObject.mutate({
        input: {
          fileName: itemName[itemName.length - 1],
          companyName: companyName,
          folder: directory,
          id: id,
        },
      })
    } else {
      deleteObject.mutate({
        input: {
          companyName: companyName,
          folder:
            directory != ''
              ? directory + '/' + itemName[itemName.length - 1]
              : itemName[itemName.length - 1],
          id: id,
        },
      })
    }
    MenuComponent.hideDropdowns(undefined)
  }

  const breadCrumbs = folder.map((item, index) => {
    if (index !== folder.length - 1) {
      return (
        <li className='breadcrumb-item' key={item + index}>
          <p
            onClick={() => handleBreadcrumb(item, index)}
            className='mb-0 text-primary fw-bold fs-base cursor-pointer'
          >
            {item}
          </p>
        </li>
      )
    } else if (index === folder.length - 1) {
      return (
        <li className='breadcrumb-item fs-base fw-bold' aria-current='page' key={item + index}>
          {item}
        </li>
      )
    }
  })

  return (
    <div className='row mt-2'>
      <div className='card card-body'>
        <div className='row'>
          <h1 className='m-3'>Documents</h1>
        </div>
        <div className='row justify-content-between ps-3'>
          <div className='col-md-12 align-items-end'>
            <button
              className='btn btn-primary fs-base fw-bold float-md-end me-5'
              onClick={() => setShowAddFolder(true)}
              disabled={showAddFolder}
            >
              + Add Folder
            </button>
            <button
              className='btn btn-secondary fs-base fw-bold float-md-end me-5'
              onClick={() => handleFileUpload()}
              disabled={showModal}
            >
              Upload
            </button>
          </div>
          {id && (
            <FileUpload
              folder={key}
              cin={''}
              vaultType={vaultType as Document_Vault}
              id={Number(id)}
              show={showModal}
              handleClose={handleFileUploadClose}
              investorId={Number(id)}
              companyName={companyName}
            />
          )}
        </div>
        <div className=''>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb ps-5'>{breadCrumbs}</ol>
          </nav>
          <div className='row g-6 g-xl-9 mb-xl-9'>
            <div className='col-12 col-sm-12 col-xl'>
              <FilesTable
                handleDeleteItem={handleDeleteItem}
                handleCreateFolder={handleCreateFolder}
                folderData={dirData}
                handleAddFolderClose={handleAddFolderClose}
                showAddFolder={showAddFolder}
                handleDirectoryClick={handleDirectoryClick}
                companyName={companyName!}
                id={Number(id)}
                vaultType={vaultType}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentViewerWrapper
