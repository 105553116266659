import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import {
  EditClassificationMutation,
  GetClassificationQuery,
  useEditClassificationMutation,
  useGetAllClassificationsQuery,
  useGetClassificationQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { ClassificationType } from '../../../../types/Classification'
import Toast, { ToastType } from '../../../../utils/toast'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../_metronic/helpers'
import { ClassificationContext } from '../../store/context/classificationContextProvider'

import PortfolioSearchItem from './PortfolioSearchItem'
import RemoveClassification from './RemoveClassification'

type EditClassificationProps = {
  handleClose: () => void
  showModal: boolean
  groupId: number
  groupName: string
}

function EditClassification({
  groupId,
  groupName: existingGroupName,
  handleClose,
  showModal,
}: EditClassificationProps) {
  const { companies } = useContext(ClassificationContext)
  const { data, isSuccess, isLoading } = useGetClassificationQuery<GetClassificationQuery>(
    graphqlRequestClient,
    { input: { id: groupId } },
    {}
  )

  const renameGroupMutation = useEditClassificationMutation<Error, EditClassificationMutation>(
    graphqlRequestClient,
    {
      onSuccess: () => {
        setEditActive(false)
        Toast('Classification Name Edited Successfully!', ToastType.success)
        queryClient.invalidateQueries(useGetClassificationQuery.getKey({ input: { id: groupId } }))
        queryClient.invalidateQueries(useGetAllClassificationsQuery.getKey())
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const group: ClassificationType = data?.classification
  const [groupName, setGroupName] = useState(existingGroupName)
  const [isEditActive, setEditActive] = useState(false)
  const [error, setError] = useState('')
  const groupCompanies = companies?.filter((company) =>
    group?.companies.some((groupCompany) => groupCompany.companyName === company.name)
  )

  function handleGroupRename() {
    renameGroupMutation.mutate({ input: { id: groupId, name: groupName } })
  }

  function handleGroupNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.value.length < 3) {
      setError('Classification Name should be more than 3 characters')
      setGroupName(e.currentTarget.value)
    } else if (e.currentTarget.value.length > 127) {
      setError('Classification Name should not be more than 127 characters')
    } else {
      setGroupName(e.currentTarget.value)
      setError('')
    }
  }

  return !isLoading && isSuccess ? (
    <span
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Modal
        id='kt_modal_add_inv'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px '
        show={showModal}
        onHide={() => {
          handleClose()
        }}
      >
        <div className='col d-flex justify-content-between align-items-baseline my-4 mx-2'>
          <div className='ms-4'>
            <h2 className='fw-bolder text-dark'>Edit Classification</h2>
          </div>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary me-1'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleClose()
            }}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </div>
        <div className='separator mb-8'></div>
        <Modal.Title className='mx-15 mb-15'>
          <div className='card card-p-0 p-0'>
            <div className='card-body'>
              <h5>Name of Classification</h5>
              <div
                className='col d-flex w-50 align-items-center'
                style={{
                  backgroundColor: `${getCSSVariableValue('--kt-input-solid-bg')}`,
                  borderColor: `${getCSSVariableValue('--kt-input-solid-bg')}`,
                }}
              >
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={groupName}
                  onChange={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleGroupNameChange(e)
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  disabled={!isEditActive}
                  placeholder='Enter new Classification Name'
                />
                {isEditActive ? (
                  <>
                    <div
                      className='text-hover-primary mx-2'
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleGroupRename()
                      }}
                    >
                      {renameGroupMutation.isLoading ? (
                        <span className='spinner-border spinner-border-sm mx-2'></span>
                      ) : (
                        <KTSVG
                          className='svg-icon-1 text-gray-500'
                          path='/media/icons/duotune/arrows/arr012.svg'
                        />
                      )}
                    </div>

                    {/* cancel icon button */}
                    <div
                      className='text-hover-primary mx-2'
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setEditActive(false)
                        // setGroupName(existingGroupName)
                        setError('')
                      }}
                    >
                      <KTSVG
                        className='svg-icon-1 text-gray-500'
                        path='/media/icons/duotune/arrows/arr061.svg'
                      />
                    </div>
                  </>
                ) : (
                  //  pencil icon button
                  <div
                    className='px-4 text-hover-primary'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setEditActive(true)
                    }}
                  >
                    <KTSVG
                      className={'svg-icon-1 text-gray-500'}
                      path='/media/icons/duotune/art/art005.svg'
                    />
                  </div>
                )}

                {/* only show delete button when there are no companies present in the group */}
                {groupCompanies && groupCompanies?.length == 0 && (
                  <RemoveClassification groupId={groupId} />
                )}
              </div>

              {/* only show items when there are companies present in the group */}
              {groupCompanies && groupCompanies?.length > 0 && (
                <>
                  <div className='my-4'>
                    <h5>Current Companies</h5>
                  </div>
                  <div>
                    {groupCompanies?.map((company, index) => (
                      <PortfolioSearchItem
                        key={company.name}
                        companyName={company.name}
                        companyId={company.companyId || ''}
                        companyLogo={company.logo}
                        pps={company.sharePrice}
                        valuation={company.valuation}
                        latestRaise={company.latestRaise}
                        noOfRounds={company.noOfRounds}
                        totalFunding={company.totalFunding}
                        isOddRow={index % 2 === 0 ? false : true}
                        business={company.business}
                        industry={company.industry}
                        isHissaVerified={company.isVerified}
                        isChecked={false}
                        handleChecked={() => console.log(company)}
                        isAddButton={false}
                        groupId={groupId}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Title>
      </Modal>
    </span>
  ) : (
    <span className='spinner-border spinner-border-sm mx-2'></span>
  )
}

export default EditClassification
