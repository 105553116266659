import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useTermsheetBuilderStore } from '../../../../../store/TermsheetBuilderStore'

function FounderRestrictions() {
  const builder = useTermsheetBuilderStore()

  return (
    <div className='card shadow-sm mt-4 p-8'>
      <div className='row align-items-center'>
        <h3 className='col fw-bold pb-4'>Founder Restrictions</h3>
        <div className='col text-end'>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => builder.removeSection('Founder Restrictions')}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </div>
      </div>
      <div className='row align-items-start pb-4'>
        <div className='col-12 mb-2'>
          <h5>Founder Vesting</h5>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='founderVesting'
                value='4'
                checked={true}
                readOnly
              />
            </span>
            <h4 className='text-start'>
              4 year vesting with 1 year cliff, subject to continued employment
            </h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='founderVesting'
                value='none'
                disabled
              />
            </span>
            <h4 className='text-start'>None</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='founderVesting'
                value='other'
                disabled
              />
            </span>
            <h4 className='text-start'>Custom</h4>
          </label>
        </div>
      </div>
      <div className='row align-items-start pb-4'>
        <div className='col-12 mb-2'>
          <h5>Founder Lock-in</h5>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='founderLockin'
                value='3'
                checked={true}
                readOnly
              />
            </span>
            <h4 className='text-start'>3 year lock-in on transfer</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='founderLockin'
                value='no'
                disabled
              />
            </span>
            <h4 className='text-start'>
              No transfer of founder shares without prior Investor consent
            </h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='founderLockin'
                value='afterExit'
                disabled
              />
            </span>
            <h4 className='text-start'>Lock-in until the exit of Investor</h4>
          </label>
        </div>
      </div>
    </div>
  )
}

export default FounderRestrictions
