import React, { useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import { ISendTermsheet } from '../../SendTermsheetHelper'
import numToWords from '../../../../../../../utils/NumToWords'
import Nouislider from 'nouislider-react'
import {
  kFormatter,
  kFormatterRupee,
  kUnformatterNumber,
} from '../../../../../../../utils/CurrencyFormatter'

import { KTSVG } from '../../../../../../../_metronic/helpers'

const InvestmentDetails = (props: any) => {
  const formik = useFormikContext<ISendTermsheet>()
  const [moneyInWords, setMoneyInWords] = useState<String>()
  const [initialValue, setInitialValue] = useState<boolean>(false)
  const [newInvestmentInWords, setNewInvestmentInWords] = useState<String>()
  const [investmentSliderRef, setInvestmentSliderRef] = useState<any>(null)
  const [newInvestmentSliderRef, setNewInvestmentSliderRef] = useState<any>(null)
  const [remainingAmount, setRemainingAmount] = useState<number>(0)
  const [newInvestors, setNewInvestors] = useState<any>(formik.values.investorList)
  let additionalCount = 0
  let tempAmount = formik.values.roundSize - formik.values.investmentAmount
  let remaining = formik.values.roundSize - formik.values.investmentAmount
  const securityTypes = [
    'Equity',
    'CCPS',
    'CCD',
    'OCD',
    'Note',
    'Warrant',
    'RPS',
    'NCD',
    'OCPS',
    'OCRPS',
    'Options',
  ]


  useEffect(() => {
    if (!props.values.isDraft) {
      formik.setFieldValue('investmentAmount', formik.values.roundSize)
      setMoneyInWords(numToWords(Math.round(formik.values.roundSize)))
    }
  }, [formik.values.roundSize])

  useEffect(() => {
    newInvestors.length > 0
      ? newInvestors.map((investment: any, index: number) =>
          setRemainingAmount((tempAmount = tempAmount - investment.investmentAmount))
        )
      : setRemainingAmount(formik.values.roundSize - formik.values.investmentAmount)
    formik.setFieldValue('remainingAmount', remainingAmount)
  }, [
    remainingAmount,
    formik.values.investmentAmount,
    formik.values.roundSize,
    formik.values.investorList,
  ])

  const tooltipFns = {
    to(value: number) {
      return kFormatter(value)
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }
  const investmentHeader = () => {
    return (
      <tr className='fw-bold text-muted text-center'>
        <th className='fs-5 min-w-125px'>Investor Name</th>
        <th className='fs-5 min-w-200px'>
          <span style={{ fontFamily: 'arial' }}></span> Investment Amount
        </th>
      </tr>
    )
  }

  const removeInvestor = (value: any) => {
    setNewInvestors((newInvestors: any) => {
      formik.setFieldValue('newInvestorName', '')
      formik.setFieldValue('newInvestmentAmount', 1)
      setNewInvestmentInWords(numToWords(1))
      formik.setFieldValue(
        'investorList',
        formik.values.investorList.filter((currentObject: any) => currentObject !== value)
      )
      return newInvestors.filter((currentObject: any) => currentObject !== value)
    })
  }

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Investment Details</h2>
      </div>

      <div className='col fv-md-row d-md-flex align-items-baseline'>
        <div className='col-12 col-md-4'>
          <label className='form-label required'>Money to be invested</label>
        </div>
        <div className='col-12 col-md-4 '>
          <Nouislider
            instanceRef={(instance) => {
              if (instance && !investmentSliderRef) {
                setInvestmentSliderRef(instance)
              }
            }}
            tooltips={[tooltipFns]}
            start={formik.values.investmentAmount}
            range={{
              min: 0,
              max: formik.values.roundSize > 0 ? Math.round(formik.values.roundSize) : 999,
            }}
            connect
            onChange={(e) => {
              formik.setFieldValue('investmentAmount', Math.round(e.at(0)))
              setMoneyInWords(numToWords(Math.round(e.at(0))))
              setRemainingAmount(formik.values.roundSize - Math.round(e.at(0)))
            }}
            className=''
          />
        </div>
        <div className='col-12 col-md-4 pt-6 pt-md-0 ps-md-6 '>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='investmentAmount'
            onChange={(e: any) => {
              formik.handleChange(e)
              const investmentAmount = parseInt(e.target.value?.replaceAll(',', '') || 0, 10)
              formik.setFieldValue('investmentAmount', Math.round(investmentAmount))
              investmentSliderRef?.noUiSlider.set(investmentAmount)
              setMoneyInWords(numToWords(Math.round(investmentAmount)))
              setRemainingAmount(formik.values.roundSize - investmentAmount)
            }}
            value={Intl.NumberFormat('en-IN').format(formik.values.investmentAmount)}
          />
          <div className='form-text'>{moneyInWords}</div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='investmentAmount' />
          </div>
        </div>
      </div>

      <div className='row align-md-items-baseline pb-4 pt-6 '>
        <div className='col-6 col-md-6'>
          <label className='form-label required '>Money Remaining in round</label>
        </div>
        <div className='col-6 col-md-5'>
          <h4>
            {remainingAmount >= 0 ? (
              kFormatterRupee(Math.round(remainingAmount))
            ) : (
              <div className='text-danger'>
                {Math.round(remainingAmount) < 1000
                  ? kFormatterRupee(Math.round(remainingAmount)).replaceAll(',', '')
                  : kFormatterRupee(Math.round(remainingAmount))}
              </div>
            )}
          </h4>
        </div>
      </div>

      <div className='row align-md-items-baseline pb-4 pt-6'>
        <div className='col-6 col-md-6'>
          <label className='form-label required '>Do you want to add new investors?</label>
        </div>
        <div className='col-6 col-md-5 '>
          <div className='form-check form-switch form-check-custom form-check-solid me-10'>
            <Field
              className='form-check-input h-20px w-40px'
              type='checkbox'
              name='isAddInvestor'
              disabled={remainingAmount === 0}
            />
          </div>
        </div>
      </div>

      {formik.values.isAddInvestor.toString() === 'true' && remainingAmount !== 0 && (
        <div className='row align-items-baseline mb-8 pt-6 pt-md-0'>
          <div className='col-12 col-md-4 '>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='newInvestorName'
              placeholder='Enter Investor Name'
              required
            />
          </div>

          <div className='col-12 col-md-4'>
            <Nouislider
              instanceRef={(instance) => {
                if (instance && !newInvestmentSliderRef) {
                  setNewInvestmentSliderRef(instance)
                }
              }}
              tooltips={[tooltipFns]}
              start={formik.values.newInvestmentAmount}
              range={{ min: 0, max: Math.round(remainingAmount) }}
              connect
              onChange={(e) => {
                formik.setFieldValue('newInvestmentAmount', Math.round(e.at(0)))
                setNewInvestmentInWords(numToWords(Math.round(e.at(0))))
              }}
              className=''
            />
          </div>
          <div className='col-12 col-md-4 pt-6 pt-md-0 ps-md-6'>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='newInvestmentAmount'
              onChange={(e: any) => {
                formik.handleChange(e)
                const newInvestmentAmount = parseInt(e.target.value?.replaceAll(',', '') || 0, 10)
                formik.setFieldValue('newInvestmentAmount', Math.round(newInvestmentAmount))
                newInvestmentSliderRef?.noUiSlider.set(newInvestmentAmount)
                setNewInvestmentInWords(numToWords(Math.round(newInvestmentAmount)))
              }}
              value={Intl.NumberFormat('en-IN').format(formik.values.newInvestmentAmount)}
            />
            <div className='form-text'>{newInvestmentInWords}</div>
            <div className='text-danger mt-2'>
              <ErrorMessage name='newInvestmentAmount' />
            </div>
          </div>
          <div className='mt-4'>
            <button
              type='button'
              className='btn border border-primary fs-base fw-bold pb-1 '
              onClick={() => {
                if (
                  formik.values.newInvestorName !== '' &&
                  formik.values.newInvestmentAmount <= remainingAmount &&
                  formik.values.newInvestmentAmount > 0
                ) {
                  setNewInvestors([
                    ...newInvestors,
                    {
                      investorName: formik.values.newInvestorName,
                      investmentAmount: Math.round(formik.values.newInvestmentAmount),
                    },
                  ])

                  setRemainingAmount(remainingAmount - formik.values.newInvestmentAmount)
                  formik.setFieldValue('investorList', [
                    ...newInvestors,
                    {
                      investorName: formik.values.newInvestorName,
                      investmentAmount: Math.round(formik.values.newInvestmentAmount),
                    },
                  ])
                  if (remainingAmount - formik.values.newInvestmentAmount > 0) {
                    formik.setFieldValue('newInvestorName', '')
                    formik.setFieldValue('newInvestmentAmount', 1)
                    setNewInvestmentInWords(numToWords(1))
                  } else {
                  }
                }
              }}
            >
              <h5 className='text-primary pb-1'>Add New Investor</h5>
            </button>
          </div>
        </div>
      )}

      {formik.values.isAddInvestor.toString() === 'true' && newInvestors.length > 0 && (
        <div className='col-md-8 d-flex'>
          <div className='col-md-6'>
            <table className='table align-middle gs-0 gy-4 table-row-dashed border-gray-200'>
              <thead>{investmentHeader()}</thead>
              <tbody>
                {newInvestors.map(
                  (investment: any, index: number) => (
                    (additionalCount += investment.investmentAmount),
                    (
                      <tr key={index}>
                        <td className='text-center'>
                          <p className='fs-base mb-0 fw-bold'>{investment.investorName}</p>
                        </td>
                        <td className='text-center'>
                          <p className='fs-base mb-0'>
                            {additionalCount <= remaining ? (
                              investment.investmentAmount.toLocaleString('en-IN')
                            ) : (
                              <div className='text-danger'>
                                {investment.investmentAmount.toLocaleString('en-IN')}
                              </div>
                            )}
                          </p>
                        </td>
                        <td>
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-active-light-primary'
                            onClick={() => {
                              setRemainingAmount(
                                remainingAmount + investment.investmentAmount <= remaining
                                  ? remainingAmount + investment.investmentAmount
                                  : remainingAmount
                              )
                              additionalCount -= investment.investmentAmount
                              removeInvestor(investment)
                            }}
                          >
                            <KTSVG
                              className='svg-icon-1 text-primary'
                              path='/media/icons/duotune/arrows/arr061.svg'
                            />
                          </button>
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <h2 className='fw-bolder text-dark required pb-6 pt-6'>Select Securities to be issued</h2>
      <div className='row align-items-baseline mb-8 pt-6 pt-md-0'>
        {securityTypes.map((item: string) => (
          <div className='col-6 col-md-4'>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input h-20px w-20px'
                  type='checkbox'
                  name='security'
                  value={item}
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>{item}</h5>
                </span>
              </span>
            </label>
          </div>
        ))}

        <div className='text-danger mt-2'>
          <ErrorMessage name='security' />
        </div>
      </div>
    </div>
  )
}

export default InvestmentDetails
