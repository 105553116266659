import { FC, useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import { ISendTermsheet } from '../../SendTermsheetHelper'

const InvestorRights: FC = () => {
  const formik = useFormikContext<ISendTermsheet>()
  const investorRightsList: string[] = [
    'Affirmative vote rights over all major decisions',
    'Pre-emption right',
    'Information and inspection rights',
    'No restrictions on transfer of shares or rights',
    'All additional rights that any future investor is offered in the Company',
  ]
  const [showOtherField, setShowOtherField] = useState(
    formik.values.investorRights?.every((val) => investorRightsList.includes(val)) === true
      ? false
      : true
  )

  const getFilteredData = () => {
    return formik.values.investorRights?.filter((x) => investorRightsList.includes(x)) || []
  }
  useEffect(() => {
    if (showOtherField === false)
      formik.values.investorRights = formik.values.investorRights?.filter((x) =>
        investorRightsList.includes(x)
      )
  }, [showOtherField])
  return (
    <div className='w-100'>
      <div className='pb-10'>
        <h2 className='fw-bolder text-dark'>Investor Rights</h2>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Investor to have the following rights:</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='checkbox'
                  name='investorRights'
                  value='Affirmative vote rights over all major decisions'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>
                    Affirmative vote rights over all major decisions
                  </h5>
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='checkbox'
                  name='investorRights'
                  value='Pre-emption right'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Pre-emption right</h5>
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='checkbox'
                  name='investorRights'
                  value='Information and inspection rights'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Information and inspection rights</h5>
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='checkbox'
                  name='investorRights'
                  value='No restrictions on transfer of shares or rights'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>
                    No restrictions on transfer of shares or rights
                  </h5>
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='checkbox'
                  name='investorRights'
                  value='All additional rights that any future investor is offered in the Company'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>
                    All additional rights that any future investor is offered in the Company
                  </h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  onClick={(e: any) => setShowOtherField(!showOtherField)}
                  name='investorRights'
                  value='custom'
                  defaultChecked={showOtherField}
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Custom</h5>
                </span>
              </span>
              {showOtherField && (
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid w-50 ms-4'
                  //   name='investorRights'
                  onBlur={(e: any) => {
                    formik.handleChange(e)

                    formik.setFieldValue('investorRights', [...getFilteredData(), e.target.value])
                  }}
                  defaultValue={
                    showOtherField &&
                    formik.values.investorRights?.filter((x) => !investorRightsList.includes(x))[0]
                  }
                  disabled={!showOtherField}
                />
              )}
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='investorRights' />
          </div>
        </div>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Anti dilution</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='antiDilution'
                  value='Full-Rachet'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Full-Rachet</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='antiDilution'
                  value='Narrow-based weighted average'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Narrow-based weighted average</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='antiDilution'
                  value='Broad-based weighted average'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Broad-based weighted average</h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='founderLockin' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvestorRights
