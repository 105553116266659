import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'

ChartJS.register(ArcElement, Tooltip, Legend)

export interface ChartData {
  datasets: {
    data: (string | number)[]
    backgroundColor: string[]
    borderColor: string[]
  }[]
}
function OpenDealsPieChart({ data: chartData, options }: { data: ChartData; options: Object }) {
  const plugins = [
    {
      id: '0',
      afterDraw(chart: ChartJS) {
        const { width, height, ctx } = chart
        ctx.restore()
        const fontSize = (height / 50).toFixed(2)
        ctx.font = `${fontSize}em poppins`
        ctx.textBaseline = 'top'
        let count = chart.data.datasets[0].data[0] || 0
        const text2 = count?.toLocaleString()
        const textX = Math.round(width - ctx.measureText(text2).width) / 2
        const textY = height / 2.3
        ctx.fillText(text2, textX, textY)
        const fontSize1 = (height / 170).toFixed(2)
        ctx.font = `${fontSize1}em poppins`
        ctx.fillStyle = getCSSVariableValue('--kt-headings-color')
        const text3 = 'Active term sheets'
        const textA = Math.round(width - ctx.measureText(text3).width) / 2
        const textB = height / 1.3
        ctx.fillText(text3, textA, textB)
        ctx.save()
      },
    },
  ]
  return (
    <div>
      <Doughnut options={options} data={chartData} plugins={plugins} />
    </div>
  )
}

export default OpenDealsPieChart
