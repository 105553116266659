import {
  GetInvestmentByStageQuery,
  useGetInvestmentByStageQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils/DomHelpers'
import DonutWithDropdown from './DonutWithDropdown'

export const optionsSeries: Object = {
  rotation: 0,
  circumference: 360,
  maintainAspectRatio: false,
  responsive: true,
  radius: '100%',
  cutout: '80%',
  plugins: {
    tooltip: { displayColors: true },
    legend: { display: false },
    title: { display: false },
  },
}

function DonutByStage(props: {
  title: string | number
  investorId: number[] | undefined
  investors: any[]
  handleIndustryShow: Function
  handleInvestmentShow: Function
  showIndustryDonut: boolean
  showInvestmentDonut: boolean
  companies?: string[]
}) {
  const input =
    props.companies !== undefined
      ? { investorIds: props.investorId, companies: props.companies }
      : { investorIds: props.investorId }

  const { data, isFetched } = useGetInvestmentByStageQuery<GetInvestmentByStageQuery, Error>(
    graphqlRequestClient,
    { input: input },
    {}
  )

  const bgColors: string[] = []
  for (let i = 0; i < 10; i++) {
    const color = getCSSVariableValue('--kt-donut-' + i)
    bgColors.push(color)
  }
  let labels: any = []
  let chartData: any = []
  let total = 0

  if (!isFetched) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  } else {
    data?.investmentByStage!.forEach((investment, index) => {
      if (investment?.count! > 0) {
        labels.push(investment!.stage?.toUpperCase())
        chartData.push(investment!.count)
        total += investment?.count!
      }
    })

    let totalCount = 0
    chartData.forEach((i: number) => {
      totalCount = totalCount + i
    })
    const seriesChartData = {
      labels: labels,
      datasets: [
        {
          label: '# of Rounds',
          data: chartData,
          backgroundColor: bgColors,
          borderColor: bgColors,
          count: totalCount,
          investors_selected_count: props.investors.length,
          investors: props.investors.map((investor) => investor),
        },
      ],
    }

    return (
      <DonutWithDropdown
        data={seriesChartData}
        options={optionsSeries}
        length={total}
        handleIndustryShow={props.handleIndustryShow}
        handleInvestmentShow={props.handleInvestmentShow}
        showIndustryDonut={props.showIndustryDonut}
        showInvestmentDonut={props.showInvestmentDonut}
      />
    )
  }
}

export default DonutByStage
