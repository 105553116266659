import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  GetDealTemplatesQuery,
  useAddDealTemplateMutation,
  useGetDealTemplatesQuery,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useTermsheetBuilderStore } from '../../../store/TermsheetBuilderStore'
import GovernanceRights from './sections/mandatory/GovernanceRights'
import Investment from './sections/mandatory/Investment'
import RoundDetails from './sections/mandatory/RoundDetails'
import TransferRestrictions from './sections/mandatory/TransferRestrictions'
import Valuation from './sections/mandatory/Valuation'
import ExitRights from './sections/mandatory/ExitRights'
import FounderRestrictions from './sections/additional/FounderRestrictions'
import InvestorRights from './sections/additional/InvestorRights'
import Miscellaneous from './sections/additional/Miscellaneous'
import { InvestorList } from '../../../../../types/InvestorList'
import TemplateUpload from './TemplateUpload'
import LiquidationPreference from './sections/mandatory/LiquidationPreference'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../utils/Select'

type Props = {
  templateList: GetDealTemplatesQuery | undefined
  investorList: InvestorList[]
  toggleBuilder: () => void
}
function TermsheetBuilder({ templateList, investorList, toggleBuilder }: Props) {
  const builder = useTermsheetBuilderStore()
  const [isEdit, setIsEdit] = useState(false)
  const [errors, setErrors] = useState('')
  const [templateName, setTemplateName] = useState('Termsheet Template')
  const [selectedInvestorId, setSelectedInvestorId] = useState<number>()
  const templateNameRef = useRef<HTMLInputElement>(null)
  const [showModal, setShowModal] = useState(false)
  const [inputData, setInputData] = useState<any>({})

  useEffect(() => {
    if (investorList) setSelectedInvestorId(investorList[0]?.investorId)
  }, [investorList])

  const mutateAddTemplate = useAddDealTemplateMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(useGetDealTemplatesQuery.getKey())
      },
    },
    {}
  )
  const sections = useMemo(() => {
    let defaultSections = [
      <RoundDetails investorList={investorList} />,
      <Valuation />,
      <Investment />,
      <LiquidationPreference />,
      <TransferRestrictions />,
      <GovernanceRights />,
      <ExitRights />,
    ]
    let selectedSections: any[] = [...defaultSections]
    builder.sections.forEach((section) => {
      if (section === 'Investor Rights') {
        selectedSections.push(<InvestorRights />)
      }
      if (section === 'Founder Restrictions') {
        selectedSections.push(<FounderRestrictions />)
      }
      if (section === 'Miscellaneous') {
        selectedSections.push(<Miscellaneous />)
      }
    })
    return selectedSections
  }, [builder.sections])

  function validateTemplateName(name: string) {
    if (name.trim().length === 0) {
      setErrors('Template name cannot be empty')
    } else if (name.length < 3) {
      setErrors('Template name must be greater than 3 characters')
    } else {
      setErrors('')
    }
  }

  function handleSubmit() {
    let templateSections = builder.sections
    let template = templateList?.dealTemplates?.find((template) => template?.name === templateName)
    if (templateSections.length == 0) {
      setErrors('Default template cannot be submitted')
    } else if (template) {
      setErrors('Template record with the same name already exists')
    } else {
      setErrors('')
      // let input: AddDealTemplateInput = {
      //   investorId: selectedInvestorId,
      //   name: templateName,
      //   sections: templateSections,
      // }
      // mutateAddTemplate.mutate({ input: input })
      setInputData({
        investorId: selectedInvestorId,
        name: templateName,
        sections: templateSections,
      })
      setShowModal(true)
    }
  }

  const investorName: any[] = []
  investorList?.map((investor: any) =>
    !investorName.find((invObj: any) => invObj.value === investor.investorId)
      ? investorName.push({
          value: investor.investorId,
          label: investor.investorName,
        })
      : {}
  )

  return (
    <div className='card card-body mt-4'>
      <div className='row'>
        <div className='col-12 col-md-3 p-8'>
          <h3 className='pb-10'>Build options -</h3>
          <h4 className='mt-4'>Investor / Fund</h4>
          <Select
            className=''
            options={investorName}
            name='investorId'
            menuPortalTarget={document.body}
            styles={selectCustomStyles}
          />
          <div className='separator border-gray-200 my-8'></div>
          <h4 className='mb-8'>Field Selection</h4>
          <button
            className='btn btn-secondary w-100 mb-2 text-start'
            disabled={builder.containsSection('Investor Rights')}
            onClick={(e) => {
              builder.addSection('Investor Rights')
            }}
          >
            <h5>Investor Rights</h5>
          </button>
          <button
            className='btn btn-secondary w-100 mb-2 text-start'
            disabled={builder.containsSection('Founder Restrictions')}
            onClick={(e) => {
              builder.addSection('Founder Restrictions')
            }}
          >
            <h5>Founder Restrictions</h5>
          </button>
          <button
            className='btn btn-secondary w-100 mb-2 text-start'
            disabled={builder.containsSection('Miscellaneous')}
            onClick={(e) => {
              builder.addSection('Miscellaneous')
            }}
          >
            <h5>Miscellaneous</h5>
          </button>
        </div>
        <div className='col-12 col-md-9 p-4'>
          <div className='min-h-600px mh-500px overflow-auto p-4'>
            <h2 className='mb-6'>Term Sheet Template Builder</h2>
            <div className='d-flex align-items-center'>
              {isEdit ? (
                <div className='d-flex'>
                  <div className='me-4'>
                    <input
                      ref={templateNameRef}
                      onChange={(e) => validateTemplateName(e.target.value)}
                      className='form-control form-control-solid me-2'
                      placeholder='Enter Template Name'
                      defaultValue={templateName}
                    />
                    <h6 className='fs-base text-danger mt-2'>{errors}</h6>
                  </div>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    type='submit'
                    disabled={errors != ''}
                    onClick={() => {
                      if (errors == '') {
                        setTemplateName(templateNameRef.current?.value!)
                      }
                      setIsEdit(false)
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr012.svg'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    onClick={() => {
                      setIsEdit(false)
                      setErrors('')
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </button>
                </div>
              ) : (
                <>
                  <h6>{templateName}</h6>
                  <button
                    className='ms-2 btn btn-icon btn-sm'
                    onClick={() => {
                      setIsEdit(!isEdit)
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/art/art005.svg'
                      className='svg-icon svg-icon-2 text-center'
                    />
                  </button>
                </>
              )}
            </div>
            <div>{sections}</div>
          </div>
          <TemplateUpload
            showModal={showModal}
            handleClose={() => {
              setShowModal(false)
              builder.reset()
            }}
            inputData={inputData}
            toggleBuilder={toggleBuilder}
          />
          <div className='text-end pt-8 text-danger'>{errors}</div>
          <div className='text-end pt-8'>
            <button
              className='btn btn-md btn-primary'
              onClick={handleSubmit}
              disabled={mutateAddTemplate.isLoading || mutateAddTemplate.isSuccess}
            >
              {mutateAddTemplate.isIdle && 'Save and Upload'}
              {mutateAddTemplate.isSuccess && 'Saved'}
              {mutateAddTemplate.isLoading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            {mutateAddTemplate.isError && (
              <h6 className='text-danger'>{mutateAddTemplate.error.message.split(':')[0]}</h6>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsheetBuilder
