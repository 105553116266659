import { useState } from 'react'
import EmailTermsheet from './EmailTermsheet'
type TermsheetDropdownProps = {
  termsheetId: number
}

function TermsheetDropdown(props: TermsheetDropdownProps) {
  const options = ['Email Termsheet', 'Edit', 'Delete']
  const [showModal, setShowModal] = useState(false)
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
      data-kt-menu='true'
    >
      {options.map((option) => {
        return (
          <label
            key={option}
            className='ms-3 form-check-custom me-5 px-3 py-3 cursor-pointer'
            onClick={() => {
              if (option === 'Email Termsheet' && props.termsheetId !== 0) {
                setShowModal(true)
              }
            }}
          >
            {option === 'Email Termsheet' && props.termsheetId === 0 ? (
              <span className='form-check-label fw-bold fs-5 ms-3 text-hover-gray-400'>
                {option}
              </span>
            ) : (
              <span className='form-check-label fw-bold fs-5 ms-3 text-hover-primary'>
                {option}
              </span>
            )}
          </label>
        )
      })}
      <EmailTermsheet
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        termsheetId={props.termsheetId}
      />
    </div>
  )
}

export default TermsheetDropdown
