type HighlightsCardProps = {
  highlights: any
}

function WatchlistHighlightTab(props: HighlightsCardProps) {
  return (
    <div className='border border-primary border-3 card shadow-sm p-4 '>
      <h2>{props.highlights}</h2>
    </div>
  )
}
export default WatchlistHighlightTab
