import Nouislider from 'nouislider-react'
import React, { useState } from 'react'
import { kFormatter, kUnformatterNumber } from '../../../../../../../utils/CurrencyFormatter'

function Valuation() {
  const [moneyInWords, setMoneyInWords] = useState<any>()

  const tooltipFns = {
    to(value: number) {
      return kFormatter(value)
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }

  return (
    <div className='card shadow-sm mt-4 p-8'>
      <div className='row align-items-center'>
        <h3 className='col fw-bold pb-4'>Valuation Details</h3>
      </div>
      <div className='row align-items-baseline'>
        <div className='col-6 col-md-4'>
          <h4>Floor Valuation / Pre-money Valuation</h4>
        </div>
        <div className='col-6 col-md-4'>
          <Nouislider
            instanceRef={(instance) => {
              // if (instance && !valuationSliderRef) {
              //     setValuationSliderRef(instance)
              // }
            }}
            tooltips={[tooltipFns]}
            start={250000000}
            range={{ min: 0, max: 1000000000 }}
            connect
            onChange={(e) => setMoneyInWords(e)}
            className=''
            disabled
          />
        </div>
        <div className='col-6 col-md-4'>
          <input
            type='text'
            value={'250000000'}
            className='form-control form-control-md form-control-solid'
            disabled
          />
          <div className='form-text'>Rupees Twenty Five Crores</div>
        </div>
      </div>
      <div className='row align-items-baseline pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Valuation Cap</h4>
        </div>
        <div className='col-6 col-md-4'>
          <Nouislider
            instanceRef={(instance) => {
              // if (instance && !valuationSliderRef) {
              //     setValuationSliderRef(instance)
              // }
            }}
            tooltips={[tooltipFns]}
            start={250000000}
            range={{ min: 0, max: 1000000000 }}
            connect
            onChange={(e) => setMoneyInWords(e)}
            className=''
            disabled
          />
        </div>
        <div className='col-6 col-md-4'>
          <input
            type='text'
            value={'250000000'}
            className='form-control form-control-md form-control-solid'
            disabled
          />
          <div className='form-text'>Rupees Twenty Five Crores</div>
        </div>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Discount / Post-round ESOP %</h4>
        </div>
        <div className='col-6 col-md-8'>
          <input
            type='text'
            className='form-control form-control-md form-control-solid w-md-50'
            value='10'
            disabled
          />
        </div>
      </div>
    </div>
  )
}

export default Valuation
