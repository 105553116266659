function TransferRestrictions() {
  return (
    <div className='card shadow-sm mt-4 p-8'>
      <div className='row align-items-center'>
        <h3 className='col fw-bold pb-4'>Transfer Restrictions</h3>
      </div>
      <div className='row'>
        <div className='col'>
          <div className='row align-items-start pb-4'>
            <div className='col-12 mb-2'>
              <h5>Drag Along Right</h5>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='dar'
                    value='1'
                    checked={true}
                    readOnly
                  />
                </span>
                <h4 className='text-start'>Investors can drag others</h4>
              </label>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input className='form-check-input' type='radio' name='dar' value='2' disabled />
                </span>
                <h4 className='text-start'>
                  Founders and other shareholders (except the Investor) can drag others
                </h4>
              </label>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input className='form-check-input' type='radio' name='dar' value='3' disabled />
                </span>
                <h4 className='text-start'>Terms to be negotiated in definitive agreements</h4>
              </label>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='row align-items-start pb-4'>
            <div className='col-12 mb-2'>
              <h5>Tag Along Right</h5>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='tar'
                    value='1'
                    checked={true}
                    readOnly
                  />
                </span>
                <h4 className='text-start'>Investor have a right to tag along</h4>
              </label>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input className='form-check-input' type='radio' name='tar' value='2' disabled />
                </span>
                <h4 className='text-start'>Founders have a right to tag along</h4>
              </label>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input className='form-check-input' type='radio' name='tar' value='3' disabled />
                </span>
                <h4 className='text-start'>Terms to be negotiated in definitive agreements</h4>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='row align-items-start pb-4'>
            <div className='col-12 mb-2'>
              <h5>Right of First Refusal</h5>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='rofr'
                    value='1'
                    checked={true}
                    readOnly
                  />
                </span>
                <h4 className='text-start'>Investor has a ROFR</h4>
              </label>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input className='form-check-input' type='radio' name='rofr' value='2' disabled />
                </span>
                <h4 className='text-start'>Promoters have a ROFR</h4>
              </label>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input className='form-check-input' type='radio' name='rofr' value='3' disabled />
                </span>
                <h4 className='text-start'>Terms to be negotiated in definitive agreements</h4>
              </label>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='row align-items-start pb-4'>
            <div className='col-12 mb-2'>
              <h5>Right of First Offer</h5>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='rofo'
                    value='1'
                    checked={true}
                    readOnly
                  />
                </span>
                <h4 className='text-start'>Investor has a ROFO</h4>
              </label>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input className='form-check-input' type='radio' name='rofo' value='2' disabled />
                </span>
                <h4 className='text-start'>Promoters have a ROFO</h4>
              </label>
            </div>
            <div className='col-12'>
              <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
                <span className='form-check form-check-custom form-check-solid me-4'>
                  <input className='form-check-input' type='radio' name='rofo' value='3' disabled />
                </span>
                <h4 className='text-start'>Terms to be negotiated in definitive agreements</h4>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransferRestrictions
