import { Doughnut } from 'react-chartjs-2'
import { optionsSeries } from './DonutByStage'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BubbleDataPoint,
  ScatterDataPoint,
} from 'chart.js'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import { ChartData } from 'chart.js'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import _ from 'lodash'

ChartJS.register(ArcElement, Tooltip, Legend)

type DonutWithCloseButtonProps = {
  header: string
  data: ChartData<'doughnut', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>
  handleClose: () => void
}

function DonutWithCloseButton(props: DonutWithCloseButtonProps) {
  let exitedTextList: number[] = []
  let updatedCount = 0
  let invList: any[] = []

  var plugins = [
    {
      id: '1',
      afterDraw(chart: ChartJS) {
        const { width, height, ctx } = chart
        ctx.restore()
        const fontSize = (height / 100).toFixed(2)
        ctx.font = `${fontSize}em poppins`
        ctx.fillStyle = getCSSVariableValue('--kt-headings-color')
        ctx.textBaseline = 'top'

        let count = 0
        chart.data.datasets.forEach((item: any) => {
          if (item.investors_selected_count !== invList.length) {
            count = item.count || 0
          } else {
            count = updatedCount
          }
        })
        const text2 = count.toLocaleString()
        const textX = Math.round((width - ctx.measureText(text2).width) / 2)
        const textY = chart.chartArea.height / 2 - 10
        ctx.fillText(text2, textX, textY)
        ctx.save()
      },
    },
    {
      id: 'htmlLegend',
      afterUpdate(chart: ChartJS) {
        const items = chart.options.plugins?.legend?.labels?.generateLabels?.(chart)
        const ul = document.createElement('ul')
        items?.map((item, index) => {
          const li = document.createElement('li')
          li.style.alignItems = 'center'
          li.style.cursor = 'pointer'
          li.style.display = 'flex'
          li.style.flexDirection = 'row'
          li.style.paddingBottom = '5px'

          const boxSpan = document.createElement('span')
          boxSpan.style.background = item.fillStyle?.toString()!
          boxSpan.style.borderColor = item.strokeStyle?.toString()!
          boxSpan.style.borderWidth = item.lineWidth + 'px'
          boxSpan.style.borderRadius = '10px'
          boxSpan.style.display = 'inline-block'
          boxSpan.style.height = '15px'
          boxSpan.style.width = '15px'
          boxSpan.style.marginRight = '10px'

          const textContainer = document.createElement('p')
          textContainer.style.color = item.fontColor?.toString()!
          textContainer.style.margin = '0'
          textContainer.style.padding = '0'
          textContainer.style.textDecoration = item.hidden ? 'line-through' : ''
          textContainer.appendChild(document.createTextNode(item.text))

          li.appendChild(boxSpan)
          li.appendChild(textContainer)

          li.onclick = () => {
            const i = (item as any).index
            const exitIndex = exitedTextList.indexOf(i)
            if (exitIndex !== -1) exitedTextList.splice(exitIndex, 1)
            else exitedTextList.push(i)
            const dataset = chart.data.datasets[0].data
            let exitedCount = 0
            exitedTextList.forEach((e) => {
              exitedCount += (dataset[e] as number) || 0
            })

            updatedCount = (chart.data.datasets[0] as any).count - exitedCount
            if (updatedCount < 0) {
              updatedCount = 0
            }
            chart.toggleDataVisibility(index)
            item.hidden = !item.hidden
            const inv_group = _.groupBy((chart.data.datasets[0] as any).investors, (data) => [
              data.id,
            ])
            invList = Object.keys(inv_group)
            chart.update()
          }

          ul.appendChild(li)
        })

        const jsLegend = document.getElementById('chart-legend-' + props.header.replaceAll(' ', ''))
        jsLegend!.innerHTML = ''
        jsLegend!.appendChild(ul)
      },
    },
  ]
  return (
    <div className='card h-100 card-custom'>
      <div className='col d-flex justify-content-between mt-5 mx-5'>
        <h1>Portfolio {props.header}</h1>
        <button
          className='btn btn-sm btn-icon'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={props.handleClose}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </button>
      </div>

      <div className='card-body min-h-250px mh-250px'>
        <Doughnut options={optionsSeries} data={props.data} plugins={plugins} />
      </div>
      <div className='h-150px' id={'chart-legend-' + props.header.replaceAll(' ', '')}></div>
    </div>
  )
}

export default DonutWithCloseButton
