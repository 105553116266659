import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgotPassword`
export const RESET_PASSWORD_URL= `${API_URL}/auth/resetpassword`
// const headers = {'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': GET_USER_BY_ACCESSTOKEN_URL}

// Server should return AuthModel
export function login(emailId: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    emailId,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(emailId: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    emailId,
  })
}

export function resetPassword(emailId:string,password:string,confirmPassword: string,jwt:string){
  return axios.post<{result:boolean}>(RESET_PASSWORD_URL,{
    emailId,
    password,
    confirmPassword,
  },
  {headers:{'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': jwt},
data:{'emailId': emailId, 'newPassword': password, 'confirmPassword': confirmPassword}},
  
  )
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
