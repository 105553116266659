/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { login } from '../../../../api/authentication'
import { useAuth } from '../core/Auth'
import { LoginRequest } from '../../../../types/Login'
import { graphqlRequestClient } from '../../../../queries/client'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import jwtDecode from 'jwt-decode'
import { JwtPayload } from '../core/_models'
import { getUserPreferences, setUserPreferences } from '../../../../utils/LocalStorageUtils'
import { UserPreferenceType } from '../../../../types/UserPreferences'
import { FilterType } from '../../../pages/store/context/reducer'

const loginSchema = Yup.object().shape({
  emailId: Yup.string()
    .email('Wrong email format')
    .max(50, 'Maximum 50 symbols')
    .required('Please enter valid email'),
  password: Yup.string()
    .min(6, 'Password length should be atleast 6')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues: LoginRequest = {
  emailId: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const { saveAuth } = useAuth()

  function showPassword() {
    setShow(!show)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const data = await login(values)
        const response = jwtDecode<JwtPayload>(data.data)
        saveAuth(data.data)
        graphqlRequestClient.setHeader('authorization', data.data)
        const userId = response.id!
        // check if userPref is already present in localStorage
        const userPref = getUserPreferences(userId)
        if (!userPref) {
          // add to localStorage if not present
          const newUserPreferences: UserPreferenceType = {
            id: userId,
            dashboardMetrics: [],
            portfolioMetrics: ['Net Profit', 'Current Value', 'Gain', 'MOIC', 'Industries'],
            selectedInvestors: [],
            selectedGroups: [],
            filterType: FilterType.fund,
          }
          setUserPreferences(newUserPreferences)
        }
      } catch (error: any) {
        saveAuth(undefined)
        setStatus(error?.response?.data?.errors)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-350px fv-plugins-bootstrap5 fv-plugins-framework mt-0 p-5'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='fw-bold display-7'>Sign In</h1>
        <div className='text-muted fs-5' hidden>
          Or create{' '}
          <Link to={''} className='link-primary fw-bold'>
            an Account
          </Link>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label fw-bold'>Email</label>
        <input
          placeholder='Email Address'
          {...formik.getFieldProps('emailId')}
          className={clsx('form-control form-control-lg form-control-solid')}
          type='email'
          name='emailId'
          autoComplete='off'
        />
        {formik.touched.emailId && formik.errors.emailId && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon-primary svg-icon-1 me-2'
                />
                {formik.errors.emailId}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'></div>
        </div>
        <div className='position-relative mb-3'>
          <div className='d-flex justify-content-between'>
            <label className='form-label fw-bold'>Password</label>
          </div>
          <input
            placeholder='Password'
            type={`${show === false ? 'password' : 'text'}`}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx('form-control form-control-lg form-control-solid w-100')}
          />
          <span
            className='btn btn-icon position-absolute translate-middle end-0 top-50 pt-9 ps-12 '
            onClick={() => showPassword()}
          >
            {show === false ? (
              <i className='bi bi-eye-slash f-2'></i>
            ) : (
              <i className='bi bi-eye f-2'></i>
            )}
          </span>
        </div>
        <div className='d-flex justify-content-end'>
          <h6>
            <Link
              to='/auth/forgot-password'
              className='link-primary fw-bold'
              style={{ marginLeft: '5px' }}
            >
              Forgot Password?
            </Link>
          </h6>
        </div>
        {formik.touched.password && formik.errors.password ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon-primary svg-icon-1 me-2'
                />
                {formik.errors.password}
              </span>
            </div>
          </div>
        ) : (
          formik.status && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className='svg-icon-primary svg-icon-1 me-2'
                  />
                  {formik.status}
                </span>
              </div>
            </div>
          )
        )}
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className={
            'btn btn-lg mb-5 ' +
            (!formik.isValid || (formik.values.emailId === '' && formik.values.password === '')
              ? 'btn-dark'
              : 'btn-primary')
          }
          disabled={
            formik.isSubmitting ||
            !formik.isValid ||
            (formik.values.emailId === '' && formik.values.password === '')
          }
        >
          {!loading && <span className='indicator-label fw-bold'>Sign In</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* <div className='mb-20 p-5'></div> */}
      <div className='text-center' hidden>
        <a
          href='#'
          className='btn btn-flex btn-outline btn-text btn-primary bg-state-light flex-center text-nowrap w-80'
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-15px me-3'
          />
          Or Sign in with Google
        </a>
      </div>
    </form>
  )
}
