import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useTermsheetBuilderStore } from '../../../../../store/TermsheetBuilderStore'

function InvestorRights() {
  const builder = useTermsheetBuilderStore()

  return (
    <div className='card shadow-sm mt-4 p-8'>
      <div className='row align-items-center'>
        <h3 className='col fw-bold pb-4'>Investor Rights</h3>
        <div className='col text-end'>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => builder.removeSection('Investor Rights')}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </div>
      </div>
      <div className='row align-items-start pb-4'>
        <div className='col-12 mb-2'>
          <h5>Investor to have the following rights:</h5>
        </div>
        <div className='col-12 col-md-6'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='checkbox'
                name='investorRights'
                value='1'
                checked={true}
                readOnly
              />
            </span>
            <h4 className='text-start'>Affirmative vote rights over all major decisions</h4>
          </label>
        </div>
        <div className='col-12 col-md-6'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='checkbox'
                name='investorRights'
                value='2'
                disabled
              />
            </span>
            <h4 className='text-start'>Pre-emption right</h4>
          </label>
        </div>
        <div className='col-12 col-md-6'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='checkbox'
                name='investorRights'
                value='3'
                disabled
              />
            </span>
            <h4 className='text-start'>Information and inspection rights</h4>
          </label>
        </div>
        <div className='col-12 col-md-6'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='checkbox'
                name='investorRights'
                value='4'
                disabled
              />
            </span>
            <h4 className='text-start'>No restrictions on transfer of shares or rights</h4>
          </label>
        </div>
        <div className='col-12 col-md-6'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='checkbox'
                name='investorRights'
                value='5'
                disabled
              />
            </span>
            <h4 className='text-start'>
              All additional rights that any future investor is offered in the Company
            </h4>
          </label>
        </div>
        <div className='col-12 col-md-6'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='checkbox'
                name='investorRights'
                value='custom'
                disabled
              />
            </span>
            <h4 className='text-start'>Custom</h4>
          </label>
        </div>
      </div>
      <div className='row align-items-start pb-4'>
        <div className='col-12 mb-2'>
          <h5>Anti dilution</h5>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='antidilution'
                value='full'
                checked={true}
                readOnly
              />
            </span>
            <h4 className='text-start'>Full-Rachet</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='antidilution'
                value='narrow'
                disabled
              />
            </span>
            <h4 className='text-start'>Narrow-based weighted average</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='antidilution'
                value='broad'
                disabled
              />
            </span>
            <h4 className='text-start'>Broad-based weighted average</h4>
          </label>
        </div>
      </div>
    </div>
  )
}

export default InvestorRights
