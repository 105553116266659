import * as React from 'react'
import { createContext, useReducer } from 'react'
import { ActionType, getInitialState, reducer, StateType } from './reducer'

export type GlobalContextType = {
  state: StateType
  dispatch: React.Dispatch<ActionType>
}

type Props = {
  ctxUserId: number
  ctxInvestorIds: number[]
  ctxGroupIds: number[]
  filterType: 'Fund' | 'Group' | 'All'
  children?: React.ReactNode
}

export const GlobalContext = createContext<GlobalContextType>({
  state: getInitialState(),
  dispatch: () => null,
})

function GlobalContextProvider(props: Props) {
  const [state, dispatch] = useReducer(reducer, getInitialState())
  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
