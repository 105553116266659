import { useLocation } from 'react-router-dom'

export function MenuInner() {
  let { pathname } = useLocation()
  pathname = pathname.toLowerCase().split('/')[1]
  type menuMappingType = {
    [key: string]: string
  }
  const asideMenuMapping: menuMappingType = {
    dashboard: 'Dashboard',
    portfolio: 'Portfolios',
    watchlist: 'Watchlist',
    deals: 'Deals',
    company: 'Company Profile',
  }
  const headerText =
    pathname in asideMenuMapping ? asideMenuMapping[pathname] : pathname.toUpperCase()
  return (
    <>
      <h1 className='d-flex align-items-center my-1 display-7 '>{headerText}</h1>
    </>
  )
}
