import { FC, useState } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import { countryList } from '../../../../../../../utils/CountryList'
import { ISendTermsheet } from '../../SendTermsheetHelper'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../../../utils/Select'

const Miscellaneous: FC = () => {
  const formik = useFormikContext<ISendTermsheet>()
  const countryData: any[] = []
  const [countryName, setCountryName] = useState<any>({
    value: formik.values.country,
    label: formik.values.country,
  })

  countryList.map((country) => {
    countryData.push({ value: country, label: country })
  })
  return (
    <div className='w-100'>
      <div className='pb-10'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Miscellaneous</h2>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Due Diligence</label>

          <div className=''>
            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
              <Field
                className='form-check-input h-20px w-40px'
                type='checkbox'
                name='diligence'
                defaultChecked
              />
              <label className='form-check-label'>{formik.values.diligence ? 'Yes' : 'No'}</label>
            </div>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='diligence' />
          </div>
        </div>
      </div>

      <div className='fv-row mb-6'>
        <label className='form-label required mb-3'>Exclusivity (months)</label>

        <Field
          type='number'
          className='form-control form-control-lg form-control-solid'
          name='exclusivity'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='exclusivity' />
        </div>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <label className='form-label required'>Transaction costs - to be borne by</label>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='transactionCost'
                  value='Company'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Company</h5>
                </span>
              </span>
            </label>
          </div>

          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='transactionCost'
                  value='Each Party'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Each Party</h5>
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='transactionCost' />
          </div>
        </div>
      </div>

      <div className='fv-row mb-6'>
        <label className='form-label required'>Governing Law</label>
        <Select
          className=''
          options={countryData}
          name='country'
          placeholder={'India'}
          menuPlacement='auto'
          onChange={(e: any) => (setCountryName(e), formik.setFieldValue('country', e.value))}
          value={countryName}
          styles={selectCustomStyles}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='country' />
        </div>
      </div>
    </div>
  )
}

export default Miscellaneous
