import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from 'chart.js'
import 'chartjs-adapter-moment'
import { Line } from 'react-chartjs-2'
import { Transaction } from '../../../../generated/graphql'
import { LineChartData } from '../../../../types/chartData'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler, TimeScale)

type props = {
  investorNames: string[]
  allInvestments?: any[]
  exitData?: Transaction[] | any[]
  companies?: string[]
}

type InvestmentRecord = {
  name: string
  roundName: string
  noOfShares: number
  investedAmount: number
  dateInvested: string
  round: string
}

type CompanyWiseData = {
  name: string
  investmentData: [InvestmentRecord]
}

function LineChart(props: props) {
  const options = {
    elements: {
      point: {
        radius: 2,
      },
    },
    plugins: {
      responsive: true,
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Total Invested over Time',
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Investments',
        },
        grid: {
          display: false,
        },
      },
      x: {
        type: 'time' as const,
        time: {
          unit: 'year' as const,
        },
        distribution: 'linear',
        title: {
          display: true,
          text: 'Date',
        },
        grid: {
          display: false,
        },
      },
    },
  }
  const optionsForManyCompanies = {
    elements: {
      point: {
        radius: 2,
      },
    },
    plugins: {
      responsive: false,
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Total Invested over Time',
      },
    },
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Investments',
        },
        grid: {
          display: false,
        },
      },
      x: {
        type: 'time' as const,
        time: {
          unit: 'year' as const,
        },
        distribution: 'linear',
        title: {
          display: true,
          text: 'Date',
        },
        grid: {
          display: false,
        },
      },
    },
  }
  const companyWiseRecords: CompanyWiseData[] = []
  props.allInvestments?.forEach((companyData) => {
    const comp: CompanyWiseData = {
      name: companyData['companyData']['name'].split(' ')[0],
      investmentData: companyData['investmentData'],
    }
    if (props.companies !== undefined && props.companies.length > 0) {
      if (props.companies?.includes(companyData['companyData']['name']))
        companyWiseRecords.push(comp)
    } else {
      companyWiseRecords.push(comp)
    }
  })

  const investmentsRecs: InvestmentRecord[] = []
  let investments: [InvestmentRecord][] = []

  companyWiseRecords.forEach((companyData) => {
    if (props.companies !== undefined && props.companies.length > 0) {
      if (props.companies.some((company) => company?.includes(companyData.name))) {
        investments.push(companyData.investmentData)
      }
    } else {
      investments.push(companyData.investmentData)
    }
  })

  investments.forEach((investments) => {
    investments.forEach((investment) => {
      if (props.investorNames.includes(investment.name)) {
        investmentsRecs.push(investment)
      }
    })
  })

  const lineChartData: LineChartData[] = []
  const exitData: Transaction[] = props.exitData as Transaction[]
  exitData.forEach((t) => {
    if (t.transactionDate && t.noOfShares && t.pricePerShare && t.companyName) {
      let amount = Number(t.pricePerShare) * Number(t.noOfShares)
      if (t.transactionType == 'SELL') {
        amount = -1 * amount
      }
      const record: InvestmentRecord = {
        name: t.investorName!,
        roundName: t.roundName!,
        noOfShares: Number(t.noOfShares),
        investedAmount: amount,
        dateInvested: t.transactionDate,
        round: t.roundName!,
      }

      if (props.companies !== undefined && props.companies.length > 0) {
        if (props.companies?.includes(t.companyName)) {
          investmentsRecs.push(record)
        }
      } else {
        if (t.transactionDate && t.noOfShares && t.pricePerShare) {
          investmentsRecs.push(record)
        }
      }
    }
  })

  investmentsRecs.sort((a, b) => a.dateInvested.localeCompare(b.dateInvested))

  let sum: number = 0
  investmentsRecs.forEach((investment) => {
    const item: LineChartData = {
      x: investment.dateInvested.split('T')[0],
      y: sum + investment.investedAmount,
    }
    sum += investment.investedAmount
    lineChartData.push(item)
  })

  lineChartData.sort((a, b) => a.x.localeCompare(b.x))

  const finalChartData: any[] = [
    {
      label: 'Invested over time',
      data: lineChartData,
      tension: 0.2,
      fill: true,
      backgroundColor: getCSSVariableValue('--kt-secondary-active'),
      borderColor: getCSSVariableValue('--kt-primary'),
    },
  ]
  const gradientPlugin = [
    {
      id: 'custom_canvas_background_color',
      beforeDraw: (chart: ChartJS) => {
        const { ctx } = chart
        ctx.save()
        ctx.globalCompositeOperation = 'destination-over'

        let gradient = ctx.createLinearGradient(0, 0, 0, 300)
        gradient.addColorStop(0, 'rgba(224, 195, 155, 1)')
        gradient.addColorStop(1, 'rgba(100, 100, 0,0)')
        ctx.fillStyle = gradient
        ctx.restore()
      },
    },
  ]

  const dataForChart = {
    datasets: finalChartData,
  }

  return companyWiseRecords.length > 40 ? (
    <Line
      options={optionsForManyCompanies}
      data={dataForChart}
      plugins={gradientPlugin}
      width={1200}
      height={400}
    />
  ) : (
    <Line options={options} data={dataForChart} plugins={gradientPlugin} />
  )
}

export default LineChart
