import React from 'react'

function DonutDropdown(props: any) {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px py-3'
      data-kt-menu='true'
    >
      <label
        key={'By Industry'}
        className='ms-3 form-check form-check-sm form-check-custom form-check-solid me-5 px-3 pt-3 py-3'
      >
        <input
          className='form-check-input'
          type='checkbox'
          value={'By Industry'}
          checked={props.showIndustryDonut}
          onChange={(e) => {
            props.handleIndustryChange()
          }}
        />
        <span className='form-check-label fw-normal fs-base ms-3'>{'By Industry'}</span>
      </label>
      <label
        key={'By Investment'}
        className='ms-3 form-check form-check-sm form-check-custom form-check-solid me-5 px-3 pt-3 py-3'
      >
        <input
          className='form-check-input'
          type='checkbox'
          value={'By Investment'}
          checked={props.showInvestmentDonut}
          onChange={(e) => {
            props.handleInvestmentChange()
          }}
        />
        <span className='form-check-label fw-normal fs-base ms-3'>{'By Investment'}</span>
      </label>
    </div>
  )
}

export default DonutDropdown
