import { KTSVG } from '../../../../../../../_metronic/helpers/components/KTSVG'
import { useTermsheetBuilderStore } from '../../../../../store/TermsheetBuilderStore'

function GovernanceRights() {
  const builder = useTermsheetBuilderStore()

  return (
    <div className='card shadow-sm mt-4 p-8'>
      <div className='row align-items-center'>
        <h3 className='col fw-bold pb-4'>Governance Rights</h3>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-12 mb-2'>
          <h5>Director Board Composition</h5>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='director'
                value='0'
                checked={true}
                readOnly
              />
            </span>
            <h4>None</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input className='form-check-input' type='radio' name='director' value='1' disabled />
            </span>
            <h4>1</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input className='form-check-input' type='radio' name='director' value='2' disabled />
            </span>
            <h4>2</h4>
          </label>
        </div>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-12 mb-2'>
          <h5>Observer Board Composition</h5>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='observer'
                value='0'
                checked={true}
                readOnly
              />
            </span>
            <h4>None</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input className='form-check-input' type='radio' name='observer' value='1' disabled />
            </span>
            <h4>1</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input className='form-check-input' type='radio' name='observer' value='2' disabled />
            </span>
            <h4>2</h4>
          </label>
        </div>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-12 mb-2'>
          <h5>Quorum Right</h5>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='none'
                value='none'
                checked={true}
                readOnly
              />
            </span>
            <h4>None</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='quorum'
                value='board'
                disabled
              />
            </span>
            <h4>Board Meeting</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='quorum'
                value='shareholders'
                disabled
              />
            </span>
            <h4>Shareholders Meeting</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='quorum'
                value='both'
                disabled
              />
            </span>
            <h4>Both</h4>
          </label>
        </div>
      </div>
    </div>
  )
}

export default GovernanceRights
