import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import CloseButton from 'react-bootstrap/esm/CloseButton'
import { KTSVG } from '../../../../_metronic/helpers'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Please enter valid email'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({ data: { result } }) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <div>
      <form
        className='form w-350px fv-plugins-bootstrap5 fv-plugins-framework mt-0 p-5'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center'>
          <h1 className='fw-bold display-7'>Forgot Password ?</h1>
          <h6 className='text-muted m-8'>
            Enter the email you used when you joined and we'll send you a password reset link.
          </h6>
          {hasErrors === false && (
            <div className='fv-plugins-message-container'>
              <h6 className='text-primary fw-bold mb-5' role='alert'>
                Password reset is sent. Please check your email
              </h6>
            </div>
          )}
          <div className='fv-row mb-10 text-start'>
            <label className='form-label fw-bold'>Email</label>
            <input
              placeholder='Email Address'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen044.svg'
                      className='svg-icon-primary svg-icon-1 me-2'
                    />
                    {formik.errors.email}
                  </span>
                </div>
              </div>
            ) : (
              hasErrors === true && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block ' role='alert'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen044.svg'
                      className='svg-icon-primary svg-icon-1 me-2'
                    />
                    <span>Email does not exist</span>
                  </div>
                </div>
              )
            )}
          </div>
          <div className='d-flex justify-content-evenly'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-lg btn-primary'>
              {!loading && <span className='indicator-label fw-bold'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light fw-bolder btn-active-light-primary me-2 '
                disabled={formik.isSubmitting || !formik.isValid}
              >
                <span className='fw-bold'>Cancel</span>
              </button>
            </Link>
          </div>
        </div>
      </form>
    </div>
  )
}
