/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../../_metronic/helpers/components/KTSVG'

type Props = {
  investmentType: string
}

const Step1: FC<Props> = (props) => {
  return (
    <div className='w-100 mx-15 '>
      <div className='pb-10'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Select Investment Method</h2>
        <div className='text-gray-400 fw-bold fs-6'>
          If know more about the investment methods, please check our{' '}
          <a
            href='https://www.rulezero.com/faq'
            target='_blank'
            rel='noreferrer'
            className='link-primary fw-bolder'
          >
            {'FAQ Page'}
          </a>
          .
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <Field
              type='radio'
              className='btn-check'
              name='investmentType'
              value='captableUpload'
              id='kt_select_manual_investment_method_cap'
            />
            <label
              className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10
              ${props.investmentType === 'captableUpload' && 'bg-primary bg-opacity-10'}`}
              htmlFor='kt_select_manual_investment_method_cap'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com006.svg'
                className={`svg-icon-3x me-5 ${
                  props.investmentType === 'captableUpload' ? 'text-primary' : ''
                }`}
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Upload CapTable</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Upload a CSV file containing multiple investment records
                  {/* <a
                    href='https://www.rulezero.com/faq'
                    target='_blank'
                    rel='noreferrer'
                    className='link-primary fw-bolder'
                  >
                    {'FAQ Page'}
                  </a>
                  . */}
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-12 '>
            <Field
              type='radio'
              className='btn-check'
              name='investmentType'
              value='manual'
              id='kt_select_manual_investment_method_manual'
            />
            <label
              className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center
              ${props.investmentType === 'manual' && 'bg-primary bg-opacity-10'}`}
              htmlFor='kt_select_manual_investment_method_manual'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen025.svg'
                className={`svg-icon-3x me-5 ${
                  props.investmentType === 'manual' ? 'text-primary' : ''
                }`}
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Manual Investment</span>
                <span className='text-gray-400 fw-bold fs-6'>Add a Manual Investment. </span>
              </span>
            </label>
          </div>

          <div className='fs-6 mt-2'>
            <ErrorMessage name='investmentType' />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step1 }
