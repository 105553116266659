import * as Yup from 'yup'

export interface IEmailTermsheet {
  companyEmails: string
  investorsEmails?: string
  emailType: 'companyEmails' | 'investorsEmails'
  isSecondFieldAdded: boolean
}

const emailTermsheetSchema = Yup.object({
  companyEmails: Yup.string().required('Company Email is a required field'),
  investorsEmails: Yup.string()
    .optional()
    .when('isSecondFieldAdded', {
      is: (field: boolean) => field === true,
      then: Yup.string().required('Co-Investors Email is a required field'),
      otherwise: Yup.string().optional(),
    }),
  emailType: Yup.string()
    .required('Email Type is a required field')
    .oneOf(['companyEmails', 'investorsEmails'], 'Email Type is a required field'),
  isSecondFieldAdded: Yup.boolean().required(),
})

const inits: IEmailTermsheet = {
  companyEmails: '',
  investorsEmails: '',
  emailType: 'companyEmails',
  isSecondFieldAdded: false,
}

export { emailTermsheetSchema, inits }
