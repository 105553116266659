import { lazy, Suspense } from 'react'

const PdfDocument = lazy(() => import('./PdfDocument'))

function LazyPdfDocument({ url }: { url: string }) {
  return (
    <Suspense fallback={<div>Document loading...</div>}>
      <PdfDocument url={url} />
    </Suspense>
  )
}

export default LazyPdfDocument
