import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useTermsheetBuilderStore } from '../../../../../store/TermsheetBuilderStore'

function ExitRights() {
  const builder = useTermsheetBuilderStore()

  return (
    <div className='card shadow-sm mt-4 p-8'>
      <div className='row align-items-center'>
        <h3 className='col fw-bold pb-4'>Exit Rights</h3>
      </div>
      <div className='row align-items-start pb-4'>
        <div className='col-12 col-md-6 '>
          <div className='col mb-12'>
            <h5>Investor to have following exit rights:</h5>
          </div>
          <div className='col-12'>
            <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid me-4'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='exitRights'
                  value='IPO'
                  checked={true}
                  readOnly
                />
              </span>
              <h4>IPO</h4>
            </label>
          </div>
          <div className='col-12'>
            <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid me-4'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='exitRights'
                  value='Strategic Sales'
                  disabled
                />
              </span>
              <h4 className='me-4'>Strategic Sales</h4>
            </label>
          </div>
          <div className='col-12'>
            <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid me-4'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='exitRights'
                  value='Buyback'
                  disabled
                />
              </span>
              <h4 className='me-4'>Buyback</h4>
            </label>
          </div>
          <div className='col-12'>
            <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid me-4'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='exitRights'
                  value='Drag Along'
                  disabled
                />
              </span>
              <h4 className='me-4'>Drag Along</h4>
            </label>
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className='col mb-12'>
            <h5>Consequences of event of default:</h5>
          </div>
          <div className='col-12'>
            <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid me-4'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='consequences'
                  value='Call/put Option'
                  checked={true}
                />
              </span>
              <h4>Call/put Option</h4>
            </label>
          </div>
          <div className='col-12'>
            <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid me-4'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='consequences'
                  value='Accelerated Exit'
                  disabled
                />
              </span>
              <h4>Accelerated Exit</h4>
            </label>
          </div>
          <div className='col-12'>
            <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid me-4'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='consequences'
                  value='None'
                  disabled
                />
              </span>
              <h4>None</h4>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExitRights
