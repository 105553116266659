import React, { useEffect, useRef, useState } from 'react'

import { Formik, Form, FormikValues } from 'formik'
import { Modal } from 'react-bootstrap'

import { Step1 } from './stepper/Step1'
import { Step3 } from './stepper/Step3'
import { addInvestmentSchemas, IAddInvestmentData, investmentInits } from './AddInvestmentHelper'

import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../_metronic/helpers'
import {
  useAddInvestmentInputMutation,
  AddInvestmentInputMutation,
  AddInvestmentInputMutationVariables,
  useGetAllInvestmentsQuery,
  useGetInvestorsQuery,
  useGetInvestmentByStageQuery,
} from '../../../../../generated/graphql'
import { Step2 } from './stepper/Step2'
import Step4 from './stepper/Step4'
import Step5 from './stepper/Step5'
import Toast, { ToastType } from '../../../../../utils/toast'

type Props = {
  showModal: boolean
  investorList: any[]
  handleClose: () => void
}

const AddInvestmentModal = ({ showModal, handleClose, investorList }: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [currentSchema, setCurrentSchema] = useState(addInvestmentSchemas[0])
  const [initValues] = useState<IAddInvestmentData>(investmentInits)
  const [isSubmitButton, setSubmitButton] = useState<Boolean>(false)
  const mutateAddInvestment = useAddInvestmentInputMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddInvestmentInputMutation,
        _variables: AddInvestmentInputMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetInvestorsQuery.getKey())
        queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())
        handleClose()
        destroyStepper()
        Toast('Investment Added Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  function handleAddInvestment(data: IAddInvestmentData) {
    mutateAddInvestment.mutate({
      input: {
        cin: data.companyId,
        currency: data.currency.slice(0, 3),
        currentSharePrice: parseFloat(data.currentSharePrice),
        industry: data.industryType,
        investmentDate: new Date(data.investmentDate).toISOString(),
        investorId: data.investorId,
        name: data.companyName,
        noOfShares: parseInt(data.noOfShares),
        round: data.roundDetails,
        valuation: parseFloat(data.valuation),
        currentExchangeRate:
          data.currentExchangeRate == '' ? 1 : parseFloat(data.currentExchangeRate),
        exchangeRate: data.exchangeRate == '' ? 1 : parseFloat(data.exchangeRate),
        jurisdiction: data.country,
        sharePrice: parseFloat(data.sharePrice),
      },
    })
  }

  function setSubmitButtonState() {
    setSubmitButton(true)
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const destroyStepper = () => {
    prevStep()
    setSubmitButton(false)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex === 4) {
      stepper.current.goPrev()
      stepper.current.goPrev()
    } else if (stepper.current.currentStepIndex === 5) {
      stepper.current.goPrev()
      stepper.current.goPrev()
      stepper.current.goPrev()
      stepper.current.goPrev()
    } else {
      stepper.current.goPrev()
    }
    setCurrentSchema(addInvestmentSchemas[stepper.current.currentStepIndex - 1])
    setSubmitButton(false)
  }

  const submitStep = (values: IAddInvestmentData, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex === 2) {
      setSubmitButtonState()
    }

    setCurrentSchema(addInvestmentSchemas[stepper.current.currentStepIndex])
    if (values.investmentType === 'captableUpload') {
      if (stepper.current.currentStepIndex === 5) {
        setLoading(true)
        setTimeout(function () {
          handleClose()
          destroyStepper()
          setLoading(false)
          queryClient.invalidateQueries(useGetInvestmentByStageQuery.getKey())
        }, 500)
      } else {
        if (stepper.current.currentStepIndex === 1) {
          stepper.current.goto(5)
          setSubmitButtonState()
        }
      }
    }

    if (values.investmentMethod === 'bulk') {
      if (stepper.current.currentStepIndex === 4) {
        setLoading(true)
        setTimeout(function () {
          handleClose()
          destroyStepper()
          setLoading(false)
          queryClient.invalidateQueries(useGetInvestmentByStageQuery.getKey())
        }, 500)
      } else {
        if (stepper.current.currentStepIndex === 2) {
          stepper.current.goto(4)
          setCurrentSchema(addInvestmentSchemas[4])
        } else {
          stepper.current.goNext()
        }
      }
    } else {
      if (stepper.current.currentStepIndex < 3) {
        stepper.current.goNext()
      } else if (stepper.current.currentStepIndex === 3) {
        setLoading(true)
        setTimeout(function () {
          handleClose()
          setLoading(false)
        }, 2000)
        handleAddInvestment(values)
        queryClient.invalidateQueries(useGetInvestmentByStageQuery.getKey())
      }
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered ${
        !stepper.current || [1, 2, 4, 5].includes(stepper?.current?.currentStepIndex!)
          ? ''
          : 'mw-900px'
      }`}
      show={showModal}
      onHide={() => {
        handleClose()
        destroyStepper()
      }}
      onEntered={loadStepper}
    >
      <div className='col d-flex justify-content-end mt-2 me-2'>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary me-1'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={() => {
            handleClose()
            destroyStepper()
          }}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>
      <Modal.Title className=''>
        <div className='card card-p-0 p-0'>
          <div className='card-body'>
            <div
              ref={stepperRef}
              className='stepper stepper-links d-flex flex-column'
              id='kt_modal_add_inv_stepper'
            >
              <div className='d-none stepper-nav mb-5'>
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title '>Select Investment Method</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Select method of Investment</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Add Manual Investment</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Add Bulk Investment</h3>
                </div>
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Add captable Investment</h3>
                </div>
              </div>

              <Formik
                validationSchema={currentSchema}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initValues}
                onSubmit={submitStep}
              >
                {({ values, handleChange }) => (
                  <Form className='mx-4 pb-10' id='kt_create_account_form'>
                    <div className='current' data-kt-stepper-element='content'>
                      <Step1 investmentType={values.investmentType} />
                    </div>
                    <div data-kt-stepper-element='content'>
                      <Step2 investmentMethod={values.investmentMethod} />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step3 investorList={investorList} handleChange={handleChange} />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step4 />
                    </div>
                    <div data-kt-stepper-element='content'>
                      <Step5 />
                    </div>

                    <div className='d-flex flex-stack pt-15 px-15'>
                      <div className='mr-2'>
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-secondary me-3'
                          data-kt-stepper-action='previous'
                          disabled={loading}
                        >
                          Back
                        </button>
                      </div>

                      <div>
                        <button type='submit' className='btn btn-lg btn-primary' disabled={loading}>
                          {!loading && (
                            <span className='indicator-label'>
                              {!isSubmitButton
                                ? 'Next'
                                : isSubmitButton && values.investmentType === 'captableUpload'
                                ? 'Done'
                                : isSubmitButton && values.investmentMethod === 'single'
                                ? 'Add Investment'
                                : 'Done'}
                            </span>
                          )}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Title>
    </Modal>
  )
}

export { AddInvestmentModal }
