import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useEmailTermsheetMutation } from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { KTSVG } from '../../../../_metronic/helpers'
import { emailTermsheetSchema, IEmailTermsheet, inits } from './EmailTermsheetHelper'

type Props = {
  showModal: boolean
  handleClose: () => void
  termsheetId: number
}

function EmailTermsheet({ handleClose, showModal, termsheetId }: Props) {
  const [showInvestors, setShowInvestors] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState('0')
  const [success, setSuccess] = useState(false)

  const mutateEmailTermsheet = useEmailTermsheetMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data) => {
        console.log(data)
        setSuccess(true)
        handleClose()
        setSuccess(false)
      },
    },
    {}
  )

  const handleSubmit = (values: IEmailTermsheet) => {
    mutateEmailTermsheet.mutate({
      input: {
        companyEmail: values.companyEmails === '' ? [] : values.companyEmails.split(';'),
        investorEmail: values.investorsEmails === '' ? [] : values.investorsEmails?.split(';'),
        termsheetId: Number(termsheetId),
      },
    })
    console.log(values)
  }

  return (
    <Modal
      id='kt_modal_email_termsheet'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px '
      show={showModal}
      onHide={handleClose}
    >
      <div className='col d-flex justify-content-between ps-10 pe-6 pb-6'>
        <div className='pt-10'>
          <h2 className='fw-bolder text-dark'>Email Termsheet</h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-6'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>
      <div className='separator '></div>

      <Modal.Title>
        <div className='p-10'>
          <div className='card-body'>Enter Email ID’s to whom termsheet is to be sent</div>
          <Formik
            validationSchema={emailTermsheetSchema}
            initialValues={inits}
            onSubmit={handleSubmit}
          >
            {({ errors, values, setFieldValue, getFieldProps, handleChange }) => {
              console.log(errors)
              return (
                <Form>
                  <div className='d-flex mt-8 align-items-start'>
                    <div>
                      <h5>Email ID</h5>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid w-400px me-4'
                        placeholder='Enter Email'
                        name='companyEmails'
                      />
                      <div className='text-danger fs-7 my-4'>
                        <ErrorMessage name='companyEmails' className=' fs-7 text-danger' />
                      </div>
                      <div className='form-text'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen044.svg'
                          className='svg-icon-primary svg-icon-2x me-2'
                        />{' '}
                        Separate multiple Email IDs with a semicolon
                      </div>
                    </div>
                    <div>
                      <h5>Receiver Type</h5>
                      <Field
                        type='select'
                        as='select'
                        className='form-select form-select-lg form-select-solid w-300px'
                        onChange={(e: any) => {
                          handleChange(e)
                          setSelectedOption(e.currentTarget.value)
                        }}
                        name='emailType'
                      >
                        <option key='0' value='0'>
                          Select Type of Receiver
                        </option>
                        <option key='companyEmails' value='companyEmails'>
                          Company
                        </option>
                        <option key='investorsEmails' value='investorsEmails'>
                          Co-Investors
                        </option>
                      </Field>
                      <div className='text-danger fs-7 my-4'>
                        <ErrorMessage name='emailType' className=' fs-7 text-danger' />
                      </div>
                    </div>
                  </div>
                  {showInvestors && (
                    <div className='d-flex pt-4 align-items-start'>
                      <div>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid w-400px me-4'
                          placeholder='Enter Email'
                          name='investorsEmails'
                        />
                        <div className='text-danger fs-7 my-4'>
                          <ErrorMessage name='investorsEmails' className=' fs-7 text-danger' />
                        </div>
                        <div className='form-text'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-primary svg-icon-2x me-2'
                          />{' '}
                          Separate multiple Email IDs with a semicolon
                        </div>
                      </div>

                      <select
                        className='form-select form-select-lg form-select-solid w-300px'
                        value={
                          selectedOption === 'companyEmails'
                            ? 'investorsEmails'
                            : selectedOption === 'investorsEmails'
                            ? 'companyEmails'
                            : ''
                        }
                        disabled
                      >
                        <option key='0' value='0'>
                          Select Type of Receiver
                        </option>
                        <option key='companyEmails' value='companyEmails'>
                          Company
                        </option>
                        <option key='investorsEmails' value='investorsEmails'>
                          Co-Investors
                        </option>
                      </select>
                    </div>
                  )}

                  <div className='pt-4'>
                    <button
                      type='button'
                      className='btn border border-primary fs-base fw-bold '
                      onClick={() => {
                        setShowInvestors(!showInvestors)
                        if (!showInvestors) {
                          setFieldValue('isSecondFieldAdded', true)
                          if (selectedOption === 'companyEmails') {
                            setFieldValue('investorsEmails', '')
                          } else {
                            setFieldValue('companyEmails', '')
                          }
                        } else {
                          setFieldValue('isSecondFieldAdded', false)
                        }
                      }}
                    >
                      {!showInvestors ? 'ADD +' : 'REMOVE -'}
                    </button>
                  </div>
                  <div className='d-flex justify-content-between pt-15'>
                    <button
                      className='btn btn-lg btn-secondary me-3'
                      type='button'
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={mutateEmailTermsheet.isLoading}
                    >
                      {!mutateEmailTermsheet.isLoading && 'Send'}
                      {mutateEmailTermsheet.isLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Sending...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                      {success && 'Sent'}
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal.Title>
    </Modal>
  )
}

export default EmailTermsheet
