import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useGetUrlForS3ObjectQuery, GetUrlForS3ObjectQuery } from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { kFormatterRupee } from '../../../../utils/CurrencyFormatter'
import { isoToDate } from '../../../../utils/FormatDate'
import { hexToRGB } from '../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../_metronic/helpers'
import { PDFModalViewer } from '../../company/components/TabViews/PDFModalViewer'
type card = {
  title: string
  value: any
}
export default function TermsheetView() {
  const location = useLocation()
  let data: any = location.state
  const [state, setState] = useState(false)
  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    { input: { key: data.props.fileURL, bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET } },
    {
      enabled: state,
    }
  )
  const additionalInvestors = () => {
    return (
      <tr className='fw-bold text-gray-600 '>
        <th className='fs-5 min-w-125px'>Investor Name</th>
        <th className='fs-5 min-w-125px'>Investment Amount</th>
      </tr>
    )
  }
  const roundDetails = [
    {
      title: 'Title',
      value: 'Round Details',
    },
    {
      title: 'Termsheet Name',
      value: data.props.termsheetName !== 'Unnamed' ? data.props.termsheetName : '-',
    },
    {
      title: 'Investor/Fund',
      value: data.props.investorName,
    },
    {
      title: 'Round Name',
      value: data.props.mandatorySections.roundDetails?.roundName || '-',
    },
    {
      title: 'Round Size',
      value: (
        <>
          <span style={{ fontFamily: 'arial' }}>₹ </span>
          {kFormatterRupee(data.props.mandatorySections.roundDetails.roundSize)}
        </>
      ),
    },
    {
      title: 'Company Name',
      value: data.props.companyName,
    },
    {
      title: 'CIN',
      value: data.props.cin,
    },
    {
      title: 'Priced Round',
      value: data.props.mandatorySections.roundDetails.isPricedRound ? 'Yes' : 'No',
    },
  ]
  const valuation = [
    {
      title: 'Title',
      value: 'Valuation',
    },
    {
      title: data.props.mandatorySections.roundDetails.isPricedRound
        ? 'Pre-money valuation of the company'
        : 'Floor Valuation',
      value: (
        <>
          <span style={{ fontFamily: 'arial' }}>₹ </span>
          {kFormatterRupee(data.props.mandatorySections.valuation.valuation)}
        </>
      ),
    },
    data.props.mandatorySections.roundDetails.isPricedRound
      ? data.props.mandatorySections.valuation?.autoDilute
        ? {
            title: 'Auto Dilute',
            value: data.props.mandatorySections.valuation?.autoDilute ? 'Yes' : 'No',
          }
        : {
            title: 'Post-round ESOP % ',
            value: data.props.mandatorySections.valuation.postRoundEsopPercentage,
          }
      : {
          title: 'Valuation Cap',
          value: (
            <>
              <span style={{ fontFamily: 'arial' }}>₹ </span>
              {kFormatterRupee(data.props.mandatorySections?.valuation?.valuationCap || 0)}
            </>
          ),
        },
    !data.props.mandatorySections.roundDetails.isPricedRound
      ? {
          title: 'Discount Percentage',
          value: data.props.mandatorySections.valuation?.discount,
        }
      : '',
  ]
  const investment = [
    {
      title: 'Title',
      value: 'Investment Details',
    },
    {
      title: 'Investment Amount',
      value: (
        <>
          <span style={{ fontFamily: 'arial' }}>₹ </span>
          {kFormatterRupee(data.props.investmentAmount)}
        </>
      ),
    },
    {
      title: 'Security',
      value: data.props.mandatorySections.investment?.security?.map(
        (security: string, index: number) =>
          index + 1 < data.props.mandatorySections.investment?.security.length
            ? security + ', '
            : security
      ),
    },
    {
      title: 'New Investors',
      value: data.props.mandatorySections.investment?.investorList?.length > 0 ? 'Yes' : 'No',
    },
  ]

  const tableData = data.props.mandatorySections.investment?.investorList?.map(
    (investment: any, index: number) => (
      <tr key={index}>
        <td className=''>
          <p className='fs-base mb-0'>{investment.investorName}</p>
        </td>
        <td className=''>
          <p className='fs-base mb-0'>{kFormatterRupee(investment.investmentAmount)}</p>
        </td>
      </tr>
    )
  )

  const newInvestors = [
    {
      title: 'Title',
      value: 'Additional Investment',
    },
  ]
  const liquidationPreference = [
    {
      title: 'Title',
      value: 'Liquidation Preference',
    },
    {
      title: 'Investment Rank',
      value: data.props.mandatorySections.liquidationPreference?.investmentRank,
    },
    {
      title: 'Pari Passu',
      value: data.props.mandatorySections.liquidationPreference?.pariPassu ? 'Yes' : 'No',
    },
    {
      title: 'Investment Multiple',
      value: data.props.mandatorySections.liquidationPreference?.investmentMultiple,
    },
    data.props.mandatorySections.liquidationPreference?.participation
      ? {
          title: 'Participation Cap',
          value: (
            <>
              <span style={{ fontFamily: 'arial' }}>₹ </span>
              {kFormatterRupee(
                data.props.mandatorySections.liquidationPreference?.participationCap
              )}
            </>
          ),
        }
      : {
          title: 'Participation',
          value: data.props.mandatorySections.liquidationPreference?.participation ? 'Yes' : 'No',
        },
  ]
  const transferRestrictions = [
    {
      title: 'Title',
      value: 'Transfer Restrictions',
    },
    {
      title: 'Drag Along Right',
      value: data.props.mandatorySections.transferRestrictions.dragAlong,
    },
    {
      title: 'Tag Along Right',
      value: data.props.mandatorySections.transferRestrictions.tagAlong,
    },
    {
      title: 'Right of First Refusal',
      value: data.props.mandatorySections.transferRestrictions.rightOfFR,
    },
    {
      title: 'Right of First Offer',
      value: data.props.mandatorySections.transferRestrictions.rightOfFO,
    },
  ]
  const validity = [
    {
      title: 'Title',
      value: 'Validity',
    },
    {
      title: 'Expiry Date',
      value: isoToDate(data.props.expiryDate),
    },
  ]
  const exitRights = [
    {
      title: data.props.mandatorySections.exitRights ? 'Title' : 'None',
      value: 'Exit Rights',
    },
    {
      title: 'Rights',
      value: data.props.mandatorySections.exitRights
        ? data.props.mandatorySections.exitRights.rights
        : 'none',
    },
    {
      title: 'Consequences',
      value: data.props.mandatorySections.exitRights
        ? data.props.mandatorySections.exitRights.consequences
        : 'none',
    },
  ]
  const governanceRights = [
    {
      title: data.props.mandatorySections.governanceRights ? 'Title' : 'None',
      value: 'Governance Rights',
    },
    {
      title: 'Quorum Rights',
      value: data.props.mandatorySections.governanceRights
        ? data.props.mandatorySections.governanceRights.quorumRights === '0'
          ? 'None'
          : data.props.mandatorySections.governanceRights.quorumRights
        : 'none',
    },
    {
      title: 'Director Board Composition',
      value: data.props.mandatorySections.governanceRights
        ? data.props.mandatorySections.governanceRights.directorBoardComposition === 0
          ? 'None'
          : data.props.mandatorySections.governanceRights.directorBoardComposition
        : 'none',
    },
    {
      title: 'Observer Board Composition',
      value: data.props.mandatorySections.governanceRights
        ? data.props.mandatorySections.governanceRights.observerBoardComposition === 0
          ? 'None'
          : data.props.mandatorySections.governanceRights.observerBoardComposition
        : 'none',
    },
  ]
  const founderRestrictions = [
    {
      title: data.props.additionalSections.founderRestrictions ? 'Title' : 'None',
      value: 'Founder Restrictions',
    },
    {
      title: 'Founder Lockin',
      value: data.props.additionalSections.founderRestrictions
        ? data.props.additionalSections.founderRestrictions.founderLockin
        : 'none',
    },
    {
      title: 'Founder Vesting',
      value: data.props.additionalSections.founderRestrictions
        ? data.props.additionalSections.founderRestrictions.founderVesting
        : 'none',
    },
  ]
  const investorRights = [
    {
      title: data.props.additionalSections.investorRights ? 'Title' : 'None',
      value: 'Investor Rights',
    },
    {
      title: 'Rights',
      value: data.props.additionalSections.investorRights ? (
        <>{data.props.additionalSections.investorRights.rights.join(' , ')}</>
      ) : (
        'none'
      ),
    },
    {
      title: 'Anti-Dilution',
      value: data.props.additionalSections.investorRights
        ? data.props.additionalSections.investorRights.antiDilution
        : 'none',
    },
  ]
  const miscellaneous = [
    {
      title: data.props.additionalSections.miscellaneous ? 'Title' : 'None',
      value: 'Miscellaneous',
    },
    {
      title: 'Country',
      value: data.props.additionalSections.miscellaneous
        ? data.props.additionalSections.miscellaneous.country
        : 'none',
    },
    {
      title: 'Exclusivity',
      value: data.props.additionalSections.miscellaneous
        ? data.props.additionalSections.miscellaneous.exclusivity
        : 'none',
    },
    {
      title: 'Due Diligence',
      value: data.props.additionalSections.miscellaneous
        ? data.props.additionalSections.miscellaneous.dueDiligence
          ? 'Yes'
          : 'No'
        : 'none',
    },
    {
      title: 'Transaction Cost',
      value: data.props.additionalSections.miscellaneous
        ? data.props.additionalSections.miscellaneous.transactionCost
        : 'none',
    },
  ]
  const mainSections: any = [
    roundDetails,
    valuation,
    investment,
    transferRestrictions,
    exitRights,
    governanceRights,
    validity,
  ]

  if (data.props.mandatorySections.investment?.investorList?.length > 0) {
    mainSections.splice(mainSections.indexOf(investment) + 1, 0, newInvestors)
  }

  if (data.props.mandatorySections?.liquidationPreference) {
    data.props.mandatorySections.investment?.investorList?.length > 0
      ? mainSections.splice(mainSections.indexOf(newInvestors) + 1, 0, liquidationPreference)
      : mainSections.splice(mainSections.indexOf(investment) + 1, 0, liquidationPreference)
  }

  const mandatoryCards: any[] = mainSections.map((section: any, index: number) => (
    <div className='card shadow-sm p-5 pb-0 mb-5' key={index}>
      {section.map((item: card, index: number) => {
        if (item.title === 'Title' && item.value === 'Additional Investment') {
          return (
            <div>
              <h3 className='card-title' key={index}>
                {item.value}
              </h3>
              <div className='p-2'>
                <div className='col-6 card border border-gray-300 p-4 pb-0'>
                  <table className='table align-middle gs-0 gy-2 table-row-dashed border-gray-200'>
                    <thead>{additionalInvestors()}</thead>
                    <tbody>{tableData}</tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        }
        if (item.title === 'Title') {
          return (
            <h3 className='card-title' key={index}>
              {item.value}
            </h3>
          )
        }
        return (
          <div className='card-body row py-0' key={index}>
            <p className='col-md-3 col-sm-12'>{item.title}</p>
            <p className='col-md-9 col-sm-12'>{item.value}</p>
          </div>
        )
      })}
    </div>
  ))
  const additionalSections: any = []
  founderRestrictions[0].title === 'Title' && additionalSections.push(founderRestrictions)
  investorRights[0].title === 'Title' && additionalSections.push(investorRights)
  miscellaneous[0].title === 'Title' && additionalSections.push(miscellaneous)
  const additionalCards: any[] = additionalSections.map((section: any, index: number) => (
    <div className='card shadow-sm p-5 pb-0 mb-5' key={index}>
      {section.map((item: card, index: number) => {
        if (item.title === 'Title') {
          return (
            <h3 className='card-title' key={index}>
              {item.value}
            </h3>
          )
        }
        return (
          <div className='card-body row py-0' key={index}>
            <p className='col-md-3 col-sm-12'>{item.title}</p>
            <p className='col-md-9 col-sm-12'>{item.value}</p>
          </div>
        )
      })}
    </div>
  ))
  return (
    <div className='card'>
      <div className='card-title fw-bold p-5 m-0 d-flex justify-content-between'>
        <h1 className='mb-0'>
          {data.props.termsheetName !== 'Unnamed' ? data.props.termsheetName : 'Term Sheet'}
          <span
            className={`badge fs-5 fw-semibold ms-5`}
            style={
              data.status === 'Active'
                ? {
                    backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.2),
                    color: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 1),
                  }
                : {
                    backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 0.2),
                    color: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 1),
                  }
            }
          >
            {data.status}
          </span>
        </h1>
        <button className='btn btn-sm btn-primary' type='button' onClick={() => setState(true)}>
          <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-muted svg-icon-2x' />
          <span className='fs-base fw-bold'>View PDF</span>
        </button>
      </div>
      <div className='card-body p-5'>
        {mandatoryCards}
        {additionalCards}
        <button className='btn btn-secondary'>
          <Link to='/deals'>Back</Link>
        </button>
      </div>
      {state && (
        <PDFModalViewer
          url={query.data?.urlForS3Object || ''}
          documentName={'PDF View'}
          handleClose={() => setState(false)}
          show={state}
        />
      )}
    </div>
  )
}
