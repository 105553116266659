import { FC } from 'react'
import { Field, ErrorMessage } from 'formik'

const Validity: FC<any> = ({ minDate }: { minDate: string }) => {
  return (
    <div className='w-100'>
      <div className='pb-10'>
        <h2 className='fw-bolder text-dark'>Validity</h2>
      </div>

      <div className='fv-row mb-6'>
        <label className='form-label mb-3'>Expiry Date</label>

        <Field
          type='date'
          placeholder='10/10/2010'
          className='form-control form-control-lg form-control-solid'
          name='validity'
          min={minDate}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='validity' />
        </div>
      </div>
    </div>
  )
}

export default Validity
