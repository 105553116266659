import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { getInitials } from '../../../../utils/Utils'
import WatchlistHighlightTab from './WatchlistHighlightCard'

type Watchlist = {
  watchlist: any[]
}

function WatchlistHighlights(props: Watchlist) {
  const navigate = useNavigate()
  const [watchlistHighlights, setWatchlistHighlights] = useState<any[]>([])

  useEffect(() => {
    const events: any[] = []
    props.watchlist?.forEach((watchlist) => {
      watchlist.companies?.forEach((company: any) => {
        watchlist.highlights?.forEach((highlight: any) => {
          if (company.name === highlight?.companyName && !events.includes(company.name)) {
            events.push({
              companyName: highlight?.companyName,
              eventType: highlight?.eventType,
              cin: company.cin,
              logo: company.logo,
            })
          }
        })
      })
    })
    setWatchlistHighlights(events)
  }, [props.watchlist])

  const highlightList = watchlistHighlights.map((event, index) => (
    <div key={index} className='min-h-30px'>
      <p className='fs-6 fw-bold mb-0 text-hover-primary'>
        <span
          className='cursor-pointer'
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            navigate('/company/stakeholders', {
              state: {
                props: {
                  name: event.companyName,
                  companyId: event.cin,
                  hissaVerified: true,
                  initials: getInitials(event.companyName),
                },
              },
            })
          }}
        >
          {event.companyName}
        </span>
        {event.eventType === 'MULTIPLE_USERS' && (
          <span className='fs-6 fw-normal text-gray-heading'>
            {' '}
            is being watched by multiple users!
          </span>
        )}
        {event.eventType === 'TERMSHEET' && (
          <span className='fs-6 fw-normal text-gray-heading'>
            {' '}
            has received most of the termsheets in last month!
          </span>
        )}
        {event.eventType === 'UNICORN' && (
          <span className='fs-6 fw-normal text-gray-heading'>
            {' '}
            has reached Unicorn status in last month!
          </span>
        )}
        {event.eventType === 'LATEST_RAISE' && (
          <span className='fs-6 fw-normal text-gray-heading'> raised money in last month!</span>
        )}
      </p>
    </div>
  ))
  let highlights: any = []
  highlightList.forEach((item: any, index: number) => {
    highlights.push(
      <div key={index} className='mw-400px gx-5'>
        <WatchlistHighlightTab highlights={item} />
      </div>
    )
  })

  return (
    <div className='card-body overflow-auto'>
      <div className='row flex-nowrap'>{highlights}</div>
    </div>
  )
}

export default WatchlistHighlights
