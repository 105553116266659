import { useEffect, useState } from 'react'
import Dropzone, {
  IFileWithMeta,
  IInputProps,
  ILayoutProps,
  IPreviewProps,
  IUploadParams,
  StatusValue,
} from 'react-dropzone-uploader'
import {
  useGetUrlForS3ObjectQuery,
  GetUrlForS3ObjectQuery,
  useGetAllInvestmentsQuery,
  useGetInvestorsQuery,
} from '../../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../../queries/client'
import { KTSVG } from '../../../../../../_metronic/helpers/components/KTSVG'
import { getAuth } from '../../../../../modules/auth'

type RowErrorType = {
  rowNum: number
  errors: ColumnErrorsType[]
  errorCount: number
}
type ColumnErrorsType = {
  column: number
  errorMessage: string
}
function Step4() {
  const auth = getAuth()!
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState<any>()
  const [csvError, setCsvError] = useState<any>()
  const [errors, setErrors] = useState<any[]>()
  const [bucket, setBucket] = useState<any>()
  const [key, setKey] = useState('')
  const [state, setState] = useState(false)

  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    { input: { key, bucket: bucket } },
    {
      enabled: state,
    }
  )

  if (query.isFetched && state == true) {
    window.open(query.data?.urlForS3Object || '')
    setState(false)
  }

  function handleTemplateDownload() {
    setBucket(process.env.REACT_APP_S3_INVESTOR_BUCKET || '')
    setKey('investor/RuleZero_BulkUpload_Template.csv')
    setState(true)
  }
  useEffect(() => {
    if (data?.errors.length > 0) {
      console.log('inside useEffect')
      let arr: any = []
      data?.errors.forEach((errorObject: RowErrorType, index: number) => {
        arr.push(errorObject)
      })
      setErrors(arr)
      setIsError(true)
      setIsSuccess(false)
    }
    if (data?.successRecords) {
      setIsSuccess(true)
      setIsError(false)
    }
  }, [data])

  const getUploadParams = async ({ meta, file }: IFileWithMeta) => {
    var formData = new FormData()
    formData.append(
      'operations',
      JSON.stringify({
        operationName: 'uploadBulkInvestments',
        query:
          '\n    mutation uploadBulkInvestments($up: Upload) {\n  addInvestmentBulkUpload(input: {filename: $up})\n}\n    ',
        variables: { up: null },
      })
    )
    formData.append('map', JSON.stringify({ '1': ['variables.up'] }))
    formData.append('1', file)

    const ret: IUploadParams = {
      url: process.env.REACT_APP_GQL_URL!,
      method: 'post',
      body: formData,
      headers: {
        authorization: auth,
      },
    }
    return ret
  }

  const handleChangeStatus = ({ meta, xhr }: IFileWithMeta, status: StatusValue) => {
    if (status === 'done') {
      let result = JSON.parse(xhr?.response)
      if (result.errors) {
        setCsvError('CSV Error: ' + result.errors[0].message)
      } else if (result.data.addInvestmentBulkUpload.errors?.length > 0) {
        setCsvError(null)
        setData({ errors: result.data.addInvestmentBulkUpload.errors, successRecords: 0 })
      } else {
        setData({ successRecords: result.data.addInvestmentBulkUpload.count, errors: [] })
        queryClient.invalidateQueries(useGetInvestorsQuery.getKey())
        queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())
      }
    }
  }

  const Input = ({ accept, onFiles, files, getFilesFromEvent, extra }: IInputProps) => {
    return (
      <div className=''>
        <label className='btn btn-outline btn-outline-dashed btn-outline-default bg-primary bg-opacity-10 p-6 d-flex justify-content-center m-10'>
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            disabled={files.length >= extra.maxFiles}
            onChange={async (e) => {
              let file = await getFilesFromEvent(e)
              onFiles(file)
            }}
          />
          <KTSVG
            path='/media/icons/duotune/files/upload.svg'
            className={`svg-icon-3x me-5 text-primary`}
          />

          <span className='text-start'>
            <h3 className='fw-bold mb-2'>Bulk Upload Investments</h3>
            <h4 className='text-gray-400'>Upload all your investments</h4>
          </span>
        </label>
        <div className='text-center'>
          <h6 className='text-gray-400'>Click or Drag to upload files</h6>
        </div>
      </div>
    )
  }

  const Preview = ({ meta, fileWithMeta }: IPreviewProps) => {
    const { name, percent, status, size } = meta
    if (status != 'done') {
      setIsError(true)
    }
    // console.log(percent, status, size)
    return (
      <div className='dropzone dropzone-queue mb-2'>
        <div className='dropzone-items'>
          <div className='dropzone-item p-5 dz-image-preview'>
            <div className='dropzone-file'>
              <div className='dropzone-filename' title={name}>
                <span className='fs-base fw-bold'>{name}</span>{' '}
                <strong>
                  (
                  <span>
                    {size > 1000000
                      ? (size / 1048576).toFixed(2) + ' MB'
                      : (size / 1024).toFixed(2) + ' KB'}
                  </span>
                  )
                </strong>
              </div>
              <h6 className={status === 'done' ? 'text-success mt-2' : 'text-primary mt-2'}>
                {status === 'done' ? 'Uploaded Successfully' : status}
              </h6>
            </div>
            <div className='dropzone-progress'>
              <div
                className={`progress + ${
                  percent === 100 && !isError ? 'bg-success' : 'bg-primary'
                }`}
              >
                <div
                  className='progress-bar bg-primary'
                  role='progressbar'
                  aria-valuemin={0}
                  aria-valuemax={100}
                  aria-valuenow={percent}
                ></div>
              </div>
            </div>
            <div className='dropzone-toolbar'>
              {isError ? (
                <span className='dropzone-delete' data-dz-remove>
                  <span
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    onClick={() => {
                      fileWithMeta.remove()
                      setErrors([])
                      setIsError(false)
                    }}
                  >
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                  </span>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    return (
      <div className=''>
        <div {...dropzoneProps} className='border-dashed border-hover-primary'>
          <div className=''>{input}</div>
        </div>
        {previews}

        {/* {files.length > 0 && submitButton} */}
      </div>
    )
  }

  const errorCards: any[] = errors
    ? errors.map((rowError: RowErrorType, index: number) => {
        return rowError.rowNum === 0 ? (
          <h5 className='text-danger'>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon-danger svg-icon-2hx me-2'
              />
            </span>
            {rowError.errors[0].errorMessage}
          </h5>
        ) : rowError.errorCount === 0 ? (
          rowError.errors.map((colError: ColumnErrorsType, index: number) => {
            return (
              <h6 className=''>
                <div className='d-flex'>
                  <div className='col-6'>
                    <span>
                      <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-warning svg-icon-2hx me-2'
                      />
                    </span>
                    {colError.errorMessage}
                  </div>
                  <div className='col-2 text-center mt-3'>Row {rowError.rowNum}</div>
                  {colError.errorMessage === 'Investment record with same details already exist' ? (
                    <div className='col-2 text-center mt-3'></div>
                  ) : (
                    <div className='col-2 text-center mt-3'>Column {colError.column}</div>
                  )}
                </div>
                <div className='separator mt-2'></div>
              </h6>
            )
          })
        ) : (
          rowError.errors.map((colError: ColumnErrorsType, index: number) => {
            return (
              <h6 className=''>
                <div className='d-flex'>
                  <div className='col-6'>
                    <span>
                      <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-danger svg-icon-2hx me-2'
                      />
                    </span>
                    {colError.errorMessage}
                  </div>
                  <div className='col-2 text-center mt-3'>Row {rowError.rowNum}</div>
                  <div className='col-2 text-center mt-3'>Column {colError.column}</div>
                </div>
                <div className='separator mt-2'></div>
              </h6>
            )
          })
        )
      })
    : []
  return (
    <div className='w-100 px-10 '>
      <div className='pb-10'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Bulk Upload Investment</h2>
      </div>
      <div className='fv-row'>
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          multiple={false}
          styles={{
            dropzone: { minHeight: 200 },
            dropzoneActive: { background: 'rgba(222, 220, 220, 0.5)' },
          }}
          accept={'.csv'}
          maxSizeBytes={100000000}
          InputComponent={Input}
          canCancel={true}
          canRemove={true}
          PreviewComponent={Preview}
          LayoutComponent={Layout}
          autoUpload={true}
          maxFiles={1}
        />
        <div className='separator my-6'></div>
        {csvError && <h5 className='text-danger'>{csvError}</h5>}
        {isSuccess && (
          <div>
            {data?.successRecords > 0 ? (
              <h5 className='text-success'>
                <span>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr016.svg'
                    className='svg-icon-success svg-icon-2hx me-2'
                  />
                </span>
                {data?.successRecords} Investment Records added successfully.
              </h5>
            ) : (
              <h5 className='text-danger'>
                <span>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className='svg-icon-danger svg-icon-2hx me-2'
                  />
                </span>
                {data?.successRecords} Investment Records added.
              </h5>
            )}
          </div>
        )}
        {isError ? (
          <div className='card shadow-sm h-200px overflow-scroll px-10 py-5'>
            <h3 className='fw-bold m-0'>Bulk Upload Errors</h3>
            <div className='separator my-3'></div>
            {errorCards}
          </div>
        ) : (
          <></>
        )}
        {isError || isSuccess || csvError ? (
          <></>
        ) : (
          <div>
            <h3 className='fw-bold pb-4'>RuleZero Cap Table template download</h3>
            <div className='d-inline-block'>
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default bg-primary bg-opacity-10 p-6 d-flex justify-content-center w-auto mb-10'
                htmlFor='kt_add_investment_type_single'
              >
                <KTSVG
                  path='/media/icons/duotune/files/fil021.svg'
                  className={`svg-icon-3x me-5 text-primary`}
                />

                <span className='text-start' onClick={handleTemplateDownload}>
                  <h4 className='fw-bold mb-2'>Download RuleZero Bulk Upload Template</h4>
                  <div className='text-gray-400 fs-base'>RuleZero_Bulk_Upload_Template.csv</div>
                </span>
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Step4
