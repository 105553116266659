import * as Yup from 'yup'
export interface ISendTermsheet {
  isPricedRound: string
  termsheetName:string
  investorId: number
  companyName: string
  roundSize: number
  roundName: string
  valuation: number
  autoDilute: boolean
  postRoundEsopPercentage: number
  investmentAmount: number
  investmentRank: number
  isPariPassu: boolean
  investmentMultiple: number
  isParticipation: boolean
  participationCap: number
  remainingAmount: number
  isAddInvestor: boolean
  newInvestorName: string
  investorList: Array<any>[]
  security: string[]
  newInvestmentAmount: number
  liquidationPreference: string
  customLiquidationPref?: string
  directorBoardComposition: string
  observerBoardComposition: string
  quorumRights?: string
  dar: string
  tar: string
  rofr: string
  rofo: string
  validity: string
  floorValuation: number
  discountConvertible: number
  valuationCap: number
  investorRights?: string[]
  antiDilution?: string
  founderVesting?: string
  customFounderVesting?: string
  founderLockin?: string
  exitRights?: string
  consequences?: string
  diligence?: boolean
  validityMisc?: string 
  exclusivity?: string
  transactionCost?: string
  country?: string,
  isDraft?: boolean
}

const sendTermsheetSchemas = [
  // Round Details
  Yup.object({
    termsheetName: Yup.string().required().label('Term Sheet Name').min(3, 'Template name must be greater than 3 characters'),
    investorId: Yup.number()
      .moreThan(0, 'Investor / Fund is required')
      .required()
      .label('investorId'),
    companyName: Yup.string().required().label('Company Name'),
    roundName: Yup.string().required().label('Round Name'),
    roundSize: Yup.number().moreThan(0).required().label('Round Size'),
    isPricedRound: Yup.string().required().label('isPricedRound'),
  }),
  // Convertible Round
  // Yup.object({
  //   floorValuation: Yup.number()
  //     .required()
  //     .moreThan(0, 'Floor Valuation is a required field')
  //     .default(10000000)
  //     .label('Floor Valuation'),
  //   valuationCap: Yup.number()
  //     .required()
  //     .moreThan(0, 'Valuation Cap is a required field')
  //     .default(10000000)
  //     .label('Valuation Cap'),
  //   discountConvertible: Yup.number()
  //     .required()
  //     .moreThan(0, 'Discount must be more than 0')
  //     .lessThan(100.0)
  //     .default(10)
  //     .label('Discount'),
  // }),
  // Valuation
  Yup.object({
    valuation: Yup.number().when('isPricedRound', {
      is: 'true',
      then: Yup.number().moreThan(0).required().label('Pre-Money Valuation'),
      otherwise: Yup.number().notRequired(),
    }),
    floorValuation: Yup.number().when('isPricedRound', {
      is: 'false',
      then: Yup.number()
        .required()
        .moreThan(0, 'Floor Valuation is a required field')
        .default(10000000)
        .label('Floor Valuation'),
      otherwise: Yup.number().notRequired(),
    }),
    valuationCap: Yup.number().when('isPricedRound', {
      is: 'false',
      then: Yup.number()
        .required()
        .moreThan(0, 'Valuation Cap is a required field')
        .default(10000000)
        .label('Valuation Cap'),
      otherwise: Yup.number().notRequired(),
    }),

    postRoundEsopPercentage: Yup.number().when(['isPricedRound', 'autoDilute'], {
      is: (isPricedRound: string, autoDilute: string) => isPricedRound && !autoDilute.toString(),
      then: Yup.number()
        .moreThan(0.0)
        .lessThan(100.0)
        .required()
        .label('Post Round Esop Percentage'),
      otherwise: Yup.number().notRequired(),
    }),

    discountConvertible: Yup.number().when('isPricedRound', {
      is: 'false',
      then: Yup.number().min(0).lessThan(100.0).required().label('Discount Percentage'),
      otherwise: Yup.number().notRequired(),
    }),

    // postRoundEsopPercentage: Yup.number().when('isPricedRound', {
    //   is: 'true',
    //   then: Yup.number()
    //     .moreThan(0.0)
    //     .lessThan(100.0)
    //     .required()
    //     .label('Post Round Esop Percentage'),
    //   otherwise: Yup.number().moreThan(0.0).lessThan(100.0).required().label('Discount Percentage'),
    // }),
  }),
  // Investment
  Yup.object({
    investmentAmount: Yup.number()
      .moreThan(0)
      .max(Yup.ref('roundSize'))
      .required()
      .label('Investment Amount'),
    newInvestmentAmount: Yup.number().when(['isAddInvestor', 'remainingAmount'], {
      is: (isAddInvestor: boolean, remainingAmount: number) =>
        isAddInvestor === true && remainingAmount > 0,
      then: Yup.number()
        .moreThan(0)
        .max(Yup.ref('remainingAmount'))
        .required()
        .label('Investment Amount'),
      otherwise: Yup.number().notRequired(),
    }),
    investorName: Yup.string().when('isAddInvestor', {
      is: true,
      then: Yup.string().label('Investor Name'),
      otherwise: Yup.string().notRequired(),
    }),
    remainingAmount: Yup.number().min(0).label('Remaining Amount'),
    security: Yup.array().min(1, 'Select at least one option').label('security'),
  }),
  //Liquidation Preferences
  Yup.object({
    investmentRank: Yup.number().moreThan(0).required().label('investment Rank'),
    investmentMultiple: Yup.number().moreThan(0).max(3).required().label('Investment Multiple'),
    participationCap: Yup.number().when('isParticipation', {
      is: true,
      then: Yup.number().moreThan(0).max(250000000).required().label('Participation Cap'),
      otherwise: Yup.number().notRequired(),
    }),
  }),
  // Transfer Restrictions
  Yup.object({
    dar: Yup.string().required().label('dar'),
    tar: Yup.string().required().label('tar'),
    rofr: Yup.string().required().label('rofr'),
    rofo: Yup.string().required().label('rofo'),
  }),
  // Governance Rights
  Yup.object({
    directorBoardComposition: Yup.string().required().label('directorBoardComposition'),
    observerBoardComposition: Yup.string().required().label('observerBoardComposition'),
  }),
  // Exit Rights
  Yup.object({
    exitRights: Yup.string().required().label('Exit Rights'),
    consequences: Yup.string().required().label('Consequences'),
  }),
  // Validity
  Yup.object({
    validity: Yup.string().required().label('Validity'),
  }),
]

const additionalSectionSchemas = new Map()
// Investor Rights
additionalSectionSchemas.set(
  'Investor Rights',
  Yup.object({
    investorRights: Yup.array().required().min(1, 'Select at least one option').label('Investor Rights'),
    antiDilution: Yup.string().required().label('Anti-Dilution'),
  })
)
// Founder Restrictions
additionalSectionSchemas.set(
  'Founder Restrictions',
  Yup.object({
    founderVesting: Yup.string().required().label('Founder Vesting'),
    founderLockin: Yup.string().required().label('Founder Lock-in'),
    customFounderVesting: Yup.string().optional(),
  })
)
// Miscellaneous
additionalSectionSchemas.set(
  'Miscellaneous',
  Yup.object({
    diligence: Yup.boolean().required().label('Diligence'),
    exclusivity: Yup.string().required().label('Exclusivity'),
    transactionCost: Yup.string().required().label('Transaction Cost'),
    country: Yup.string().required().label('Governing Law'),
  })
)

const inits = {
  termsheetName: 'My Termsheet',
  investorId: 0,
  roundSize: 250000000,
  roundName:'',
  isPricedRound: 'true',
  valuation: 25000000,
  autoDilute: 'true',
  postRoundEsopPercentage: 0,
  investmentAmount: 0,
  investmentRank: 0,
  isPariPassu: false,
  investmentMultiple: 1,
  isParticipation: false,
  participationCap: 0,
  isAddInvestor: false,
  remainingAmount: 0,
  investorName: '',
  newInvestmentAmount: 0,
  investorList: [],
  security: ['Equity'],
  liquidationPreference: '1x or Pro-Rata',
  directorBoardComposition: '0',
  observerBoardComposition: '0',
  quorumRights: 'Board Meeting',
  dar: 'Investors can drag others',
  tar: 'Investor have a right to tag along',
  rofr: 'Investor has a ROFR',
  rofo: 'Investor has a ROFO',
  validity: '',
  floorValuation: 25000000,
  valuationCap: 25000000,
  discountConvertible: 0,
  investorRights: ['Affirmative vote rights over all major decisions'],
  antiDilution: 'Full-Rachet',
  founderVesting: '4 year vesting with 1 year cliff, subject to continued employment',
  founderLockin: '3 year lock-in on transfer',
  exitRights: 'IPO',
  consequences: 'Call/Put Option',
  diligence: 'true',
  validityMisc: '',
  exclusivity: '',
  transactionCost: 'Company',
  country: 'India',
  companyName: '',
  isDraft: false,
}

export { sendTermsheetSchemas, additionalSectionSchemas, inits }
