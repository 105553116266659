import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useGetDealTemplatesQuery, GetDealTemplatesQuery } from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'
import { hexToRGB } from '../../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { SendTermsheetWizard } from './SendTermsheetWizard'

function getInits(data: any) {
  let inits = {
    termsheetName: data.termsheetName ? data.termsheetName : 'My Termsheet',
    investorId: data.investorId,
    companyName: data.companyName,
    investorName: data.investorName,
    cin: data.cin,
    roundSize: Number(data.roundSize),
    roundName: data.mandatorySections.roundDetails.roundName
      ? data.mandatorySections.roundDetails.roundName
      : '',
    isPricedRound: data.mandatorySections.roundDetails.isPricedRound.toString(),
    valuation: Number(data.valuation),
    autoDilute: data.mandatorySections.valuation.autoDilute,
    postRoundEsopPercentage:
      data.mandatorySections.roundDetails.isPricedRound.toString() == 'true' &&
      data.mandatorySections.valuation.autoDilute.toString() == 'false'
        ? data.mandatorySections.valuation.postRoundEsopPercentage
        : '',
    investmentAmount: Number(data.mandatorySections.investment.investedAmount),
    investmentRank: data.mandatorySections.liquidationPreference.investmentRank,
    isPariPassu: data.mandatorySections.liquidationPreference.pariPassu,
    investmentMultiple: data.mandatorySections.liquidationPreference.investmentMultiple,
    isParticipation: data.mandatorySections.liquidationPreference.participation,
    participationCap:
      data.mandatorySections.liquidationPreference.participation === true
        ? data.mandatorySections.liquidationPreference.participationCap
        : 0,
    isAddInvestor: data.mandatorySections.investment.investorList.length > 0 ? true : false,
    remainingAmount: data.mandatorySections.investment.remainingAmount,
    newInvestmentAmount: 1,
    investorList: data.mandatorySections.investment.investorList,
    security: data.mandatorySections.investment.security,
    liquidationPreference: data.mandatorySections.investment.liquidationPreference,
    directorBoardComposition:
      data.mandatorySections.governanceRights.directorBoardComposition.toString(),
    observerBoardComposition:
      data.mandatorySections.governanceRights.observerBoardComposition.toString(),
    quorumRights: data.mandatorySections.governanceRights.quorumRights,
    dar: data.mandatorySections.transferRestrictions.dragAlong,
    tar: data.mandatorySections.transferRestrictions.tagAlong,
    rofr: data.mandatorySections.transferRestrictions.rightOfFR,
    rofo: data.mandatorySections.transferRestrictions.rightOfFO,
    validity: data.expiryDate?.split('T')[0],
    floorValuation:
      data.mandatorySections.roundDetails.isPricedRound === false
        ? data.mandatorySections.valuation.valuation
        : '',
    valuationCap:
      data.mandatorySections.roundDetails.isPricedRound === false
        ? data.mandatorySections.valuation.valuationCap
        : '',
    discountConvertible:
      data.mandatorySections.roundDetails.isPricedRound === false
        ? data.mandatorySections.valuation.discount
        : '',
    exitRights: data.mandatorySections.exitRights.rights,
    consequences: data.mandatorySections.exitRights.consequences,
    founderVesting: '4 year vesting with 1 year cliff, subject to continued employment',
    founderLockin: '3 year lock-in on transfer',
    investorRights: ['Affirmative vote rights over all major decisions'],
    antiDilution: 'Full-Rachet',
    diligence: 'true',
    validityMisc: '',
    exclusivity: '',
    transactionCost: 'Company',
    country: 'India',
    isDraft: true,
  }
  if (data.additionalSections) {
    const keys = Object.keys(data.additionalSections)
    keys.forEach((key) => {
      if (key === 'founderRestrictions') {
        inits['founderLockin'] = data['additionalSections']['founderRestrictions'].founderLockin
        inits['founderVesting'] = data['additionalSections']['founderRestrictions'].founderVesting
      } else if (key === 'investorRights') {
        inits['investorRights'] = data['additionalSections']['investorRights'].rights
        inits['antiDilution'] = data['additionalSections']['investorRights'].antiDilution
      } else if (key === 'miscellaneous') {
        inits['diligence'] = data['additionalSections']['miscellaneous'].dueDiligence
        inits['exclusivity'] = data['additionalSections']['miscellaneous'].exclusivity
        inits['transactionCost'] = data['additionalSections']['miscellaneous'].transactionCost
        inits['country'] = data['additionalSections']['miscellaneous'].country
      }
    })
  }
  return inits
}

export default function DraftTermsheetView() {
  const location = useLocation()
  let props: any = location.state
  const [allowedSections, setAllowedSections] = useState<any[]>([])
  const [templateId, setTemplateId] = useState()
  const { data, isLoading, error, isSuccess, isFetched } = useGetDealTemplatesQuery<
    GetDealTemplatesQuery,
    Error
  >(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (props.props.templateId) {
      const template = data?.dealTemplates?.find(
        (template) => template?.id === props.props.templateId
      )
      if (template) {
        setAllowedSections(template?.sections!)
      }
      setTemplateId(props.props.templateId)
    }
  }, [isFetched])

  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  if (error) return <p>{error.message}</p>
  const inits = getInits(props.props)

  return (
    <div className='card'>
      <div className='card-title fw-bold p-5 m-0 d-flex justify-content-between'>
        <h1 className='mb-0'>
          Term Sheet
          <span
            className={`badge fs-5 fw-semibold ms-5`}
            style={{
              backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 0.2),
              color: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 1),
            }}
          >
            Draft
          </span>
        </h1>
      </div>
      <div className='card-body'>
        {/* {data?.dealTemplates && allowedSections.length > 0 && ( */}
        {templateId ? (
          allowedSections.length > 0 && (
            <SendTermsheetWizard
              initialValues={inits}
              allowedSections={allowedSections}
              templateId={props.props.templateId}
              draftTermsheetId={props.props.id}
            />
          )
        ) : (
          <SendTermsheetWizard
            initialValues={inits}
            allowedSections={allowedSections}
            templateId={props.props.templateId}
            draftTermsheetId={props.props.id}
          />
        )}
      </div>
    </div>
  )
}
