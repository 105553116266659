import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Error404: FC = () => {
  return (
    <div className='d-flex flex-column flex-root h-100'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        <img
          src={toAbsoluteUrl('/media/illustrations/sketchy-1/15.png')}
          alt=''
          className='mw-100 mb-10 h-lg-150px'
        />
        <h1 className='fw-bold mb-10'>Oops, somethings not right. </h1>
        <h3 className='mb-10 text-muted'>Lets start again!</h3>
        <Link to='/' className='btn btn-primary'>
          Go to Dashboard
        </Link>
      </div>
    </div>
  )
}

export { Error404 }
