import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useTermsheetBuilderStore } from '../../../../../store/TermsheetBuilderStore'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../../../utils/Select'

function Miscellaneous() {
  const builder = useTermsheetBuilderStore()
  const countryData: any[] = []
  const countryList: any[] = ['India', 'Singapore', 'Spain', 'USA', 'UK']

  countryList.map((country) => {
    countryData.push({ value: country, label: country })
  })
  return (
    <div className='card shadow-sm mt-4 p-8'>
      <div className='row align-items-center'>
        <h3 className='col fw-bold pb-4'>Miscellaneous</h3>
        <div className='col text-end'>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => builder.removeSection('Miscellaneous')}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </div>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Due Diligence</h4>
        </div>
        <div className='col-6 col-md-8'>
          <div className='form-check form-switch form-check-custom form-check-solid me-10'>
            <input
              className='form-check-input h-20px w-40px ms-4  '
              type='checkbox'
              value=''
              readOnly
              checked
            />
          </div>
        </div>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Exclusivity (months)</h4>
        </div>
        <div className='col-6 col-md-8'>
          <input
            type='number'
            className='form-control form-control-md form-control-solid w-md-50'
            disabled
          />
        </div>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-12 mb-2'>
          <h5>Transaction costs - to be borne by</h5>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='transactionCost'
                value='company'
                checked={true}
                readOnly
              />
            </span>
            <h4>Company</h4>
          </label>
        </div>
        <div className='col-12 col-md-8'>
          <label className='d-flex btn btn-outline btn-outline-dashed btn-outline-default align-items-start justify-content-start mb-6 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-4'>
              <input
                className='form-check-input'
                type='radio'
                name='transactionCost'
                value='eachParty'
                disabled
              />
            </span>
            <h4 className='me-4'>Each Party</h4>
          </label>
        </div>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Governing Law</h4>
        </div>
        <div className='col-6 col-md-4'>
          <Select
            className=''
            options={countryData}
            name='country'
            placeholder={'India'}
            menuPlacement='auto'
            menuPortalTarget={document.body}
            styles={selectCustomStyles}
          />
        </div>
      </div>
    </div>
  )
}

export default Miscellaneous
