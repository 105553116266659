import { Card } from 'react-bootstrap'
import { kFormatter } from '../../../../utils/CurrencyFormatter'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import {
  useGetWatchlistsQuery,
  useRemoveCompanyMutation,
  RemoveCompanyMutationVariables,
  RemoveCompanyMutation,
  useAddCompanyMutation,
  AddCompanyMutation,
  AddCompanyMutationVariables,
  AddStarCompanyMutation,
  AddStarCompanyMutationVariables,
  RemoveStarCompanyMutation,
  RemoveStarCompanyMutationVariables,
  useAddStarCompanyMutation,
  useRemoveStarCompanyMutation,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { getInitials } from '../../../../utils/Utils'
import Toast, { ToastType } from '../../../../utils/toast'

type WatchlistItemProps = {
  companyName: string
  companyLogo: string
  pps: number
  valuation: number
  latestRaise: number
  noOfRounds: number
  totalFunding: number
  isOddRow: boolean
  watchlistId: number
  companyId: string
  ctaText: string
  isSearchResults: boolean
  business: string
  industry: string
  starred?: boolean
  isAdded?: boolean
}

function WatchListItem(props: WatchlistItemProps) {
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)
  const queryClient = useQueryClient()
  const getWatchlistQueryKey = useGetWatchlistsQuery.getKey()
  const [isStarred, setIsStarred] = useState(props.starred)
  const addStarCompany = useAddStarCompanyMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddStarCompanyMutation,
        _variables: AddStarCompanyMutationVariables,
        _context: unknown
      ) => {
        setIsStarred(true)
        queryClient.invalidateQueries(getWatchlistQueryKey)
      },
    },
    {}
  )
  const removeStarCompany = useRemoveStarCompanyMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: RemoveStarCompanyMutation,
        _variables: RemoveStarCompanyMutationVariables,
        _context: unknown
      ) => {
        setIsStarred(false)
        queryClient.invalidateQueries(getWatchlistQueryKey)
      },
    },
    {}
  )
  const mutateRemoveCompany = useRemoveCompanyMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: RemoveCompanyMutation,
        _variables: RemoveCompanyMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(getWatchlistQueryKey)
      },
    },
    {}
  )

  const mutateAddCompany = useAddCompanyMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddCompanyMutation,
        _variables: AddCompanyMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(getWatchlistQueryKey)
      },
    },
    {}
  )

  function addStarred() {
    addStarCompany.mutate({
      input: {
        companyName: props.companyName,
        watchlistId: props.watchlistId,
      },
    })
  }
  function removeStarred() {
    removeStarCompany.mutate({
      input: {
        companyName: props.companyName,
        watchlistId: props.watchlistId,
      },
    })
  }
  function handleRemoveCompany(e: React.MouseEvent) {
    e.stopPropagation()
    // Disable indicator after 3 seconds
    deleteBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    setTimeout(() => {
      // Activate indicator
      deleteBtnRef.current?.removeAttribute('data-kt-indicator')
    }, 3000)
    Toast('Company Removed From Watchlist!', ToastType.success)
    e.preventDefault()

    mutateRemoveCompany.mutate({ cin: props.companyId, id: props.watchlistId })
  }

  function handleAddCompany(e: React.MouseEvent) {
    e.stopPropagation()
    e.preventDefault()
    mutateAddCompany.mutate({
      input: {
        cin: props.companyId,
        id: props.watchlistId,
        name: props.companyName,
      },
    })
  }

  function handleRemoveStarCompany(e: React.MouseEvent) {
    e.preventDefault()
    removeStarred()
  }

  function handleAddStarCompany(e: React.MouseEvent) {
    e.preventDefault()
    addStarred()
  }

  const cardClass = props.isSearchResults
    ? 'shadow-sm h-100px col-5 col-sm-4 col-md-2 w-md-125px mb-1 mb-lg-0 mt-1 mt-lg-0'
    : 'shadow-sm h-100px col-5 col-sm-4 col-md-2 w-md-125px w-xl-150px mb-1 mb-lg-0 mt-1 mt-lg-0'

  return (
    <Link
      to='/company/stakeholders'
      state={{
        props: {
          name: props.companyName,
          companyId: props.companyId,
          isVerified: true,
          starred: props.starred,
          watchlistId: props.watchlistId,
          initials: getInitials(props.companyName),
        },
      }}
    >
      <Card
        className={
          props.isOddRow
            ? 'wl-odd-row shadow-sm mb-4 z-index-0'
            : 'wl-even-row shadow-sm mb-4 z-index-0'
        }
      >
        <div className='row px-6 pt-6 pb-2 justify-content-center align-items-start justify-content-lg-start'>
          <div className='col-xs-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 h-auto text-center'>
            <Card className='shadow-sm min-h-80px w-80px justify-content-center h-md-125px w-md-125px m-auto'>
              <Card.Body className={'d-flex justify-content-center align-items-center p-0'}>
                {props.companyLogo !== null &&
                props.companyLogo !== 'NA' &&
                props.companyLogo !== '' ? (
                  <img
                    alt={props.companyName}
                    className='h-75px logo text-center'
                    src={toAbsoluteUrl(props.companyLogo)}
                  />
                ) : (
                  <div>
                    <p
                      className='mb-0 fs-5x'
                      style={{
                        color: getCSSVariableValue('--kt-donut-9'),
                      }}
                    >
                      {getInitials(props.companyName)}
                    </p>
                  </div>
                )}
              </Card.Body>
            </Card>
            {!props.isSearchResults ? (
              <div className='d-flex justify-content-center'>
                {isStarred ? (
                  <button className='btn btn-icon' onClick={handleRemoveStarCompany}>
                    <KTSVG
                      path='/media/icons/duotune/general/gen003.svg'
                      className='svg-icon-2x text-center text-primary '
                    />
                  </button>
                ) : (
                  <button className='btn btn-icon' onClick={handleAddStarCompany}>
                    <KTSVG
                      path='/media/icons/duotune/general/gen003.svg'
                      className='svg-icon-2x text-center text-grey '
                    />
                  </button>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className=' col d-flex justify-content-center mt-1 text-primary'>
              <KTSVG
                path='/media/icons/duotune/finance/fin001.svg'
                className=' svg-icon-2 text-center text-primary px-2'
              />
              <p>{props.industry || 'Others'}</p>
            </div>
          </div>

          <div className='col'>
            <div className='row d-flex'>
              <div className='col-md-8 col-sm-12'>
                {props.companyName ? (
                  <>
                    <h2 className='text-hover-primary'>
                      {props.companyName}{' '}
                      <KTSVG
                        path='/media/icons/duotune/general/gen043.svg'
                        className='text-primary'
                      />
                    </h2>
                  </>
                ) : (
                  <></>
                )}

                <span className='text-muted fw-bold d-block fs-5'>{props.business}</span>
              </div>
              <div className='col-md-4 col-sm-12 justify-content-end d-flex'>
                <div>
                  {props.isSearchResults ? (
                    <button
                      className='btn btn-secondary'
                      onClick={(e) => handleAddCompany(e)}
                      disabled={
                        (mutateAddCompany.isSuccess &&
                          mutateAddCompany.data.addCompanyToList.name === props.companyName) ||
                        props.isAdded
                      }
                    >
                      {mutateAddCompany.isLoading ? (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Adding...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (mutateAddCompany.isSuccess &&
                          mutateAddCompany.data.addCompanyToList.name === props.companyName) ||
                        props.isAdded ? (
                        <span>Added</span>
                      ) : (
                        props.ctaText
                      )}
                    </button>
                  ) : (
                    <Link
                      to='/deals/issueTermsheet'
                      state={{ companyId: props.companyId, name: props.companyName }}
                    >
                      <button className='btn btn-secondary'>
                        <span className='fs-base fw-bold'>{props.ctaText}</span>
                      </button>
                    </Link>
                  )}
                </div>
                <div>
                  {!props.isSearchResults && (
                    <button
                      ref={deleteBtnRef}
                      className='btn ms-3 btn-icon btn-secondary'
                      onClick={(e) => {
                        handleRemoveCompany(e)
                      }}
                    >
                      {mutateRemoveCompany.isLoading ? (
                        <span className='indicator-progress text-center'>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <KTSVG
                          path='media/icons/duotune/general/gen027.svg'
                          className='svg-icon-2x text-center text-primary'
                        />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex flex-wrap justify-content-center justify-content-sm-start mt-10'>
              <Card className={cardClass + ' me-2 me-sm-3'}>
                <div className='row m-2 mt-4 d-flex justify-content-between align-items-center h-100'>
                  <h1
                    className=' lh-1 mt-2'
                    style={{ color: getCSSVariableValue('--kt-donut-' + 9) }}
                  >
                    {props.pps === 0 ? (
                      'NA'
                    ) : (
                      <>
                        <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                        <span>{kFormatter(props.pps)}</span>
                      </>
                    )}
                  </h1>
                  <p className='text-gray-600'>PPS value</p>
                </div>
              </Card>
              <Card className={cardClass + ' me-2 me-sm-3'}>
                <div className='row m-2 mt-4 d-flex justify-content-between align-items-center h-100'>
                  <h1
                    className='lh-1 mt-2'
                    style={{ color: getCSSVariableValue('--kt-donut-' + 6) }}
                  >
                    {props.valuation === 0 ? (
                      'NA'
                    ) : (
                      <>
                        <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                        <span>{kFormatter(props.valuation)}</span>
                      </>
                    )}
                  </h1>
                  <p className=' text-gray-600'>Valuation</p>
                </div>
              </Card>
              <Card className={cardClass + ' me-2 me-sm-3'}>
                <div className='row m-2 mt-4 d-flex justify-content-between align-items-center h-100'>
                  <h1
                    className='lh-1 mt-2'
                    style={{ color: getCSSVariableValue('--kt-donut-' + 0) }}
                  >
                    {props.latestRaise === 0 ? (
                      'NA'
                    ) : (
                      <>
                        <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                        <span>{kFormatter(props.latestRaise)}</span>
                      </>
                    )}
                  </h1>
                  <p className=' text-gray-600'>Latest raise</p>
                </div>
              </Card>
              <Card className={cardClass + ' me-2 me-sm-3'}>
                <div className='row m-2 mt-4 d-flex justify-content-between align-items-center h-100'>
                  <h1
                    className='lh-1 mt-2'
                    style={{ color: getCSSVariableValue('--kt-donut-' + 4) }}
                  >
                    {props.noOfRounds === 0 ? 'NA' : props.noOfRounds}
                  </h1>
                  <p className='text-gray-600'>No. of rounds</p>
                </div>
              </Card>
              <Card className={cardClass}>
                <div className='row m-2 mt-4 d-flex justify-content-between align-items-center h-100'>
                  <h1
                    className='lh-1 mt-2'
                    style={{ color: getCSSVariableValue('--kt-donut-' + 3) }}
                  >
                    {props.totalFunding === 0 ? (
                      'NA'
                    ) : (
                      <>
                        <span style={{ fontFamily: 'arial' }}>₹ </span>{' '}
                        <span>{kFormatter(props.totalFunding)}</span>
                      </>
                    )}
                  </h1>
                  <p className=' text-gray-600'>Total funding</p>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  )
}

export { WatchListItem }
