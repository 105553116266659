import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Dropzone, {
  IInputProps,
  ILayoutProps,
  IPreviewProps,
  IUploadParams,
} from 'react-dropzone-uploader'
import { getS3UrlForUpload } from '../../../../api/investorApi'
import {
  useUploadLinksMutation,
  UploadLinksMutation,
  UploadLinksMutationVariables,
  Document_Vault,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { KTSVG } from '../../../../_metronic/helpers'

type Props = {
  handleClose: Function
  show: boolean
  cin: string
  folder: String
  investorId?: number
  id?: number
  vaultType?: Document_Vault
  companyName?: string
}

const FileUpload = (props: Props) => {
  const [fileUpload, setFileUpload] = useState(true)
  const [uploadType, setUploadType] = useState('file upload')
  const [linkUpload, setLinkUpload] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [name, setName] = useState('')
  const [link, setLink] = useState('')
  const mutateAddLinks = useUploadLinksMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: UploadLinksMutation,
        _variables: UploadLinksMutationVariables,
        _context: unknown
      ) => {
        setSuccessMessage('Successfully Uploaded')
      },
      onError(error: Error) {
        setErrorMessage(error.message.split(':')[0])
      },
    },
    {}
  )
  const getUploadParams = async ({ meta }: any) => {
    let uploadUrl = ''
    if (props.id) {
      uploadUrl = await getS3UrlForUpload(
        props.companyName + '/' + props.id + '/' + props.folder + meta.name
      )
    } else {
      uploadUrl = await getS3UrlForUpload(
        'investor/' + props.investorId + '/' + props.cin + '/' + props.folder + meta.name
      )
    }
    const ret: IUploadParams = {
      url: uploadUrl,
      method: 'put',
    }
    return ret
  }
  function handleLinkSubmit() {
    mutateAddLinks.mutate({
      input: {
        cin: props.cin,
        investorId: props.investorId,
        name: name,
        link: link,
        folder: String(props.folder),
      },
    })
  }
  const handleChangeStatus = ({ meta }: any, status: any) => {}

  const handleSubmit = (files: any[], allFiles: any[]) => {
    allFiles.forEach((f: { remove: () => any }) => f.remove())
  }

  const Preview = ({ meta, fileWithMeta }: IPreviewProps) => {
    const { name, percent, status, size, validationError } = meta
    return (
      <div className='dropzone dropzone-queue mb-2'>
        <div className='dropzone-items'>
          <div className='dropzone-item p-5 dz-image-preview'>
            <div className='dropzone-file'>
              <div className='dropzone-filename' title={name}>
                <span className='fs-base fw-bold'>{name}</span>{' '}
                <strong>
                  (
                  <span>
                    {size > 1000000
                      ? (size / 1048576).toFixed(2) + ' MB'
                      : (size / 1024).toFixed(2) + ' KB'}
                  </span>
                  )
                </strong>
              </div>
              <div className={`${status === 'done' ? 'text-success' : 'dropzone-error'}`}>
                {status}
              </div>
            </div>
            <div className='dropzone-progress'>
              <div className='progress'>
                <div
                  className={`${status === 'done' ? 'bg-success' : 'bg-primary'}`}
                  role='progressbar'
                  aria-valuemin={0}
                  aria-valuemax={100}
                  aria-valuenow={percent}
                  data-dz-uploadprogress
                  style={{ width: percent + '%' }}
                ></div>
              </div>
            </div>
            <div className='dropzone-toolbar'>
              {status != 'done' ? (
                <span className='dropzone-delete' data-dz-remove>
                  <span
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    onClick={() => {
                      fileWithMeta.cancel()
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </span>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  const Input = ({ accept, onFiles, files, getFilesFromEvent }: IInputProps) => {
    const text = files.length > 0 ? 'Add more files' : 'Choose files'

    const fileList = files.map((file) => (
      <h6 key={file.file.name}>
        {file.file.name} -{' '}
        {file.file.size > 1000000
          ? (file.file.size / 1000000).toFixed(2) + ' MB'
          : (file.file.size / 1000).toFixed(2) + ' KB'}
      </h6>
    ))

    return (
      <div className=''>
        <label className='btn btn-outline btn-outline-dashed btn-outline-default bg-primary bg-opacity-10 p-6 d-flex justify-content-center m-10'>
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            onChange={async (e) => {
              let file = await getFilesFromEvent(e)
              onFiles(file)
            }}
          />
          <KTSVG
            path='/media/icons/duotune/files/upload.svg'
            className={`svg-icon-3x me-5 text-primary`}
          />

          <span className='text-start'>
            <h3 className='fw-bold mb-2'>Select Files</h3>
          </span>
        </label>
        <div className='text-center'>
          <h6 className='text-gray-400'>Drag to upload Files</h6>
        </div>
      </div>
    )
  }

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    return (
      <div className=''>
        <div {...dropzoneProps} className='border-dashed border-hover-primary'>
          <div className=''>{input}</div>
        </div>
        {previews}

        {/* {files.length > 0 && submitButton} */}
      </div>
    )
  }
  return (
    <Modal
      id='kt_header_search_modal'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered h-auto mw-1000px '
      show={props.show}
      centered
      animation={true}
      backdrop={true}
      onHide={() => {
        props.handleClose()
      }}
    >
      <Modal.Header>
        <Modal.Title className='fs-1 fw-bold px-8'>Select Upload Type</Modal.Title>
        <div className='row justify-content-end'>
          <button
            className='btn btn-sm btn-icon'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => {
              props.handleClose()
              setSuccessMessage('')
              setErrorMessage('')
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        {!props.id && (
          <div className='p-8 d-flex justify-content-center'>
            <input
              className='form-check-input'
              type='radio'
              checked={uploadType === 'file upload'}
              value='file upload'
              onChange={(e) => {
                setUploadType(e.target.value)
                setLinkUpload(false)
                setFileUpload(true)
                setErrorMessage('')
                setSuccessMessage('')
                setLink('')
                setName('')
              }}
            />
            <h2 className='form-check-label mx-3 fw-bold'>File Upload</h2>
            <input
              className='form-check-input'
              type='radio'
              value='link upload'
              checked={uploadType === 'link upload'}
              onChange={(e) => {
                setUploadType(e.target.value)
                setFileUpload(false)
                setLinkUpload(true)
                setErrorMessage('')
                setSuccessMessage('')
              }}
            />
            <h2 className='form-check-label ms-3 fw-bold'>Link Upload</h2>
          </div>
        )}
        {fileUpload && (
          <>
            <Modal.Title className='fs-1 fw-bold px-8 mb-5'>Select Files to upload</Modal.Title>
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              onSubmit={handleSubmit}
              styles={{ dropzone: { minHeight: 200 } }}
              accept={'application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf'}
              PreviewComponent={Preview}
              maxSizeBytes={100000000}
              SubmitButtonComponent={() => <></>}
              InputComponent={Input}
              LayoutComponent={Layout}
            />
          </>
        )}
        {linkUpload && (
          <>
            <Modal.Title className='fs-1 fw-bold px-8 mb-5'>Link Upload</Modal.Title>
            <div className='fv-row mb-4'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-4 form-label required'>
                <h4>name</h4>
              </label>
              <input
                className='form-control form-control-lg form-control-solid'
                onChange={(e) => {
                  setName(e.target.value)
                  if (e.target.value.trim().length === 0) {
                    setErrorMessage('Name cannot be empty')
                  } else {
                    setErrorMessage('')
                  }
                }}
              />
            </div>
            <div className='fv-row mb-4'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-4 form-label required'>
                <h4>Link</h4>
              </label>
              <input
                className='form-control form-control-lg form-control-solid'
                onChange={(e) => {
                  setLink(e.target.value)
                  if (e.target.value.trim().length === 0) {
                    setErrorMessage('Link cannot be empty')
                  } else {
                    setErrorMessage('')
                  }
                }}
              />
            </div>
            <div className='text-danger mt-2'>{errorMessage}</div>
            <div className='text-success mt-2'>{successMessage}</div>
            <div className='d-grid d-md-flex justify-content-md-end'>
              <button
                className='btn btn-primary'
                type='button'
                onClick={() => handleLinkSubmit()}
                disabled={name.trim().length === 0 || link.trim().length === 0}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default FileUpload
