import React, { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import {
  AddWatchlistMutation,
  AddWatchlistMutationVariables,
  useAddWatchlistMutation,
  useGetWatchlistsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'

function AddNewWatchlist(props: any) {
  const submitBtnRef = useRef<HTMLButtonElement | null>(null)
  const [watchlistName, setWatchlistName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const queryKey = useGetWatchlistsQuery.getKey()
  const queryClient = useQueryClient()
  const { mutate } = useAddWatchlistMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddWatchlistMutation,
        _variables: AddWatchlistMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(queryKey)
        props.setActiveTab(data.addWatchlist.name)
        Toast('Watchlist Created Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        setErrorMessage(error.message.substring(0, 29))
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  function handleSubmit(e: React.MouseEvent) {
    if (watchlistName.trim().length === 0) {
      setErrorMessage('Watchlist name cannot be empty')
    } else if (errorMessage.length === 0) {
      // Disable indicator after 3 seconds
      submitBtnRef.current?.setAttribute('data-kt-indicator', 'on')
      setTimeout(() => {
        // Activate indicator
        submitBtnRef.current?.removeAttribute('data-kt-indicator')
      }, 2000)
      e.preventDefault()

      mutate({ input: { name: watchlistName } })
      setWatchlistName('')
    }
  }
  return (
    <div className='d-flex justify-content-center'>
      <div className='card shadow-sm min-w-50 w-md-50 p-8 m-4'>
        <div className='fv-row mb-4'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-4 form-label required'>
            <h4>Watchlist Name</h4>
          </label>

          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            minLength={3}
            placeholder='Enter Watchlist Name'
            value={watchlistName}
            onChange={(e) => {
              setWatchlistName(e.target.value)
              if (e.target.value.trim().length === 0) {
                setErrorMessage('Watchlist name cannot be empty')
              } else if (e.target.value.length < 3) {
                setErrorMessage('Watchlist name must be greater than 3 characters')
              } else {
                setErrorMessage('')
              }
            }}
          />
          <div className='text-danger mt-2'>{errorMessage}</div>
        </div>
        <div className='fv-row d-flex justify-content-end'>
          <button
            type='submit'
            ref={submitBtnRef}
            id='kt_button_1'
            onClick={(e) => handleSubmit(e)}
            className='btn btn-lg btn-primary mt-3'
          >
            <span className='indicator-label'>Create Watchlist</span>
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </div>{' '}
      </div>
    </div>
  )
}

export default AddNewWatchlist
