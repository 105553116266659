import React, { useContext, useState } from 'react'
import {
  useRemoveClassificationMutation,
  RemoveClassificationMutation,
  useGetClassificationQuery,
  useGetAllClassificationsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { GlobalContext } from '../../store/context/globalContext'
import { Actions, FilterType } from '../../store/context/reducer'

function RemoveClassification({ groupId }: { groupId: number }) {
  const { state, dispatch } = useContext(GlobalContext)
  const { ctxGroupIds } = state
  const [isLoading, setLoading] = useState(false)

  const removeClassification = useRemoveClassificationMutation<Error, RemoveClassificationMutation>(
    graphqlRequestClient,
    {
      onSuccess: () => {
        let ids = ctxGroupIds?.filter((id) => id !== Number(groupId))
        if (ids.length > 0) {
          dispatch({ type: Actions.setGroupIds, payload: ids })
        } else {
          dispatch({ type: Actions.setFilterType, payload: FilterType.all })
        }
        Toast('Classification Removed Successfully!', ToastType.success)
        queryClient.invalidateQueries(useGetClassificationQuery.getKey({ input: { id: groupId } }))
        queryClient.invalidateQueries(useGetAllClassificationsQuery.getKey())
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  function handleRemoveClassification(groupId: number) {
    setLoading(true)
    removeClassification.mutate({ input: { id: groupId } })
    setLoading(false)
  }

  return (
    <div className='col d-flex justify-content-end align-items-baseline'>
      <button
        className='btn btn-lg btn-primary ms-4'
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          handleRemoveClassification(groupId)
        }}
        disabled={removeClassification.isSuccess}
      >
        {removeClassification.isLoading ? (
          <span className='indicator-progress' style={{ display: 'block' }}>
            Removing...
            <span className='spinner-border spinner-border-sm align-middle mx-2'></span>
          </span>
        ) : removeClassification.isSuccess ? (
          'Removed'
        ) : (
          'Remove'
        )}
      </button>
    </div>
  )
}

export default RemoveClassification
