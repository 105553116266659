import { useMutation } from 'react-query'
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  LoginRequest,
  LoginResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from '../types/Login'
import Toast, { ToastType } from '../utils/toast'
import { investorApi } from './investorApi'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
// export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const RESET_PASSWORD_URL = `${API_URL}/auth/resetPassword`
export const CHANGE_PASSWORD_URL = `${API_URL}/auth/changePassword`

export async function login(credentials: LoginRequest): Promise<LoginResponse> {
  return investorApi.post(LOGIN_URL, credentials).then((res) => res.data)
}
export async function resetPassword(
  credentials: ResetPasswordRequest
): Promise<ResetPasswordResponse> {
  return investorApi
    .post(RESET_PASSWORD_URL, credentials, { headers: { authorization: credentials.jwt } })
    .then((res) => res.data)
}
export function useChangePassword() {
  return useMutation({
    mutationKey: 'changePassword',
    mutationFn: changePassword,
    onSuccess: (data) => {
      Toast('Change Password Successful', ToastType.success)
    },
    onError: (error) => {
      Toast('Change Password Failed', ToastType.error)
    },
  })
}

export async function changePassword(
  credentials: ChangePasswordRequest
): Promise<ChangePasswordResponse> {
  return investorApi
    .post(CHANGE_PASSWORD_URL, credentials, { headers: { authorization: credentials.jwt } })
    .then((res) => res.data)
}
