import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { GetDealTemplatesQuery } from '../../../../../generated/graphql'
import { isoToDate } from '../../../../../utils/FormatDate'
import { hexToRGB } from '../../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import TemplateView from '../termsheet-issuer/TemplateView'

type Props = {
  data: GetDealTemplatesQuery | undefined
  toggleBuilder: (value: boolean) => void
  toggleWizard: (value: boolean) => void
  setAllowedSections: (arr: any) => void
  isBuilderVisible: boolean
}
function TemplatesTable({ data, setAllowedSections }: Props) {
  const location = useLocation()
  const defaultOption = (location.state as any)?.defaultOption
  const [isBuilderVisible, setIsBuilderVisible] = useState(
    defaultOption === 'builder' ? true : false
  )

  const tagMapping = [
    {
      name: 'Governance Rights',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 1),
    },
    {
      name: 'Investor Rights',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 1),
    },
    {
      name: 'Founder Restrictions',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 2), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 2), 1),
    },
    {
      name: 'Exit Rights',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 3), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 3), 1),
    },
    {
      name: 'Miscellaneous',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 1),
    },
  ]

  const tableData = data?.dealTemplates?.map((template) => {
    let tags = template?.sections?.map((section: any) => {
      let tag = tagMapping.find((tag) => tag.name === section)
      if (tag)
        return (
          <span
            key={section}
            className={`badge fs-9 fw-semibold mb-1 me-2`}
            style={{ background: tag.backgroundColor, color: tag.foregroundColor }}
          >
            {tag.name}
          </span>
        )
    })

    return (
      <tr key={template?.id}>
        <td>
          <h6>{template?.name}</h6>
        </td>
        <td>
          <h6>{tags}</h6>
        </td>
        <td>
          <h6>{isoToDate(template?.creationDate || new Date().toISOString())}</h6>
        </td>
      </tr>
    )
  })

  tableData?.unshift(
    <tr key={'default'}>
      <td>
        <h6>Default Template</h6>
      </td>
      <td>
        <h6>
          <span
            key={'default'}
            className={`badge fs-9 fw-semibold mb-1 me-2`}
            style={{
              background: hexToRGB(getCSSVariableValue('--kt-donut-' + 8), 0.2),
              color: hexToRGB(getCSSVariableValue('--kt-donut-' + 8), 1),
            }}
          >
            Default
          </span>
        </h6>
      </td>
      <td></td>
    </tr>
  )

  return (
    <div>
      <TemplateView
        data={data}
        toggleBuilder={(value: boolean) => setIsBuilderVisible(value)}
        isBuilderVisible={isBuilderVisible}
        setAllowedSections={setAllowedSections}
      />
    </div>
  )
}

export default TemplatesTable
