import differenceInDays from 'date-fns/differenceInDays'

export function getInitials(name: string) {
  const n = name.trim()
  const i = n.indexOf(' ') > 0 ? n.charAt(0) + n.charAt(n.indexOf(' ') + 1) : n.charAt(0)
  return i.toUpperCase()
}

export function setupInitials(allComp: any[]) {
  let initMap = new Map<string, any>()
  allComp.forEach((comp: any) => {
    if (!initMap.has(comp.name.trim())) {
      let count = 0
      const init = getInitials(comp.name)

      for (let [key, value] of Array.from(initMap.entries())) {
        if (value.initials === init && value.id !== comp.companyId) {
          count = initMap.get(key).count + 1
        }
      }
      initMap.set(comp.name, {
        initials: init,
        id: comp.companyId,
        count: count,
      })
    }
  })
  return initMap
}

export function calculateXirr(values: number[], dates: Date[], guess = 0) {
  // Calculates the resulting amount
  const irrResult = function (values: number[], dates: Date[], rate: number) {
    const r = rate + 1
    let result = values[0]
    values.forEach((value, i) => {
      if (i === 0) return
      result += value / Math.pow(r, differenceInDays(dates[i], dates[0]) / 365)
    })
    return result
  }
  // Calculates the first derivation
  const irrResultDeriv = function (values: number[], dates: Date[], rate: number) {
    const r = rate + 1
    let result = 0
    for (let i = 1; i < values.length; i++) {
      const frac = differenceInDays(dates[i], dates[0]) / 365
      result -= (frac * values[i]) / Math.pow(r, frac + 1)
    }
    return result
  }
  // Check that values contains at least one positive value and one negative value
  let positive = false
  let negative = false
  for (const element of values) {
    if (element > 0) positive = true
    if (element < 0) negative = true
  }
  // Return error if values does not contain at least one positive value and one negative value
  if (!positive || !negative) return 'NA'
  // Initialize guess and resultRate
  guess = typeof guess === 'undefined' ? 0.1 : guess
  let resultRate = guess
  // Set maximum epsilon for end of iteration
  let epsMax = 1e-10
  // Set maximum number of iterations
  let iterMax = 50
  // Implement Newton's method
  let newRate, epsRate, resultValue
  let iteration = 0
  let contLoop = true
  do {
    resultValue = irrResult(values, dates, resultRate)
    newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate)
    epsRate = Math.abs(newRate - resultRate)
    resultRate = newRate
    contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax
  } while (contLoop && ++iteration < iterMax)
  if (contLoop) return 'NA'
  // Return internal rate of return
  return resultRate
}
