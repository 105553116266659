import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  GetAllInvestmentsQuery,
  GetInvestorsQuery,
  GetAllClassificationsQuery,
  Investor,
  GetWatchlistsQuery,
} from '../../../../generated/graphql'
import { GlobalContext } from './globalContext'
import { Actions } from './reducer'

type Props = {
  allInvestments: GetAllInvestmentsQuery
  investors: GetInvestorsQuery
  groups: GetAllClassificationsQuery
  watchlists: GetWatchlistsQuery
  selectedGroups?: number[]
  children: React.ReactNode
}

type ClassificationContextType = {
  allInvestments?: GetAllInvestmentsQuery
  investors?: GetInvestorsQuery
  groups?: GetAllClassificationsQuery
  selectedGroups?: number[]
  companies?: CompanyType[]
}

export type CompanyType = {
  name: string
  companyId?: string
  sharePrice: number
  valuation: number
  latestRaise: number
  noOfRounds: number
  totalFunding: number
  business: string
  industry: string
  isVerified: boolean
  logo: string
}

export const ClassificationContext = createContext<ClassificationContextType>({})

function ClassificationContextProvider(props: Props) {
  const { allInvestments, investors, groups, watchlists } = props
  const [companies, setCompanies] = useState<CompanyType[]>([])
  const { dispatch } = useContext(GlobalContext)
  let originalData: CompanyType[] = []
  useEffect(() => {
    originalData = []
    let holdings = investors.investors.map((investor: Investor) => investor.holdings).flat()
    allInvestments?.allInvestments?.forEach((company) => {
      const data = company.companyData
      const isVerified =
        holdings.find((holding: any) => holding.name === data.name)?.hissaVerified || false
      originalData.push({
        name: data.name,
        companyId: data.cin,
        latestRaise: isVerified ? data.latestRaise : 0,
        noOfRounds: isVerified ? data.noOfRounds : company.investmentData.length,
        sharePrice: isVerified ? data.sharePrice : 0,
        totalFunding: isVerified ? data.totalFunding : 0,
        valuation: isVerified ? data.valuation : 0,
        business: data.business,
        industry: data.industry,
        isVerified: isVerified,
        logo: data.logo,
      })
    })
    watchlists?.watchlists!.forEach((wl: any) => {
      wl.companies.forEach((c: any) => {
        originalData.push({
          name: c.name,
          companyId: c.cin,
          latestRaise: c.latestRaise,
          noOfRounds: c.noOfRounds,
          sharePrice: c.sharePrice,
          totalFunding: c.totalFunding,
          valuation: c.valuation,
          business: c.business,
          industry: c.industry,
          isVerified: true,
          logo: c.logo,
        })
      })
    })
    dispatch({ type: Actions.setCompanies, payload: originalData })
    setCompanies(originalData)
  }, [investors, allInvestments, groups, watchlists])

  const [selectedGroups1, setSelectedGroups] = useState<number[]>([])

  const value = {
    allInvestments: props.allInvestments,
    investors: props.investors,
    groups: props.groups,
    selectedGroups: props.selectedGroups,
    companies: companies,
  }

  function addGroup(group: number) {
    setSelectedGroups((prev) => [...prev, group])
  }

  function removeGroup(group: number) {
    setSelectedGroups((prev) => prev.filter((item) => item !== group))
  }

  return (
    <ClassificationContext.Provider value={value}>{props.children}</ClassificationContext.Provider>
  )
}

export default ClassificationContextProvider
