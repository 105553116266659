// Create our number formatter.
export var currencyFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export function kFormatter(num: number) {
  if (Math.abs(num) > 9999999999) {
    return Math.sign(num) * Math.round(Math.abs(num) / 10000000) + ' Cr'
  } else if (Math.abs(num) > 9999999) {
    return (Math.sign(num) * Math.round(Math.abs(num) / 1000000)) / 10 + ' Cr'
  } else if (Math.abs(num) > 99999) {
    return (Math.sign(num) * Math.round(Math.abs(num) / 1000)) / 100 + ' L'
  } else if (Math.abs(num) > 999) {
    return (Math.sign(num) * Math.round(Math.abs(num) / 10)) / 100 + ' k'
  } else {
    return Number((Math.sign(num) * Math.abs(num)).toFixed(2)) + ''
  }
}

export function kFormatterNumber(num: number) {
  if (Math.abs(num) > 9999999) {
    var value = (Math.sign(num) * Math.round(Math.abs(num) / 100000)) / 100

    return `${value > 999 ? value.toFixed(0) : value} Cr`
  } else if (Math.abs(num) > 99999) {
    value = (Math.sign(num) * Math.round(Math.abs(num) / 1000)) / 100
    return Number(value.toFixed(2)) + ' L'
  }
  if (Math.abs(num) > 999) {
    value = (Math.sign(num) * Math.round(Math.abs(num) / 10)) / 100
    return Number(value.toFixed(2)) + ' k'
  } else {
    value = Math.sign(num) * Math.abs(num)
    return Number(value.toFixed(2)) + ''
  }
}

export function kFormatterRupee(num: number) {
  var rupee = num.toString()
  var lastThree = rupee.substring(rupee.length - 3)
  var otherNumber = rupee.substring(0, rupee.length - 3)
  if (otherNumber !== '') lastThree = ',' + lastThree
  rupee = otherNumber.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree
  return rupee
}

export function kUnformatterNumber(str: string) {
  str = str.replaceAll(',', '')
  return parseInt(str)
}
