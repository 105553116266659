import axios from 'axios'

export const investorApi = axios.create({
  baseURL: localStorage.getItem('apiUrl') || process.env.REACT_APP_API_URL,
})

export const investorGqlApi = axios.create({
  baseURL: localStorage.getItem('apiUrl') || process.env.REACT_APP_GQL_URL,
})

export const hissaApi = axios.create({
  baseURL: localStorage.getItem('apiUrl') || process.env.REACT_APP_HISSA_URL,
})

export const capTableApi = axios.create({
  baseURL: process.env.REACT_APP_CAPTABLE_URL,
})

investorApi.defaults.headers.post['Content-Type'] = 'application/json'
hissaApi.defaults.headers.post['Content-Type'] = 'application/json'

export async function getS3UrlForUpload(key: String): Promise<any> {
  return investorApi
    .get('/files/s3PutUrl', {
      params: { key: key, bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET },
      headers: { 'Access-Control-Allow-Origin': '*' },
    })
    .then((res) => res.data)
}

export async function checkCapTableData(cinNumber: String): Promise<any> {
  return capTableApi
    .get('/v1/company/events', {
      params: { cinNumber: cinNumber },
      headers: { 'Access-Control-Allow-Origin': '*' },
    })
    .then((res) => res)
}
