/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers/components/KTSVG'
import { Field, ErrorMessage } from 'formik'

type Props = {
  investmentMethod: string
}

const Step2: FC<Props> = (props) => {
  return (
    <div className='w-100 mx-15'>
      <div className='pb-10'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Select Method of Investment
        </h2>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <Field
              type='radio'
              className='btn-check'
              name='investmentMethod'
              value='single'
              id='kt_add_investment_type_single'
            />
            <label
              className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10 ${
                props.investmentMethod === 'single' && 'bg-primary bg-opacity-10'
              }`}
              htmlFor='kt_add_investment_type_single'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com006.svg'
                className={`svg-icon-3x me-5 ${
                  props.investmentMethod === 'single' ? 'text-primary' : ''
                }`}
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  Enter Single Investment
                </span>
                <span className='text-gray-400 fw-bold fs-6'>Add a single investment.</span>
              </span>
            </label>
          </div>

          <div className='col-lg-12'>
            <Field
              type='radio'
              className='btn-check'
              name='investmentMethod'
              value='bulk'
              id='kt_add_investment_type_bulk'
            />
            <label
              className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10 ${
                props.investmentMethod === 'bulk' && 'bg-primary bg-opacity-10'
              }`}
              htmlFor='kt_add_investment_type_bulk'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen025.svg'
                className={`svg-icon-3x me-5 ${
                  props.investmentMethod === 'bulk' ? 'text-primary' : ''
                }`}
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  Upload all investments
                </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Upload a CSV file containing multiple investment records
                </span>
              </span>
            </label>
          </div>

          <div className='fs-6 mt-2'>
            <ErrorMessage name='investmentMethod' />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step2 }
