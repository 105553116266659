import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GetTermsheetsQuery, useGetTermsheetsQuery } from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { Tag, Termsheet } from '../../../../types/Termsheet'
import { kFormatter } from '../../../../utils/CurrencyFormatter'
import { formatDate, isoToDate, stringToDate } from '../../../../utils/FormatDate'
import { hexToRGB } from '../../../../utils/HexToRgba'
import { getInitials } from '../../../../utils/Utils'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../_metronic/helpers'
import TermsheetDropdown from './TermsheetDropdown'

function OpenDealsTable(props: any) {
  const [activeToggle, setActiveToggle] = useState('Active')
  const navigate = useNavigate()

  const { isLoading, error, data, isFetched, isPreviousData } = useGetTermsheetsQuery<
    GetTermsheetsQuery,
    Error
  >(graphqlRequestClient, {}, {}, {})

  const [termsheets, setTermsheets] = useState<Termsheet[]>()
  const [activeTermsheets, setActiveTermsheets] = useState<Termsheet[]>()
  const [lapsedTermsheets, setLapsedTermsheets] = useState<Termsheet[]>()
  const [draftTermsheets, setDraftTermsheets] = useState<Termsheet[]>()

  useEffect(() => {
    if (props.tab.length > 0)
      setActiveToggle(props.tab.charAt(0).toUpperCase() + props.tab.substr(1).toLowerCase())
  }, [props])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 5000)
  }, [isFetched])

  useEffect(() => {
    if (!isFetched || isPreviousData) return
    else {
      let newActiveTermsheets: Termsheet[] = []
      let newLapsedTermsheets: Termsheet[] = []
      let newDraftTermsheets: Termsheet[] = []

      data?.termsheets?.forEach((termsheet: any) => {
        let tags: Tag[] = []
        if (termsheet.mandatorySections.investment.liquidationPreference === '1x or Pro-Rata') {
          tags.push({
            name: 'Pro-Rata',
            backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 0.2),
            foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 1),
          })
        }
        if (
          termsheet.mandatorySections.transferRestrictions.dragAlong?.includes(
            'Investors can drag others'
          )
        ) {
          tags.push({
            name: 'DRAG-Investors',
            backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.2),
            foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 1),
          })
        }
        if (termsheet.mandatorySections.transferRestrictions.tagAlong?.includes('Investor')) {
          tags.push({
            name: 'TAG-Investors',
            backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 2), 0.2),
            foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 2), 1),
          })
        } else if (
          termsheet.mandatorySections.transferRestrictions.tagAlong?.includes('Founders')
        ) {
          tags.push({
            name: 'TAG-Founders',
            backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 3), 0.2),
            foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 3), 1),
          })
        }
        if (termsheet.mandatorySections.transferRestrictions.rightOfFR?.includes('Investor')) {
          tags.push({
            name: 'ROFR-Investors',
            backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 0.2),
            foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 1),
          })
        } else if (
          termsheet.mandatorySections.transferRestrictions.rightOfFR?.includes('Promoters')
        ) {
          tags.push({
            name: 'ROFR-Promoters',
            backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 5), 0.2),
            foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 5), 1),
          })
        }
        if (termsheet.mandatorySections.transferRestrictions.rightOfFO?.includes('Investor')) {
          tags.push({
            name: 'ROFO-Investors',
            backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 6), 0.2),
            foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 6), 1),
          })
        } else if (
          termsheet.mandatorySections.transferRestrictions.rightOfFO?.includes('Promoters')
        ) {
          tags.push({
            name: 'ROFO-Promoters',
            backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 7), 0.2),
            foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 7), 1),
          })
        }

        termsheet.tags = tags
        if (termsheet.status === 'Draft') {
          newDraftTermsheets.push(termsheet)
        }
        // convert date from dd/mm/yyyy to dd-Mon-yyyy
        else {
          let expiryDate = formatDate(termsheet.expiryDate, 'dd/MM/yyyy')
          let d = expiryDate.length === 10 ? stringToDate(expiryDate) : new Date(expiryDate)
          if (new Date() > d) {
            newLapsedTermsheets.push(termsheet)
          } else {
            newActiveTermsheets.push(termsheet)
          }
        }
      })

      newActiveTermsheets = newActiveTermsheets.sort(
        (a, b) => new Date(b.expiryDate).getTime() - new Date(a.expiryDate).getTime()
      )
      newLapsedTermsheets = newLapsedTermsheets.sort(
        (a, b) => new Date(b.expiryDate).getTime() - new Date(a.expiryDate).getTime()
      )

      setTermsheets(newDraftTermsheets)
      setActiveTermsheets(newActiveTermsheets)
      setLapsedTermsheets(newLapsedTermsheets)
      setDraftTermsheets(newDraftTermsheets)
    }
  }, [isFetched, isPreviousData, data])

  const tableData = termsheets?.map((item: Termsheet, index: number) => (
    <tr key={index} className='bg-hover-primary-light'>
      <td
        onClick={() =>
          item.status === 'Draft'
            ? navigate('draftTermsheet', {
                state: { props: item },
              })
            : navigate('termsheetview', {
                state: { props: item, status: activeToggle },
              })
        }
      >
        <div className='d-flex align-items-center'>
          {item.logo && item.logo !== '' && item.logo !== 'NA' ? (
            <div className='symbol symbol-50px mx-3'>
              <img src={item.logo} alt='' className='symbol-label' />
            </div>
          ) : (
            <div className='bg-secondary rounded min-h-50px min-w-50px fw-bold d-flex justify-content-center align-items-center mx-3'>
              <p
                className='mb-0 fs-2'
                style={{
                  color: getCSSVariableValue('--kt-donut-' + (9 % 10)),
                }}
              >
                {getInitials(item.companyName)}
              </p>
            </div>
          )}
          <div className='d-flex justify-content-start flex-column'>
            <span
              className='fs-base fw-bold text-hover-primary mb-1'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                navigate('/company/stakeholders', {
                  state: {
                    props: {
                      name: item.companyName,
                      companyId: item.cin,
                      initials: getInitials(item.companyName),
                      hissaVerified: true,
                    },
                  },
                })
              }}
            >
              {item.companyName}
            </span>
            <span className='text-muted fw-semibold fs-7'>{item.industry}</span>
          </div>
        </div>
      </td>
      <td
        className={item.status === 'Draft' ? 'text-center' : ''}
        onClick={() =>
          item.status === 'Draft'
            ? navigate('draftTermsheet', {
                state: { props: item },
              })
            : navigate('termsheetview', {
                state: { props: item, status: activeToggle },
              })
        }
      >
        {item.status !== 'Draft'
          ? item.tags?.map((tag: Tag) => (
              <span
                key={tag.name}
                className={`badge fs-9 fw-semibold mb-1 me-2`}
                style={{ background: tag.backgroundColor, color: tag.foregroundColor }}
              >
                {tag.name}
              </span>
            ))
          : '-'}
      </td>
      <td
        className='text-center'
        onClick={() =>
          item.status === 'Draft'
            ? navigate('draftTermsheet', {
                state: { props: item },
              })
            : navigate('termsheetview', {
                state: { props: item, status: activeToggle },
              })
        }
      >
        {kFormatter(item.roundSize)}
      </td>
      <td
        className='text-center'
        onClick={() =>
          item.status === 'Draft'
            ? navigate('draftTermsheet', {
                state: { props: item },
              })
            : navigate('termsheetview', {
                state: { props: item, status: activeToggle },
              })
        }
      >
        {item.status !== 'Draft' ? kFormatter(item.investmentAmount) : '-'}
      </td>
      <td
        className='text-center'
        onClick={() =>
          item.status === 'Draft'
            ? navigate('draftTermsheet', {
                state: { props: item },
              })
            : navigate('termsheetview', {
                state: { props: item, status: activeToggle },
              })
        }
      >
        {isoToDate(item.proposedDate)}
      </td>
      <td
        className='text-center'
        onClick={() =>
          item.status === 'Draft'
            ? navigate('draftTermsheet', {
                state: { props: item },
              })
            : navigate('termsheetview', {
                state: { props: item, status: activeToggle },
              })
        }
      >
        {item.status !== 'Draft' ? isoToDate(item.expiryDate) : '-'}
      </td>
      <td>
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
        <TermsheetDropdown termsheetId={activeToggle === 'Active' ? item.id || 0 : 0} />
      </td>
    </tr>
  ))

  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  // if (error) return <p>{error.message}</p>

  return (
    <div className={`card ${props.className} `}>
      <div className='card-header border-0 pt-5'>
        <h2 className='card-title fw-bold mb-1'>{activeToggle} Deals</h2>
        <div className='card-toolbar'>
          <div className='btn-group'>
            <div
              className={`btn btn-sm fs-6 ${
                activeToggle === 'Draft' ? 'btn-primary btn-active-primary ' : 'btn-bg-light'
              }`}
              onClick={() => {
                setTermsheets(draftTermsheets)
                setActiveToggle('Draft')
              }}
            >
              <p className={activeToggle === 'Draft' ? 'text-white mb-0' : 'text-gray-900 mb-0'}>
                Draft
              </p>
            </div>
            <div
              className={`btn btn-sm fs-6 ${
                activeToggle === 'Active' ? 'btn-primary btn-active-primary ' : 'btn-bg-light'
              }`}
              onClick={() => {
                setTermsheets(activeTermsheets)
                setActiveToggle('Active')
              }}
            >
              <p className={activeToggle === 'Active' ? 'text-white mb-0' : 'text-gray-900 mb-0'}>
                Active
              </p>
            </div>
            <div
              className={`btn btn-sm fs-6 ${
                activeToggle === 'Lapsed' ? 'btn-primary btn-active-primary ' : 'btn-bg-light'
              }`}
              onClick={() => {
                setTermsheets(lapsedTermsheets)
                setActiveToggle('Lapsed')
              }}
            >
              <p className={activeToggle === 'Lapsed' ? 'text-white mb-0' : 'text-gray-900 mb-0'}>
                Lapsed
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body py-3 overflow-auto scrollable min-h-500px'>
        <div className='table-responsive h-auto mh-400px'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='text-gray-600'>
                <th className='ps-4 min-w-325px rounded-start'>
                  <div className='fs-base fw-bold'>Company</div>
                </th>
                <th className='min-w-250px text-center'>
                  <span className='fs-base fw-bold'>Overview</span>
                </th>
                <th className='min-w-100px text-center'>
                  <span className='fs-base fw-bold'>
                    Round <span style={{ fontFamily: 'arial' }}>(₹)</span>
                  </span>
                </th>
                <th className='min-w-200px text-center'>
                  <span className='fs-base fw-bold'>
                    Investment Amount <span style={{ fontFamily: 'arial' }}>(₹)</span>
                  </span>
                </th>
                <th className='min-w-125px text-center'>
                  <span className='fs-base fw-bold'>
                    {activeToggle === 'Draft' ? 'Proposed Date' : 'Issued Date'}
                  </span>
                </th>
                <th className='min-w-125px text-center'>
                  <span className='fs-base fw-bold'>Validity Date</span>
                </th>
                <th className='min-w-50px rounded-end'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
                    disabled
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen053.svg'
                      className='svg-icon-muted svg-icon-2x'
                    />
                  </button>
                </th>
              </tr>
            </thead>

            <tbody>
              {tableData?.length === 0 ? (
                <tr>
                  <td colSpan={7} className='text-center'>
                    <h6>Looks like there is no data!</h6>
                  </td>
                </tr>
              ) : (
                tableData
              )}
            </tbody>
          </table>
        </div>
        <div className='py-4 d-flex align-items-stretch justify-content-between'>
          <Link to='/deals/buildTermsheet' state={{ defaultOption: 'builder' }}>
            <div className='btn btn-md btn-outline btn-outline-primary fs-base fw-bold border-5 btn-outline-default me-4'>
              Create New Template
            </div>
          </Link>
          <Link to='/deals/issueTermsheet' state={{ defaultOption: 'issue' }}>
            <div className='btn btn-md btn-primary fs-base fw-bold'>Issue New Term Sheet</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default OpenDealsTable
