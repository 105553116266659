import { UserPreferenceType } from '../types/UserPreferences'
import { getAuth, JwtPayload } from '../app/modules/auth'
import jwtDecode from 'jwt-decode'

export function setUserId(id: number) {
  localStorage.setItem('userId', id.toString())
}

export function getUserId() {
  let userId = undefined
  const auth = getAuth()
  if (auth) {
    const jwt = jwtDecode<JwtPayload>(auth)
    userId = Number(jwt.id)
  }
  return userId
}

export function setUserPreferences(object: UserPreferenceType) {
  localStorage.setItem('userPreferences', JSON.stringify(object))
}

export function getUserPreferences(id: number) {
  let userPref = localStorage.getItem('userPreferences')
  if (userPref) {
    let object: UserPreferenceType = JSON.parse(userPref)
    if (object?.id == id) {
      return object
    } else return undefined
  }
  return undefined
}

export function setDashboardMetricEnabled(name: string) {
  let userId = getUserId()
  let userPreferencesObj = userId ? getUserPreferences(userId) : null

  if (userPreferencesObj) {
    let dashboardMetrics = userPreferencesObj.dashboardMetrics
    if (!dashboardMetrics.includes(name)) {
      dashboardMetrics.push(name)
    } else {
      let index = dashboardMetrics.indexOf(name)
      if (index > -1) dashboardMetrics.splice(index, 1)
    }
    const newUserPref = { ...userPreferencesObj, dashboardMetrics: dashboardMetrics }
    setUserPreferences(newUserPref)
  }
}

export function isDashboardMetricEnabled(name: string) {
  let userId = getUserId()
  let userPreferencesObj = userId ? getUserPreferences(userId) : null

  if (userPreferencesObj) {
    if (userPreferencesObj.dashboardMetrics.includes(name)) {
      return true
    } else {
      return false
    }
  }
  return false
}
export function setPortfolioMetricEnabled(name: string) {
  let userId = getUserId()
  let userPreferencesObj = userId ? getUserPreferences(userId) : null

  if (userPreferencesObj) {
    let portfolioMetrics = userPreferencesObj.portfolioMetrics
    if (!portfolioMetrics.includes(name)) {
      portfolioMetrics.push(name)
    } else {
      let index = portfolioMetrics.indexOf(name)
      if (index > -1) portfolioMetrics.splice(index, 1)
    }
    const newUserPref = { ...userPreferencesObj, portfolioMetrics: portfolioMetrics }
    setUserPreferences(newUserPref)
  }
}

export function isPortfolioMetricEnabled(name: string) {
  let userId = getUserId()
  let userPreferencesObj = userId ? getUserPreferences(userId) : null

  if (userPreferencesObj) {
    if (userPreferencesObj.portfolioMetrics.includes(name)) {
      return true
    } else {
      return false
    }
  }
  return false
}
