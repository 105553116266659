import { FC } from 'react'
import { Field, ErrorMessage } from 'formik'

const ExitRights: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10'>
        <h2 className='fw-bolder text-dark'>Exit Rights</h2>
      </div>
      <div className='fv-row mb-6'>
        <div className='row'>
          <div className='col-12 mb-2'>
            <h5>Investor to have following exit rights:</h5>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field className='form-check-input' type='radio' name='exitRights' value='IPO' />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>IPO</h5>
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='exitRights'
                  value='Strategic Sales'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Strategic Sales</h5>
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='exitRights'
                  value='Buyback'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Buyback</h5>
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='exitRights'
                  value='Drag Along'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Drag Along</h5>
                </span>
              </span>
            </label>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='exitRights' />
          </div>
        </div>
      </div>
      <div className='fv-row mb-6'>
        <div className='row'>
          <div className='col-12 mb-2'>
            <h5>Consequences of event of default:</h5>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='consequences'
                  value='Call/Put Option'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Call/Put Option</h5>
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='consequences'
                  value='Accelerated Exit'
                />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>Accelerated Exit</h5>
                </span>
              </span>
            </label>
          </div>
          <div className=''>
            <label className='d-flex flex-left mb-2 cursor-pointer'>
              <span className='form-check form-check-custom form-check-solid'>
                <Field className='form-check-input' type='radio' name='consequences' value='None' />
              </span>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>None</h5>
                </span>
              </span>
            </label>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='consequences' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExitRights
