import Nouislider from 'nouislider-react'
import { InvestorList } from '../../../../../../../types/InvestorList'
import { kFormatter, kUnformatterNumber } from '../../../../../../../utils/CurrencyFormatter'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../../../utils/Select'

type Props = {
  investorList: InvestorList[]
}

function RoundDetails({ investorList }: Props) {
  const tooltipFns = {
    to(value: number) {
      return kFormatter(value)
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }

  const investorName: any[] = []
  investorList &&
    investorList.map((investor: any) =>
      !investorName.find((invObj: any) => invObj.value === investor.investorId)
        ? investorName.push({
            value: investor.investorId,
            label: investor.investorName,
          })
        : {}
    )

  return (
    <div className='card shadow-sm mt-4 p-8'>
      <div className='row align-items-center'>
        <h3 className='col fw-bold pb-4'>Round Details</h3>
      </div>
      <div className='row align-items-baseline pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Select Investor/Fund</h4>
        </div>
        <div className='col-6 col-md-4'>
          <Select
            className=''
            options={investorName}
            name='investorId'
            menuPortalTarget={document.body}
            styles={selectCustomStyles}
          />
        </div>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Company Name</h4>
        </div>
        <div className='col-6 col-md-8'>
          <input
            type='text'
            className='form-control form-control-md form-control-solid w-md-50'
            disabled
          />
        </div>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>CIN</h4>
        </div>
        <div className='col-6 col-md-8'>
          <input
            type='text'
            className='form-control form-control-md form-control-solid w-md-50'
            disabled
          />
        </div>
      </div>
      <div className='row align-items-baseline pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Enter round size</h4>
        </div>
        <div className='col-6 col-md-4'>
          <Nouislider
            instanceRef={(instance) => {}}
            tooltips={[tooltipFns]}
            start={250000000}
            range={{ min: 0, max: 1000000000 }}
            connect
            className=''
            disabled
          />
        </div>
        <div className='col-6 col-md-4'>
          <input
            type='text'
            value={'250000000'}
            className='form-control form-control-md form-control-solid'
            disabled
          />
          <div className='form-text'>Rupees Twenty Five Crores</div>
        </div>
      </div>
      <div className='row align-items-center pb-4'>
        <div className='col-6 col-md-4'>
          <h4>Is it a priced round?</h4>
        </div>
        <div className='col-6 col-md-8'>
          <div className='form-check form-switch form-check-custom form-check-solid me-10'>
            <input className='form-check-input h-20px w-40px' type='checkbox' value='' readOnly />
            <label className='form-check-label'>Yes</label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoundDetails
