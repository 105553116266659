import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { DealTemplate, GetDealTemplatesQuery } from '../../../../../generated/graphql'
import { isoToDate } from '../../../../../utils/FormatDate'
import { hexToRGB } from '../../../../../utils/HexToRgba'
import { getInitials } from '../../../../../utils/Utils'
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { toAbsoluteUrl, KTSVG } from '../../../../../_metronic/helpers'
import { SendTermsheetWizard } from './SendTermsheetWizard'

type Props = {
  data: GetDealTemplatesQuery | undefined
  toggleBuilder: (value: boolean) => void
  setAllowedSections: (arr: any) => void
  isBuilderVisible: boolean
}

function TermsheetCards({
  templateData,
  setTemplate,
  selectedId,
}: {
  templateData: DealTemplate
  setTemplate: Function
  selectedId: number
}) {
  const tagMapping = [
    {
      name: 'Governance Rights',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 1),
    },
    {
      name: 'Investor Rights',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 1),
    },
    {
      name: 'Founder Restrictions',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 2), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 2), 1),
    },
    {
      name: 'Exit Rights',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 3), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 3), 1),
    },
    {
      name: 'Miscellaneous',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 1),
    },
  ]

  let tags = templateData?.sections?.map((section: any) => {
    let tag = tagMapping.find((tag) => tag.name === section)
    if (tag) {
      return (
        <span
          key={section}
          className={`badge fs-9 fw-semibold mb-1 me-2`}
          style={{ background: tag.backgroundColor, color: tag.foregroundColor }}
        >
          {tag.name}
        </span>
      )
    }
  })
  let uniqCompanies: any[] = _.uniq(templateData?.companiesIssuedTo!)
  if (uniqCompanies.length > 5)
    uniqCompanies = ['+ ' + (uniqCompanies.length - 5).toString()].concat(uniqCompanies.slice(0, 5))
  const companyIssuedTo = uniqCompanies.map((company, index) => {
    return (
      <div className='symbol symbol-35px symbol-circle cursor-pointer'>
        <span
          className={'symbol-label fw-bolder fs-8 bg-secondary cursor-pointer'}
          style={{ color: getCSSVariableValue('--kt-donut-' + ((index + 9) % 10)) }}
        >
          {getInitials(company)}
        </span>
      </div>
    )
  })

  return (
    <div
      className={`card shadow-sm mw-375px h-400px mx-2 card-hover p-0 ${
        selectedId === templateData.id ? 'card-bordered border-primary border-5' : ''
      }`}
      onClick={() => {
        setTemplate(templateData.id)
      }}
    >
      <div className='card-body pb-0 '>
        <h3>{templateData?.name}</h3>
        <div>
          <p className='fs-8'>Features</p>
          <p className='fs-9'>
            {tags}{' '}
            {!tags || tags?.length == 0 ? (
              <span className={`badge fs-9 fw-semibold mb-1 me-2`}>
                <br />
              </span>
            ) : (
              <></>
            )}
          </p>
        </div>
        <div className='card border'>
          <div className='card-body p-1'>
            <img
              alt=''
              className='w-100'
              src={toAbsoluteUrl('/media/illustrations/misc/deal-template.png')}
            />
          </div>
        </div>
        <div className='pt-2'>
          <span className='fs-8'>Last Issued On -</span>
          <span className='float-end fs-base'>
            {templateData.lastIssuedDate !== null && templateData.lastIssuedDate !== ''
              ? isoToDate(templateData.lastIssuedDate!)
              : 'NA'}
          </span>
        </div>

        <div className='mt-2'>
          <span className='fs-8 pt-5 mt-5'> Issued To -</span>

          {companyIssuedTo?.map((c) => (
            <span className='float-end fs-base'>{c}</span>
          ))}
        </div>
      </div>
    </div>
  )
}

function TemplateView({ data, toggleBuilder, isBuilderVisible }: Props) {
  const [templateId, setTemplateId] = useState(-1)
  const [isWizardVisible, setIsWizardVisible] = useState(false)
  const [allowedSections, setAllowedSections] = useState<any[]>([])
  const wizardRef = useRef<any>(null)
  const executeScroll = () => wizardRef.current?.scrollIntoView({ behavior: 'smooth' })

  useEffect(() => {
    executeScroll()
  }, [isWizardVisible])

  const setTemplate = (templateId: number) => {
    setTemplateId(templateId)
  }

  const defaultTemplateData: DealTemplate = {
    name: 'Default Template',
    sections: [],
    creationDate: new Date().toISOString(),
    fileURL: '',
    id: 10000000,
    companiesIssuedTo: [],
    lastIssuedDate: '',
  }

  return (
    <div>
      <div className='card mt-3 ps-5'>
        <h1 className='fw-bold mt-6 px-4'>Term Sheet Templates</h1>
        {window.location.pathname !== '/deals/buildTermsheet' && (
          <h6 className='mt-2 px-4'>Select a term sheet template you want to issue</h6>
        )}
        <div className='card-body py-3 overflow-auto scrollable '>
          <div className='row flex-nowrap'>
            {
              <TermsheetCards
                templateData={defaultTemplateData}
                setTemplate={setTemplate}
                selectedId={templateId}
                key={'default'}
              />
            }
            {templateId &&
              data &&
              data.dealTemplates &&
              data.dealTemplates.map(
                (data, index) =>
                  data && (
                    <TermsheetCards
                      templateData={data}
                      setTemplate={setTemplate}
                      selectedId={templateId}
                      key={index}
                    />
                  )
              )}
          </div>
        </div>
        <div className='row justify-content-between ps-5 pt-5 mb-5'>
          <div className='col-md-12 align-items-end'>
            {window.location.pathname !== '/deals/buildTermsheet' && (
              <button
                disabled={templateId < 0}
                className='btn btn-primary fs-base fw-bold float-md-end me-5'
                onClick={() => {
                  setIsWizardVisible(true)
                  setAllowedSections(
                    data?.dealTemplates?.filter((t) => t?.id === templateId)[0]?.sections || []
                  )
                  executeScroll()
                }}
              >
                Issue New Term Sheet
              </button>
            )}
          </div>
        </div>
      </div>
      <div ref={wizardRef} id={'1234'}>
        {isWizardVisible && (
          <div className='card mt-3 ps-5'>
            <h1 className='fw-bold mt-6 px-4'>Issue New Term Sheet</h1>
            <div className='row justify-content-between ps-5 pt-5 mb-5'>
              <div className='col-md-12 align-items-end'>
                {allowedSections && (
                  <SendTermsheetWizard
                    allowedSections={allowedSections}
                    wizardRef={wizardRef}
                    templateId={templateId}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TemplateView
