import { useContext, useEffect, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { GetTermsheetsQuery } from '../../../../generated/graphql'
import { kFormatterRupee } from '../../../../utils/CurrencyFormatter'
import { isoToDate, stringToDate } from '../../../../utils/FormatDate'
import { getInitials } from '../../../../utils/Utils'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { GlobalContext } from '../../store/context/globalContext'
import { FilterType } from '../../store/context/reducer'
import OpenDealsPieChart from './OpenDealsPieChart'

type Props = {
  data: UseQueryResult<GetTermsheetsQuery, Error>
  companies?: string[]
}
type Termsheet = {
  proposedDate: string
  name: string
  logo: string
  companyId: string
  hissaVerified: boolean
  expiryDate: string
  industry: string
  status: string
}

function OpenDealsCard({ data: apiResponse, companies }: Props) {
  const { state } = useContext(GlobalContext)
  const { ctxFilterType, ctxInvestorIds } = state
  const navigate = useNavigate()
  const data = apiResponse.data
  const [totalTermsheets, setTotalTermsheets] = useState(data?.termsheets?.length || 0)
  const [termsheetCount, setTermsheetCount] = useState({ active: 0, inActive: 0 })
  let totalInvestment = 0
  const termsheets: Termsheet[] = []
  const industries: any = []

  if (apiResponse.isSuccess)
    data?.termsheets?.forEach((termsheet: any) => {
      if (ctxInvestorIds?.includes(Number(termsheet.investorId))) {
        if (companies && companies.length > 0) {
          if (companies.includes(termsheet.companyName)) {
            if (termsheet.status !== 'Draft') {
              totalInvestment += parseInt(termsheet.investmentAmount)
            }
            termsheets.push({
              proposedDate: termsheet.proposedDate,
              name: termsheet.companyName,
              logo: termsheet.logo,
              companyId: termsheet.cin,
              hissaVerified: true,
              expiryDate: termsheet.expiryDate,
              industry: termsheet.industry,
              status: termsheet.status,
            })
          }
        } else {
          if (termsheet.status !== 'Draft') {
            totalInvestment += parseInt(termsheet.investmentAmount)
          }
          termsheets.push({
            proposedDate: termsheet.proposedDate,
            name: termsheet.companyName,
            logo: termsheet.logo,
            companyId: termsheet.cin,
            hissaVerified: true,
            expiryDate: termsheet.expiryDate,
            industry: termsheet.industry,
            status: termsheet.status,
          })
        }
        const indVal = termsheet.industry ? termsheet.industry : 'Others'
        if (!industries.includes(indVal)) industries.push(indVal)
      }
    })
  useEffect(() => {
    if (ctxFilterType === FilterType.group) {
      setTotalTermsheets(termsheets.length)
    }
  }, [ctxFilterType, termsheets.length])

  let lastIssuedTermsheet = termsheets[0]
  let inActive = 0
  let active = 0

  termsheets.forEach((termsheet) => {
    if (termsheet.status !== 'Draft') {
      if (stringToDate(termsheet.proposedDate) > stringToDate(lastIssuedTermsheet.proposedDate)) {
        lastIssuedTermsheet = termsheet
      }
      let today = new Date()
      today.setDate(today.getDate() - 1)
      if (today < stringToDate(termsheet.expiryDate)) {
        active++
      } else {
        inActive++
      }
    }
  })
  useEffect(() => {
    setTermsheetCount({ active: active, inActive: inActive })
    setTotalTermsheets(termsheetCount.active + termsheetCount.inActive)
  }, [inActive, active, totalTermsheets, termsheetCount.active, termsheetCount.inActive])

  const optionsSeries: Object = {
    rotation: -90,
    circumference: 180,
    maintainAspectRatio: false,
    responsive: true,
    radius: '80%',
    cutout: '88%',
    aspectRatio: 1,
    plugins: {
      tooltip: {
        display: false,
        displayColors: false,
      },
      legend: {
        display: false,
        displayColors: false,
        position: 'bottom',
        align: 'start',
        labels: {
          display: false,
          usePointStyle: false,
          boxWidth: 500,
          boxHeight: 100,
        },
      },
    },
    elements: {
      arc: {
        borderAlign: 'inner',
      },
    },
  }

  const seriesChartData = {
    display: false,
    labels: ['Active', 'Expired'],
    datasets: [
      {
        label: '# of Termsheets',
        data: [termsheetCount.active, termsheetCount.inActive],
        backgroundColor: ['rgba(255, 168, 0, 1)', 'rgba(255, 168, 0, 0.15)'],
        borderColor: ['rgba(255, 168, 0, 1)', 'rgba(255, 168, 0, 0.15)'],
      },
    ],
  }

  return (
    <>
      {(active > 0 || inActive > 0) && (
        <div
          className={'d-flex justify-content-between p-5 card h-100 card-hover'}
          onClick={() => navigate('/deals')}
        >
          <div className='d-flex justify-content-between'>
            <div className='flex-column'>
              <h1>Open Deals</h1>
              <span className='text-muted fs-8'>{active + inActive} Term Sheets Issued</span>
            </div>
            <div>
              <button
                className='btn btn-primary btn-sm'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigate('/deals/issueTermsheet')
                }}
              >
                <p className='fw-bold fs-base m-0'>New term sheet</p>
              </button>
            </div>
          </div>
          {data && <OpenDealsPieChart data={seriesChartData} options={optionsSeries} />}
          <div className='row'>
            <div className='d-flex d-none'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs005.svg'
                className='p-4 svg-icon-muted svg-icon-3x'
                svgClassName='text-gray-400'
              />
              <div className='d-flex align-self-start flex-column'>
                <p className='mb-auto fw-semibold mt-3'>6</p>
                <div className='text-muted fs-8'>Term sheets accepted</div>
              </div>
            </div>
            <div className='d-flex'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs005.svg'
                className='p-4 svg-icon-muted svg-icon-3x'
                svgClassName='text-gray-400'
              />
              <div className='d-flex align-self-start flex-column mt-2'>
                <p className='mb-auto fw-semibold mt-3'>{kFormatterRupee(totalInvestment)}</p>
                <div className='text-muted fs-8'>Value of termsheets</div>
              </div>
            </div>
            <div className='d-flex'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs005.svg'
                className='p-4 svg-icon-muted svg-icon-3x'
                svgClassName='text-gray-400'
              />
              <div className='d-flex align-self-start flex-column mt-2'>
                <p className='mb-auto fw-semibold mt-3'>{industries.length} Industries</p>
                <div className='text-muted fs-8'>{industries.join(',')}</div>
              </div>
            </div>
            <div className='d-flex'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs005.svg'
                className='p-4 svg-icon-muted svg-icon-3x'
                svgClassName='text-gray-400'
              />
              <div className='d-flex align-self-start flex-column'>
                <p className='mb-auto fw-semibold mt-3'>Last issued term sheet</p>
                <div className='row'>
                  <div className='d-flex mt-2'>
                    {lastIssuedTermsheet?.logo &&
                    lastIssuedTermsheet.logo !== '' &&
                    lastIssuedTermsheet.logo !== 'NA' ? (
                      <div className='symbol symbol-40px'>
                        <img src={lastIssuedTermsheet?.logo} alt='' className='symbol-label' />
                      </div>
                    ) : (
                      <div className='bg-secondary rounded min-h-40px min-w-40px mh-40px mw-40px fw-bold d-flex justify-content-center align-items-center me-3'>
                        <p
                          className='mb-0 fs-2'
                          style={{
                            color: getCSSVariableValue('--kt-donut-' + (9 % 10)),
                          }}
                        >
                          {getInitials(lastIssuedTermsheet?.name!)}
                        </p>
                      </div>
                    )}
                    <div className='d-flex ms-3 align-self-start flex-column '>
                      <p
                        className='mb-auto cursor-pointer text-hover-primary'
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          navigate('/company/stakeholders', {
                            state: {
                              props: {
                                name: lastIssuedTermsheet?.name,
                                companyId: lastIssuedTermsheet?.companyId,
                                hissaVerified: lastIssuedTermsheet?.hissaVerified,
                                initials: getInitials(lastIssuedTermsheet?.name),
                              },
                            },
                          })
                        }}
                      >
                        {lastIssuedTermsheet?.name}
                      </p>
                      <div className='text-muted fs-8'>
                        {isoToDate(lastIssuedTermsheet?.proposedDate)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {active === 0 && inActive === 0 && (
        <div className='card h-100 p-5 justify-content-evenly'>
          <div className='row'>
            <img
              src={toAbsoluteUrl('/media/icons/duotune/general/no-openDeals.svg')}
              alt='no-openDeals'
              className='h-100px my-10'
            />
            <h2 className='text-center'>You currently have no active term sheets</h2>
          </div>
          <div className='text-center'>
            <button
              className='btn btn-primary btn-sm'
              onClick={() => navigate('/deals/buildTermsheet')}
            >
              <p className='fs-base m-0 fw-bold'>New Term Sheet</p>
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default OpenDealsCard
