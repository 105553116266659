import { useEffect, useState } from 'react'
import { Card, Tab } from 'react-bootstrap'
import {
  EditWatchlistNameMutation,
  EditWatchlistNameMutationVariables,
  GetWatchlistsQuery,
  useEditWatchlistNameMutation,
  useGetWatchlistsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { KTSVG } from '../../../../_metronic/helpers'
import AddCompanyToWatchlist from './AddCompanyToWatchlist'
import AddNewWatchlist from './AddNewWatchlist'
import { WatchListItem } from './WatchListItem'
import EmptyWatchlistItem from './EmptyWatchlistItem'
import WatchlistHighlights from './WatchlistHighlights'
import Toast, { ToastType } from '../../../../utils/toast'

function TabView() {
  const [isEdit, setIsEdit] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [err, setErr] = useState('')
  const { isLoading, error, data, isPreviousData } = useGetWatchlistsQuery<
    GetWatchlistsQuery,
    Error
  >(graphqlRequestClient, {}, {})
  const getWatchlistsQueryKey = useGetWatchlistsQuery.getKey()
  var watchlists: any = []
  const [showModal, setShowModal] = useState(false)
  data?.watchlists?.forEach((watchlist: any) => {
    watchlists.push({
      id: watchlist.id,
      name: watchlist.name,
      companies: watchlist.companies,
      highlights: watchlist.highlights,
    })
  })
  const editWatchlistName = useEditWatchlistNameMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        _data: EditWatchlistNameMutation,
        _variables: EditWatchlistNameMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(getWatchlistsQueryKey)
        setIsEdit(false)
        setErr('')
        setTabName(_variables.input.name!)
        Toast('Watchlist Name Edited Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  function editWatchlist(id: number, name: string) {
    if (name.trim().length === 0) {
      setErr('Watchlist name cannot be empty')
    } else if (name.length < 3) {
      setErr('Watchlist name must be greater than 3 characters')
    } else {
      editWatchlistName.mutate({
        input: {
          id: id,
          name: name,
        },
      })
    }
  }

  const [watchlistName, setWatchlistName] = useState(null)
  const [watchlistId, setWatchlistId] = useState(null)

  const [tabName, setTabName] = useState('')
  const [previousTabName, setPreviousTabName] = useState('')

  useEffect(() => {
    if (isPreviousData) return
    if (previousTabName === '') setTabName(watchlists[0]?.name)
  }, [isPreviousData, data, previousTabName])

  useEffect(() => {
    var index = watchlists.findIndex((watchlist: any) => watchlist.name === tabName)
    if (index > 0) setPreviousTabName(watchlists[index - 1].name)
  }, [tabName, watchlists])

  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  if (error) return <p>{error.message}</p>

  let watchlistNames: string[] = []
  watchlists.map((watchlist: any) => {
    if (watchlist.id === watchlistId) {
      watchlist.companies.map((company: any) => {
        if (!watchlistNames.includes(company.name) && watchlistId) watchlistNames.push(company.name)
      })
    }
  })

  var tabs = watchlists.map((watchlist: any, index: number) => {
    return (
      <div
        className={`'position-absolute tab-pane fade ${
          tabName === watchlist.name ? 'active show' : ''
        }`}
        key={index}
      >
        <Card>
          <Card.Body>
            <div className='col d-flex justify-content-between'>
              {watchlist.companies.length > 0 && !isEdit && (
                <div className='d-flex'>
                  <h3 className='mt-5'>{watchlist.name}</h3>
                  <button className='btn btn-icon' onClick={() => setIsEdit(true)}>
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                  </button>
                </div>
              )}
              {isEdit && (
                <div className='d-flex'>
                  <div className='me-4'>
                    <input
                      onChange={(e) => setSearchName(e.target.value)}
                      className='form-control form-control-solid me-2'
                      placeholder='Enter new watchlist name'
                    />
                    <h6 className='fs-base text-danger mt-2'>{err}</h6>
                  </div>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    type='submit'
                    onClick={() => editWatchlist(watchlist.id, searchName)}
                  >
                    {editWatchlistName.isLoading ? (
                      <span className='spinner-border spinner-border-sm mx-2'></span>
                    ) : (
                      <KTSVG
                        className='svg-icon-1 text-primary'
                        path='/media/icons/duotune/arrows/arr012.svg'
                      />
                    )}
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    onClick={() => {
                      setIsEdit(false)
                      setErr('')
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </button>
                </div>
              )}
              {watchlist.companies.length > 0 ? (
                <>
                  <button
                    className='btn btn-primary my-4'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_1'
                    onClick={() => {
                      setWatchlistName(watchlist.name)
                      setWatchlistId(watchlist.id)
                      setShowModal(true)
                    }}
                  >
                    <div className='fs-base fw-bold'>Add company +</div>
                  </button>
                  <AddCompanyToWatchlist
                    show={showModal}
                    handleClose={() => {
                      setShowModal(false)
                    }}
                    watchlistId={watchlistId!}
                    watchlistName={watchlistName!}
                    companyList={watchlistNames}
                  />
                </>
              ) : (
                <></>
              )}
            </div>

            <div className='row mb-5'>
              <WatchlistHighlights watchlist={[watchlist]} />
            </div>

            {watchlist.companies.length === 0 ? (
              <div>
                <EmptyWatchlistItem
                  key={watchlist.id}
                  watchlistId={watchlist.id}
                  watchlistName={watchlist.name}
                  previousWatchlistName={previousTabName}
                  setActiveTab={setTabName}
                />
              </div>
            ) : (
              watchlist.companies.map((company: any, index: number) => {
                return (
                  <WatchListItem
                    key={index}
                    companyId={company.cin}
                    watchlistId={watchlist.id}
                    companyLogo={company.logo}
                    companyName={company.name}
                    pps={company.sharePrice}
                    // valuation={kFormatter(company.valuation)}
                    // valuation={currencyFormatter.format(company.valuation)}
                    valuation={company.valuation}
                    latestRaise={company.latestRaise}
                    // latestRaise={kFormatter(company.latestRaise)}
                    noOfRounds={company.noOfRounds}
                    totalFunding={company.totalFunding}
                    isOddRow={index % 2 ? false : true}
                    ctaText='Issue term sheet'
                    isSearchResults={false}
                    business={company.business}
                    industry={company.industry!}
                    starred={company.starred}
                  />
                )
              })
            )}
            {/* {watchlist.companies.length > 0 ? <CompanyComparisonMain /> : null} */}
          </Card.Body>
        </Card>
      </div>
    )
  })

  if (tabs.length > 0) {
    tabs.push(
      <div
        className={`'position-absolute tab-pane fade ${
          tabName === 'add-watchlist' ? 'active show' : ''
        }`}
        key='add-watchlist'
      >
        <Card id='add-watchlist'>
          <Card.Body>
            <Tab.Content>
              <div>
                <AddNewWatchlist setActiveTab={setTabName} />
              </div>
            </Tab.Content>
          </Card.Body>
        </Card>
      </div>
    )
  } else {
    tabs = (
      <div className={`'position-absolute tab-pane fade active show`} key='add-watchlist'>
        <Card id='add-watchlist'>
          <Card.Body>
            <Tab.Content>
              <div>
                <AddNewWatchlist setActiveTab={setTabName} />
              </div>
            </Tab.Content>
          </Card.Body>
        </Card>
      </div>
    )
  }

  var tabBar = watchlists.map((watchlist: any, index: number) => {
    return (
      <li className='nav-item' key={watchlist.id}>
        <a
          className={`nav-link p-2 ms-4 fs-3 fw-semibold ${
            tabName === watchlist.name ? 'active text-primary fw-bold' : 'text-gray-heading'
          }`}
          data-bs-toggle='tab'
          href={`#${watchlist.id}`}
          onClick={() => {
            setTabName(watchlist.name)
          }}
        >
          {watchlist.name}
        </a>
      </li>
    )
  })

  if (tabBar.length > 0) {
    tabBar.push(
      <li className='nav-item' key='addWL'>
        <a
          className={`nav-link p-2 ms-4 fs-4 fw-semibold ${
            tabName === 'add-watchlist' ? 'active text-primary fw-bold' : 'text-gray-heading'
          }`}
          data-bs-toggle='tab'
          href={`#add-watchlist`}
          onClick={() => {
            setTabName('add-watchlist')
          }}
        >
          <KTSVG
            path='media/icons/duotune/general/gen035.svg'
            className='svg-icon-2 text-center text-primary px-2'
          />
          Add New Watchlist
        </a>
      </li>
    )
  } else {
    tabBar = (
      <li className='nav-item' key='addWL'>
        <a
          className={`nav-link p-2 ms-4 fs-4 fw-semibold active text-primary fw-bold`}
          data-bs-toggle='tab'
          href={`#add-watchlist`}
          onClick={() => {
            setTabName('add-watchlist')
          }}
        >
          <KTSVG
            path='media/icons/duotune/general/gen035.svg'
            className='svg-icon-2 text-center text-primary px-2'
          />
          Add New Watchlist
        </a>
      </li>
    )
  }

  return (
    <>
      <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-6'>{tabBar}</ul>
      <div className='tab-content' id='myTabContent'>
        {tabs}
      </div>
    </>
  )
}

export default TabView
