/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title='Dashboard'
        icon='/media/icons/duotune/layouts/lay001.svg'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/portfolio'
        title='Portfolios'
        icon='/media/icons/duotune/graphs/gra010.svg'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/watchlist'
        title='Watchlist'
        icon='/media/icons/duotune/general/gen004.svg'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/deals'
        title='Deals'
        icon='/media/icons/duotune/finance/fin010.svg'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
