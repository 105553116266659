import React from 'react'
import {useNavigate} from 'react-router-dom'

function CustomRowDropDown(props: any) {
  const options = ['Invite to Platform', 'Invite to verify', 'View Details']
  const navigate = useNavigate()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
      data-kt-menu='true'
    >
      {options.map((option) => {
        return (
          <label
            key={option}
            className='form-check-custom py-3 bg-hover-primary text-hover-white'
            onClick={() => {
              if (option === 'View Details') {
                navigate('/company/stakeholders', {state: {props: props.state}})
              }
            }}
          >
            <span className='form-check-label fs-base fw-normal ms-9 cursor-pointer '>
              {option}
            </span>
          </label>
        )
      })}
    </div>
  )
}

export default CustomRowDropDown
