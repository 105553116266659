import { FC, useContext, useEffect, useRef, useState } from 'react'
import Select, { ActionMeta, MultiValue } from 'react-select'
import PortfolioCompaniesCard from './components/PortfolioCompaniesCard'
import WatchlistHighlights from './components/WatchlistHighlights'
import TaskOverview from './components/TaskOverview'
import {
  GetAllClassificationsQuery,
  GetAllInvestmentsQuery,
  GetExitDataInvestorWiseQuery,
  GetInvestorsQuery,
  Investor,
  useGetAllClassificationsQuery,
  Transaction,
  useGetAllInvestmentsQuery,
  useGetExitDataInvestorWiseQuery,
  useGetInvestorsQuery,
  useGetWatchlistsQuery,
  GetWatchlistsQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'
import DonutByStage from './components/DonutByStage'
import { getAuth, JwtPayload } from '../../../app/modules/auth'
import jwtDecode from 'jwt-decode'
import PortfolioCardsOverview from './components/PortfolioCardsOverview'
import PortfolioMetrics from './components/PortfolioMetrics'
import StackedBarChart from './components/StackedBarChart'
import LineChart from './components/LineChart'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import DonutWithCloseButton from './components/DonutWithCloseButton'
import { multiSelectCustomStyles, SelectOptionType } from '../../../utils/MultiSelect'
import makeAnimated from 'react-select/animated'
import { convertRate, RateInterval, xirr } from 'node-irr'
import { ClassificationType } from '../../../types/Classification'
import ClassificationContextProvider from '../store/context/classificationContextProvider'
import { GlobalContext, GlobalContextType } from '../store/context/globalContext'
import _ from 'lodash'
import { getUserId, getUserPreferences, setUserPreferences } from '../../../utils/LocalStorageUtils'
import { UserPreferenceType } from '../../../types/UserPreferences'
import { Actions, FilterType } from '../store/context/reducer'
import { calculateXirr } from '../../../utils/Utils'

const animatedComponents = makeAnimated()

type MetricOptionType = {
  title: string
  display: boolean
}

type Portfolio = {
  investorId: number
  investorName: string
  totalInvested: number
  gain: number
  moic: string
  currentValue: number
  irr: number
  avgRoundSize: number
  upRounds: number
  exits: number
  rounds: number
  leadInvestments: number
  totalInvestments: number
  portfolioCompanies: number
  investments: any[]
  exitData?: Transaction[]
}

type Group = {
  groupId: number
  groupName: string
  totalInvested: number
  gain: number
  moic: string
  currentValue: number
  irr: number
  avgRoundSize: number
  upRounds: number
  exits: number
  rounds: number
  leadInvestments: number
  totalInvestments: number
  totalCompanies: number
  investments: any[]
  exitData?: Transaction[]
}

type Industry = {
  industryName: string
  count: number
}

type Investment = {
  range: string
  count: number
}

function DashboardPage() {
  const { state, dispatch } = useContext<GlobalContextType>(GlobalContext)
  const { ctxFilterType, ctxGroupIds, ctxInvestorIds, ctxUserId } = state
  const auth = getAuth()!
  const jwt = jwtDecode<JwtPayload>(auth)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([])
  const [investorNames, setInvestorNames] = useState<any[]>([])
  const [active, setIsActive] = useState(true)
  const [groupCompanies, setGroupCompanies] = useState<string[]>([])
  const [fundOptions, setFundOptions] = useState<SelectOptionType[]>([])
  const [groupOptions, setGroupOptions] = useState<SelectOptionType[]>([])
  const [industriesChartData, setIndustriesChartData] = useState<Industry[]>([])
  const [investmentsChartData, setInvestmentsChartData] = useState<Investment[]>([])
  const [showIndustryDonut, setShowIndustryDonut] = useState(true)
  const [showInvestmentDonut, setShowInvestmentDonut] = useState(true)
  const [dataToggle, setDataToggle] = useState('time')
  const [chartHeader, setChartHeader] = useState('Total Invested over Companies')
  const [portfolios, setPortfolios] = useState<Portfolio[]>([])
  const multiSelectRef = useRef<any>(null)
  const [investorIds, setInvestorIds] = useState<number[]>([])
  const [portfolioMetrics, setPortfolioMetrics] = useState({
    avgRoundSize: 0,
    index: 0,
    exits: 0,
    rounds: 0,
    leadInvestments: 0,
    totalInvestments: 0,
    portfolioCompanies: 0,
    gain: 0,
    moic: 0,
    irr: 0,
  })

  const { isLoading, error, data } = useGetInvestorsQuery<GetInvestorsQuery, Error>(
    graphqlRequestClient,
    {},
    {
      onSuccess(data) {
        setInvestorIds(data?.investors.map((investor: Investor) => Number(investor.id)))
      },
    }
  )

  const getAllClassifications = useGetAllClassificationsQuery<GetAllClassificationsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const getAllInvestments = useGetAllInvestmentsQuery<GetAllInvestmentsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )
  const getWatchlists = useGetWatchlistsQuery<GetWatchlistsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const getExitData = useGetExitDataInvestorWiseQuery<GetExitDataInvestorWiseQuery, Error>(
    graphqlRequestClient,
    { input: selectedIds },
    { enabled: selectedIds?.length! > 0 }
  )

  const metricsOptions = ['Portfolio companies', 'Gain', 'MOIC', 'IRR']

  let userId = getUserId()

  const [options, setOptions] = useState<MetricOptionType[]>([])

  function updateOptions(opts: any) {
    setOptions(opts)
  }

  // get the user preferences from localStorage on first load
  // and set the context values for the same
  useEffect(() => {
    if (getUserId() !== ctxUserId || (ctxInvestorIds?.length === 0 && ctxGroupIds?.length === 0)) {
      let userPreferencesObj = userId ? getUserPreferences(userId) : undefined
      let defaultMetricsOptions: MetricOptionType[] = []
      if (userPreferencesObj !== undefined) {
        let userPref = userPreferencesObj as UserPreferenceType
        // if localStorage is empty then
        if (userPref.filterType === FilterType.fund && userPref.selectedInvestors.length === 0) {
          dispatch({ type: Actions.setFilterType, payload: FilterType.fund })
          dispatch({ type: Actions.setGroupIds, payload: [] })
          dispatch({
            type: Actions.setInvestorIds,
            payload: investorIds,
          })
        } else if (userPref.filterType === FilterType.group) {
          dispatch({ type: Actions.setFilterType, payload: FilterType.group })
          dispatch({ type: Actions.setGroupIds, payload: userPref.selectedGroups })
          dispatch({ type: Actions.setInvestorIds, payload: userPref.selectedInvestors })
        } else if (userPref.filterType === FilterType.fund) {
          dispatch({ type: Actions.setFilterType, payload: FilterType.fund })
          dispatch({ type: Actions.setGroupIds, payload: [] })
          dispatch({ type: Actions.setInvestorIds, payload: userPref.selectedInvestors })
        } else {
          dispatch({ type: Actions.setFilterType, payload: FilterType.all })
          dispatch({ type: Actions.setGroupIds, payload: [] })
          dispatch({ type: Actions.setInvestorIds, payload: [] })
        }

        // setting dashboard metrics
        let optionsFromUserPreferences = userPreferencesObj.dashboardMetrics
        defaultMetricsOptions = metricsOptions.map((option) => {
          if (optionsFromUserPreferences.includes(option)) {
            return {
              title: option,
              display: true,
            }
          } else {
            return {
              title: option,
              display: false,
            }
          }
        })
        setOptions(defaultMetricsOptions)
      } else {
        const newUserPreferences: UserPreferenceType = {
          id: userId || Number(jwt.id),
          dashboardMetrics: [],
          portfolioMetrics: ['Net Profit', 'Current Value', 'Gain', 'MOIC', 'Industries'],
          selectedInvestors: investorIds,
          selectedGroups: [],
          filterType: FilterType.fund,
        }
        setUserPreferences(newUserPreferences)

        // set default preferences
        dispatch({ type: Actions.setFilterType, payload: FilterType.fund })
        dispatch({ type: Actions.setGroupIds, payload: [] })
        dispatch({
          type: Actions.setInvestorIds,
          payload: investorIds,
        })

        defaultMetricsOptions = metricsOptions.map((option) => {
          return {
            title: option,
            display: false,
          }
        })
        setOptions(defaultMetricsOptions)
      }
    }
  }, [data])

  // setting Portfolio data
  useEffect(() => {
    let ports: Portfolio[] = []
    let totalCompanies: string[] = []
    if (groupCompanies !== undefined && groupCompanies.length > 0) {
      data?.investors?.forEach((investor: Investor, index: number) => {
        let totalInvested = 0
        let currentValue = 0
        let totalIRR = 0
        let count = 0
        let upRounds = 0
        let rounds = 0
        let leadInvestments = 0
        let holdingsFromGroup = investor.holdings?.filter((holding) =>
          groupCompanies.includes(holding?.name!)
        )

        holdingsFromGroup?.forEach((item) => {
          if (!totalCompanies.includes(item?.name!)) {
            totalCompanies.push(item?.name!)
          }
        })

        holdingsFromGroup?.forEach((holding) => {
          if (!holding?.isExited) {
            totalInvested += holding?.investedAmount!
            currentValue += holding?.currentValue!
            totalIRR += holding?.irr!
          }
        })

        const exitData: Transaction[] = getExitData.data?.exitDataInvestorWise?.filter(
          (t) => t?.investorName === investor.name
        ) as unknown as Transaction[]

        ports.push({
          investorId: investor.id,
          investorName: investor.name!,
          totalInvested: totalInvested,
          gain: currentValue - totalInvested,
          moic: currentValue === 0 ? '0' : (currentValue / totalInvested).toFixed(2),
          currentValue: currentValue,
          avgRoundSize: 0,
          irr: 0,
          portfolioCompanies: holdingsFromGroup ? totalCompanies.length : 0,
          totalInvestments: -1,
          upRounds: 0,
          exits: 0,
          rounds: 0,
          leadInvestments: 0,
          investments: [],
          exitData: exitData,
        })

        groupCompanies.forEach((group) => {
          let index = getAllInvestments.data?.allInvestments?.findIndex(
            (company) => company.companyData.name == group
          )

          if (index !== undefined && index > -1) {
            let company = getAllInvestments.data?.allInvestments?.at(index)
            company.investmentData.forEach((investment: any) => {
              let portIndex = -1
              portIndex = ports.findIndex(
                (portfolio) =>
                  portfolio.investorName.toString().toLowerCase() ===
                  investment.name.toString().toLowerCase()
              )
              if (
                portIndex > -1 &&
                investment.name.toString().toLowerCase() === investor.name?.toString().toLowerCase()
              ) {
                investment.companyName = company.companyData.name
                investment.cin = company.companyData.cin
                investment.industry = company.companyData.industry
                ports[portIndex].investments.push(investment)
                count++
                if (investment.lead_investor_name === investment.name) {
                  leadInvestments++
                }
                let fyYear = new Date().getFullYear()
                const month = new Date().getMonth()
                if (month < 3) {
                  fyYear--
                }
                if (new Date(investment.dateInvested) > new Date(fyYear, 3, 1)) {
                  rounds++
                }
              }
            })
          }
        })

        ports.slice(-1)[0].upRounds = upRounds
        ports.slice(-1)[0].totalInvestments = count
        ports.slice(-1)[0].avgRoundSize = totalInvested / count
        ports.slice(-1)[0].irr = currentValue === 0 ? 0 : totalIRR / count
        ports.slice(-1)[0].leadInvestments = leadInvestments
        ports.slice(-1)[0].rounds = rounds
      })
    } else {
      data?.investors?.forEach((investor: Investor, index: number) => {
        let totalInvested = 0
        let currentValue = 0
        let totalIRR = 0
        let count = 0
        let upRounds = 0
        let rounds = 0
        let leadInvestments = 0

        investor.holdings?.forEach((holding) => {
          if (!holding?.isExited) {
            totalInvested += holding?.investedAmount!
            currentValue += holding?.currentValue!
            totalIRR += holding?.irr!
          }
        })

        const exitData: Transaction[] = getExitData.data?.exitDataInvestorWise?.filter(
          (t) => t?.investorName === investor.name
        ) as unknown as Transaction[]

        ports.push({
          investorId: investor.id,
          investorName: investor.name!,
          totalInvested: totalInvested,
          gain: currentValue - totalInvested,
          moic: currentValue === 0 ? '0' : (currentValue / totalInvested).toFixed(2),
          currentValue: currentValue,
          avgRoundSize: 0,
          irr: 0,
          portfolioCompanies: investor.holdings ? investor.holdings.length : 0,
          totalInvestments: -1,
          upRounds: 0,
          exits: 0,
          rounds: 0,
          leadInvestments: 0,
          investments: [],
          exitData: exitData,
        })

        getAllInvestments.data?.allInvestments?.forEach((company) => {
          company.investmentData.forEach((investment: any) => {
            let index = -1
            index = ports.findIndex((portfolio: any) => {
              return portfolio.investorName === investment.name
            })
            if (index > -1 && investment.name === investor.name) {
              investment.companyName = company.companyData.name
              investment.cin = company.companyData.cin
              investment.industry = company.companyData.industry
              ports[index].investments.push(investment)
              count++
              if (investment.lead_investor_name === investment.name) {
                leadInvestments++
              }
              let fyYear = new Date().getFullYear()
              const month = new Date().getMonth()
              if (month < 3) {
                fyYear--
              }
              if (new Date(investment.dateInvested) > new Date(fyYear, 3, 1)) {
                rounds++
              }
            }
          })
        })
        ports.slice(-1)[0].upRounds = upRounds
        ports.slice(-1)[0].totalInvestments = count
        ports.slice(-1)[0].avgRoundSize = totalInvested / count
        ports.slice(-1)[0].irr = currentValue === 0 ? 0 : totalIRR / count
        ports.slice(-1)[0].leadInvestments = leadInvestments
        ports.slice(-1)[0].rounds = rounds
      })
    }

    setPortfolios(ports)
  }, [
    ctxFilterType,
    ctxGroupIds,
    ctxInvestorIds,
    data,
    getAllInvestments.data,
    getExitData.data,
    groupCompanies,
    selectedIds,
  ])

  //setting investor names
  useEffect(() => {
    let investorNamesArray: string[] = []
    portfolios.forEach((p: Portfolio) => {
      if (selectedIds?.includes(Number(p.investorId))) {
        investorNamesArray.push(p.investorName)
      }
    })

    setInvestorNames(investorNamesArray)
    timeSelection()
  }, [selectedIds, portfolios])

  // building multi-select dropdown
  useEffect(() => {
    const funds: SelectOptionType[] = portfolios.map((p: Portfolio) => {
      return { value: p.investorId, label: p.investorName, type: FilterType.fund }
    })

    const groups: SelectOptionType[] = getAllClassifications.data?.classifications.map(
      (group: ClassificationType) => {
        return { value: group.id, label: group.name, type: FilterType.group }
      }
    )
    setFundOptions(funds)
    setGroupOptions(groups)
  }, [getAllClassifications.data, portfolios])

  // setting Group Companies
  useEffect(() => {
    let selectedGroupCompanies: string[] = []
    getAllClassifications?.data?.classifications
      .filter((group: ClassificationType) => selectedGroupIds.includes(Number(group.id)))
      .forEach((group: ClassificationType) =>
        group.companies.forEach((comp) => selectedGroupCompanies.push(comp.companyName))
      )
    setGroupCompanies(selectedGroupCompanies)
  }, [getAllClassifications?.data, selectedGroupIds])

  // setting data for donuts
  useEffect(() => {
    let industryData: Industry[] = []
    let investmentData: Investment[] = [
      { range: '< 1mn', count: 0 },
      { range: '1-3mn', count: 0 },
      { range: '3-10mn', count: 0 },
      { range: '10-25mn', count: 0 },
      { range: '> 25mn', count: 0 },
    ]
    let index = 0
    let exits = 0
    let rounds = 0
    let leadInvestments = 0
    let totalInvestments = 0
    let gain = 0
    let moic = 0
    const compDone: string[] = []

    selectedIds?.forEach((investorId) => {
      let index = portfolios.findIndex((portfolio) => {
        return portfolio.investorId == investorId
      })

      if (index > -1) {
        index += portfolios[index].upRounds
        exits += portfolios[index].exits
        rounds += portfolios[index].rounds
        leadInvestments += portfolios[index].leadInvestments
        totalInvestments += portfolios[index].totalInvestments
        gain += portfolios[index].gain
        moic += Number(portfolios[index].moic)

        const investorData = data?.investors?.filter((e: any) => e.id == investorId)

        if (investorData && investorData.length > 0) {
          const holdings = investorData[0].holdings
          if (groupCompanies && groupCompanies.length > 0) {
            holdings.forEach((c: any) => {
              if (groupCompanies.includes(c.name)) {
                if (!c.isExited && !compDone.includes(c.name)) {
                  if (c.industry === null || c.industry === '') {
                    let othersObj = industryData.find(
                      (industry) => industry.industryName === 'Others'
                    )
                    if (othersObj) {
                      othersObj.count += 1
                    } else {
                      industryData.push({ industryName: 'Others', count: 1 })
                    }
                  } else {
                    let industryObj = industryData.find(
                      (industry) => industry.industryName === c.industry
                    )
                    if (industryObj) {
                      industryObj.count += 1
                    } else {
                      industryData.push({ industryName: c.industry, count: 1 })
                    }
                  }
                  compDone.push(c.name)
                }
              }
            })
          } else {
            holdings.forEach((c: any) => {
              if (!c.isExited && !compDone.includes(c.name)) {
                if (c.industry === null || c.industry === '') {
                  let othersObj = industryData.find(
                    (industry) => industry.industryName === 'Others'
                  )
                  if (othersObj) {
                    othersObj.count += 1
                  } else {
                    industryData.push({ industryName: 'Others', count: 1 })
                  }
                } else {
                  let industryObj = industryData.find(
                    (industry) => industry.industryName === c.industry
                  )
                  if (industryObj) {
                    industryObj.count += 1
                  } else {
                    industryData.push({ industryName: c.industry, count: 1 })
                  }
                }
                compDone.push(c.name)
              }
            })
          }
        }
        industryData.sort((a, b) => b.count - a.count)
        if (industryData.length > 5) {
          const others: Industry[] = industryData.filter((e) =>
            e.industryName.toLowerCase().includes('other')
          )
          if (others.length > 0) {
            const nonOtherIndustries = industryData.filter(
              (e) => !e.industryName.toLowerCase().includes('other')
            )
            nonOtherIndustries.sort((a, b) => b.count - a.count)
            const sum = nonOtherIndustries.slice(4).reduce((acc, e) => acc + e.count, 0)
            const othersSum = others.reduce((acc, e) => acc + e.count, 0)
            const data: Industry = { industryName: 'Others', count: sum + othersSum }
            industryData = [...nonOtherIndustries.slice(0, 4), data]
          } else {
            const data: Industry = {
              industryName: 'Others',
              count: industryData.slice(4).reduce((acc, e) => acc + e.count, 0),
            }
            industryData = [...industryData.slice(0, 4), data]
          }
        }
        const investments: any[] = []
        const invName = portfolios[index].investorName
        if (groupCompanies && groupCompanies.length > 0) {
          getAllInvestments.data?.allInvestments?.forEach((company) => {
            if (groupCompanies.includes(company.companyData.name))
              company.investmentData.forEach((investment: any) => {
                if (investment.name.toLowerCase() === invName.toLowerCase()) {
                  investment.companyName = company.companyData.name
                  investment.cin = company.companyData.cin
                  investment.industry = company.companyData.industry
                  investments.push(investment)
                }
              })
          })
        } else {
          getAllInvestments.data?.allInvestments?.forEach((company) => {
            company.investmentData.forEach((investment: any) => {
              if (investment.name.toLowerCase() === invName.toLowerCase()) {
                investment.companyName = company.companyData.name
                investment.cin = company.companyData.cin
                investment.industry = company.companyData.industry
                investments.push(investment)
              }
            })
          })
        }
        investments.forEach((investment: any) => {
          // setting investment data for donut
          let investmentObj
          if (parseFloat(investment.investedAmount) < 1000000) {
            investmentObj = investmentData.find((investment) => investment.range === '< 1mn')
            if (investmentObj) investmentObj!.count += 1
          } else if (
            parseFloat(investment.investedAmount) >= 1000000 &&
            parseFloat(investment.investedAmount) < 3000000
          ) {
            investmentObj = investmentData.find((investment) => investment.range === '1-3mn')
            if (investmentObj) investmentObj!.count += 1
          } else if (
            parseFloat(investment.investedAmount) >= 3000000 &&
            parseFloat(investment.investedAmount) < 10000000
          ) {
            investmentObj = investmentData.find((investment) => investment.range === '3-10mn')
            if (investmentObj) investmentObj!.count += 1
          } else if (
            parseFloat(investment.investedAmount) >= 10000000 &&
            parseFloat(investment.investedAmount) < 25000000
          ) {
            investmentObj = investmentData.find((investment) => investment.range === '10-25mn')
            investmentObj!.count += 1
          } else if (parseFloat(investment.investedAmount) >= 25000000) {
            investmentObj = investmentData.find((investment) => investment.range === '> 25mn')
            if (investmentObj) investmentObj!.count += 1
          }
        })
      }
    })
    if (selectedIds) {
      // calculating Portfolio Metrics
      let total = 0
      let currVal = 0
      let count = 0
      let irrData: any = []
      let irr: number | null = 0
      let totalCompanies: string[] = []

      portfolios.forEach((investments: Portfolio) => {
        if (
          selectedIds?.includes(investments.investorId) ||
          selectedIds?.includes(Number(investments.investorId))
        ) {
          total += investments.totalInvested
          currVal += investments.currentValue
          count += investments.totalInvestments
          investments?.investments.forEach((inv: any) => {
            if (selectedIds.length > 0) {
              if (inv.dateInvested && inv.dateInvested !== '')
                irrData.push({ amount: inv.investedAmount * -1, date: new Date(inv.dateInvested) })

              if (!totalCompanies.includes(inv.companyName)) totalCompanies.push(inv.companyName)
            }
          })
          investments.exitData?.forEach((t) => {
            let amount = Number(t.pricePerShare) * Number(t.noOfShares)
            if (t.transactionType === 'SELL') {
              amount = -1 * amount
            }
            if (selectedIds.length > 0) {
              irrData.push({ amount: amount * -1, date: new Date(t.transactionDate!) })
            }
          })
        }
      })
      if (selectedIds.length > 0) {
        irrData.push({ amount: currVal, date: new Date() })
      }
      if (irrData.length > 1) {
        if (isNaN(convertRate(xirr(irrData).rate, RateInterval.Year))) {
          const values = irrData.map((value: any) => value.amount)
          const dates = irrData.map((date: any) => new Date(date.date))
          const irrValue = calculateXirr(values, dates)
          irr = irrValue !== 'NA' ? irrValue * 100 : null
        } else {
          irr = convertRate(xirr(irrData).rate, RateInterval.Year) * 100
        }
      }
      setPortfolioMetrics({
        avgRoundSize: count > 0 ? total / count : 0,
        index: selectedIds.length > 1 ? index / selectedIds.length : index,
        exits: exits,
        rounds: rounds,
        leadInvestments: leadInvestments,
        totalInvestments: totalInvestments,
        portfolioCompanies: totalCompanies.length,
        gain: gain,
        moic: selectedIds.length > 1 ? currVal / total : moic,
        irr: irr!,
      })
    }
    setIndustriesChartData(industryData)
    setInvestmentsChartData(investmentData)
  }, [selectedIds, getAllInvestments.data, portfolios, data?.investors, groupCompanies])

  const bgColors: string[] = []
  for (let i = 0; i < 10; i++) {
    const color = getCSSVariableValue('--kt-donut-' + i)
    bgColors.push(color)
  }

  const investorsList: any[] = []
  const investorwiseGrouped = _.groupBy(data?.investors, (data) => [data.id])
  let holdings: any[] = []

  const keys = Object.keys(investorwiseGrouped)
  selectedIds?.forEach((id) => {
    keys.forEach((key) => {
      if (String(id) === key) {
        investorsList.push(...investorwiseGrouped[id])
      }
    })
  })
  investorsList?.forEach((investor: any) => {
    investor.holdings.forEach((holding: any) => {
      if (!holding.isExited) {
        holdings.push(holding)
      }
    })
  })

  const industryChartData = {
    labels: industriesChartData.map((industry) => industry.industryName),
    datasets: [
      {
        label: '# of Industries',
        data: industriesChartData.map((industry) => industry.count),
        backgroundColor: bgColors,
        borderColor: bgColors,
        count: industriesChartData.reduce((a, b) => a + b.count, 0),
        investors_selected_count: investorsList.length,
        investors: investorsList,
      },
    ],
  }

  const investmentChartData = {
    labels: investmentsChartData.map((investment) => investment.range),
    datasets: [
      {
        label: '# of Investments',
        data: investmentsChartData.map((investment) => investment.count),
        backgroundColor: bgColors,
        borderColor: bgColors,
        count: investmentsChartData.reduce((a, b) => a + b.count, 0),
        investors_selected_count: investorsList.length,
        investors: investorsList,
      },
    ],
  }

  function companiesSelection() {
    setDataToggle('companies')
    setChartHeader('Total Invested over companies')
  }

  function timeSelection() {
    setDataToggle('time')
    setChartHeader('Total Invested over time')
  }

  function handleFundSelect(
    e: MultiValue<SelectOptionType>,
    actionMeta: ActionMeta<SelectOptionType>
  ) {
    if (e.length === 0) {
      setGroupCompanies([])
      setIsActive(false)
      dispatch({ type: Actions.setFilterType, payload: FilterType.all })
      dispatch({ type: Actions.setInvestorIds, payload: [] })
      dispatch({ type: Actions.setGroupIds, payload: [] })
    } else if (e.some((item) => item.type === FilterType.group)) {
      let groups: any[] = []
      setIsActive(true)

      e.forEach((item) => {
        groups.push(item)
      })
      handleGroupSelect(groups)
    } else {
      dispatch({
        type: Actions.setFilterType,
        payload: FilterType.fund,
      })
      setGroupCompanies([])
      let selected = e.map((item) => Number(item.value))
      dispatch({ type: Actions.setInvestorIds, payload: selected })
      dispatch({ type: Actions.setGroupIds, payload: [] })

      selected.length <= 0 ? setIsActive(false) : setIsActive(true)
    }
  }

  function handleGroupSelect(e: MultiValue<SelectOptionType>) {
    dispatch({
      type: Actions.setFilterType,
      payload: FilterType.group,
    })

    let selected = portfolios.map((item) => Number(item.investorId))
    dispatch({ type: Actions.setInvestorIds, payload: selected })
    selected.length <= 0 ? setIsActive(false) : setIsActive(true)
    let arr = e.map((e) => Number(e.value))
    dispatch({ type: Actions.setGroupIds, payload: arr })
  }

  useEffect(() => {
    setSelectedIds(ctxInvestorIds)
    return () => setSelectedIds([])
  }, [ctxInvestorIds])

  useEffect(() => {
    setSelectedGroupIds(ctxGroupIds)
  }, [ctxGroupIds])

  useEffect(() => {
    if (ctxFilterType === FilterType.all) {
      setIsActive(false)
    }
  }, [ctxFilterType])

  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  if (error) return <p>{error.message}</p>

  return (
    <>
      <h1 className='pb-5 display-7'>Welcome {jwt.fullName}!</h1>
      {portfolios.length >= 1 ? (
        <div className='row gy-4'>
          <div className='col-sm-12'>
            <h1>Please choose the classification you would like to view</h1>
          </div>
          <div className='col-sm-6 col-xl-8 mt-0'>
            <div className='pb-5'>
              {fundOptions?.length > 0 && (
                <Select
                  ref={multiSelectRef}
                  className='w-100 text-primary'
                  options={[
                    { label: 'Investors / Funds', options: fundOptions },
                    { label: 'Classification Groups', options: groupOptions },
                  ]}
                  backspaceRemovesValue
                  value={
                    ctxFilterType === FilterType.fund
                      ? ctxInvestorIds?.length > 0
                        ? fundOptions?.filter((option) =>
                            ctxInvestorIds?.includes(Number(option.value))
                          )
                        : [...fundOptions]
                      : ctxGroupIds?.length > 0
                      ? groupOptions?.filter((option) =>
                          ctxGroupIds?.includes(Number(option.value))
                        )
                      : []
                  }
                  isMulti
                  closeMenuOnSelect={false}
                  styles={multiSelectCustomStyles}
                  components={animatedComponents}
                  onChange={handleFundSelect}
                  isOptionDisabled={(option) =>
                    option.type !== ctxFilterType && ctxFilterType !== FilterType.all
                  }
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {active ? (
        <div className='row gy-4'>
          <div className='col-sm-6 col-lg-6 col-xl-8 '>
            <div className='row'>
              <div className='col-sm-12 col-xl-6'>
                {selectedIds && (
                  <PortfolioCompaniesCard
                    investments={portfolios}
                    investors={data?.investors}
                    investorIds={selectedIds || [Number(fundOptions[0].value)]}
                    companies={groupCompanies}
                  />
                )}
                {/* <NotificationsTab className='' /> */}
                <div className='mb-4'>
                  {showIndustryDonut && selectedIds && (
                    <DonutWithCloseButton
                      header='by Industry'
                      data={industryChartData}
                      handleClose={() => setShowIndustryDonut(false)}
                    />
                  )}
                </div>
                <div className='overflow-auto scrollable mb-4'>
                  {
                    <>
                      {showInvestmentDonut && selectedIds && (
                        <DonutWithCloseButton
                          header='by Investments'
                          data={investmentChartData}
                          handleClose={() => setShowInvestmentDonut(false)}
                        />
                      )}
                    </>
                  }
                </div>
              </div>
              <div className='col-sm-12 col-xl-6 mb-4'>
                <div className=' overflow-auto scrollable'>
                  {selectedIds ? (
                    <PortfolioMetrics
                      avgRoundSize={portfolioMetrics.avgRoundSize}
                      index={portfolioMetrics.index}
                      exits={portfolioMetrics.exits}
                      rounds={portfolioMetrics.rounds}
                      leadInvestments={portfolioMetrics.leadInvestments}
                      totalInvestments={portfolioMetrics.totalInvestments}
                      portfolioCompanies={portfolioMetrics.portfolioCompanies}
                      gain={portfolioMetrics.gain}
                      moic={portfolioMetrics.moic}
                      irr={portfolioMetrics.irr}
                      investorIds={selectedIds}
                      companies={groupCompanies}
                      handleOptions={updateOptions}
                      activeOptCards={options}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className='mb-4'>
                  {selectedIds && (
                    <DonutByStage
                      title='Portfolio by Stage'
                      investorId={selectedIds || [fundOptions[0].value]}
                      investors={investorsList}
                      handleIndustryShow={() => setShowIndustryDonut(!showIndustryDonut)}
                      handleInvestmentShow={() => setShowInvestmentDonut(!showInvestmentDonut)}
                      showIndustryDonut={showIndustryDonut}
                      showInvestmentDonut={showInvestmentDonut}
                      companies={groupCompanies}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='col-sm-6 col-lg-6 col-xl-4'>
            <div className='card overflow-auto mb-4 min-h-600px mh-600px'>
              <div className='card-body col justify-content-between'>
                {portfolios.length > 1 ? <h1 className='mb-4'>Portfolio Overview</h1> : <></>}
                {selectedIds && getAllClassifications.isSuccess ? (
                  <ClassificationContextProvider
                    allInvestments={getAllInvestments.data!}
                    investors={data!}
                    groups={getAllClassifications.data}
                    selectedGroups={selectedGroupIds}
                    watchlists={getWatchlists.data!}
                  >
                    <PortfolioCardsOverview
                      investorIds={selectedIds || fundOptions.map((fund) => Number(fund.value))}
                      type={ctxFilterType}
                      allInvestments={getAllInvestments.data}
                      exitData={getExitData?.data?.exitDataInvestorWise!}
                    ></PortfolioCardsOverview>
                  </ClassificationContextProvider>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className='overflow-auto scrollable mb-4 z-index-0'>
              <WatchlistHighlights companies={groupCompanies} />
            </div>
            <div className='card overflow-auto scrollable mb-4'>
              <TaskOverview
                exitData={getExitData?.data?.exitDataInvestorWise!}
                companies={groupCompanies}
              />
            </div>
          </div>
          <div
            className={`col-sm-12 ${
              selectedIds && investorNames.length > 40
                ? 'col-md-12 col-lg-12 col-xl-12'
                : 'col-md-8 col-lg-8 col-xl-8'
            }`}
          >
            <div className='card h-100'>
              <div className='card-header mt-5'>
                <div className='col-md-8'>
                  <h1 className='pb-5 mb-5'>{chartHeader}</h1>
                </div>
                <div className='col-md-4'>
                  <div className='btn-group mb-10 float-lg-end float-md-end'>
                    <button
                      className={`btn btn-sm fs-5 ${
                        dataToggle === 'companies'
                          ? 'btn-primary btn-active-primary'
                          : 'btn-gray-800 btn-bg-light'
                      }`}
                      onClick={companiesSelection}
                    >
                      Companies
                    </button>
                    <button
                      className={`btn btn-sm fs-5 ${
                        dataToggle === 'time'
                          ? 'btn-primary btn-active-primary'
                          : 'btn-gray-800 btn-bg-light'
                      }`}
                      onClick={timeSelection}
                    >
                      Time
                    </button>
                  </div>
                </div>
              </div>
              <div className='card-body overflow-auto'>
                {selectedIds && dataToggle === 'companies' && getAllInvestments.data
                  ? investorNames.length > 0 && (
                      <StackedBarChart
                        investorNames={investorNames}
                        allInvestments={getAllInvestments.data.allInvestments!}
                        companies={groupCompanies}
                      />
                    )
                  : selectedIds &&
                    getAllInvestments.data &&
                    getExitData.data &&
                    investorNames.length > 0 && (
                      <LineChart
                        investorNames={investorNames}
                        allInvestments={getAllInvestments.data.allInvestments!}
                        exitData={getExitData.data.exitDataInvestorWise!}
                        companies={groupCompanies}
                      />
                    )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
