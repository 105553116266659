import React, { useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormikContext, useField, FormikProps } from 'formik'
import { countryData } from '../../../../../../utils/CountryData'
import { IAddInvestmentData } from '../AddInvestmentHelper'
import {
  useGetCurrencyExchangeRateMutation,
  GetCurrencyExchangeRateMutationVariables,
  GetCurrencyExchangeRateMutation,
  CurrencyExchangeInput,
  CurrencyExchangeRate,
} from '../../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../../queries/client'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../../utils/Select'
const countryList: any[] = []
const currencyList: any[] = []
const investorName: any[] = []

type Props = {
  investorList: any[]
  handleChange: (e: any) => any
}

const CurrencyField = (props: any) => {
  const formik = useFormikContext()
  const [currency, setCurrency] = useState<any>()
  const {
    values: { country },
    touched,
    setFieldValue,
  } = useFormikContext<IAddInvestmentData>()
  const [field, meta] = useField(props)

  const mutateCurrencyExchangeRate = useGetCurrencyExchangeRateMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: GetCurrencyExchangeRateMutation,
        _variables: GetCurrencyExchangeRateMutationVariables,
        _context: unknown
      ) => {
        setFieldValue('currentExchangeRate', data.getCurrencyExchangeRate?.currencyRate)
        props.setCerDate(data.getCurrencyExchangeRate?.updatedAt)
        return console.log(
          'result from currency exchange rate query:',
          data.getCurrencyExchangeRate
        )
      },
      onError(error: Error) {
        setFieldValue('currentExchangeRate', '')
        return console.log(
          'Error from currency exchange rate query:',
          error.message.substring(0, 30)
        )
      },
    },
    {}
  )

  function getCurrencyExchangeRate(currencyName?: string, currencyCode?: string) {
    mutateCurrencyExchangeRate.mutate({
      input: {
        currencyName: currencyName,
        currencyCode: currencyCode,
      },
    })
  }

  React.useEffect(() => {
    // set the value of currency, based on country
    if (country.trim() !== '' && touched.country) {
      var value = countryData.find((item) => item.name === country)
      setFieldValue(props.name, value?.currency.code + country)
      setCurrency({
        value:
          props.value?.name + '-' + props.value?.currency.code + '-' + props.value?.currency.symbol,
        label:
          props.value?.name + '-' + props.value?.currency.code + '-' + props.value?.currency.symbol,
      })
      if (value?.currency.code === 'INR') {
        setFieldValue('exchangeRate', 1)
        setFieldValue('currentExchangeRate', 1)
      } else {
        setFieldValue('exchangeRate', '')
        getCurrencyExchangeRate(value?.currency.name, value?.currency.code)
      }
    }
  }, [country, touched.country, setFieldValue, props.name])

  return (
    <>
      <Select
        className=''
        options={currencyList.filter((value) => Object.keys(value).length !== 0)}
        name='currency'
        value={currency}
        styles={selectCustomStyles}
        onChange={(e: any) => (
          setCurrency(e), setFieldValue('currency', props.value?.currency.code + props.value?.name)
        )}
      />

      {!!meta.touched && !!meta.error && (
        <div className='text-danger fs-7 mt-1'>
          <ErrorMessage name='currency' />
        </div>
      )}
    </>
  )
}

const Step3 = ({ investorList, handleChange }: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [cerDate, setCerDate] = useState<string>()
  let date = new Date()
  let maxDate = date.toISOString().split('T')[0]
  const formik = useFormikContext()

  countryData.map((country) => {
    !countryList.find((nameObj) => nameObj.label === country.name)
      ? countryList.push({ value: country.name, label: country.name })
      : currencyList.push(
          !currencyList.find(
            (nameObj) => nameObj.value === country.currency.code + '-' + country.name
          )
            ? {
                value: country.currency.code + '-' + country.name,
                label:
                  country.name + ' - ' + country.currency.code + ' - ' + country.currency.symbol,
              }
            : {}
        )
  })

  investorList.map((investor: any) =>
    !investorName.find((invObj) => invObj.value === investor.investorId)
      ? investorName.push({
          value: investor.investorId,
          label: investor.investorName,
        })
      : {}
  )

  return (
    <div className='w-100 mx-15'>
      <div className='pb-6 pb-lg-8'>
        <h2 className='fw-bolder text-dark'>Add Manual Investment</h2>
      </div>
      <div className='separator mb-8'></div>
      <div className='row'>
        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label required'>Select Country</label>

          <Select
            className=''
            options={countryList}
            name='country'
            placeholder={'India'}
            styles={selectCustomStyles}
            onChange={(e: any) => {
              handleChange(e.value)
              formik.setFieldValue('country', e.value)
              if (e.value !== 'India') {
                setIsVisible(true)
              } else {
                setIsVisible(false)
              }
            }}
          />
          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='country' />
          </div>
        </div>

        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label required'>Select Investor or Fund</label>

          <Select
            className=''
            options={investorName}
            name='investorId'
            onChange={(e: any) => formik.setFieldValue('investorId', e.value)}
            styles={selectCustomStyles}
          />

          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='investorId' />
          </div>
        </div>

        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label required'>Company Name</label>

          <Field
            name='companyName'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter Company Name'
          />
          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='companyName' />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label required'>Select Currency</label>
          <CurrencyField
            name='currency'
            setCerDate={setCerDate}
            value={countryData.find((item) => item.name === formik.getFieldProps('country').value)}
          />
        </div>

        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label required'>Industry Type</label>

          <Field
            name='industryType'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter Industry Type'
          />
          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='industryType' />
          </div>
        </div>

        {isVisible && (
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Exchange Rate</label>

            <Field
              name='exchangeRate'
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Exchange Rate'
            />
            <div className='text-danger fs-7 mt-1'>
              <ErrorMessage name='exchangeRate' />
            </div>
          </div>
        )}
      </div>
      <div className='row'>
        {isVisible && (
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label'>Current Exchange Rate</label>

            <Field
              name='currentExchangeRate'
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Current Exchange Rate'
            />
            <div className='text-danger fs-7 mt-1'>
              <ErrorMessage name='currentExchangeRate' />
            </div>
            {cerDate && (
              <div className='ps-3 fs-7'>
                As Of: {new Date(Number(cerDate)).toISOString().split('T')[0]}
              </div>
            )}
          </div>
        )}

        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label required'>Share Price</label>

          <Field
            name='sharePrice'
            type='number'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter Share Price'
          />
          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='sharePrice' />
          </div>
        </div>

        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label '>Current Share Price</label>

          <Field
            name='currentSharePrice'
            type='number'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter Current Share Price'
          />
          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='currentSharePrice' />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label'>Company ID</label>

          <Field
            name='companyId'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter Company ID'
          />
          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='companyId' />
          </div>
        </div>

        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label required'>Investment Date</label>

          <Field
            type='date'
            max={maxDate}
            name='investmentDate'
            className='form-control form-control-lg form-control-solid'
          />

          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='investmentDate' />
          </div>
        </div>

        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label required'>Number of Shares</label>

          <Field
            name='noOfShares'
            type='number'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter Number of Shares'
          />
          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='noOfShares' />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label required'>Valuation</label>

          <Field
            name='valuation'
            type='number'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter Valuation'
          />
          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='valuation' />
          </div>
        </div>

        <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-4'>
          <label className='form-label required'>Round Details</label>

          <Field
            name='roundDetails'
            className='form-control form-control-lg form-control-solid'
            placeholder='Enter Round Details'
          />
          <div className='text-danger fs-7 mt-1'>
            <ErrorMessage name='roundDetails' />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step3 }
