import { useEffect, useState } from 'react'
import { IconUserModel } from '../../../modules/profile/ProfileModels'
import { Holding, Investor, Maybe } from '../../../../generated/graphql'
import { AddInvestmentModal } from '../../manual-investments/components/AddInvestment/AddInvestmentModal'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { getInitials, setupInitials } from '../../../../utils/Utils'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import _ from 'lodash'

type Props = {
  investors: any[]
  investments: any[]
  investorIds: number[]
  companies?: string[]
}

const PortfolioCompaniesCard = (props: Props) => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [holdings, setHoldings] = useState<IconUserModel[]>([])

  useEffect(() => {
    let holdingsList: IconUserModel[] = []
    let originalData: any = []

    props.investors.forEach((investor: Investor) => {
      investor.holdings?.forEach((holding: any) => {
        if (props.companies !== undefined && props.companies?.length > 0) {
          if (props.companies?.includes(holding?.name)) {
            originalData.push({
              investorId: investor.id,
              investorName: investor.name,
              name: holding?.name,
              companyId: holding?.cin,
              logo: null,
              hissaVerified: holding?.hissaVerified,
            })
          }
        } else {
          originalData.push({
            investorId: investor.id,
            investorName: investor.name,
            name: holding?.name,
            companyId: holding?.cin,
            logo: null,
            hissaVerified: holding?.hissaVerified,
          })
        }
      })
    })

    originalData.sort((a: any, b: any) => a.name.localeCompare(b.name))
    const initialsMap = setupInitials(originalData)

    props.investors.forEach((investor: Investor) => {
      if (
        props.investorIds?.includes(Number(investor.id)) ||
        props.investorIds?.includes(investor.id)
      ) {
        investor.holdings?.forEach((holding: Maybe<Holding>, index: number) => {
          if (!holding?.isExited) {
            if (props.companies && props.companies?.length > 0) {
              if (props.companies?.includes(holding?.name!)) {
                const initColours = initialsMap.get(holding?.name!)
                holdingsList.push({
                  investorId: investor.id,
                  name: holding?.name!,
                  companyId: holding?.cin!,
                  hissaVerified: holding?.hissaVerified!,
                  avatar: holding?.logo!,
                  initials: initColours.initials,
                  color: getCSSVariableValue('--kt-donut-' + ((initColours.count + 9) % 10)),
                })
              }
            } else {
              const initColours = initialsMap.get(holding?.name!)
              holdingsList.push({
                investorId: investor.id,
                name: holding?.name!,
                companyId: holding?.cin!,
                hissaVerified: holding?.hissaVerified!,
                avatar: holding?.logo!,
                initials: initColours.initials,
                color: getCSSVariableValue('--kt-donut-' + ((initColours.count + 9) % 10)),
              })
            }
          }
        })
      }
    })

    setHoldings(holdingsList)
  }, [props, props.investorIds.length])

  const PortCard = (cProps: any) => {
    const inv = props.investments.filter((h: any) => h.investorId == cProps.invId)
    const companyCount = holdings.filter((h: any) => h.investorId == inv[0]!.investorId).length
    return inv.length === 1 && companyCount > 0 ? (
      <div className='col p-1 d-flex justify-content-between align-items-center' key={cProps.invId}>
        <div className='card card-bordered h-auto min-w-100px p-1'>
          <span className='text-center fs-8 fw-bold' children={inv[0]!.investorName} />
          <div className='text-center'>
            {holdings
              .filter((h: any) => h.investorId == inv[0]!.investorId)
              .map((h: any, i: number) => (
                <OverlayTrigger
                  key={`${i}-${h.name}`}
                  placement='top'
                  overlay={<Tooltip id='tooltip-user-name'>{h.name}</Tooltip>}
                >
                  <div
                    className='symbol symbol-35px symbol-circle m-1 cursor-pointer'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate('/company/stakeholders', {
                        state: {
                          props: {
                            name: h.name,
                            companyId: h.companyId,
                            hissaVerified: h.hissaVerified,
                            initials: getInitials(h.name),
                          },
                        },
                      })
                    }}
                  >
                    {h.avatar && h.avatar !== '' && h.avatar !== 'NA' ? (
                      <img src={toAbsoluteUrl(h.avatar)} alt='' />
                    ) : (
                      h.initials && (
                        <span
                          className={'symbol-label fw-bolder fs-8 bg-secondary cursor-pointer'}
                          style={{ color: h.color }}
                        >
                          {getInitials(h.name)}
                        </span>
                      )
                    )}
                  </div>
                </OverlayTrigger>
              ))}
          </div>
        </div>
      </div>
    ) : (
      <></>
    )
  }

  function handleAddInvestment(e: React.MouseEvent) {
    e.stopPropagation()
    e.preventDefault()
    setShowModal(!showModal)
  }

  return (
    <>
      <div className='card card-hover mb-4 z-index-0' onClick={() => navigate('/portfolio')}>
        <div className='card-body'>
          <div className='row'>
            <div className='col d-flex justify-content-between align-items-center'>
              <div>
                <h1>Portfolio Companies</h1>
              </div>
              <div>
                <h1>{_.uniqBy(holdings, 'name').length}</h1>
              </div>
            </div>
          </div>
          <div className='row p-0 m-0 d-flex justify-content-between align-items-center'>
            {props.investorIds.map((inv, index) => (
              <PortCard key={`${index}-${inv}`} invId={inv} />
            ))}
          </div>
          <div className='row pt-2'>
            <div className='col d-flex justify-content-between'>
              {holdings.length > 0 ? (
                <div></div>
              ) : (
                <div>
                  <p className='fs-6'>No companies in the Portfolio</p>
                </div>
              )}
              <div>
                <button
                  className='btn btn-primary fs-base fw-bold'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                  onClick={(e) => handleAddInvestment(e)}
                >
                  Add +
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddInvestmentModal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        investorList={props.investments}
      />
    </>
  )
}

export default PortfolioCompaniesCard
