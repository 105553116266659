import { useContext, useEffect, useMemo, useState } from 'react'
import { UseQueryResult } from 'react-query'
import {
  GetPortfolioEventsQuery,
  GetTermsheetsQuery,
  useGetPortfolioEventsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { LineChartData } from '../../../../types/chartData'
import { kFormatter } from '../../../../utils/CurrencyFormatter'
import { GlobalContext } from '../../store/context/globalContext'
import KeyMetrics from './KeyMetrics'
import ScatterChart from './ScatterChart'

type Props = {
  getInvestors: any
  getAllInvestments: any
  chartColor: string
  strokeColor: string
  chartHeight: string
  companies?: string[]
  termsheetData: UseQueryResult<GetTermsheetsQuery, Error>
}

type Metric = {
  investorId: string
  investorName: string
  netProfit: number
  currentValue: number
  gain: number
  moic: number
  totalSharesValue: number
  openDeals: number
  industries: string
}

function ChartComponent(props: Props) {
  const { state } = useContext(GlobalContext)
  const { ctxInvestorIds } = state
  const [keyMetrics, setKeyMetrics] = useState<Metric[]>()
  const [compMap, setCompMap] = useState<any>([])
  const [investorIds, setInvestorIds] = useState<any>(ctxInvestorIds)
  const [centMilData, setCentMilData] = useState<any>([])
  const [centMilMap, setCentMilMap] = useState<any>([])
  const [unicornData, setUnicornData] = useState<any>([])
  const [unicornMap, setUnicornMap] = useState<any>([])
  let termsheetData = props.termsheetData

  useMemo(() => {
    let investorsData: any = []
    if (termsheetData.isSuccess) {
      props.getInvestors?.investors.forEach((investor: any) => {
        let openDealsCount =
          termsheetData?.data?.termsheets?.filter(
            (termsheet: any) =>
              props.companies?.includes(termsheet?.companyName) &&
              termsheet.investorId == investor.id
          ).length || 0

        if (ctxInvestorIds?.includes(Number(investor.id))) {
          let industries: any = []
          let currentValue = 0
          let totalInvested = 0
          let moic = 0

          investor.holdings?.forEach((holding: any) => {
            let indVal = null
            if (props.companies && props.companies.length > 0) {
              if (props.companies?.includes(holding.name)) {
                currentValue += holding?.currentValue || 0
                totalInvested += holding?.investedAmount || 0
                indVal = holding?.industry ? holding?.industry : 'Others'
                if (!industries.includes(indVal) && !holding?.isExited) {
                  industries.push(indVal)
                }
              }
            } else {
              currentValue += holding?.currentValue || 0
              totalInvested += holding?.investedAmount || 0
              indVal = holding?.industry ? holding?.industry : 'Others'
              if (!industries.includes(indVal) && !holding?.isExited) {
                industries.push(indVal)
              }
            }
          })

          moic = currentValue === 0 ? 0 : currentValue / totalInvested
          let gain = currentValue - totalInvested
          let netProfit = (gain / totalInvested) * 100 || 0
          investorsData.push({
            investorId: investor.id.toString(),
            investorName: investor.name,
            netProfit: netProfit,
            currentValue: currentValue,
            gain: gain,
            moic: moic,
            totalSharesValue: totalInvested,
            openDeals:
              props.companies && props.companies.length > 0
                ? openDealsCount
                : termsheetData.data?.termsheets?.filter(
                    (termsheet: any) => termsheet.investorId == investor.id
                  ).length,

            industries: industries,
          })
        }
      })
    }
    setKeyMetrics(investorsData)
    if (props.getInvestors?.investors.length > 0)
      setInvestorIds(props.getInvestors?.investors.map((a: any) => a.id))
    return investorsData
  }, [ctxInvestorIds, props.companies, props.getInvestors?.investors])

  const firstInvData = useMemo(() => {
    let fiData: LineChartData[] = []
    let cMap: any[] = []
    if (props.getAllInvestments && props.getAllInvestments.data) {
      const maxInvAmt = Math.max(
        ...props.getAllInvestments?.data?.allInvestments.map((o: any) =>
          o.investmentData.reduce((p: any, e: any) => p + e.investedAmount, 0)
        )
      )

      props.getAllInvestments?.data?.allInvestments.forEach((inv: any) => {
        if (props.companies && props.companies.length > 0) {
          if (props.companies.includes(inv.companyData.name)) {
            const comp = inv.companyData.name
            const compKey =
              inv.investmentData.reduce((p: any, e: any) => p + e.investedAmount, 0) / maxInvAmt +
              Math.random() / 1000
            cMap.push({ compKey, comp })

            const item: LineChartData = {
              x: inv.investmentData[0].dateInvested,
              y: compKey,
            }
            if (inv.investmentData[0].dateInvested!.length > 0) fiData.push(item)
          }
        } else {
          if (inv.investmentData.length > 0) {
            let data = inv.investmentData.filter((item: any) =>
              ctxInvestorIds?.includes(Number(item.investorId))
            )
            const comp = inv.companyData.name
            const compKey =
              data.reduce((p: any, e: any) => p + e.investedAmount, 0) / maxInvAmt +
              Math.random() / 1000
            cMap.push({ compKey, comp })

            const item: LineChartData = {
              x: data[0]?.dateInvested,
              y: compKey,
            }
            if (data[0]?.dateInvested!.length > 0) fiData.push(item)
          }
        }
      })
      setCompMap(cMap)
    }
    return fiData
  }, [ctxInvestorIds, props.companies, props.getAllInvestments])

  const input =
    props.companies !== undefined
      ? { investorIds: investorIds, companies: props.companies }
      : { investorIds: investorIds }

  const portEvents = useGetPortfolioEventsQuery<GetPortfolioEventsQuery, Error>(
    graphqlRequestClient,
    { input: input },
    { enabled: investorIds && investorIds.length > 0 }
  )

  useEffect(() => {
    let cmData: LineChartData[] = []
    let cmMap: any[] = []
    let uData: LineChartData[] = []
    let uMap: any[] = []
    portEvents.data?.portfolioEvents?.forEach((event: any) => {
      const comp = event.companyName
      if (props.companies && props.companies.length > 0) {
        if (props.companies.includes(comp)) {
          if (compMap.length > 0 && event.eventType === '100 Million Round') {
            const compKey = 1 + compMap.filter((e: any) => e.comp === event.companyName)[0].compKey
            const item: LineChartData = {
              x: event.eventDate,
              y: compKey,
            }
            if (!cmMap.some((e) => e.compKey === compKey && e.comp === comp)) {
              cmMap.push({ compKey, comp })
              cmData.push(item)
            }
          }
          if (compMap.length > 0 && event.eventType === 'Unicorn Status') {
            const compKey = 2 + compMap.filter((e: any) => e.comp === event.companyName)[0].compKey
            const item: LineChartData = {
              x: event.eventDate,
              y: compKey,
            }
            if (!uMap.some((e) => e.compKey === compKey && e.comp === comp)) {
              uMap.push({ compKey, comp })
              uData.push(item)
            }
          }
        }
      } else {
        if (compMap.length > 0 && event.eventType === '100 Million Round') {
          const compKey = 1 + compMap.filter((e: any) => e.comp === event.companyName)[0].compKey
          const item: LineChartData = {
            x: event.eventDate,
            y: compKey,
          }
          if (!cmMap.some((e) => e.compKey === compKey && e.comp === comp)) {
            cmMap.push({ compKey, comp })
            cmData.push(item)
          }
        }
        if (compMap.length > 0 && event.eventType === 'Unicorn Status') {
          const compKey = 2 + compMap.filter((e: any) => e.comp === event.companyName)[0]!?.compKey
          const item: LineChartData = {
            x: event.eventDate,
            y: compKey,
          }
          if (!uMap.some((e) => e.compKey === compKey && e.comp === comp)) {
            uMap.push({ compKey, comp })
            uData.push(item)
          }
        }
      }
    })
    setCentMilData(cmData)
    setCentMilMap(cmMap)
    setUnicornData(uData)
    setUnicornMap(uMap)
  }, [compMap, portEvents.data, props.companies])

  return (
    <div className='card-body p-0'>
      <div className='rounded-4' style={{ background: `linear-gradient(#4F86BF, #9ECEFF4D)` }}>
        <ScatterChart
          firstInvData={firstInvData}
          firstInvMap={compMap}
          centMilData={centMilData}
          centMilMap={centMilMap}
          unicornData={unicornData}
          unicornMap={unicornMap}
        />
      </div>
      <KeyMetrics
        currentValue={kFormatter(keyMetrics?.reduce((p, e) => p + e.currentValue, 0)!)}
        gain={kFormatter(keyMetrics?.reduce((p, e) => p + e.gain, 0)!)}
        // irr={37.5}
        netProfit={
          (
            (keyMetrics?.reduce((p, e) => p + e.gain, 0)! * 100) /
            keyMetrics?.reduce((p, e) => p + e.totalSharesValue, 0)!
          ).toFixed(2) + '%'
        }
        industries={
          Array.from(
            new Set(keyMetrics?.reduce((p, e) => (p = p.concat(e.industries)), [] as string[])!)
          ).length || 0
        }
        moic={
          (
            keyMetrics?.reduce((p, e) => p + e.currentValue, 0)! /
            keyMetrics?.reduce((p, e) => p + e.totalSharesValue, 0)!
          ).toFixed(2) + 'X'
        }
        totalShareValue={kFormatter(keyMetrics?.reduce((p, e) => p + e.totalSharesValue, 0)!)}
        openDeals={keyMetrics?.reduce((p, e) => p + e.openDeals, 0)!}
      />
    </div>
  )
}

export default ChartComponent
