import { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  useGetDealTemplatesQuery,
  GetDealTemplatesQuery,
  useGetInvestorsQuery,
  GetInvestorsQuery,
  Investor,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { SendTermsheetWizard } from './termsheet-issuer/SendTermsheetWizard'
import TemplatesTable from './termsheet-builder/TemplatesTable'
import TermsheetBuilder from './termsheet-builder/TermsheetBuilder'
import { InvestorList } from '../../../../types/InvestorList'

function TermsheetWrapper() {
  const location = useLocation()
  const defaultOption = (location.state as any)?.defaultOption
  const { data } = useGetDealTemplatesQuery<GetDealTemplatesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )
  const [isBuilderVisible, setIsBuilderVisible] = useState(
    defaultOption === 'builder' ? true : false
  )
  // const [isWizardVisible, setIsWizardVisible] = useState(defaultOption === 'builder' ? false : true)
  const [isWizardVisible, setIsWizardVisible] = useState(false)
  const [allowedSections, setAllowedSections] = useState([])
  const [templateId, setTemplateId] = useState()
  const wizardRef = useRef<any>(null)
  const executeScroll = () => wizardRef.current?.scrollIntoView({ behavior: 'smooth' })
  const getInvestorsQuery = useGetInvestorsQuery<GetInvestorsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const investorList: InvestorList[] = getInvestorsQuery.data?.investors.map(
    (investor: Investor) => {
      return { investorId: investor.id, investorName: investor.name }
    }
  )

  return (
    <div>
      <div>
        <TemplatesTable
          data={data}
          toggleBuilder={(value: boolean) => setIsBuilderVisible(value)}
          toggleWizard={(value: boolean) => setIsWizardVisible(value)}
          isBuilderVisible={isBuilderVisible}
          setAllowedSections={setAllowedSections}
        />
      </div>
      <div>
        {isBuilderVisible && (
          <TermsheetBuilder
            templateList={data}
            investorList={investorList}
            toggleBuilder={() => setIsBuilderVisible(!isBuilderVisible)}
          />
        )}
      </div>
      <div>
        {isWizardVisible && (
          <SendTermsheetWizard
            allowedSections={allowedSections}
            wizardRef={wizardRef}
            templateId={templateId}
          />
        )}
      </div>
    </div>
  )
}

export default TermsheetWrapper
