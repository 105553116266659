import { useLocation } from 'react-router-dom'
import OpenDealsTable from './components/OpenDealsTable'

const OpenDealsWrapper = () => {
  const location = useLocation()
  let data: any = location.state

  return (
    <div>
      <OpenDealsTable className='mb-4' tab={data ? data.props.tab : 'Draft'} />
    </div>
  )
}

export { OpenDealsWrapper }
