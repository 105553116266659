import { StylesConfig } from 'react-select'
import { getCSSVariableValue } from '../_metronic/assets/ts/_utils'
import { SelectOptionType } from './MultiSelect'

export const selectCustomStyles: StylesConfig<SelectOptionType> = {
  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: getCSSVariableValue('--kt-table-bg'),
      borderColor: getCSSVariableValue('--kt-primary'),
      border: state.isFocused
        ? '1px solid ' + getCSSVariableValue('--kt-primary')
        : '1px solid #cccccc',
      boxShadow: state.isFocused ? '0px 0px 1px ' + getCSSVariableValue('--kt-primary') : 'none',
      '&:hover': {
        color: getCSSVariableValue('--kt-primary'),
        border: '1px solid ' + getCSSVariableValue('--kt-primary'),
        boxShadow: '0px 0px 1px ' + getCSSVariableValue('--kt-primary'),
      },
    }
  },
  menu: (provided, state) => {
    return {
      ...provided,
      backgroundColor: getCSSVariableValue('--kt-table-active-bg'),
    }
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? getCSSVariableValue('--kt-primary-light')
        : isFocused
        ? getCSSVariableValue('--kt-primary')
        : undefined,
      color: isDisabled ? '#ccc' : isFocused ? 'white' : getCSSVariableValue('--kt-gray-heading'),

      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? getCSSVariableValue('--kt-primary')
            : getCSSVariableValue('--kt-primary-light')
          : undefined,
      },
    }
  },
  input: (styles) => {
    return {
      ...styles,
      color: getCSSVariableValue('--kt-body-color'),
    }
  },
}
