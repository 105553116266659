import * as Yup from 'yup'

export type IClassificationData = {
  groupName: string
  investorId: number
}

const classificationSchema = Yup.object().shape({
  groupName: Yup.string()
    .required('Classification name is a required field')
    .min(3, 'Classification name should be more than 3 characters'),
  investorId: Yup.number().required('Investor/ Fund is required'),
})

const classificationInits: IClassificationData = {
  groupName: '',
  investorId: 0,
}

export { classificationInits, classificationSchema }
