import * as Yup from 'yup'

export interface IAddInvestmentData {
  investmentType: 'captableUpload' | 'manual'
  investmentMethod: 'single' | 'bulk'
  country: string
  investor: string
  investorId: number
  companyName: string
  currency: string
  industryType: string
  sharePrice: string
  currentSharePrice: string
  exchangeRate: string
  currentExchangeRate: string
  companyId: string
  investmentDate: string
  noOfShares: string
  valuation: string
  roundDetails: string
  isSuccess: boolean
}

const addInvestmentSchemas = [
  Yup.object({
    investmentType: Yup.string().required().label('Investment Type'),
  }),
  Yup.object({
    investmentMethod: Yup.string().required().label('Investment Method'),
  }),
  Yup.object({
    country: Yup.string().required('Select country').label('Country'),
    investorId: Yup.number().positive().required('Select an investor or fund').min(1, 'Select an investor or fund').label('Investor ID'),
    companyName: Yup.string().required().min(3, 'Company name cannot be less than 3 characters').label('Company name'),
    currency: Yup.string().required('Select currency').label('Currency'),
    industryType: Yup.string().required().min(3, 'Industry type cannot be less than 3 characters').label('Industry type'),
    companyId: Yup.string().optional().min(3, 'Company ID cannot be less than 3 characters').label('Company ID'),
    noOfShares: Yup.number().positive().required().moreThan(0, 'Should be greater than 0').label('Number of shares'),
    sharePrice: Yup.number().positive().moreThan(0, 'Should be greater than 0').required().label('Share price'),
    exchangeRate: Yup.number().positive().required().moreThan(0, 'Should be greater than 0').label('Exchange rate'),
    investmentDate: Yup.string().required('Select investment date').label('Investment Date'),
    currentSharePrice: Yup.number().positive().moreThan(0, 'Should be greater than 0').optional().label('Current share price'),
    currentExchangeRate: Yup.number().positive().moreThan(0, 'Should be greater than 0').optional().label('Current exchange rate'),
    valuation: Yup.number().positive().required().moreThan(0, 'Should be greater than 0').label('Valuation'),
    roundDetails: Yup.string().required().min(1, 'Round name cannot be less than 1 character').label('Round details')
  }),
  Yup.object({
    isSuccess: Yup.bool().equals([true]).required(),
  })
]

const investmentInits: IAddInvestmentData = {
  investmentType: 'manual',
  investmentMethod: 'single',
  country: 'India',
  investor: '',
  investorId: 0,
  companyName: '',
  currency: 'INR',
  industryType: '',
  sharePrice: '',
  currentSharePrice: '',
  exchangeRate: '',
  currentExchangeRate: '',
  companyId: '',
  investmentDate: '',
  noOfShares: '',
  valuation: '',
  roundDetails: '',
  isSuccess: false
}

export { investmentInits, addInvestmentSchemas }
