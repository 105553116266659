import { useState, useRef } from 'react'
import { Card, Stack } from 'react-bootstrap'
import { useQueryClient } from 'react-query'
import {
  useGetWatchlistsQuery,
  useRemoveWatchlistMutation,
  RemoveWatchlistMutation,
  RemoveWatchlistMutationVariables,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import AddCompanyToWatchlist from './AddCompanyToWatchlist'

type EmptyWatchListItemProps = {
  watchlistId: number
  watchlistName: string
  previousWatchlistName: string
  setActiveTab: Function
}

function EmptyWatchlistItem(props: EmptyWatchListItemProps) {
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)
  const queryKey = useGetWatchlistsQuery.getKey()
  const queryClient = useQueryClient()
  const [showModal, setShowModal] = useState(false)

  const { mutate } = useRemoveWatchlistMutation<RemoveWatchlistMutation, Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: RemoveWatchlistMutation,
        _variables: RemoveWatchlistMutationVariables,
        context: unknown
      ) => {
        queryClient.invalidateQueries(queryKey)
        props.setActiveTab(props.previousWatchlistName)
      },
    },
    {}
  )

  function handleRemoveWatchlist() {
    // Disable indicator after 3 seconds
    deleteBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    setTimeout(() => {
      // Activate indicator
      deleteBtnRef.current?.removeAttribute('data-kt-indicator')
    }, 3000)
    mutate({ input: props.watchlistId })
    Toast('Watchlist Deleted Successfully!', ToastType.success)
  }
  return (
    <div className='row justify-content-center  text-center'>
      <Card className=''>
        <Stack className='text-center pt-4'>
          <h1>No companies present in {props.watchlistName}</h1>
          <img
            alt='no items'
            src={toAbsoluteUrl('/media/icons/duotune/general/no-items.svg')}
            className='h-100px my-10'
          />
          <div className='col justify-content-between'>
            <button
              className='btn btn-secondary mt-4 mx-8'
              ref={deleteBtnRef}
              onClick={handleRemoveWatchlist}
              id='kt_button_1'
            >
              <span className='text-gray-600 fw-bold'>Delete Watchlist</span>
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
            <button
              className='btn btn-primary mt-4 mx-8'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              onClick={() => {
                setShowModal(!showModal)
              }}
            >
              Add Company +
            </button>
            <AddCompanyToWatchlist
              show={showModal}
              handleClose={() => {
                setShowModal(false)
              }}
              watchlistId={props.watchlistId}
              watchlistName={props.watchlistName}
              companyList={[]}
            />
          </div>
        </Stack>
      </Card>
    </div>
  )
}

export default EmptyWatchlistItem
