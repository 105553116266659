import {Card} from 'react-bootstrap'
import TabView from './Components/WatchlistTabView'

function WatchlistWrapper() {
  return (
    <div className='row'>
      <div className='col'>
        <Card className='shadow-sm'>
          <Card.Header bsPrefix='flex-fill'>
            <TabView />
          </Card.Header>
        </Card>
      </div>
    </div>
  )
}

export default WatchlistWrapper
