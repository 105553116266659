import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

export enum ToastType {
  success,
  warning,
  error,
}

function Toast(msg: string | Error, toastType: ToastType) {
  toastr.options = {
    positionClass: 'toast-bottom-right',
    hideDuration: 300,
    timeOut: 3000,
  }
  if (toastType === ToastType.success) {
    toastr.success(msg.toString())
  } else if (toastType === ToastType.warning) {
    toastr.warning(msg.toString())
  } else {
    toastr.error(msg.toString())
  }
}

export default Toast
