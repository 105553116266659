import React from 'react'
import { useNavigate } from 'react-router'
import { getInitials } from '../../../../utils/Utils'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'

type Props = {
  event: WatchlistEvents
}

type WatchlistEvents = {
  companyName: string
  companyId: string
  eventType: string
  companyLogo?: string
}

function WatchlistHighlightsItem({ event }: Props) {
  const navigate = useNavigate()

  return (
    <div className='d-flex align-items-center mb-5 justify-content-between'>
      {event.companyLogo && event.companyLogo !== '' && event.companyLogo !== 'NA' ? (
        <div className='symbol symbol-50px'>
          <img src={event.companyLogo!} alt='' className='symbol-label' />
        </div>
      ) : (
        <div className='bg-secondary rounded min-h-50px min-w-50px fw-bold d-flex justify-content-center align-items-center'>
          <p
            className='mb-0 fs-2'
            style={{
              color: getCSSVariableValue('--kt-donut-' + (9 % 10)),
            }}
          >
            {getInitials(event.companyName!)}
          </p>
        </div>
      )}
      <div className='flex-grow-1 ms-4'>
        <p className='fs-7 fw-bold'>
          <span
            className='cursor-pointer text-hover-primary'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate('/company/stakeholders', {
                state: {
                  props: {
                    name: event.companyName,
                    companyId: event?.companyId,
                    hissaVerified: true,
                    initials: getInitials(event.companyName!),
                  },
                },
              })
            }}
          >
            {event.companyName}
          </span>
          {event.eventType === 'MULTIPLE_USERS' && (
            <span className='fw-normal'> is being watched by multiple users!</span>
          )}
          {event.eventType === 'TERMSHEET' && (
            <span className='fw-normal'> has received most of the termsheets in last month!</span>
          )}
          {event.eventType === 'UNICORN' && (
            <span className='fw-normal'> has reached Unicorn status in last month!</span>
          )}
          {event.eventType === 'LATEST_RAISE' && (
            <span className='fw-normal'> raised money in last month!</span>
          )}
        </p>
      </div>
    </div>
  )
}

export default WatchlistHighlightsItem
