import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  GetWatchlistHighlightsQuery,
  useGetWatchlistHighlightsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import WatchlistHighlightsItem from './WatchlistHighlightsItem'
import { GlobalContext } from '../../store/context/globalContext'

type Props = {
  companies?: string[]
}

type WatchlistEvents = {
  companyName: string
  companyId: any
  eventType: string
  companyLogo?: string
}

function WatchlistHighlights(props: Props) {
  const navigate = useNavigate()
  const { data } = useGetWatchlistHighlightsQuery<GetWatchlistHighlightsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )
  const [watchlistHighlights, setWatchlistHighlights] = useState<WatchlistEvents[]>([])
  const { state } = useContext(GlobalContext)
  const { ctxCompanies: companies } = state

  useEffect(() => {
    const events: WatchlistEvents[] = []
    data?.watchlists?.forEach((watchlist) => {
      watchlist?.highlights?.forEach((highlight) => {
        const ccComp = companies?.filter((c) => c.name === highlight?.companyName!)
        if (props.companies !== undefined && props.companies.length > 0) {
          if (
            props.companies!.includes(highlight?.companyName!) &&
            !events.some(
              (e) =>
                e.companyName === highlight?.companyName && e.eventType === highlight?.eventType
            )
          ) {
            events.push({
              companyName: highlight?.companyName!,
              companyId: ccComp![0] && ccComp![0].companyId,
              eventType: highlight?.eventType!,
              companyLogo: ccComp![0] && ccComp![0].logo!,
            })
          }
        } else {
          if (
            !events.some(
              (e) =>
                e.companyName === highlight?.companyName && e.eventType === highlight?.eventType
            )
          ) {
            events.push({
              companyName: highlight?.companyName!,
              companyId: ccComp![0] && ccComp![0].companyId,
              eventType: highlight?.eventType!,
              companyLogo: ccComp![0] && ccComp![0].logo!,
            })
          }
        }
      })
    })

    setWatchlistHighlights(events)
  }, [companies, data, props.companies])

  let highlightList = watchlistHighlights.map((event, index) => {
    if (props.companies !== undefined && props.companies.length > 0) {
      if (props.companies?.includes(event.companyName!))
        return <WatchlistHighlightsItem event={event} key={index} />
    } else {
      return <WatchlistHighlightsItem event={event} key={index} />
    }
  })

  return (
    <div className={`card card-hover mh-350px`} onClick={() => navigate('/watchlist')}>
      <div className='card-header border-0'>
        <h1 className='mt-5'>Watchlist highlights</h1>
      </div>
      <div className='card-body card-scroll pt-2 pb-0 mb-5'>
        <div>{highlightList}</div>
      </div>
    </div>
  )
}

export default WatchlistHighlights
