import { useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import { ISendTermsheet } from '../../SendTermsheetHelper'
import numToWords from '../../../../../../../utils/NumToWords'
import Nouislider from 'nouislider-react'
import { kFormatter, kUnformatterNumber } from '../../../../../../../utils/CurrencyFormatter'

const ValuationDetails = (props: any) => {
  const formik = useFormikContext<ISendTermsheet>()
  const [moneyInWords, setMoneyInWords] = useState<String>(
    formik.values.isPricedRound === 'false'
      ? numToWords(formik.values.floorValuation)
      : numToWords(formik.values.valuation)
  )

  const [valuationInWords, setValuationInWords] = useState<String>(
    numToWords(formik.values.valuation)
  )
  const [valuationFloorInWords, setValuationFloorInWords] = useState<String>(
    numToWords(formik.values.floorValuation)
  )
  const [valuationCapInWords, setValuationCapInWords] = useState<String>(
    numToWords(formik.values.valuationCap)
  )
  const [valuationSliderRef, setValuationSliderRef] = useState<any>(null)
  const [valuationFloorSliderRef, setValuationFloorSliderRef] = useState<any>(null)
  const [valuationCapSliderRef, setValuationCapSliderRef] = useState<any>(null)

  const tooltipFns = {
    to(value: number) {
      return kFormatter(value)
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }

  useEffect(() => {
    setMoneyInWords(numToWords(formik.values.floorValuation))
  }, [formik.values.floorValuation])

  return (
    <div className='w-100'>
      <div className='pb-10'>
        <h2 className='fw-bolder text-dark'>Valuation Details</h2>
      </div>

      {formik.values.isPricedRound === 'false' && (
        <>
          <div className='col fv-md-row'>
            <label className='form-label required '>Floor Valuation</label>
            <div className='col md-row d-md-flex align-items-baseline'>
              <div className='col-12 col-md-7'>
                <Nouislider
                  instanceRef={(instance) => {
                    if (instance && !valuationFloorSliderRef) {
                      setValuationFloorSliderRef(instance)
                    }
                  }}
                  tooltips={[tooltipFns]}
                  start={formik.values.floorValuation}
                  range={{ min: 100, max: 1000000000 }}
                  connect
                  onChange={(e) => {
                    formik.setFieldValue('floorValuation', e.at(0))
                    setValuationFloorInWords(numToWords(Math.round(e.at(0))))
                  }}
                  className=''
                />
              </div>
              <div className='col-12 col-md-5 pt-6 pt-md-0 ps-md-6'>
                <Field
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='floorValuation'
                  onChange={(e: any) => {
                    formik.handleChange(e)
                    const floorValuation = parseInt(e.target.value?.replaceAll(',', '') || 0, 10)
                    formik.setFieldValue('floorValuation', floorValuation)
                    valuationFloorSliderRef?.noUiSlider.set(floorValuation)
                    setValuationFloorInWords(numToWords(Math.round(floorValuation)))
                  }}
                  value={Intl.NumberFormat('en-IN').format(formik.values.floorValuation)}
                />
                <div className='form-text'>{valuationFloorInWords}</div>
              </div>
            </div>
            <div className='text-danger mt-2'>
              <ErrorMessage name='floorValuation' />
            </div>
          </div>
          <div className='col fv-md-row pt-6 pt-md-0'>
            <label className='form-label required '>Valuation Cap</label>

            <div className='col md-row d-md-flex align-items-baseline'>
              <div className='col-12 col-md-7'>
                <Nouislider
                  instanceRef={(instance) => {
                    if (instance && !valuationCapSliderRef) {
                      setValuationCapSliderRef(instance)
                    }
                  }}
                  tooltips={[tooltipFns]}
                  start={formik.values.valuationCap}
                  range={{ min: 100, max: 1000000000 }}
                  connect
                  onChange={(e) => {
                    formik.setFieldValue('valuationCap', e.at(0))
                    setValuationCapInWords(numToWords(Math.round(e.at(0))))
                  }}
                  className=''
                />
              </div>
              <div className='col-12 col-md-5 pt-6 pt-md-0 ps-md-6'>
                <Field
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  name='valuationCap'
                  onChange={(e: any) => {
                    formik.handleChange(e)
                    const valuationCap = parseInt(e.target.value?.replaceAll(',', '') || 0, 10)
                    formik.setFieldValue('valuationCap', valuationCap)
                    valuationCapSliderRef?.noUiSlider.set(valuationCap)
                    setValuationCapInWords(numToWords(Math.round(valuationCap)))
                  }}
                  value={Intl.NumberFormat('en-IN').format(formik.values.valuationCap)}
                />
                <div className='form-text'>{valuationCapInWords}</div>
              </div>
            </div>
            <div className='text-danger mt-2'>
              <ErrorMessage name='valuationCap' />
            </div>
          </div>
          <div className='fv-row mb-6'>
            <label className='form-label required mb-3'>Discount</label>
            <Field
              type='number'
              className='form-control form-control-lg form-control-solid'
              name='discountConvertible'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='discountConvertible' />
            </div>
          </div>
        </>
      )}
      {formik.values.isPricedRound === 'true' && (
        <>
          <div className='col fv-md-row'>
            <label className='form-label required'>Pre-Money Valuation</label>
            <div className='col md-row d-md-flex align-items-baseline'>
              <div className='col-12 col-md-7'>
                <Nouislider
                  instanceRef={(instance) => {
                    if (instance && !valuationSliderRef) {
                      setValuationSliderRef(instance)
                    }
                  }}
                  tooltips={[tooltipFns]}
                  start={formik.values.valuation}
                  range={{ min: 0, max: 1000000000 }}
                  connect
                  onChange={(e) => {
                    formik.setFieldValue('valuation', e.at(0))
                    setValuationInWords(numToWords(Math.round(e.at(0))))
                  }}
                  className=''
                />
              </div>
              <div className='col-12 col-md-5 pt-6 pt-md-0 ps-md-6'>
                <Field
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='valuation'
                  onChange={(e: any) => {
                    formik.handleChange(e)
                    const valuation = parseInt(e.target.value?.replaceAll(',', '') || 0, 10)
                    formik.setFieldValue('valuation', valuation)
                    valuationSliderRef?.noUiSlider.set(valuation)
                    setValuationInWords(numToWords(Math.round(valuation)))
                  }}
                  value={Intl.NumberFormat('en-IN').format(formik.values.valuation)}
                />
                <div className='form-text'>{valuationInWords}</div>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='valuation' />
                </div>
              </div>
            </div>
          </div>

          <div className='row align-md-items-baseline pb-4 pt-6 '>
            <div className='col-6 col-md-4'>
              <label className='form-label required '>Auto Dilute ESOP's</label>
            </div>
            <div className='col-6 col-md-8 '>
              <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                <Field
                  className='form-check-input h-20px w-40px'
                  type='checkbox'
                  name='autoDilute'
                />
              </div>
            </div>
          </div>

          {formik.values.autoDilute.toString() === 'false' && (
            <div className='row align-items-baseline mb-8 pt-6 pt-md-0'>
              <div className='col-6 col-md-6'>
                <label className='form-label required mb-3'>Post Round ESOP %</label>
              </div>

              <div className='col-6 col-md-6 '>
                <Field
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  name='postRoundEsopPercentage'
                />
                <div className='text-danger mt-2'>
                  <>
                    <ErrorMessage name='postRoundEsopPercentage' />
                  </>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ValuationDetails
