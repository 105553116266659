import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { QueryClientProvider } from 'react-query'
import { queryClient } from '../queries/client'
import GlobalContextProvider from './pages/store/context/globalContext'
import { getUserId, getUserPreferences } from '../utils/LocalStorageUtils'
import { amplitudeInit } from '../utils/amplitude'

const App = () => {
  amplitudeInit()
  let userId = getUserId()
  let userPreferencesObj = userId ? getUserPreferences(userId) : undefined
  let ctxGroupIds: number[] = []
  let ctxInvestorIds: number[] = []
  let ctxUserId = 0
  let filterType: 'Fund' | 'Group' | 'All' = 'Fund'
  if (userPreferencesObj && userId) {
    ctxUserId = userId
    ctxGroupIds = userPreferencesObj.selectedGroups
    ctxInvestorIds = userPreferencesObj.selectedInvestors
    filterType =
      userPreferencesObj.filterType === 'Fund'
        ? 'Fund'
        : userPreferencesObj.filterType === 'Group'
        ? 'Group'
        : 'All'
  }

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalContextProvider
        ctxUserId={ctxUserId}
        ctxGroupIds={ctxGroupIds}
        ctxInvestorIds={ctxInvestorIds}
        filterType={filterType}
      >
        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            <LayoutProvider>
              <Outlet />
              <MasterInit />
            </LayoutProvider>
          </I18nProvider>
        </Suspense>
      </GlobalContextProvider>
    </QueryClientProvider>
  )
}

export { App }
