import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { resetPassword } from '../../../../api/authentication'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import jwtDecode from 'jwt-decode'
import { JwtPayload } from '../core/_models'
import { ResetPasswordRequest } from '../../../../types/Login'

const initialValues: ResetPasswordRequest = {
  emailId: '',
  newPassword: '',
  confirmPassword: '',
  jwt: '',
}

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, 'Password length should be atleast 6')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character'
    )
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('newPassword', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        "Password and Confirm Password didn't match"
      ),
    }),
})
export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const token = window.location.href.split('=')
  const jwt = token[1]
  var decode = jwtDecode<JwtPayload>(jwt)

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      values.jwt = jwt
      values.emailId = decode.emailId!
      resetPassword(values)
        .then(({ data: { result } }) => {
          setIsSuccess(true)
          setLoading(false)
        })
        .catch((error: any) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(error?.response?.data?.errors)
        })
    },
  })
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  return (
    <form
      className='form fv-plugins-bootstrap5 fv-plugins-framework mt-0 p-5'
      onSubmit={formik.handleSubmit}
    >
      {!isSuccess && (
        <>
          <h1 className='fw-bold display-7 text-center'>Setup new password</h1>
          <h6 className='text-muted mb-10 text-center'>
            Have you already reset the password?{' '}
            <Link to='/auth/login' className='fw-bold'>
              Sign in
            </Link>
          </h6>
          <div className='pb-5' data-kt-password-meter='true'>
            <label className='form-label fw-bold'>Password</label>
            <input
              type='password'
              placeholder='New password'
              autoComplete='off'
              className='form-control form-control-lg form-control-solid'
              {...formik.getFieldProps('newPassword')}
            />
            <div
              className='d-flex align-items-center mt-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
          </div>
          <div>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container mb-3'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen045.svg'
                      className='svg-icon-primary svg-icon-1 me-2'
                    />
                    {formik.errors.newPassword}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div>
            <label className='form-label fw-bold'>Confirm password</label>
            <input
              type='password'
              placeholder='Confirm new password'
              autoComplete='off'
              className='form-control form-control-lg form-control-solid'
              {...formik.getFieldProps('confirmPassword')}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen044.svg'
                      className='svg-icon-primary svg-icon-1 me-2'
                    />
                    {formik.errors.confirmPassword}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='mt-5'>
            <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                required
              />
              <h6 className='mt-1'>
                I Agree to the{' '}
                <a href='https://www.rulezero.com/terms-of-use' target='_blank' className='fw-bold'>
                  Terms & Conditions
                </a>
              </h6>
            </label>
          </div>
          {formik.status?.includes('TokenExpiredError: jwt expired') ? (
            <></>
          ) : (
            formik.status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen044.svg'
                      className='svg-icon-primary svg-icon-1 me-2'
                    />
                    {formik.status}
                  </span>
                </div>
              </div>
            )
          )}
          <div className='text-center mt-5'>
            <button
              className='btn btn-lg btn-primary mb-5'
              type='submit'
              disabled={
                formik.status ||
                formik.isSubmitting ||
                !formik.isValid ||
                (formik.values.newPassword === '' && formik.values.confirmPassword === '')
              }
            >
              {!loading && <span className='indicator-label fw-bold'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            {formik.status?.includes('TokenExpiredError: jwt expired') ? (
              <div className='fv-plugins-message-container'>
                <div className='text-primary fw-bold mb-5' role='alert'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className='svg-icon-primary svg-icon-1 me-2'
                  />
                  The Password reset link has expired, please click
                  <br /> on button below to send another reset link
                </div>
                <Link to='/auth/forgot-password' className='fw-bold'>
                  {' '}
                  <button className='btn btn-lg btn-primary'>Reset Password</button>
                </Link>
              </div>
            ) : (
              <> </>
            )}
          </div>
        </>
      )}
      {isSuccess && (
        <div className='text-center'>
          <h1 className='fw-bold display-7 mb-4'>Password changed</h1>
          <h6 className='text-muted mb-10'>
            Your password is successfully changed. Please Sign in to your account.
          </h6>
          <Link to='/auth/login' className='fw-bold'>
            {' '}
            <button className='btn btn-lg btn-primary'>Sign In</button>
          </Link>
        </div>
      )}
    </form>
  )
}
