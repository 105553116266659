import LazyPdfDocument from '../../../../../../../utils/LazyPdfDocument'

function Preview({ url }: { url: string }) {
  return (
    <div className='mh-500px min-h-500px overflow-auto scrollable'>
      {url !== '' ? (
        <LazyPdfDocument url={url || ''} />
      ) : (
        <div className='text-center'>
          <h3>Preview not available. Please submit the termsheet.</h3>
        </div>
      )}
    </div>
  )
}

export default Preview
