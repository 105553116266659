import { create } from 'zustand'
export type PortfolioCompaniesTableFilterState = {
  searchText: string
  isVerified: string | undefined
  industry: string | undefined
  dateOfInvestment: Date | undefined
  investorId: string
  dateRange: number
  roundName: string
  minValuation: number
  maxValuation: number
  minLatestRaise: number
  maxLatestRaise: number
  groupId: number
  setInvestorId: (text: string) => void
  setSearchText: (text: string) => void
  setIsVerified: (text: string) => void
  setIndustry: (text: string) => void
  setDateOfInvestment: (text: Date) => void
  setDateRange: (text: number) => void
  setRoundName: (text: string) => void
  setMinValuation: (text: number) => void
  setMaxValuation: (text: number) => void
  setMinLatestRaise: (text: number) => void
  setMaxLatestRaise: (text: number) => void
  setGroupId: (text: number) => void
  reset: () => void
}

const initialState = {
  groupId: 0,
  searchText: '',
  isVerified: undefined,
  industry: undefined,
  dateOfInvestment: undefined,
  investorId: '',
  dateRange: 0,
  roundName: '',
  minValuation: 0,
  maxValuation: 0,
  minLatestRaise: 0,
  maxLatestRaise: 0,
}

const useStore = create<PortfolioCompaniesTableFilterState>((set) => ({
  ...initialState,
  setSearchText: (text) => {
    set((state) => ({ ...state, searchText: text }))
  },
  setInvestorId: (text) => {
    set((state) => ({ ...state, investorId: text }))
  },
  setIsVerified: (text) => {
    set((state) => ({ ...state, isVerified: text }))
  },
  setIndustry: (text) => {
    set((state) => ({ ...state, industry: text }))
  },
  setDateOfInvestment: (text) => {
    set((state) => ({ ...state, dateOfInvestment: text }))
  },
  setDateRange: (text) => {
    set((state) => ({ ...state, dateRange: text }))
  },
  setRoundName: (text) => {
    set((state) => ({ ...state, roundName: text }))
  },
  setMinValuation: (text) => {
    set((state) => ({ ...state, minValuation: text }))
  },
  setMaxValuation: (text) => {
    set((state) => ({ ...state, maxValuation: text }))
  },
  setMinLatestRaise: (text) => {
    set((state) => ({ ...state, minLatestRaise: text }))
  },
  setMaxLatestRaise: (text) => {
    set((state) => ({ ...state, maxLatestRaise: text }))
  },
  setGroupId: (text) => {
    set((state) => ({ ...state, groupId: text }))
  },
  reset: () => {
    set(() => initialState)
  },
}))

export const usePortfolioCompaniesTableStore = useStore
