import { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { kFormatter } from '../../../../utils/CurrencyFormatter'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import { GlobalContext } from '../../store/context/globalContext'
import { Actions, FilterType } from '../../store/context/reducer'
import AddClassificationModal from '../portfolio grouping/AddClassificationModal'
import EditClassification from '../portfolio grouping/EditClassification'

type CustomCardProps = {
  cardTitle: string
  currentValue: number
  cardValueText: string
  totalInvested: number
  gain: number
  irr: number | null
  moic: number
  id: number
  isGroup: boolean
  investorIds: number[]
}

function CustomCard(props: CustomCardProps) {
  const navigate = useNavigate()
  const { dispatch } = useContext(GlobalContext)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  return (
    <div
      className='card card-hover shadow-sm mb-8'
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (props.isGroup) {
          dispatch({ type: Actions.setGroupIds, payload: [Number(props.id)] })
          dispatch({ type: Actions.setFilterType, payload: FilterType.group })
          dispatch({ type: Actions.setInvestorIds, payload: props.investorIds })
        } else {
          dispatch({ type: Actions.setInvestorIds, payload: [Number(props.id)] })
          dispatch({ type: Actions.setFilterType, payload: FilterType.fund })
          dispatch({ type: Actions.setGroupIds, payload: [] })
        }
        navigate('/portfolio')
      }}
    >
      <div className='card-body'>
        <div className='col d-flex justify-content-between'>
          <h1 className='mb-2'>{props.cardTitle}</h1>

          {props.isGroup && (
            <div className=''>
              <button
                className='btn btn-sm btn-icon me-n2 mt-n2'
                type='button'
                data-bs-toggle='tooltip'
                title='Add Companies'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setShowAddModal(true)
                }}
              >
                <KTSVG
                  className='svg-icon-1 text-primary'
                  path='/media/icons/duotune/abstract/abs011.svg'
                />
              </button>
              <AddClassificationModal
                isAddCompany={true}
                groupName={props.cardTitle}
                groupId={props.id!}
                handleClose={() => setShowAddModal(false)}
                showModal={showAddModal}
              />

              <button
                className='btn btn-sm btn-icon me-n2 mt-n2'
                type='button'
                data-bs-toggle='tooltip'
                title='Edit Classification'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setShowEditModal(true)
                }}
              >
                <KTSVG
                  className='svg-icon-1 text-primary'
                  path='/media/icons/duotune/art/art005.svg'
                />
              </button>
              <EditClassification
                showModal={showEditModal}
                handleClose={() => setShowEditModal(false)}
                groupId={props.id!}
                groupName={props.cardTitle}
              />
            </div>
          )}
        </div>
        <div className='row justify-content-between'>
          <div className='col'>
            <h3 className='fw-light'>{props.cardValueText}</h3>
          </div>
          <div className='col d-flex justify-content-end'>
            <h2>
              <span style={{ fontFamily: 'arial' }}>₹ </span>
              {kFormatter(props.currentValue)}
            </h2>
          </div>
        </div>
        <div className='separator separator-dashed pt-2 mb-3'></div>
        <div className='row justify-content-between'>
          <div className='col'>
            <p className='fs-base mb-0 fw-light'>Total Invested</p>
          </div>
          <div className='col d-flex justify-content-end'>
            <p className='fs-4 mb-0'>
              <span style={{ fontFamily: 'arial' }}>₹ </span>
              {kFormatter(props.totalInvested)}
            </p>
          </div>
        </div>
        <div className='separator separator-dashed pt-2 mb-3'></div>
        <div className='row justify-content-between'>
          <div className='col-4'>
            <p className='fs-base mb-0 fw-light'>Gain</p>
          </div>
          <div className='col d-flex justify-content-end'>
            <p className={props.gain > 0 ? 'text-success fs-4 mb-0' : 'text-danger fs-4 mb-0'}>
              <span style={{ fontFamily: 'arial' }}>₹ </span>
              {kFormatter(props.gain)}
            </p>
          </div>
        </div>
        <div className='separator separator-dashed pt-2 mb-3'></div>
        <div className='row justify-content-between'>
          <div className='col-4'>
            <p className='fs-base mb-0 fw-light'>IRR</p>
          </div>
          <div className='col d-flex justify-content-end'>
            <p className={props.irr! > 0 ? 'text-success fs-4 mb-0' : 'text-danger fs-4 mb-0'}>
              {props.irr === null || undefined || props.irr < 0
                ? 'NA %'
                : props.irr?.toFixed(2) + ' %'}
            </p>
          </div>
        </div>
        <div className='separator separator-dashed pt-2 mb-3'></div>
        <div className='row justify-content-between'>
          <div className='col-4'>
            <p className='fs-base mb-0 fw-light'>MOIC</p>
          </div>
          <div className='col d-flex justify-content-end'>
            <p className={props.moic >= 1 ? 'text-success fs-4 mb-0' : 'text-danger fs-4 mb-0'}>
              {props.moic}X
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomCard
