import { useEffect } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import DonutDropdown from './DonutDropdown'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import _ from 'lodash'

ChartJS.register(ArcElement, Tooltip, Legend)
export interface ChartData {
  labels: (string | number)[] | undefined
  datasets: {
    label: string
    data: (string | number | undefined)[]
    backgroundColor: string[]
    borderColor: string[]
  }[]
}

function DonutWithDropdown({
  data,
  options,
  length,
  handleIndustryShow,
  handleInvestmentShow,
  showIndustryDonut,
  showInvestmentDonut,
}: {
  data: any
  options: Object
  length: number | undefined
  handleIndustryShow: Function
  handleInvestmentShow: Function
  showIndustryDonut: boolean
  showInvestmentDonut: boolean
}) {
  const exitedTextList: number[] = []
  let updatedCount = 0
  let invList: any[] = []
  const plugins = [
    {
      id: '0',
      afterDraw(chart: ChartJS) {
        const { width, height, ctx } = chart
        ctx.restore()
        const fontSize = ((height - chart.chartArea.top) / 100).toFixed(2)
        ctx.font = `${fontSize}em poppins`
        ctx.fillStyle = getCSSVariableValue('--kt-headings-color')
        ctx.textBaseline = 'middle'
        let count = 0

        chart.data.datasets.forEach((item: any) => {
          if (item.investors_selected_count !== invList.length) {
            count = item.count || 0
          } else {
            count = updatedCount
          }
        })
        const text2 = count.toLocaleString()
        const textX = Math.round((width - ctx.measureText(text2).width) / 2)
        const textY = chart.chartArea.height / 2 + 10
        ctx.fillText(text2, textX, textY)
        ctx.save()
      },
    },
    {
      id: 'htmlLegend',
      afterUpdate(chart: ChartJS) {
        const items = chart.options.plugins?.legend?.labels?.generateLabels?.(chart)
        const ul = document.createElement('ul')
        items?.map((item, index) => {
          const li = document.createElement('li')
          li.style.alignItems = 'center'
          li.style.cursor = 'pointer'
          li.style.display = 'flex'
          li.style.flexDirection = 'row'
          li.style.paddingBottom = '5px'

          const boxSpan = document.createElement('span')
          boxSpan.style.background = item.fillStyle?.toString()!
          boxSpan.style.borderColor = item.strokeStyle?.toString()!
          boxSpan.style.borderWidth = item.lineWidth + 'px'
          boxSpan.style.borderRadius = '10px'
          boxSpan.style.display = 'inline-block'
          boxSpan.style.height = '15px'
          boxSpan.style.width = '15px'
          boxSpan.style.marginRight = '10px'

          const textContainer = document.createElement('p')
          textContainer.style.color = item.fontColor?.toString()!
          textContainer.style.margin = '0'
          textContainer.style.padding = '0'
          textContainer.style.textDecoration = item.hidden ? 'line-through' : ''
          textContainer.appendChild(document.createTextNode(item.text))

          li.appendChild(boxSpan)
          li.appendChild(textContainer)
          li.onclick = () => {
            const i = (item as any).index
            const exitIndex = exitedTextList.indexOf(i)
            if (exitIndex !== -1) exitedTextList.splice(exitIndex, 1)
            else exitedTextList.push(i)
            const dataset = chart.data.datasets[0].data
            let exitedCount = 0
            exitedTextList.forEach((e) => {
              exitedCount += (dataset[e] as number) || 0
            })

            updatedCount = (chart.data.datasets[0] as any).count - exitedCount
            if (updatedCount < 0) {
              updatedCount = 0
            }
            chart.toggleDataVisibility(index)
            item.hidden = !item.hidden
            const inv_group = _.groupBy((chart.data.datasets[0] as any).investors, (data) => [
              data.id,
            ])
            invList = Object.keys(inv_group)
            chart.update()
          }
          ul.appendChild(li)
        })

        const jsLegend = document.getElementById('chart-stage-legend')
        jsLegend!.innerHTML = ''
        jsLegend!.appendChild(ul)
      },
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [])
  return (
    <div className='card h-100 card-custom'>
      <div className='col d-flex justify-content-between mt-5 mx-5'>
        <h1>Portfolio by Stage</h1>
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary '
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-start'
          data-kt-menu-flip='top-end'
        >
          <KTSVG path='/media/icons/duotune/general/gen053.svg' className='svg-icon-2' />
        </button>
        <DonutDropdown
          handleIndustryChange={handleIndustryShow}
          handleInvestmentChange={handleInvestmentShow}
          showIndustryDonut={showIndustryDonut}
          showInvestmentDonut={showInvestmentDonut}
        />
      </div>
      <div className='card-body min-h-250px mh-250px'>
        <Doughnut options={options} data={data} plugins={plugins} />
      </div>
      <div className='h-150px' id='chart-stage-legend'></div>
    </div>
  )
}

export default DonutWithDropdown
