import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { kFormatter } from '../../../../utils/CurrencyFormatter'
import { graphqlRequestClient } from '../../../../queries/client'
import {
  useGetPortfolioMetricsQuery,
  GetPortfolioMetricsQuery,
} from '../../../../generated/graphql'
import { useNavigate } from 'react-router'
import {
  isDashboardMetricEnabled,
  setDashboardMetricEnabled,
} from '../../../../utils/LocalStorageUtils'

type portMetricsProps = {
  avgRoundSize: number
  index: number
  exits: number
  rounds: number
  leadInvestments: number
  totalInvestments: number
  portfolioCompanies: number
  gain: number
  moic: number
  irr: number | null
  investorIds?: number[]
  companies?: string[]
  handleOptions: any
  activeOptCards: any[]
}
export type Action = {
  name: string
  disabled?: boolean
}

function PortfolioMetrics(props: portMetricsProps) {
  const navigate = useNavigate()

  const input =
    props.companies !== undefined
      ? { investorIds: props.investorIds, companies: props.companies }
      : { investorIds: props.investorIds }

  const { data } = useGetPortfolioMetricsQuery<GetPortfolioMetricsQuery, Error>(
    graphqlRequestClient,
    { input: input },
    {}
  )

  let upRounds = 0
  data?.portfolioMetrics?.forEach((inv) => (upRounds += Number(inv?.count)))
  const cards = [
    {
      title: 'Average Round Size',
      display: true,
      value: kFormatter(props.avgRoundSize),
    },
    {
      title: 'Up Rounds',
      display: true,
      value: upRounds,
    },
    // {
    //   title: 'Exits',
    //   display: true,
    //   value: props.exits,
    // },
    {
      title: 'Rounds per last FY ' + new Date().getFullYear(),
      display: true,
      value: props.rounds,
    },
    {
      title: 'Lead Investments',
      display: true,
      value: props.leadInvestments,
    },
    {
      title: 'Total Investments',
      display: true,
      value: props.totalInvestments,
    },
    {
      title: 'Portfolio companies',
      display: isDashboardMetricEnabled('Portfolio companies'),
      value: props.portfolioCompanies,
    },
    {
      title: 'Gain',
      display: isDashboardMetricEnabled('Gain'),
      value: kFormatter(props.gain),
      color: props.gain > 0 ? 'text-success' : 'text-danger',
    },
    {
      title: 'IRR',
      display: isDashboardMetricEnabled('IRR'),
      value:
        props.irr === null || undefined || props.irr < 0 ? 'NA %' : props.irr?.toFixed(2) + ' %',
      color: props.irr! > 0 ? 'text-success' : 'text-danger',
    },
    {
      title: 'MOIC',
      display: isDashboardMetricEnabled('MOIC'),
      value: props.moic.toFixed(2) + 'X',
      color: props.moic >= 1 ? 'text-success' : 'text-danger',
    },
  ]

  const [activeCards, setActiveCards] = useState(cards)

  function handleAction(cardTitle: any) {
    const cardIndex = activeCards.findIndex((card) => {
      return card.title === cardTitle.option
    })

    const newActiveCards = activeCards.map((card, index) => {
      if (index === cardIndex) {
        const display = card.display
        return { ...card, display: !display }
      }
      return card
    })
    const newActiveOptCards = props.activeOptCards.map((card, index) => {
      if (card.title === cardTitle.option) {
        const display = card.display
        return { ...card, display: !display }
      }
      return card
    })
    props.handleOptions(newActiveOptCards)
    setActiveCards(newActiveCards)
    setDashboardMetricEnabled(cardTitle.option)
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [])
  const options = ['Portfolio companies', 'Gain', 'IRR', 'MOIC']

  useEffect(() => {
    props.activeOptCards.map((card, index) => {
      const cardIndex = cards.findIndex((oldCard) => {
        return card.title === oldCard.title
      })
      cards[cardIndex].display = card.display
      return card
    })
    setActiveCards(cards)
  }, [upRounds, props])

  const allCards = activeCards?.map((card: any) => {
    return (
      <div key={card.title} className='card shadow-sm px-4 py-6 mb-4' hidden={!card.display}>
        <div className='col d-flex justify-content-between'>
          <h2 className='mb-0 fw-light'>{card.title}</h2>
          <h1 className={'mb-0 ' + card.color}>
            {card.title === 'Gain' || card.title === 'Average Round Size' ? (
              <span style={{ fontFamily: 'arial' }}>₹ </span>
            ) : (
              <></>
            )}
            {card.value}
          </h1>
        </div>
      </div>
    )
  })

  return (
    <div className='card card-hover mb-4 min-h-600px mh-600px'>
      <div className='card-header border-0'>
        <h1 className='mt-5'>Portfolio Metrics</h1>
        {
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary mt-4'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-start'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen053.svg' className='svg-icon-2' />
          </button>
        }
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px py-3'
          data-kt-menu='true'
        >
          <div className='menu-item p-0 m-0 px-3 '>
            <p className='menu-content text-muted px-3 fs-base'>Summary</p>
          </div>
          <div className=' mt-n4'>
            {options.map((option) => {
              return (
                <label
                  key={option}
                  className='ms-3 form-check form-check-sm form-check-custom form-check-solid me-5 p-3 fw-normal'
                >
                  <input
                    id={option}
                    className='form-check-input'
                    type='checkbox'
                    checked={isDashboardMetricEnabled(option)}
                    onChange={(e) => handleAction({ option })}
                  />
                  <span className='form-check-label fs-base ms-3 fw-normal'>{option}</span>
                </label>
              )
            })}
          </div>
        </div>
      </div>
      <div className='card-body card-scroll pt-2 mb-4' onClick={() => navigate('/portfolio')}>
        <div className='row'>{allCards}</div>
      </div>
    </div>
  )
}

export default PortfolioMetrics
