import { useMutation } from 'react-query';
import { hissaApi } from './investorApi'

export async function exportExcel(reqExcelBody: any): Promise<any> {
  return hissaApi.post("/JsonToExcel/investor", reqExcelBody).then((res) => res.data);
}

export function useExportToExcel() {
  return useMutation({
    mutationKey: "excelExport",
    mutationFn: exportExcel,
  });
}